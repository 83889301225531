import { Modal, Button } from "react-bootstrap";
import EnquiryModalData from "../modalData/EnquiryModalData";
import ExpireModalData from "../modalData/ExpireModalData";
import FollowUpModalData from "../modalData/FollowUpModalData";
import DueModalAmount from "../modalData/DueModalAmount";
import InvoiceModalData from "../modalData/InvoiceModalData";
import TodayBirthdayModal from "../modalData/TodayBirthdayModal";
import TodayMemberBirthdayModal from "../modalData/TodayMemberBirthdayModal";
import TotalExpenseModal from "../modalData/TotalExpenseModal";
import TodayExpenseModal from "../modalData/TodayExpenseModal";
import TotalMemberModal from "../modalData/TotalMemberModal";
import PurchaseModal from "../modalData/PurchaseModal";
import SellModal from "../modalData/SellModal";
import StockModal from "../modalData/StockModal";
import PaymentSop from "../modalData/PaymentSop";
import ProfitModal from "../modalData/ProfitModal";
import { useIntl } from "react-intl";
import PosExpenseModal from "../modalData/PosExpenseModal";
import PosProfitModal from "../modalData/PosProfitModal";
import MemberProfitModal from "../modalData/MemberProfitModal";

const EnquiryModal = ({
  showModal,
  setShowModal,
  selectedDate,
  cardTitle,
  isFrom,
}: any) => {
  // console.log(cardTitle);
  const intl = useIntl();
  console.log(isFrom, "Expiry all");
  const handleClose = () => setShowModal(false);
  let modalContent, modalTitle;

  switch (cardTitle) {
    case "Enquiry Details":
      modalContent = <EnquiryModalData selectedDate={selectedDate} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.ENQUIRY" });
      break;
    case "Expire Package":
      modalContent = <ExpireModalData selectedDate={selectedDate} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.EXPIRYPACKAGE" });
      break;
    case "All Expire Package":
      modalContent = (
        <ExpireModalData selectedDate={selectedDate} isFrom={isFrom} />
      );
      modalTitle = intl.formatMessage({
        id: "TITLE.DASHBOARD.ALLEXPIREDPACKAGE",
      });
      break;
    case "FollowUps":
      modalContent = <FollowUpModalData selectedDate={selectedDate} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.FOLLOWUPS" });
      break;
    case "Remaing Balance":
      modalContent = <DueModalAmount selectedDate={selectedDate} />;
      modalTitle = intl.formatMessage({
        id: "TITLE.DASHBOARD.REMAININGBALANCE",
      });
      break;
    case "Invoice":
      modalContent = <InvoiceModalData />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.INVOICE" });
      break;
    case "Today Invoice":
      modalContent = <InvoiceModalData selectedDate={selectedDate} />;
      modalTitle = intl.formatMessage({ id: "DASHBOARD.SETTING.INVOICE" });
      break;
    case "Today Employee Birthday":
      modalContent = <TodayBirthdayModal selectedDate={selectedDate} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.EMPBIRTHDAY" });
      break;
    case "Today Member Birthday":
      modalContent = <TodayMemberBirthdayModal selectedDate={selectedDate} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.MEMBIRTHDAY" });
      break;
    case "Total Expense":
      modalContent = <TotalExpenseModal selectedDate={selectedDate} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.TOTALEXPENSE" });
      break;
    case "Today Expense":
      modalContent = <TodayExpenseModal selectedDate={selectedDate} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.TODAYEXPENSE" });
      break;
    case "Active Member":
      modalContent = (
        <TotalMemberModal selectedDate={selectedDate} status={1} />
      );
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.ACTIVEMEMBER" });
      break;
    case "InActive member":
      modalContent = (
        <TotalMemberModal selectedDate={selectedDate} status={0} />
      );
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.INACTIVEMEMBER" });
      break;
    case "Today Purchase":
      modalContent = (
        <PurchaseModal selectedDate={selectedDate} isFrom={isFrom} />
      );
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.TPURCHASE" });
      break;
    case "Total Purchase":
      modalContent = <PurchaseModal />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.PURCHASE" });
      break;
    case "Total Stock Value":
      modalContent = <StockModal />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.STOCKVALUE" });
      break;
    case "Total ClearAmount":
      modalContent = <PurchaseModal />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.CLEARAMOUNT" });
      break;
    case "Today Sell":
      modalContent = <SellModal selectedDate={selectedDate} isFrom={isFrom} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.TSELL" });
      break;
    case "Total Sell":
      modalContent = <SellModal />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.SELL" });
      break;
    case "Total Sell Purchase Value":
      modalContent = <SellModal />;
      modalTitle = intl.formatMessage({
        id: "TITLE.DASHBOARD.SELLPURCHASEVALUE",
      });
      break;
    case "Due Sell Amount":
      modalContent = <SellModal isDue={true} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.SELLDUEAMOUNT" });
      break;
    case "Low Stock":
      modalContent = <StockModal stockType={"low"} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.LOWSTOCK" });
      break;
    case "Today Sell Invoice":
      modalContent = <PaymentSop selectedDate={selectedDate} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.SELLTINVOICE" });
      break;
    case "Total Sell Invoice":
      modalContent = <PaymentSop />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.SELLINVOICE" });
      break;
    case "Total Profit":
      modalContent = <ProfitModal isProfit={isFrom} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.TOTALPROFIT" });
      break;
    case "Total Pos Expense":
      modalContent = <PosExpenseModal />;
      modalTitle = intl.formatMessage({
        id: "TITLE.DASHBOARD.TOTALPOSEXPENSE",
      });
      break;
    case "Today Pos Expense":
      modalContent = <PosExpenseModal selectedDate={selectedDate} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.TPOSEXPENSE" });
      break;
    case "Pos Profit":
      modalContent = <PosProfitModal isProfit={isFrom} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.POSPROFIT" });
      break;
    case "Member Profit":
      modalContent = <MemberProfitModal isProfit={isFrom} />;
      modalTitle = intl.formatMessage({ id: "TITLE.DASHBOARD.MEMBERPROFIT" });
      break;
    default:
      modalContent = null;
  }
  // console.log(selectedDate, "selectedDate");
  return (
    <Modal show={showModal} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalContent}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EnquiryModal;
