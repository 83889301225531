/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { Form, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import swal from "sweetalert";
import { useFormik } from "formik";
import { KTSVG, StatusBadge } from "../../../../_metronic/helpers";
// import Dummy from "../../../../../src/loading_assests/dummy.png";
// import Logo from "../../../../../src/loading_assests/logormbg.png";
import * as Usermembers from "../../MemberShip/redux/MembersRedux";
import {
  GetMembers,
  UpdateStatus,
  ActiveEnquiryList,
  ActiveEmpList,
  ActivePackageList,
  GetGenders,
  GetMaritalStatus,
  ActiveOccupationList,
  ActiveBatchList,
  DeleteMember,
  //   GetMemberDetails,
  batchIDAPII,
  //   activeUserList,
  GetPackageDetails,
} from "../../MemberShip/redux/MembersCRUD";
// import MemberModal from "./modal/MemberModal";
// import AddOnsModel from "./modal/AddOnsModel";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/DateRangePicker";
import { useIntl } from "react-intl";
// import { Search } from "../../../../_metronic/partials";

import Select from "react-select";
import moment from "moment";
// import InvoiceModal from "./modal/InvoiceModal";
// import { enrolmentDueAmount } from "../../../modules/finance/modules/invoice/component/redux/invoiceCRUD";
import Pagination from "../../../modules/GenricComponant/Pagination";
// import PackageListModal from "./modal/PackageListModal";
import { jsPDF } from "jspdf";
import { exportToXLS } from "../../../modules/GenricComponant/exportToxls/exportToxls";
// import Loading from "../../../modules/GenricComponant/loading/Loading";
// import PackageListModal from "../../../modules/members/component/modal/PackageListModal";
import MemberShipModal from "../../MemberShip/componants/modal/MemberShipModal";
import { useHistory, useLocation, useParams } from "react-router-dom";
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: object) => jsPDF;
  }
}

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const MyMembershipModal = () => {
  const intl = useIntl();
  const [sortConfig, setSortConfig] = useState<any>({
    key: " iSpotID", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchUUID = ActiveBranch?.[0]?.iBranchUUID;
  const activeBranchID = ActiveBranch?.[0]?.iBranchID;

  const dispatch = useDispatch();
  const Usermember: any = useSelector(
    (state: any) => state?.Usermembers?.Usermembers?.data
  );
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  const [UpdateUser, setUpdateUser] = useState<any>({});

  const [selectedPackage, setSelectedPackage] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.NAME",
    })}`,
  });
  const [selectConsultant, setSelectedConsultant] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.CONSULTANT",
    })}`,
  });
  const [selectedOccupation, setSelectedOccupation] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.OCCUPATION",
    })}`,
  });
  const [selectedGender, setSelectedGender] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.GENDER",
    })}`,
  });
  const [selectedDate, setSelectedDate] = useState({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.EMPLOYEE.SELECTINITIAL",
    })}`,
  });
  const option1 = {
    label: intl.formatMessage({ id: "MODULE.EMPLOYEE.resign" }),
    value: "resign",
  };
  const option2 = {
    label: intl.formatMessage({ id: "MODULE.EMPLOYEE.join" }),
    value: "join",
  };
  const option3 = {
    label: intl.formatMessage({ id: "MODULE.EMPLOYEE.dob" }),
    value: "DOB",
  };
  const dateOption = [option3, option2, option1];

  const [selectedMarital, setSelectedMarital] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.MARITAL",
    })}`,
  });
  const [batchData, setBatchData] = useState<any>();
  const [batchid, setBatchid] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const [activityStatus, setActivityStatus] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.ACTIVITYSTATUS",
    })}`,
  });
  const [dateFormat, setdateFormat] = useState<any>("YYYY-MM-DD");
  // const initialDates = [new Date(), new Date()];
  const [value, setValue] = React.useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [memberUId]
  const history = useHistory();
  const slug: any = useLocation();
  const { packageType } = slug.state || {};
  console.log(packageType, "heyy");

  const initialDates = moment().format("YYYY-MM-DD");
  const endDate = moment(initialDates).add(10, "days").format("YYYY-MM-DD");
  console.log(initialDates, endDate, "expired");
  let initialState = useMemo(
    () => ({
      branchID: activeBranchID,
      status: "",
      page_limit: 10,
      package_type: packageType || "",
      page_no: 1,
      EnrolType: "",
      employeeID: "",
      occuptionID: "",
      gender: "",
      martitalStatus: "",
      batchID: "",
      packageID: "",
      packageGroupID: "",
      search: "",
      start_date: packageType ? initialDates : "",
      end_date: packageType ? endDate : "",
      order_key: "iEnrolID",
      order_value: "desc",
    }),
    [packageType]
  );
  // const [addModalShow, setAddModalShow] = useState<boolean>(false);
  // const [renewalShow, setRenewalShow] = useState<boolean>(false);
  // const [memberListShow, setMemberListShow] = useState<boolean>(false);
  // const [memberUID, setMemberUID] = useState<any>();
  // const [addFreezeShow, setAddFreezeShow] = useState<boolean>(false);
  // const [invoiceShow, setInvoiceShow] = useState<boolean>(false);
  // const [isMemberShip, setIsMemberShip] = useState<boolean>(false);
  // const [discountAMT, setDiscountAMT] = useState<any>();

  const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);
  //props states
  const [isUpdate, setisUpdate] = useState(false);

  const [activeEnqList, setActiveEnqList] = useState<any>([]);
  const [activeEmpList, setActiveEmpList] = useState<any>([]);
  const [activeOccList, setActiveOccList] = useState<any>([]);
  // const [branch_id, setBranch_id] = useState<any>("0");
  const [activePackageList, setActivePackageList] = useState<any>([]);
  const [gender, setGender] = useState<any>([]);
  const [maritalStatus, setMaritalStatus] = useState<any>([]);
  const [activeBatchList, setActiveBatchList] = useState<any>([]);
  //for check box
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [isAllRowsChecked, setIsAllRowsChecked] = useState<any>(false);
  //const [filteredData, setfilteredData] = useState<any>([]);
  // for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // for error
  const [error, setError] = useState("");
  const [isFilter, setisFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState(initialState);
  // const [searchValue, setSearchValue] = useState<string>();
  // const [status, setStatus] = useState<any>();
  const [filteredData, setFilteredData] = useState<any>([]);
  const [baseURL, setBaseURL] = useState<any>([]);
  const [TBLData, setTBLData] = useState<any>([]);
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  // selected user
  // const [selecteduser, setselecteduser] = useState<any>({});
  //option for status
  const StatusOption = [
    {
      value: 1,
      label: `${intl.formatMessage({
        id: "MODULE.SELECTPACKAGE.ACTIVE",
      })}`,
    },
    {
      value: 0,
      label: `${intl.formatMessage({
        id: "MODULE.SELECTPACKAGE.INACTIVE",
      })}`,
    },
  ];

  // define rows for listing
  // const firstColumnTitle = `${intl.formatMessage({
  //   id: "MODULE.MEMBERS.MEMBERID",
  // })}`;
  const secondColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.ENROLTYPE",
  })}`;
  // const thirdColumnTitle = `${intl.formatMessage({
  //   id: "MODULE.MEMBERS.MOBILE",
  // })}`;
  // const fourthColumnTitle = `${intl.formatMessage({
  //   id: "MODULE.MEMBERS.GENDER",
  // })}`;
  const fifthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.EMPLOYEE",
  })}`;
  const sixthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGE",
  })}`;
  const seventhColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.BATCH",
  })}`;
  const eigthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.STARTDATE",
  })}`;
  const ninthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.ENDDATE",
  })}`;

  const tenthColumnTitle = `${intl.formatMessage({
    id: "COMMON.STATUS",
  })}`;
  const eleventhColumnTitle = `${intl.formatMessage({
    id: "COMMON.ACTIONS",
  })}`;
  // const twelthColumnTitle = `${intl.formatMessage({
  //   id: "COMMON.PHOTO",
  // })}`;
  const packageFee = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGEFEE",
  })}`;
  const DueAmount = `${intl.formatMessage({
    id: "MODULE.MEMBERS.DUOAMT",
  })}`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: async (values, action) => {
      setIsLoading(true);
      setFilterValues(values);
      setPage(1);
      // const response = await GetMembers({
      //   ...values,
      //   page_no: page,
      // });
      // setFilteredData(response?.data?.data);
      // console.log("responsed", response);
      // if (response) {
      //   setIsLoading(false);
      // }
      // ;
      // filterValues = { ...filterValues, values };
      // action.resetForm();
    },
  });
  // console.log("sdsfsdf", members);
  const getMembers = useCallback(() => {
    setIsLoading(true);
    GetMembers({
      ...filterValues,
      page_no: page,
      package_type: packageType,
      page_limit: pageLimit,
      order_key: sortConfig.key,
      order_value: sortConfig.direction,
    }).then((data: any) => {
      dispatch(Usermembers.actions.GetUserMembers(data));
      setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
      setIsLoading(false);
    });
  }, [dispatch, pageLimit, filterValues, page, sortConfig, packageType]);

  /*   useEffect(() => {
    getMembers();
  }, [pageLimit]);
 */
  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    setBaseURL(Usermember?.imageBaseURL);
    setFilteredData(Usermember?.data);
    handleExports();
  }, [Usermember?.data]);

  /*   const getMembers = async () => {
    const response = await GetMembers({
      page_limit: pageLimit,
      order_key: " iSpotID",
      order_value: "desc",
      Search: searchValue,
      status: status,
    });
    console.log("members responseeee", response?.data?.data);
    setfilteredData(response?.data?.data);
  };
 */
  const downloadfileName = `Member_${Date.now()}`;

  const handleExports = () => {
    const tableRows = Usermember?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [secondColumnTitle]: element?.eEnrol_Type,
        [fifthColumnTitle]: element?.employeeName,
        [sixthColumnTitle]: element?.packageName,
        [packageFee]: element?.payableFee,
        [DueAmount]: element?.dueAmt,
        [seventhColumnTitle]: element?.batchName,
        [eigthColumnTitle]: element.dtStartingdate,
        [ninthColumnTitle]: element.dtExpirydate,
        [tenthColumnTitle]:
          element.eStatus === 1
            ? intl.formatMessage({ id: "COMMON.ACTIVE" })
            : intl.formatMessage({ id: "COMMON.INACTIVE" }),
      };
    });

    setTBLData(tableRows);
  };

  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    const titleFontSize = 9;
    const tableFontSize = 6;
    doc.setFontSize(titleFontSize);
    doc.text(intl.formatMessage({ id: "MODULE.MEMBER.MEMBERSHIP" }), 10, 10);
    const tableRows = Usermember.data.map((element: any, index: number) => {
      return [
        index + 1,
        element.eEnrol_Type,
        element.employeeName,
        element.packageName,
        element.payableFee,
        element.dueAmt,
        element.batchName,
        element.dtStartingdate,
        element.dtExpirydate,
        element.eStatus === 1
          ? intl.formatMessage({ id: "COMMON.ACTIVE" })
          : intl.formatMessage({ id: "COMMON.INACTIVE" }),
      ];
    });
    doc.autoTable({
      head: [
        [
          "#",
          secondColumnTitle,
          fifthColumnTitle,
          sixthColumnTitle,
          packageFee,
          DueAmount,
          seventhColumnTitle,
          eigthColumnTitle,
          ninthColumnTitle,
          tenthColumnTitle,
        ],
      ],
      body: tableRows,
      startY: 50,
      styles: {
        fontSize: tableFontSize,
      },
      columnStyles: {
        0: { columnWidth: 20 },
      },
    });

    doc.save(downloadfileName + "pdf");
  };

  useEffect(() => {
    getActiveEnquiryList(ActiveBranchUUID);
    getActiveEmpList(ActiveBranchUUID);
    getActiveOccupationList(ActiveBranchUUID);
    getActivePackageList(ActiveBranchUUID);
    getActiveBatchList(ActiveBranchUUID);
    getGenders();
    getMaritalStatus();
  }, []);

  //start get active enquiry list
  const getActiveEnquiryList = async (branchUUID: any) => {
    const response = await ActiveEnquiryList(branchUUID);
    setActiveEnqList(response?.data?.data);
  };
  //end get active enquiry list

  //start get active member list
  const getActiveEmpList = async (branchUUID: any) => {
    const response = await ActiveEmpList(branchUUID);
    setActiveEmpList(response?.data?.data);
  };
  //end get active member list

  //start get active occupation list
  const getActiveOccupationList = async (branchUUID: any) => {
    const response = await ActiveOccupationList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActiveOccList(response?.data?.data);
  };
  //end get active occupation list

  //start get active batch list
  const getActiveBatchList = async (branchUUID: any) => {
    const response = await ActiveBatchList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActiveBatchList(response?.data?.data);
    setBatchData(response?.data?.data);
  };
  //end get active batch list

  //start get active package list
  const getActivePackageList = async (branchUUID: any) => {
    const response = await ActivePackageList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActivePackageList(response?.data?.data);
  };
  //end get active package list

  //start get active gender list
  const getGenders = async () => {
    const response = await GetGenders();
    setGender(response?.data?.data);
  };
  //end get active gender list

  //start get active marital list
  const getMaritalStatus = async () => {
    const response = await GetMaritalStatus();
    setMaritalStatus(response?.data?.data);
  };
  //end get active marital list

  const UpdateUserStatus = (prop: any) => {
    swal({
      title: `${intl.formatMessage({ id: "COMMON.AREYOUSURE" })}`,
      text: intl.formatMessage({ id: "COMMON.NOTPERFORMOPERATION" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SUBMIT" }),
      ], //["Cancel", "Submit"],
      dangerMode: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        const Update_user = {
          branchID: activeBranchID,
          uuid_array: checkedRows,
          select_type: "",
          unchecked_uuid_array: [],
          status: prop.status,
        };
        if (prop.status !== "2") {
          UpdateStatus(Update_user)
            .then((data: any) => {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });

              getMembers();
            })
            .catch(() => {
              // swal("Somthing Went Wrong!");
              swal(
                `${intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" })}`
              );
            });
        } else {
          DeleteMember(Update_user)
            .then((data: any) => {
              //swal(`Proof! Selected Users Is ${prop.message}!`, {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });
              setCheckedRows([]);
              //getting all roled again
              getMembers();
            })
            .catch(() => {
              swal(intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" }));
            });
        }
      } else {
        swal(`${intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" })}`);
      }
    });
  };

  //define page limit
  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));

    setPage(1);
  };
  //handle check box
  const handleRowCheckboxChange = (rowId: any) => {
    if (checkedRows.includes(rowId)) {
      setCheckedRows(checkedRows.filter((id: any) => id !== rowId));
    } else {
      setCheckedRows([...checkedRows, rowId]);
    }
  };
  // handle all check box
  const handleSelectAllCheckboxChange = (Event: any) => {
    if (isAllRowsChecked) {
      setCheckedRows([]);
      setIsAllRowsChecked(false);
    } else {
      const allRowIds = filteredData?.map((element: any) => element?.iSpotUUID);
      setCheckedRows(allRowIds);
      setIsAllRowsChecked(true);
    }
  };

  const handleReset = () => {
    formik.resetForm();
    handleResetFilter();
    setFilterValues(initialState);
    // getMembers();
  };

  const handleRefreshButton = () => {
    setFilterValues(initialState);
    // setIsLoading(true);
    // getMembers();
    setCheckedRows([]);
    setPageLimit(pageDefaultValue);
    setSortConfig({ key: "iSpotID", direction: "desc" });
    // setSearchValue("");
    // setStatus("");
    if (packageType) {
      history.push("/usermember/membership/list");
    }
  };

  const handleResetFilter = () => {
    setSelectedPackage({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.NAME" })}`,
    });
    setBatchid({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.NAME" })}`,
    });
    setSelectedConsultant({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.CONSULTANT" })}`,
    });
    setSelectedGender({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.GENDER" })}`,
    });
    setSelectedMarital({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.MARITAL" })}`,
    });
    setActivityStatus({
      value: "",
      label: `${intl.formatMessage({
        id: "MODULE.SELECTPACKAGE.ACTIVITYSTATUS",
      })}`,
    });
    setValue(null);
    setSearchText("");
  };

  const handlePackageChange = async (Event: any) => {
    const [iProgramUUID, iProgramID] = Event.value?.split(" ");
    formik.setFieldValue("packageID", iProgramID);

    setSelectedPackage({ value: Event.value, label: Event.label });

    const result = activePackageList?.find((ele: any) => {
      return ele.iProgramUUID === iProgramUUID;
    });
    // console.log(result, "dddddddd");
    const { iProgramgrpID } = result;
    formik.setFieldValue("packageGroupID", iProgramgrpID);
    if (Event.value) {
      const response = await batchIDAPII(iProgramUUID);
      setBatchData(response?.data?.data);
    }
    setBatchid({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.BATCH" })}`,
    });
  };
  const handleEmpChange = (Event: any) => {
    formik.setFieldValue("employeeID", Event.value);
    setSelectedConsultant({
      value: Event.value,
      label: Event.label,
    });
  };
  const handleDateChoose = (Event: any) => {
    formik.setFieldValue("date_type", Event.value);
    setSelectedDate({
      value: Event.value,
      label: Event.label,
    });
  };
  const handleDateReset = (values: any) => {
    setValue(null);
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
  };
  const handleChange = (values: any) => {
    setValue(values);
    // console.log(values,)
    if (values && values.length !== 0) {
      formik.setFieldValue("start_date", moment(values[0]).format(dateFormat));
      formik.setFieldValue("end_date", moment(values[1]).format(dateFormat));
    }
  };

  const handleGenderChange = (Event: any) => {
    formik.setFieldValue("gender", Event.value);
    setSelectedGender({
      value: Event.value,
      label: Event.label,
    });
  };
  const handleMaritalStatus = (Event: any) => {
    formik.setFieldValue("maritalStatus", Event.value);
    setSelectedMarital({
      value: Event.value,
      label: Event.value,
    });
  };
  const handleStatusChange = (Event: any) => {
    formik.setFieldValue("status", Event.value);

    setActivityStatus({
      value: Event.value,
      label: Event.label,
    });
  };
  const handlesearchChange = (Event: any) => {
    formik.setFieldValue("search", Event.target.value);
    setSearchText(Event.target.value);
  };
  const handleBatchID = (Event: any) => {
    formik.setFieldValue("batchID", Event.value);
    setBatchid({
      value: Event.value,
      label: Event.label,
    });
  };
  const handleOccupationChange = (Event: any) => {
    formik.setFieldValue("occupationID", Event.value);
    setSelectedOccupation({
      value: Event.value,
      label: Event.label,
    });
  };

  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };
  // const formattedValue =
  //   value && value.length === 2
  //     ? `${moment(value[0]).format("MM/DD/YYYY")} - ${moment(value[1]).format(
  //         "MM/DD/YYYY"
  //       )}`
  //     : "";

  const getmemberShip = async (spotUUID: any) => {
    const response = await GetPackageDetails(spotUUID);

    setUpdateUser(response?.data?.data[0]);

    // setInvoiceShow(false);
  };

  return (
    <>
      {/* Filter Section Start */}
      {isFilter && (
        <div className="card my-4">
          <div className="card-header">
            {" "}
            <Card className="w-100">
              <Card.Body className="py-5">
                <Form onSubmit={formik.handleSubmit}>
                  {/* <Col md={4} className="mb-4">
                      <Form.Group controlId="search">
                        <Form.Label>Search by Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder="Enter name"
                          onChange={(e) => setSearchValue(e.target.value)}
                          value={searchValue}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={3} className="mb-4">
                      <Form.Group controlId="status">
                        <Form.Label>
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}
                        >
                          <option value="">
                            {intl.formatMessage({ id: "COMMON.ALL" })}
                          </option>
                          <option value="1">
                            {" "}
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {" "}
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col> */}
                  <Row>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="packageUUID" className="form-label">
                          {intl.formatMessage({ id: "MODULE.MEMBERS.PACKAGE" })}
                        </label>
                        <Select
                          id="packageUUID"
                          name="packageUUID"
                          className="basic-single"
                          value={selectedPackage}
                          options={activePackageList.map((item: any) => ({
                            value: item.iProgramUUID + " " + item.iProgramID,
                            label: item.vProgram,
                          }))}
                          onChange={(e) => handlePackageChange(e)}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="batch" className="form-label">
                          {intl.formatMessage({ id: "MODULE.MEMBERS.BATCH" })}
                        </label>
                        <Select
                          className=" basic-single"
                          id="batchID"
                          name="batchID"
                          value={batchid}
                          options={batchData?.map((item: any) => ({
                            value: item.iBatchID,
                            label: item.batchName,
                          }))}
                          onChange={(e) => handleBatchID(e)}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="employeeID" className="form-label">
                          {intl.formatMessage({
                            id: "MDOULE.MEMBERS.EMPLOYEE",
                          })}
                        </label>
                        <Select
                          className=" basic-single"
                          id="employeeID"
                          name="employeeID"
                          value={selectConsultant}
                          options={activeEmpList.map((item: any) => ({
                            value: item.iEmployeeID,
                            label:
                              item.vEFirstname +
                              `(${item?.vEmpCountryCode}-${item.vEPhoneno})`,
                          }))}
                          onChange={(e) => handleEmpChange(e)}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-3">
                      <label htmlFor="date_type" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.EMPLOYEE.DATEOPTION",
                        })}
                      </label>
                      <Select
                        id="date_type"
                        name="date_type"
                        className="basic-single"
                        value={selectedDate}
                        options={dateOption.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                        onChange={(e) => handleDateChoose(e)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.DATERANGE",
                          })}
                        </label>

                        <DateRangePicker
                          value={value}
                          onChange={handleChange}
                          format="MM/dd/yyyy"
                          placeholder="Select Date Range"
                          placement="right"
                          style={{ margin: "2px" }}
                          onClean={handleDateReset}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({ id: "COMMON.GENDER" })}
                        </label>
                        <Select
                          id="gender"
                          name="gender"
                          className="basic-single"
                          value={selectedGender}
                          options={gender.map((item: any) => ({
                            value: item.key,
                            label: item.value,
                          }))}
                          onChange={(e) => handleGenderChange(e)}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="martialStatus" className="form-label">
                          {intl.formatMessage({ id: "COMMON.MARITALSTATUS" })}
                        </label>

                        <Select
                          id="maritalStatus"
                          name="maritalStatus"
                          value={selectedMarital}
                          options={maritalStatus.map((item: any) => ({
                            value: item?.key,
                            label: item?.value,
                          }))}
                          onChange={(e) => handleMaritalStatus(e)}
                        />
                      </div>
                    </Col>

                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </label>
                        <Select
                          id="status"
                          name="status"
                          className="basic-single"
                          onChange={(e) => handleStatusChange(e)}
                          // onBlur={formik.handleBlur("status")}
                          value={activityStatus}
                          options={StatusOption.map((item: any) => ({
                            value: item?.value,
                            label: item?.label,
                          }))}
                        />
                        {/* <option>select status</option>
                          <option value="1">
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </select> */}
                      </div>
                    </Col>
                  </Row>

                  {/* Rest of the form fields */}
                  <Row>
                    <Col md={3} className="mb-5 ">
                      {/* <div className="col-md-5"> */}
                      <label htmlFor="department_name" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.OCCUPATION",
                        })}
                      </label>
                      <Select
                        className=" basic-single "
                        id="occupationID"
                        name="occupationID"
                        value={selectedOccupation}
                        options={activeOccList.map((item: any) => ({
                          value: item?.iOccupationID,
                          label: item?.occupationName,
                        }))}
                        onChange={(e) => handleOccupationChange(e)}
                      />
                      {/* </div> */}
                    </Col>
                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="search" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.SEARCHLABEL",
                          })}
                        </label>
                        <input
                          className=" form-control"
                          id="search"
                          name="search"
                          placeholder={intl.formatMessage({
                            id: "MODULE.EXPIREPACKAGE.SEARCHLABEL",
                          })}
                          value={searchText}
                          onChange={(e) => handlesearchChange(e)}
                        />
                      </div>
                    </Col>

                    <Col
                      md={9}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <Button
                        type="submit"
                        variant="primary"
                        className="me-3 mr-4 w-25"
                        // onClick={(e) => handleSearchClick(e)}
                        // disabled={isLoading}
                      >
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleReset}
                        className="me-3 w-25"
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      {/* Filtsser Section End */}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        {/* begin::Header */}
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div
                className="d-flex  flex-wrap gap-1"
                data-kt-user-table-toolbar="base"
              >
                {checkedRows?.length > 0 ? (
                  <>
                    <div className="fw-bold me-5">
                      <span className="me-2"> {checkedRows?.length}</span>
                      {intl.formatMessage({ id: "COMMON.SELECTED" })}
                    </div>

                    <>
                      <button
                        type="button"
                        className="btn btn-success me-3"
                        onClick={() =>
                          UpdateUserStatus({
                            status: "1",
                            message: "Activated",
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className="btn btn-light-warning me-3"
                        onClick={() =>
                          UpdateUserStatus({
                            status: "0",
                            message: " Inactivated",
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger me-3"
                        onClick={() =>
                          UpdateUserStatus({
                            status: "2",
                            message: "deleted",
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.DELETE" })}
                      </button>
                    </>
                  </>
                ) : (
                  <>
                    <span
                      className="min-w-40px mb-3 d-flex  align-items-center"
                      style={{ color: "#7e8299", fontSize: "14px" }}
                    >
                      {intl.formatMessage({ id: "COMMON.SHOW" })}
                    </span>
                    <div>
                      <select
                        className="form-control form-select form-select-solid me-3 mb-4"
                        value={pageLimit}
                        onChange={(e) => getPageLimit(e.target.value)}
                      >
                        {finalPageLimitArray?.map((item: any, i = 0) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex  flex-wrap gap-1"
              data-kt-user-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-primary me-3"
                onClick={handleRefreshButton}
              >
                {intl.formatMessage({ id: "COMMON.REFRESH" })}
              </button>

              {checkedRows?.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="btn btn-light-primary me-3"
                    >
                      <KTSVG
                        path="/media/icons/duotune/files/fil021.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                      {intl.formatMessage({ id: "MODULE.BRANCH.EXPORT" })}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4">
                      <CSVLink
                        data={TBLData || []}
                        filename="branches.csv"
                        className="menu-item px-3"
                      >
                        <span className="px-3">
                          {intl.formatMessage({ id: "COMMON.EXPORTCSV" })}
                        </span>
                      </CSVLink>
                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={() => exportToXLS(TBLData, downloadfileName)}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTEXCEL" })}
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={exportToPDF}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTPDF" })}
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* This is workable button of adding */}
                </>
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* modal start */}
        {/* modal start */}

        <MemberShipModal
          show={show}
          setShow={setShow}
          getMembers={getMembers}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          activeEnqList={activeEnqList}
          activeEmpList={activeEmpList}
          activeOccList={activeOccList}
          activePackageList={activePackageList}
          gender={gender}
          maritalStatus={maritalStatus}
          activeBatchList={activeBatchList}
          UpdatUser={UpdateUser}
          error={error}
          setError={setError}
          baseURL={baseURL}
        />

        {/*
        <AddOnsModel
          addModalShow={addModalShow}
          setAddModalShow={setAddModalShow}
          memberUID={memberUID}
          activePackageList={activePackageList}
          activeBatchList={activeBatchList}
          setMemberUID={setMemberUID}
        />
        <RenewalModal
          renewalShow={renewalShow}
          setRenewalShow={setRenewalShow}
          memberUID={memberUID}
          activePackageList={activePackageList}
          activeBatchList={activeBatchList}
        />

        <FreezeModal
          addFreezeShow={addFreezeShow}
          setAddFreezeShow={setAddFreezeShow}
          memberUID={memberUID}
          activePackageList={activePackageList}
          activeBatchList={activeBatchList}
          isMemberShip={isMemberShip}
          setIsMemberShip={setIsMemberShip}
        />

        <InvoiceModal
          show={invoiceShow}
          setShow={setInvoiceShow}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          isUpdate={isUpdate}
          activeEmpList={activeEmpList}
          discountAMT={discountAMT}
          getMembers={getMembers}
        />*/}
        {/* <PackageListModal
          memberListShow={show}
          setMemberListShow={setShow}
          memberUID={memberUID}
          setMemberUID={setMemberUID}
        /> */}
        {/* {console.log("freezee", addFreezeShow)} */}
        {/* <Main show={show} setShow={setShow}/> */}
        {/* modal End */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* {isLoading && <Loading />} */}
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted text-capitalize fs-6">
                  {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="iEnrolID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {firstColumnTitle}
                      {sortConfig.key !== "iEnrolID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "iEnrolID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "iEnrolID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="eEnrol_Type"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {secondColumnTitle}
                      {sortConfig.key !== "eEnrol_Type" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "eEnrol_Type" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "eEnrol_Type" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  {/* <th className="min-w-100px notVisibleInMobile">
                    {twelthColumnTitle}
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="mobileNo"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumnTitle}
                      {sortConfig.key !== "mobileNo" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "mobileNo" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "mobileNo" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="eGender"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fourthColumnTitle}
                      {sortConfig.key !== "eGender" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "eGender" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "eGender" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="employeeName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fifthColumnTitle}
                      {sortConfig.key !== "employeeName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "employeeName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "employeeName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="packageName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumnTitle}
                      {sortConfig.key !== "packageName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="payableFee"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {packageFee}
                      {sortConfig.key !== "payableFee" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "payableFee" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "payableFee" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dueAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {DueAmount}
                      {sortConfig.key !== "dueAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dueAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dueAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="batchName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumnTitle}
                      {sortConfig.key !== "batchName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "batchName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "batchName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtStartingdate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eigthColumnTitle}
                      {sortConfig.key !== "dtStartingdate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtStartingdate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtStartingdate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtExpirydate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumnTitle}
                      {sortConfig.key !== "dtExpirydate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtExpirydate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtExpirydate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="eStatus"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {tenthColumnTitle}
                      {sortConfig.key !== "eStatus" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "eStatus" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "eStatus" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  {/* <th className="min-w-100px notVisibleInMobile">
                    {eleventhColumnTitle}
                  </th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredData && filteredData?.length === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((element: any, index: number) => {
                    const isChecked = checkedRows.includes(element.iSpotUUID);
                    // console.log("uidofmember", element);
                    return (
                      <tr key={index} className="fs-6">
                        <td data-label={secondColumnTitle}>
                          {element.eEnrol_Type}
                        </td>
                        {/* <td data-label={firstColumnTitle}>
                            {element.iEnrolID}
                          </td> */}
                        {/* <td data-label={firstColumnTitle}>
                            <div className="d-flex align-items-center border-0">
                              <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                <a href="../../demo1/dist/apps/user-management/users/view.html">
                                  <div className="symbol-label">
                                    <img
                                      src={
                                        element.vPhoto
                                          ? `${baseURL}${element.vPhoto}`
                                          : Dummy
                                      }
                                      width={50}
                                      height={50}
                                      className="w-100"
                                    />
                                  </div>
                                </a>
                              </div>

                              <div className="d-flex flex-column">
                                {element.name}

                                <span>
                                  <a
                                    href={`tel:${
                                      element.countryCode + element.mobileNo
                                    }`}
                                  >
                                    {element.countryCode}-{element.mobileNo}
                                  </a>
                                </span>
                              </div>
                            </div>
                          </td> */}
                        {/*
                          <td data-label={twelthColumnTitle}>
                            {element.vPhoto && (
                              <img
                                src={`${baseURL}${element.vPhoto}`}
                                width={50}
                                height={50}
                              />
                            )}
                          </td>
                          <td data-label={thirdColumnTitle}>
                            <a
                              href={`tel:${
                                element.countryCode + element.mobileNo
                              }`}
                            >
                              {element.countryCode}-{element.mobileNo}
                            </a>
                          </td> */}
                        {/* <td data-label={fourthColumnTitle}>
                            {element.eGender}
                          </td> */}
                        <td data-label={fifthColumnTitle}>
                          {element.employeeName}
                        </td>
                        <td data-label={sixthColumnTitle}>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              setShow(true);
                              // setMemberUID(element.iEnrolUUID);
                              getmemberShip(element.iEnrolUUID);
                              setisUpdate(true);
                            }}
                            title="click for package"
                          >
                            {element.packageName}
                          </a>
                        </td>
                        <td data-label={packageFee}>{element.payableFee}</td>
                        <td data-label={DueAmount}>{element.dueAmt}</td>
                        <td data-label={seventhColumnTitle}>
                          {element.batchName}
                        </td>

                        <td data-label={eigthColumnTitle}>
                          {moment(element.dtStartingdate).format(dateFormat)}
                        </td>
                        <td data-label={ninthColumnTitle}>
                          {moment(element.dtExpirydate).format(dateFormat)}
                        </td>
                        <td data-label={tenthColumnTitle}>
                          {StatusBadge(element.eStatus)}
                        </td>

                        <td data-label={eleventhColumnTitle}>
                          {/* <div className="d-flex flex-shrink-0 floatRight">
                              <a
                                href="#g"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-1"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="AddOns"
                                onClick={() => {
                                  setAddModalShow(true);
                                  setMemberUID(element.iSpotUUID);
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/general/gen035.svg"
                                  className="svg-icon-1"
                                />
                              </a>
                              <a
                                href="#g"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-1"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Renewal"
                                onClick={() => {
                                  setRenewalShow(true);
                                  setMemberUID(element.iSpotUUID);
                                }}
                              >
                                <i className="fa-solid fa-arrows-rotate fs-5"></i>
                              </a>
                              <a
                                href="#g"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-1"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="FreezePackage"
                                onClick={() => {
                                  setIsMemberShip(true);
                                  setAddFreezeShow(true);
                                  setMemberUID(element.iSpotUUID);
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/general/gen051.svg"
                                  className="svg-icon-1"
                                />
                              </a>
                              {element?.dueAmt > 0 && (
                                <a
                                  href="#g"
                                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-1"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="invoice"
                                  onClick={() => {
                                    getMemberDetails(
                                      activeBranchID,
                                      element.iSpotUUID
                                    );
                                    getDUEAMT(
                                      activeBranchID,
                                      element.iEnrolUUID
                                    );
                                  }}
                                >
                                  {ActiveBranch?.currencySymbol}
                                </a>
                              )}

                              <span
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                onClick={() => {
                                  setMemberListShow(true);
                                  setMemberUID(element.iSpotUUID);
                                }}
                              >
                                <i className="bi bi-eye"></i>
                              </span>

                              <a
                                href="#g"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                onClick={() => {
                                  setisUpdate(true);
                                  getMemberDetails(
                                    activeBranchID,
                                    element.iSpotUUID
                                  );
                                  setShow(true);
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/art/art005.svg"
                                  className="svg-icon-3"
                                />
                              </a>
                            </div> */}
                        </td>
                      </tr>
                    );
                  })
                )}
                <tr className="fw-bolder text-muted text-capitalize fs-6">
                  {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="biometric_member_id"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {firstColumnTitle}
                      {sortConfig.key !== "biometric_member_id" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "biometric_member_id" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "biometric_member_id" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="eEnrol_Type"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {secondColumnTitle}
                      {sortConfig.key !== "eEnrol_Type" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "eEnrol_Type" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "eEnrol_Type" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  {/* <th className="min-w-100px notVisibleInMobile">
                    {twelthColumnTitle}
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="mobileNo"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumnTitle}
                      {sortConfig.key !== "mobileNo" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "mobileNo" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "mobileNo" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="eGender"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fourthColumnTitle}
                      {sortConfig.key !== "eGender" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "eGender" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "eGender" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="employeeName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fifthColumnTitle}
                      {sortConfig.key !== "employeeName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "employeeName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "employeeName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="packageName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumnTitle}
                      {sortConfig.key !== "packageName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="payableFee"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {packageFee}
                      {sortConfig.key !== "payableFee" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "payableFee" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "payableFee" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dueAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {DueAmount}
                      {sortConfig.key !== "dueAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dueAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dueAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="batchName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumnTitle}
                      {sortConfig.key !== "batchName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "batchName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "batchName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtStartingdate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eigthColumnTitle}
                      {sortConfig.key !== "dtStartingdate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtStartingdate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtStartingdate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtExpirydate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumnTitle}
                      {sortConfig.key !== "dtExpirydate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtExpirydate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtExpirydate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="eStatus"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {tenthColumnTitle}
                      {sortConfig.key !== "eStatus" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "eStatus" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "eStatus" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  {/* <th className="min-w-100px notVisibleInMobile">
                    {eleventhColumnTitle}
                  </th> */}
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {/* begin:: pagination  */}
          {/* <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination">
                  <li className="page-item previous">
                    <a
                      className={`page-link page-text me-5 paginate_button page-item previous ${
                        page === 1 ? "disable" : ""
                      } `}
                      style={{ cursor: "pointer" }}
                      onClick={() => selectPageHandler(page - 1)}
                    >
                      Previous
                    </a>
                  </li>
                  {[...Array(totalPages)].map((_, i) => {
                    return (
                      <li
                        className={
                          page === i + 1 ? "page-item active" : "page-item"
                        }
                        onClick={() => selectPageHandler(i + 1)}
                        key={i}
                      >
                        <a className="page-link" style={{ cursor: "pointer" }}>
                          {i + 1}
                        </a>
                      </li>
                    );
                  })}

                  <li
                    className={`paginate_button page-item next ${
                      page === totalPages ? "disable" : ""
                    } `}
                  >
                    <a
                      className="page-link page-text"
                      style={{ cursor: "pointer" }}
                      onClick={() => selectPageHandler(page + 1)}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
          {/* end:: pagination */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  );
};

export default MyMembershipModal;
