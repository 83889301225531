import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
// import ExpenseCategoryModal from "../../expenceCategory/component/modal/ExpenseCategoryModal";
import * as Supplyments from "../../../modules/newPos/modules/sale_Product/Redux/SellProductREDUX";
// import Logo from "../../../../../../../src/loading_assests/logormbg.png";
import swal from "sweetalert";
import { useIntl } from "react-intl";
import { Form, Row, Col, Card, Button, Dropdown } from "react-bootstrap";

import { CSVLink } from "react-csv";
// import PackagePeriodModal from "./modal/ExpenseCategoryModal";
import { KTSVG } from "../../../../_metronic/helpers";
import {
  DeleteSellProduct,
  GetActiveMember,
  GetActiveProduct,
  ActiveCustomerList,
  GetSellProductList,
  detailofSellProduct,
  detailsOfBillGenerate,
} from "../../../modules/newPos/modules/sale_Product/Redux/SellProductCRUD";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
// import ExpenseModal from "./modal/SupplementModal";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/esm/DateRangePicker";
import moment from "moment";
// import SellProductModal from "./modal/SellProductModal";
// import InvoicePage from "./InvoicePage";
import Pagination from "../../../modules/GenricComponant/Pagination";
import { exportToXLS } from "../../../modules/GenricComponant/exportToxls/exportToxls";
import permission from "../../../modules/GenricComponant/permissions/permission";
// import Loading from "../../../../Genric componant/loading/Loading";
import { ActivePaymentMode } from "../../../modules/finance/modules/invoice/component/redux/invoiceCRUD";
// import PaymentModal from "./modal/PaymentModal";
// import InvoiceModal from "./modal/InvoiceModal";
import SaleDetailsModal from "../../../modules/newPos/modules/sale_Product/component/modal/DetailsModal";
// import { ActiveEmpList } from "../../../../members/redux/MembersCRUD";
import BillPage from "../../../modules/newPos/modules/sale_Product/component/modal/BillPage";
import { ActiveEmpList } from "../../../modules/members/redux/MembersCRUD";
import { dueAmountPosNotifications } from "../redux/dashboardCRUD";
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: object) => jsPDF;
  }
}

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const SellModal = ({ selectedDate, isDue }: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<any>({
    key: " iProductSellDetailID", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  const Supplyment = useSelector(
    (state: any) => state?.Supplyments?.Supplyments?.data
  );

  // active branch data taken from local storage
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchID = ActiveBranch.iBranchID;
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;
  const CurrentCurrency = ActiveBranch?.currencyCode;
  const currencySymbol = ActiveBranch?.currencySymbol;

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("sellproduct");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });
  const [dateFormat, setdateFormat] = useState<any>("YYYY-MM-DD");
  // const initialDates = [new Date(), new Date()];
  const [value, setValue] = React.useState<any>();

  const [UpdateUser, setUpdateUser] = useState<any>({
    expenseCatUUID: "",
    branchId: "",
    name: "",
    Status: "",
  });

  //page state
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  const startDate = selectedDate?.startDate;
  const endDate = selectedDate?.endDate;
  let initialState = {
    status: "",
    userType: "",
    spotID: "",
    customerID: "",
    employeeID: "",
    sellProductID: "",
    page_limit: pageLimit,
    page_no: 1,
    search: "",
    branchID: ActiveBranch.iBranchID,
    start_date: startDate ? startDate : "",
    end_date: endDate ? endDate : "",
    order_key: " iProductSellDetailID",
    order_value: "desc",
  };

  const [show, setShow] = useState<any>(false);
  const [activeCatagory, setActiveCatagory] = useState<any>();
  const [activeMember, setActiveMember] = useState<any>();
  const [selectMember, setSelectMember] = useState<any>({
    value: "",
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
  });
  const [selectCustomer, setSelectCustomer] = useState<any>("");
  const [CustomertOpt, setCustomerOpt] = useState<any>([]);
  // const [selectPaymentMode, setSelectPaymentMode] = useState({
  //   label: intl.formatMessage({ id: "COMMON.SELECT" }),
  //   value: "",
  // });
  const [selectTaxApplicable, setSelectTaxApplicable] = useState({
    label: `${intl.formatMessage({
      id: "COMMON.YES",
    })}`,
    value: 1,
  });
  const taxAllow = [
    {
      value: 1,
      label: `${intl.formatMessage({
        id: "COMMON.YES",
      })}`,
    },
    {
      value: 0,
      label: `${intl.formatMessage({
        id: "COMMON.NO",
      })}`,
    },
  ];
  const [activeProduct, setActiveProduct] = useState<any>();
  const handleShow = () => setShow(true);

  const [isUpdate, setisUpdate] = useState(false);
  //for check box
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [isAllRowsChecked, setIsAllRowsChecked] = useState<any>(false);
  const [branch_id, setBranch_id] = useState<any>("0");
  // for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // for error
  // const [error, setError] = useState("");
  const [isFilter, setisFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState(initialState);
  // const [searchValues, setSearchValues] = useState(initialState);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [printData, setPrintData] = useState<any>();
  const [printShow, setPrintShow] = useState<any>(false);
  const [BillShow, setBillShow] = useState<any>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [TBLData, setTBLData] = useState<any>([]);
  const [baseURL, setBaseURL] = useState<any>("");
  const [selectData, setSelectData] = useState<any>("");
  const [paymodeOpt, setPaymodeOpt] = useState<any>([]);
  const [payShow, setPayShow] = useState<any>(false);
  const [InvoiceShow, setInvoiceShow] = useState<any>(false);
  const [title, setTitle] = useState<any>("");
  const [selectEmployee, setSelectEmployee] = useState<any>("");
  const [EmpOpt, setEmpOpt] = useState<any>([]);
  const [selectCategory, setSelectCatagory] = useState<any>({
    value: "",
    label: "select a category",
  });
  const [selectUserType, setSelectUserType] = useState<any>("");
  const CunsumerType = [
    {
      value: "Member",
      label: `${intl.formatMessage({
        id: "COMMON.MEMBER",
      })}`,
    },
    {
      value: "Customer",
      label: `${intl.formatMessage({
        id: "COMMON.CUSTOMER",
      })}`,
    },
  ];
  // "MODULE.SUPPLEMENTPRD.HEADING"
  const Idcolumn = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.ID",
  })}`;
  const firstColumn = `${intl.formatMessage({
    id: "MODULE.SUPPLIMENT.TABLE.NAMES",
  })}`;
  const PayAmtColumn = `${intl.formatMessage({
    id: "MODULE.SUPPLIMENT.TABLE.PAYAMT",
  })}`;
  const EmployeeColumn = `${intl.formatMessage({
    id: "PAYMENT.SUPPLIER.EMPLOYEE",
  })}`;
  const secondColumn = `${intl.formatMessage({
    id: "MODULE.SUPPLIMENT.TABLE.QUANTITY",
  })}`;
  const thirdColumn = `${intl.formatMessage({
    id: "MODULE.SUPPLIMENT.TABLE.PURCHASEPRICE",
  })}`;
  const fourthColumn = `${intl.formatMessage({
    id: "MODULE.SUPPLIMENT.TABLE.PRICE",
  })}`;
  const fifthColumn = `${intl.formatMessage({
    id: "MODULE.SUPPLIMENT.TAXAPPLICATION",
  })}`;
  const sixthColumn = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.TAXAMT",
  })}`;
  const seventhColumn = `${intl.formatMessage({
    id: "MODULE.MEMBERS.DISCOUNT",
  })}`;
  const eightColumn = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.FINALAMT",
  })}`;
  const ninthColumn = `${intl.formatMessage({
    id: "POS.SELLPRODUCT.REMAINAMT",
  })}`;
  const tenthColumn = `${intl.formatMessage({
    id: "MODULE.SUPPLIMENT.TABLE.PROFIT",
  })}`;
  const elevanthColumn = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.DATE",
  })}`;
  // const ExpenseRemarkRowAction = `${intl.formatMessage({
  //   id: "COMMON_REMARK",
  // })}`;
  // const ExpenseExpenseRowAction = `${intl.formatMessage({
  //   id: "MODULE.SUPPLIMENT.TAXINCLUDED.TABLE",
  // })}`;

  const twelthColumn = `${intl.formatMessage({ id: "COMMON.ACTIONS" })}`;

  //download fileName
  const downloadfileName = "SellProduct_" + Date.now();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: (values) => {
      setFilterValues(values);
      setPage(1);
    },
  });
  const getSellProduct = useCallback(() => {
    if (UserType === "admin" || view !== 0 || UserType === "channelPartner") {
      setIsLoading(true);
      GetSellProductList({
        ...filterValues,
        page_no: page,
        page_limit: pageLimit,
        order_key: sortConfig.key,
        order_value: sortConfig.direction,
      }).then((data: any) => {
        // console.log(data, "data");
        if (data?.status !== 200) {
          setFilteredData([]);
          setIsLoading(false);
        } else {
          dispatch(Supplyments.actions.GetSupplyMents(data));
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, filterValues, page, sortConfig, view]);

  useEffect(() => {
    getSellProduct();
  }, [getSellProduct]);

  useEffect(() => {
    setFilteredData(Supplyment?.data);
    handleExports();
  }, [Supplyment?.data]);
  useEffect(() => {
    getActiveMember(ActiveBranchUUID);
    getActiveProductDetails(ActiveBranchUUID);
    GetActivePayMethod(ActiveBranchUUID);
    getActiveCustomerList(ActiveBranchUUID);
    getActiveEmployeeList(ActiveBranchUUID);
  }, []);
  const getActiveEmployeeList = async (branchUUID: any) => {
    const response = await ActiveEmpList(branchUUID);
    // console.log(response, "customer list");
    setEmpOpt(response?.data?.data);
  };
  const GetActivePayMethod = async (branchUUID: any) => {
    const response = await ActivePaymentMode(branchUUID);
    setPaymodeOpt(response?.data?.data);
    // console.log(response?.data, "reponseofactive");
  };
  const getActiveCustomerList = async (branchUUID: any) => {
    const response = await ActiveCustomerList(branchUUID);
    // console.log(response, "customer list");
    setCustomerOpt(response?.data?.data);
  };

  const getActiveMember = async (branchUUID: any) => {
    const response = await GetActiveMember(branchUUID);
    // console.log(response, "response");
    setActiveMember(response?.data?.data);
  };
  const getActiveProductDetails = async (branchUUID: any) => {
    const response = await GetActiveProduct(branchUUID);
    // console.log(response, "response");
    setActiveProduct(response?.data?.data);
    setActiveCatagory(response?.data?.data);
  };
  // update Role Status
  const UpdateUserStatus = (prop: any) => {
    swal({
      title: `${intl.formatMessage({ id: "COMMON.AREYOUSURE" })}`,
      text: intl.formatMessage({ id: "COMMON.NOTPERFORMOPERATION" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SUBMIT" }),
      ], //["Cancel", "Submit"],
      dangerMode: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        const Update_user = {
          branchID: branch_id,
          uuid_array: checkedRows,
          select_type: "",
          unchecked_uuid_array: [],
          status: prop.status,
        };
        if (prop.status === "2") {
          DeleteSellProduct(Update_user)
            .then((data: any) => {
              //swal(`Proof! Selected Users Is ${prop.message}!`, {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });
              setCheckedRows([]);
              setIsAllRowsChecked(false);
              //getting all roled again
              getSellProduct();
            })
            .catch(() => {
              swal(intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" }));
            });
        }
      } else {
        swal(`${intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" })}`);
      }
    });
  };

  //handle check box

  const handleRowCheckboxChange = (rowId: any, branchId: any) => {
    setBranch_id(branchId);
    if (checkedRows.includes(rowId)) {
      setCheckedRows(checkedRows.filter((id: any) => id !== rowId));
    } else {
      setCheckedRows([...checkedRows, rowId]);
    }
  };
  // handle all check box
  const handleSelectAllCheckboxChange = () => {
    setBranch_id(ActiveBranch.iBranchID);
    if (isAllRowsChecked) {
      setCheckedRows([]);
      setIsAllRowsChecked(false);
    } else {
      const allRowIds =
        Supplyment?.data?.map(
          (element: any) => element.iProductSellDetailUUID
        ) || [];
      //console.log('packageperiods====',packageperiods);
      //console.log('allRowIds====',allRowIds);
      setCheckedRows(allRowIds);
      setIsAllRowsChecked(true);
    }
  };
  const selectPageHandler = (selectedPage: any) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPages &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };
  const handleReset = () => {
    formik.resetForm();
    setFilterValues(initialState);
    handleDateReset();
    setValue(null);
    setSelectCatagory("");
    setSelectMember("");
    setSelectEmployee("");
    // setSelectPaymentMode({
    //   label: intl.formatMessage({ id: "COMMON.SELECT" }),
    //   value: "",
    // });
    setSelectTaxApplicable({
      label: `${intl.formatMessage({
        id: "COMMON.YES",
      })}`,
      value: 1,
    });
  };

  const handleExports = () => {
    const tableRows = Supplyment?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [Idcolumn]: element.iProductSellDetailID,
        // [userTypeColumn]: element.saleUserType,
        [firstColumn]:
          element.saleUserType === "Member"
            ? element.saleUserType +
              " " +
              element.memName +
              `(${element?.memcountrycode}-${element?.memmobileno})`
            : element.saleUserType +
              " " +
              element.customerName +
              `(${element?.CusCountryCode}-${element?.mobileno})`,
        // [secondColumn]: element.sellProducts?.length
        //   ? element.sellProducts
        //       .map((item: any) => item.name)
        //       .filter(Boolean)
        //       .join(", ")
        //   : "",

        [thirdColumn]: element.totalPurchasePrice,
        [fourthColumn]: element.totalSellPrice,
        [secondColumn]: element.totalQuantity,
        [sixthColumn]: element.taxAmount,
        [eightColumn]: element.finalAmt,
        [seventhColumn]: element.discount,
        [PayAmtColumn]: element.totalPayableAmt,
        [ninthColumn]: element.dueAmount,
        [tenthColumn]: element.totalPayableAmt - element.totalPurchasePrice,
        [elevanthColumn]:
          element.sell_date +
          " " +
          moment(element.sellTime, "HH:mm").format("hh:mm A"),
      };
    });

    setTBLData(tableRows);
  };

  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    const titleFontSize = 8;
    const tableFontSize = 5;

    doc.setFontSize(titleFontSize);
    doc.text(`${intl.formatMessage({ id: "MODULE.SELLPRODUCT" })}`, 10, 10);
    const tableRows = Supplyment.data.map((element: any, index: number) => {
      return [
        index + 1,
        element.iProductSellDetailID,
        element.saleUserType === "Member"
          ? element.saleUserType +
            " " +
            element.memName +
            `(${element?.memcountrycode}-${element?.memmobileno})`
          : element.saleUserType +
            " " +
            element.customerName +
            `(${element?.CusCountryCode}-${element?.mobileno})`,
        // element.sellProducts?.map((item: any, index: number) =>
        //   index < element.sellProducts.length - 1 ? `${item.name}, ` : item.name
        // ),

        element.totalPurchasePrice,
        element.totalSellPrice,
        element.totalQuantity,
        element.taxAmount,
        element.finalAmt,
        element.discount,
        element.totalPayableAmt,
        element.dueAmount,
        element.totalPayableAmt - element.totalPurchasePrice,
        element.sell_date +
          " " +
          moment(element.sellTime, "HH:mm").format("hh:mm A"),
        /*new Date(element.dtCreated_Date).toLocaleString("en-US", {
           dateStyle: "medium",
           timeStyle: "short",+
         }),*/
      ];
    });
    doc.autoTable({
      head: [
        [
          "#",
          Idcolumn,
          // userTypeColumn,
          firstColumn,
          thirdColumn,
          fourthColumn,
          secondColumn,
          sixthColumn,
          eightColumn,
          seventhColumn,
          PayAmtColumn,
          ninthColumn,
          tenthColumn,
          elevanthColumn,
        ],
      ],
      body: tableRows,
      startY: 50,
      styles: {
        fontSize: tableFontSize,
      },
      columnStyles: {
        0: { columnWidth: 20 },
        // Set custom width for the first column
        // Add more column styles as needed
      },
    });

    doc.save(downloadfileName + ".pdf");
  };

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
    setPage(1);
  };

  const handleRefreshButton = () => {
    // setIsLoading(true);
    // setCheckedRows([]);
    // setPage(1);
    // setPageLimit(pageDefaultValue);
    // setSearchValues(initialState);
    setSortConfig({ key: " iProductSellDetailID", direction: "desc" });
  };

  const getDetailsForUpdate = async (branchID: any, memberuid: any) => {
    const response = await detailofSellProduct(branchID, memberuid);
    setUpdateUser(response?.data?.data);
    // console.log(response, "detailsof");
  };
  const handlePrintDetail = async (branchID: any, memberuid: any) => {
    const response = await detailofSellProduct(branchID, memberuid);
    setPrintData(response?.data?.data);
    setPrintShow(true);
    setBaseURL(response?.data?.imageBaseURL);
    // console.log(response, "detailsof");
  };
  const handleBillGenerate = async (branchID: any, detailsuuid: any) => {
    const response = await detailsOfBillGenerate(branchID, detailsuuid, "");
    setPrintData(response?.data?.data);
    setBillShow(true);
    setBaseURL(response?.data?.imageBaseURL);
    // console.log(response, "detailsof");
  };

  const HandleUserTypeChange = (e: any) => {
    if (e) {
      setSelectUserType({ value: e.value, label: e.label });
      formik.setFieldValue("saleUserType", e.value);
      formik.setFieldValue("spotID", "");
      formik.setFieldValue("customerID", "");

      setSelectMember({
        value: "",
        label: `${intl.formatMessage({
          id: "COMMON.SELECT",
        })}`,
      });
      setSelectCustomer("");
    } else {
      setSelectUserType("");
    }
  };

  const handleCustomerChange = (e: any) => {
    setSelectCustomer({ value: e.value, label: e.label });
    formik.setFieldValue("customerID", e.value);
  };
  const handleMemberChange = async (Event: any) => {
    setSelectMember({
      value: Event.value,
      label: Event.label,
    });

    formik.setFieldValue("spotID", Event.value);
  };
  const handleEmployeeChange = (e: any) => {
    // if (e) {
    setSelectEmployee({ value: e.value, label: e.label });
    formik.setFieldValue("employeeID", e.value);
    // } else {
    //   setSelectEmployee("");
    //   formik.setFieldValue("employeeID", "");
    // }
  };
  // const handlePaymentModeChange = (Event: any) => {
  //   //   formik.setFieldValue("packageUUID", Event.value);
  //   formik.setFieldValue("mode_of_payment", Event.value);
  //   setSelectPaymentMode({
  //     value: Event.value,
  //     label: Event.label,
  //   });
  //   // handlePaymentReset();
  // };
  const handletaxApplicable = (Event: any) => {
    setSelectTaxApplicable({ value: Event.value, label: Event.label });
    formik.setFieldValue("taxesApplicable", Event.value);
  };
  const handleChange = (values: any) => {
    setValue(values);
    if (values && values.length !== 0) {
      formik.setFieldValue("start_date", moment(values[0]).format(dateFormat));
      formik.setFieldValue("end_date", moment(values[1]).format(dateFormat));
    }
  };
  const handleSELECTChange = (Event: any) => {
    setSelectCatagory({
      label: Event.label,
      value: Event.value,
    });

    formik.setFieldValue("sellProductID", Event.value);
  };
  const handleDateReset = () => {
    setValue(null);
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
  };
  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };
  const handleShowModal = (title: any) => {
    setTitle(title);
    setPayShow(true);
  };

  const handleSendNotifications = async (
    branchID: any,
    iProductSellDetailID: any
  ) => {
    // Show a confirmation dialog
    const willSend = await swal({
      title: intl.formatMessage({ id: "COMMON.AREYOUSURE" }),
      text: intl.formatMessage({ id: "COMMON.DOYOUSEND" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SEND" }),
      ], // Cancel and Send buttons
      dangerMode: true, // Optional: Highlights the "Send" button
    });

    // If the user confirms, proceed with the API call
    if (willSend) {
      const obj = {
        branchID: branchID,
        iProductSellDetailID: iProductSellDetailID,
      };
      const response = await dueAmountPosNotifications(obj);
      if (response?.status === 200) {
        swal(response?.data?.replyMsg, { icon: "success" });
      } else {
        swal(response?.data?.replyMsg, { icon: "error" });
      }
    } else {
      // Handle the cancel action (optional)
      swal(
        "Cancelled",
        intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" }),
        "info"
      );
    }
  };
  return (
    <>
      {/* Filter Section Start of Package Period List */}
      {isFilter && (
        <div className="card my-4">
          <div className="card-header">
            {" "}
            <Card className="w-100">
              <Card.Body className="py-5">
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>
                          {intl.formatMessage({
                            id: "MODULE.SUPPLEMENTPRD.HEADING",
                          })}
                        </Form.Label>
                        <Select
                          name="sellProductID"
                          className="basic-single"
                          onChange={(e) => handleSELECTChange(e)}
                          value={selectCategory}
                          options={activeCatagory?.map((item: any) => ({
                            value: item.iSupplierPurchaseProductID,
                            label: item.prodName,
                          }))}
                        />
                        {/* {activeCatagory?.map((ele: any) => (
                            <option value={ele?.iSupplementProductID}>
                              {ele?.name}
                            </option>
                          ))} */}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <label htmlFor="saleUserType" className="  form-label">
                          {intl.formatMessage({
                            id: "SELLPRODUCT.USERTYPE",
                          })}
                        </label>
                        <Select
                          id="saleUserType"
                          name="saleUserType"
                          className="basic-single"
                          value={selectUserType}
                          options={CunsumerType?.map((item: any) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                          onChange={(e) => HandleUserTypeChange(e)}
                          isClearable
                        />
                      </div>
                    </Col>
                    {selectUserType?.value !== "Customer" ? (
                      <Col md={3}>
                        <label htmlFor="spotID" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERSHIP.MEMBER",
                          })}
                        </label>
                        <Select
                          id="spotID"
                          name="spotID"
                          className="basic-single"
                          value={selectMember}
                          options={activeMember?.map((item: any) => ({
                            value: item.iSpotID,
                            label: item.name,
                          }))}
                          onChange={(e) => handleMemberChange(e)}
                        />
                      </Col>
                    ) : (
                      <Col md={3}>
                        <div className="mb-3">
                          <label htmlFor="customerID" className="  form-label">
                            {intl.formatMessage({
                              id: "COMMON.CUSTOMER",
                            })}
                          </label>
                          <Select
                            id="customerID"
                            name="customerID"
                            className="basic-single"
                            value={selectCustomer}
                            options={CustomertOpt?.map((item: any) => ({
                              value: item.icustomerID,
                              label: item.customerName,
                            }))}
                            onChange={(e) => handleCustomerChange(e)}
                          />
                        </div>
                      </Col>
                    )}

                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.SUPPLIMENT.DATERANGE",
                          })}
                        </label>

                        <DateRangePicker
                          value={value}
                          onChange={handleChange}
                          format="MM/dd/yyyy"
                          placeholder={intl.formatMessage({
                            id: "COMMON.PLACEHOLDER.DATERANGE",
                          })}
                          placement="auto"
                          style={{ margin: "2px" }}
                          onClean={handleDateReset}
                        />
                      </div>
                    </Col>

                    {/* <Col md={3} className="mb-4">
                      <Form.Group controlId="status">
                        <Form.Label>
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </Form.Label>

                        <Form.Control
                          as="select"
                          onChange={formik.handleChange}
                          value={formik.values.status}
                        >
                          <option value="">
                            {intl.formatMessage({ id: "COMMON.ALL" })}
                          </option>
                          <option value="1">
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col> */}
                    {/* Rest of the form fields */}
                  </Row>

                  <Row>
                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="employeeID" className="  form-label">
                          {intl.formatMessage({
                            id: "PAYMENT.SUPPLIER.EMPLOYEE",
                          })}
                        </label>
                        <Select
                          id="employeeID"
                          name="employeeID"
                          className="basic-single"
                          value={selectEmployee}
                          options={EmpOpt?.map((item: any) => ({
                            value: item.iEmployeeID,
                            label: `${item.vEFirstname} (${item?.vEmpCountryCode}-${item?.vEPhoneno})`,
                          }))}
                          onChange={(e) => handleEmployeeChange(e)}
                          // isClearable
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-4">
                      <label htmlFor="taxesApplicable" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.SUPPLIMENT.TAXAPPLICATION",
                        })}
                      </label>
                      <Select
                        className="basic-single"
                        name="taxesApplicable"
                        id="taxesApplicable"
                        value={selectTaxApplicable}
                        onChange={(e) => handletaxApplicable(e)}
                        options={taxAllow?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Col>
                    <Col md={3} className="mb-4">
                      <Form.Group controlId="search">
                        <Form.Label>
                          {intl.formatMessage({
                            id: "MODULE.EXPENSECATEGORY.SEARCH",
                          })}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder={intl.formatMessage({
                            id: "MODULE.EXPENSECATEGORY.ENTERSEARCH",
                          })}
                          onChange={formik.handleChange}
                          value={formik.values.search}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3} className="d-flex  align-items-center">
                      <Button type="submit" variant="primary" className="me-3 ">
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.APPLY",
                        })}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleReset}
                        className="me-3"
                      >
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.RESET",
                        })}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      {/* Filtsser Section End */}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div
                className="d-flex  flex-wrap gap-1"
                data-kt-user-table-toolbar="base"
              >
                {checkedRows?.length > 0 ? (
                  <>
                    <div className="fw-bold me-5">
                      <span className="me-2"> {checkedRows?.length}</span>
                      {intl.formatMessage({ id: "COMMON.SELECTED" })}
                    </div>

                    <>
                      <button
                        type="button"
                        className="btn btn-success me-3 d-none"
                        onClick={() =>
                          UpdateUserStatus({
                            status: "1",
                            message: `${intl.formatMessage({
                              id: "COMMON.ACTIVATED",
                            })}`,
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className="btn btn-light-warning me-3 d-none"
                        onClick={() =>
                          UpdateUserStatus({
                            status: "0",
                            message: `${intl.formatMessage({
                              id: "COMMON.INACTIVATED",
                            })}`,
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className={`btn btn-danger me-3 ${
                          del === 0 && UserType === "employee" && "d-none"
                        }`}
                        onClick={() =>
                          UpdateUserStatus({
                            status: "2",
                            message: `${intl.formatMessage({
                              id: "COMMON.DELETE",
                            })}`,
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.DELETE" })}
                      </button>
                    </>
                  </>
                ) : (
                  <>
                    <span
                      className="min-w-40px mb-3 d-flex  align-items-center"
                      style={{ color: "#7e8299", fontSize: "14px" }}
                    >
                      {intl.formatMessage({ id: "COMMON.SHOW" })}
                    </span>
                    <div>
                      <select
                        className="form-control form-select form-select-solid me-3 mb-4"
                        value={pageLimit}
                        onChange={(e) => getPageLimit(e.target.value)}
                      >
                        {finalPageLimitArray?.map((item: any, i = 0) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex  flex-wrap gap-1"
              data-kt-user-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-primary me-3"
                onClick={handleRefreshButton}
              >
                {" "}
                {intl.formatMessage({ id: "COMMON.REFRESH" })}
              </button>
              {checkedRows?.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>

                  <Button
                    variant="primary"
                    className={` ${
                      add === 0 && UserType === "employee" && "d-none"
                    }`}
                    onClick={() => {
                      handleShow();
                      setisUpdate(false);
                    }}
                  >
                    {intl.formatMessage({
                      id: "ADD.SELL",
                    })}
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="btn btn-light-primary me-3"
                    >
                      <KTSVG
                        path="/media/icons/duotune/files/fil021.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                      {intl.formatMessage({ id: "MODULE.BRANCH.EXPORT" })}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4">
                      <CSVLink
                        data={TBLData || []}
                        filename={downloadfileName + ".csv"}
                        className="menu-item px-3"
                      >
                        <span className="px-3">
                          {intl.formatMessage({ id: "COMMON.EXPORTCSV" })}
                        </span>
                      </CSVLink>
                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={() => exportToXLS(TBLData, downloadfileName)}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTEXCEL" })}
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={exportToPDF}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTPDF" })}
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* modal start */}
        {/* modal start */}
        {/* <SellProductModal
          show={show}
          setShow={setShow}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          activeCatagory={activeMember}
          activeProduct={activeProduct}
          getExpenseCategory={getSellProduct}
          CurrentCurrency={CurrentCurrency}
          currencySymbol={currencySymbol}
          paymodeOpt={paymodeOpt}
        /> */}
        {/* <Main show={show} setShow={setShow}/> */}
        <BillPage
          printShow={BillShow}
          setPrintShow={setBillShow}
          printData={printData}
          CurrentCurrency={CurrentCurrency}
          currencySymbol={currencySymbol}
          baseURL={baseURL}
        />
        {/* <PaymentModal
          show={payShow}
          setShow={setPayShow}
          title={title}
          setTitle={setTitle}
          data={selectData}
          InvoiceShow={InvoiceShow}
          setInvoiceShow={setInvoiceShow}
        />
        <InvoiceModal
          show={InvoiceShow}
          setShow={setInvoiceShow}
          payShow={payShow}
          setPayShow={setPayShow}
          data={selectData}
          setTitle={setTitle}
          title={title}
          getList={getSellProduct}
        /> */}
        <SaleDetailsModal
          show={printShow}
          setShow={setPrintShow}
          printData={printData}
          CurrentCurrency={CurrentCurrency}
          currencySymbol={currencySymbol}
        />
        {/* modal End */}

        {/* begin::Body */}

        <div className="card-body py-3">
          {/* {isLoading && <Loading />} */}
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className=" mobilewidth">
                    <div className="mobileViewalign w-100">
                      <div className=" selectAll ">
                        {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                      </div>
                      <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                          checked={isAllRowsChecked}
                          onChange={handleSelectAllCheckboxChange}
                        />
                      </div>
                    </div>
                  </th>
                  {/* <th className="min-w-100px">{ExpenseIdRowTitle}</th> */}
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="iProductSellDetailID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {Idcolumn}
                      {sortConfig.key !== "iProductSellDetailID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "iProductSellDetailID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "iProductSellDetailID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="saleUserType"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {firstColumn}
                      {sortConfig.key !== "saleUserType" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "saleUserType" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "saleUserType" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  {/* <th className="min-w-100px notVisibleInMobile">
                              {secondColumn}
                            </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalPurchasePrice"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumn}
                      {sortConfig.key !== "totalPurchasePrice" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalPurchasePrice" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalPurchasePrice" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalSellPrice"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fourthColumn}
                      {sortConfig.key !== "totalSellPrice" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalSellPrice" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalSellPrice" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalQuantity"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {secondColumn}
                      {sortConfig.key !== "totalQuantity" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="taxAmount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumn}
                      {sortConfig.key !== "taxAmount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "taxAmount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "taxAmount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="finalAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eightColumn}
                      {sortConfig.key !== "finalAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="discount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumn}
                      {sortConfig.key !== "discount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "discount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "discount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalPayableAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {PayAmtColumn}
                      {sortConfig.key !== "totalPayableAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalPayableAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalPayableAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dueAmount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumn}
                      {sortConfig.key !== "dueAmount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dueAmount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dueAmount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {tenthColumn}
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="sell_date"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {elevanthColumn}
                      {sortConfig.key !== "sell_date" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "sell_date" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "sell_date" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {twelthColumn}
                  </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredData && filteredData.length === 0 ? (
                  <tr>
                    <td colSpan={11} className="text-center">
                      {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                    </td>
                  </tr>
                ) : UserType === "employee" && view === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger fs-4">
                      {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((element: any, index: number) => {
                    const isChecked = checkedRows.includes(
                      element.iProductSellDetailUUID
                    );
                    return (
                      <tr key={element.iProductSellDetailUUID} className="fs-5">
                        <td
                          data-label={intl.formatMessage({
                            id: "COMMON.SELECT",
                          })}
                        >
                          <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                            <input
                              className="form-check-input widget-9-check"
                              type="checkbox"
                              value="1"
                              checked={isChecked}
                              onChange={() =>
                                handleRowCheckboxChange(
                                  element.iProductSellDetailUUID,
                                  element.iBranchID
                                )
                              }
                            />
                          </div>
                        </td>
                        <td data-label={Idcolumn}>
                          {element.iProductSellDetailID}
                        </td>
                        {/* <td data-label={userTypeColumn}>
                                    
                                  </td> */}
                        <td data-label={firstColumn}>
                          <div>{element.saleUserType}</div>
                          {element.saleUserType === "Member"
                            ? element?.memName
                            : element?.customerName}
                          <a
                            href={`tel:${
                              element.saleUserType === "Member"
                                ? element.memcountrycode + element.memmobileno
                                : element.CusCountryCode + element.mobileno
                            }`}
                          >{`(${
                            element.saleUserType === "Member"
                              ? element.memcountrycode +
                                "-" +
                                element.memmobileno
                              : element.CusCountryCode + "-" + element.mobileno
                          })`}</a>
                        </td>
                        {/* <td data-label={EmployeeColumn}>
                                    {element?.employeename ? (
                                      <div>
                                        {element?.employeename}
                                        <a
                                          href={`tel:${
                                            element.employeecountcode +
                                            element.employeemobileno
                                          }`}
                                        >{`(${
                                          element.employeecountcode +
                                          "-" +
                                          element.employeemobileno
                                        })`}</a>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </td> */}
                        {/* <td data-label={secondColumn}>
                                    {element.sellProducts?.map(
                                      (item: any, index: number) =>
                                        index < element.sellProducts.length - 1
                                          ? `${item.name}, `
                                          : item.name
                                    )}
                                  </td> */}

                        <td data-label={thirdColumn}>
                          {element?.totalPurchasePrice?.toFixed(2)}
                        </td>
                        <td data-label={fourthColumn}>
                          {element.TaxesWithSellAmt == 1
                            ? element.totalSellPrice?.toFixed(2)
                            : element?.totalSellPrice?.toFixed(2)}
                        </td>
                        <td data-label={secondColumn}>
                          {element.totalQuantity}
                        </td>
                        {/* <td data-label={fifthColumn}>
                                    {element.taxesApplicable === 1
                                      ? intl.formatMessage({ id: "COMMON.YES" })
                                      : intl.formatMessage({
                                          id: "COMMON.NO",
                                        })}
                                  </td> */}
                        <td data-label={sixthColumn}>
                          {element?.taxAmount?.toFixed(2)}
                        </td>
                        <td data-label={eightColumn}>
                          {element?.finalAmt?.toFixed(2)}
                        </td>
                        <td data-label={seventhColumn}>
                          {element?.discount?.toFixed(2)}
                        </td>
                        <td data-label={PayAmtColumn}>
                          {element?.totalPayableAmt?.toFixed(2)}
                        </td>

                        <td data-label={ninthColumn}>
                          {element?.dueAmount?.toFixed(2)}
                        </td>
                        <td
                          data-label={tenthColumn}
                          className={
                            element.totalPayableAmt -
                              element.totalPurchasePrice <
                            0
                              ? "text-danger"
                              : ""
                          }
                        >
                          {(
                            element?.totalPayableAmt -
                            element?.totalPurchasePrice
                          )?.toFixed(2)}
                        </td>

                        {/* <td data-label={ExpensepaymentmodeRowAction}>
                                    {element.mode_of_payment}
                                  </td> */}
                        <td data-label={elevanthColumn}>
                          <div>{element.sell_date}</div>
                          <div>
                            {moment(element.sellTime, "HH:mm").format(
                              "hh:mm A"
                            )}
                          </div>
                        </td>

                        {/* <td>
                                        <div className="d-flex align-items-center">
                                          <div className="d-flex flex-column">
                                            <p className="text-dark fw-bolder text-hover-primary mb-1">
                                              {element.TaxesWithSellAmt == 1
                                                ? intl.formatMessage({ id: "COMMON.YES" })
                                                : intl.formatMessage({
                                                    id: "COMMON.NO",
                                                  })}
                                            </p>
                                          </div>
                                        </div>
                                      </td> */}

                        {/* <td>
                                        <div className="d-flex align-items-center">
                                          <div className="d-flex flex-column">
                                            <p className="text-dark fw-bolder text-hover-primary mb-1">
                                              {element.iDays}
                                            </p>
                                          </div>
                                        </div>
                                      </td> */}

                        <td data-label={twelthColumn}>
                          <div className="d-flex flex-shrink-0 gap-2 floatRight">
                            <span
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                view === 0 &&
                                UserType === "employee" &&
                                "d-none"
                              }`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Payment List"
                              onClick={() => {
                                handleBillGenerate(
                                  ActiveBranchID,
                                  element.iProductSellDetailUUID
                                );
                              }}
                            >
                              <i className="fas fa-money-bill"></i>
                            </span>
                            {/* <span
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                view === 0 &&
                                UserType === "employee" &&
                                "d-none"
                              }`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Payment List"
                              onClick={() => {
                                handleShowModal("List");
                                setSelectData(element);
                              }}
                            >
                              <i className="fa-solid fa-receipt me-2"></i>
                            </span> */}
                            {/* <span
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                view === 0 &&
                                UserType === "employee" &&
                                "d-none"
                              }`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Add Payment"
                              onClick={() => {
                                setInvoiceShow(true);
                                setSelectData(element);
                              }}
                            >
                              <i className="fa-solid fa-credit-card"></i>
                            </span> */}
                            <span
                              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Details"
                              onClick={() => {
                                handlePrintDetail(
                                  ActiveBranchID,
                                  element.iProductSellDetailUUID
                                );
                              }}
                            >
                              <i className="bi bi-eye"></i>
                            </span>
                            {/* <a
                              href="#g"
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                edit === 0 &&
                                UserType === "employee" &&
                                "d-none"
                              }`}
                              onClick={() => {
                                getDetailsForUpdate(
                                  ActiveBranchID,
                                  element.iProductSellDetailUUID
                                );
                                setisUpdate(true);
                                setShow(true);
                              }}
                            >
                              <KTSVG
                                path="/media/icons/duotune/art/art005.svg"
                                className="svg-icon-3"
                              />
                            </a> */}
                            {element.dueAmount > 0 && isDue ? (
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  handleSendNotifications(
                                    element?.iBranchID,
                                    element.iProductSellDetailID
                                  );
                                }}
                              >
                                {intl.formatMessage({ id: "COMMON.SEND" })}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className=" mobilewidth">
                    <div className="mobileViewalign w-100">
                      <div className=" selectAll ">
                        {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                      </div>
                      <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                          checked={isAllRowsChecked}
                          onChange={handleSelectAllCheckboxChange}
                        />
                      </div>
                    </div>
                  </th>
                  {/* <th className="min-w-100px">{ExpenseIdRowTitle}</th> */}
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="iProductSellDetailID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {Idcolumn}
                      {sortConfig.key !== "iProductSellDetailID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "iProductSellDetailID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "iProductSellDetailID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="saleUserType"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {firstColumn}
                      {sortConfig.key !== "saleUserType" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "saleUserType" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "saleUserType" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  {/* <th className="min-w-100px notVisibleInMobile">
                              {secondColumn}
                            </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalPurchasePrice"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumn}
                      {sortConfig.key !== "totalPurchasePrice" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalPurchasePrice" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalPurchasePrice" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalSellPrice"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fourthColumn}
                      {sortConfig.key !== "totalSellPrice" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalSellPrice" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalSellPrice" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalQuantity"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {secondColumn}
                      {sortConfig.key !== "totalQuantity" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="taxAmount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumn}
                      {sortConfig.key !== "taxAmount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "taxAmount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "taxAmount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="finalAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eightColumn}
                      {sortConfig.key !== "finalAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="discount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumn}
                      {sortConfig.key !== "discount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "discount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "discount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalPayableAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {PayAmtColumn}
                      {sortConfig.key !== "totalPayableAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalPayableAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalPayableAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dueAmount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumn}
                      {sortConfig.key !== "dueAmount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dueAmount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dueAmount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {tenthColumn}
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="sell_date"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {elevanthColumn}
                      {sortConfig.key !== "sell_date" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "sell_date" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "sell_date" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {twelthColumn}
                  </th>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {/* begin:: pagination  */}

          {filteredData && filteredData.length > 0 && (
            <Pagination page={page} totalPages={totalPages} setPage={setPage} />
          )}
          {/* end:: pagination */}
        </div>

        {/* begin::Body */}
      </div>
    </>
  );
};

export default SellModal;
