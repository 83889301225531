import { useFormik } from "formik";
// import "./setting.css";
// import * as Yup from "yup";
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { UpdateCompany } from "../redux/settingCRUD";

const DashboardSetting = ({
  UpdateUser,
  ActiveBranchUUID,
  ActiveBranchID,
  detailsCompany,
  setSuccessResponse,
  setErrorResponse,
  setErr,
  ActiveBranchSettingUUID,
  Settingedit,
}: any) => {
  const intl = useIntl();
  let data1: any = localStorage?.getItem("activeUserType");
  const userType = JSON.parse(data1);
  //   const Schemas = Yup.object().shape({
  //     dashTotalMember: Yup.string()
  //       .nullable()
  //       .required(intl.formatMessage({ id: "REQ.MODULE.SMS.COMPANY" })),
  //     dashTotalActiveMember: Yup.string()
  //       .nullable()
  //       .required(intl.formatMessage({ id: "REQ.MODULE.SMS.USERNAME" })),
  //     smsPassword: Yup.string()
  //       .nullable()
  //       .required(intl.formatMessage({ id: "REQ.MODULE.SMS.PASSWORD" })),
  //     smsSenderID: Yup.string()
  //       .nullable()
  //       .required(intl.formatMessage({ id: "REQ.MODULE.SMS.SENDERID" })),
  //     smsStatus: Yup.string()
  //       .nullable()
  //       .required(intl.formatMessage({ id: "REQ.MODULE.SMS.Status" })),
  //   });

  const initialState = {
    dashTotalMember: UpdateUser?.dashTotalMember ? true : false,
    dashTotalActiveMember: UpdateUser?.dashTotalActiveMember ? true : false,
    dashTotalInactiveMember: UpdateUser?.dashTotalInactiveMember ? true : false,
    dashTodayExpiry: UpdateUser?.dashTodayExpiry ? true : false,
    dashTotalInvoice: UpdateUser?.dashTotalInvoice ? true : false,
    dashTodayEnrolled: UpdateUser?.dashTodayEnrolled ? true : false,
    dashTodayEMPBirthday: UpdateUser?.dashTodayEMPBirthday ? true : false,
    dashTodayInvocie: UpdateUser?.dashTodayInvocie ? true : false,
    dashTotalDueAmt: UpdateUser?.dashTotalDueAmt ? true : false,
    dashTodayMemBirtdhay: UpdateUser?.dashTodayMemBirtdhay ? true : false,
    dashTodayExpense: UpdateUser?.dashTodayExpense ? true : false,
    dashTodayEmployee: UpdateUser?.dashTodayEmployee ? true : false,
    dashTotalExpense: UpdateUser?.dashTotalExpense ? true : false,
    dashAllExpiredPackage: UpdateUser?.dashAllExpiredPackage ? true : false,
    dashTotalProfit: UpdateUser?.dashTotalProfit ? true : false,
    dashTodayEnquiry: UpdateUser?.dashTodayEnquiry ? true : false,
    dashTodayFollowup: UpdateUser?.dashTodayFollowup ? true : false,
    dashTodayPurchase: UpdateUser?.dashTodayPurchase ? true : false,
    dashTotalPurchase: UpdateUser?.dashTotalPurchase ? true : false,
    dashTotalClearingAmount: UpdateUser?.dashTotalClearingAmount ? true : false,
    dashTodaySell: UpdateUser?.dashTodaySell ? true : false,
    dashTotalSell: UpdateUser?.dashTotalSell ? true : false,
    dashtotalsellDueAmt: UpdateUser?.dashtotalsellDueAmt ? true : false,
    dashlowstock: UpdateUser?.dashlowstock ? true : false,
    dashEnrolledAmount: UpdateUser?.dashEnrolledAmount ? true : false,
    dashStockValue: UpdateUser?.dashStockValue ? true : false,
    dashtSellPurchaseValue: UpdateUser?.dashtSellPurchaseValue ? true : false,
    dashtodayPOSAmtInvoice: UpdateUser?.dashtodayPOSAmtInvoice ? true : false,
    dashtotalPOSAmtInvoice: UpdateUser?.dashtotalPOSAmtInvoice ? true : false,
    dashTotalMemberProfit: UpdateUser?.dashTotalMemberProfit ? true : false,
    dashTotalPOSProfit: UpdateUser?.dashTotalPOSProfit ? true : false,
    dashProfitFromBill: UpdateUser?.dashProfitFromBill ? true : false,
    dashPOSProfitFromBill: UpdateUser?.dashPOSProfitFromBill ? true : false,
    dashTodayPosExpense: UpdateUser?.dashTodayPosExpense ? true : false,
    dashTotalPOSExpense: UpdateUser?.dashTotalPOSExpense ? true : false,
  };

  // dashEnrolledAmount,
  // dashStockValue,
  // dashtSellPurchaseValue,
  // dashtodayPOSAmtInvoice,
  // dashtotalPOSAmtInvoice,

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,

    // validationSchema: Schemas,

    onSubmit: async (values) => {
      //   console.log(values, "valee");
      // setsmsData(values);
      const formData = {
        dashTotalMember: values?.dashTotalMember ? 1 : 0,
        dashTotalActiveMember: values?.dashTotalActiveMember ? 1 : 0,
        dashTotalInactiveMember: values?.dashTotalInactiveMember ? 1 : 0,
        dashTodayExpiry: values?.dashTodayExpiry ? 1 : 0,
        dashTotalInvoice: values?.dashTotalInvoice ? 1 : 0,
        dashTodayEnrolled: values?.dashTodayEnrolled ? 1 : 0,
        dashTodayEMPBirthday: values?.dashTodayEMPBirthday ? 1 : 0,
        dashTodayInvocie: values?.dashTodayInvocie ? 1 : 0,
        dashTotalDueAmt: values?.dashTotalDueAmt ? 1 : 0,
        dashTodayMemBirtdhay: values?.dashTodayMemBirtdhay ? 1 : 0,
        dashTodayExpense: values?.dashTodayExpense ? 1 : 0,
        dashTodayEmployee: values?.dashTodayEmployee ? 1 : 0,
        dashTotalExpense: values?.dashTotalExpense ? 1 : 0,
        dashAllExpiredPackage: values?.dashAllExpiredPackage ? 1 : 0,
        dashTotalProfit: values?.dashTotalProfit ? 1 : 0,
        dashTodayEnquiry: values?.dashTodayEnquiry ? 1 : 0,
        dashTodayFollowup: values?.dashTodayFollowup ? 1 : 0,
        dashTodayPurchase: values?.dashTodayPurchase ? 1 : 0,
        dashTotalPurchase: values?.dashTotalPurchase ? 1 : 0,
        dashTotalClearingAmount: values?.dashTotalClearingAmount ? 1 : 0,
        dashTodaySell: values?.dashTodaySell ? 1 : 0,
        dashTotalSell: values?.dashTotalSell ? 1 : 0,
        dashtotalsellDueAmt: values?.dashtotalsellDueAmt ? 1 : 0,
        dashlowstock: values?.dashlowstock ? 1 : 0,
        dashEnrolledAmount: values?.dashEnrolledAmount ? 1 : 0,
        dashStockValue: values?.dashStockValue ? 1 : 0,
        dashtSellPurchaseValue: values?.dashtSellPurchaseValue ? 1 : 0,
        dashtodayPOSAmtInvoice: values?.dashtodayPOSAmtInvoice ? 1 : 0,
        dashtotalPOSAmtInvoice: values?.dashtotalPOSAmtInvoice ? 1 : 0,
        dashTotalMemberProfit: values?.dashTotalMemberProfit ? 1 : 0,
        dashTotalPOSProfit: values?.dashTotalPOSProfit ? 1 : 0,
        dashProfitFromBill: values?.dashProfitFromBill ? 1 : 0,
        dashPOSProfitFromBill: values?.dashPOSProfitFromBill ? 1 : 0,
        dashTodayPosExpense: values?.dashTodayPosExpense ? 1 : 0,
        dashTotalPOSExpense: values?.dashTotalPOSExpense ? 1 : 0,
        notifyreceiverMobilenoCountryCode:
          UpdateUser?.notifyreceiverMobilenoCountryCode || "",
        notifyreceiverMobileNos: UpdateUser?.notifyreceiverMobileNos || "",
        notifyreceiverWhatsAPPCountryCode:
          UpdateUser?.notifyreceiverWhatsAPPCountryCode || "",
        notifyreceiverWhatsAPPMobileNos:
          UpdateUser?.notifyreceiverWhatsAPPMobileNos || "",
        notifyreceiverEmailIDs: UpdateUser?.notifyreceiverEmailIDs || "",
        notifytodayExpire: UpdateUser?.notifytodayExpire || 0,
        notifytodayBirthday: UpdateUser?.notifytodayBirthday || 0,
        notifytodayAnniversary: UpdateUser?.notifytodayAnniversary || 0,
        notifytodayFollowup: UpdateUser?.notifytodayFollowup || 0,
        notifytodayPresent: UpdateUser?.notifytodayPresent || 0,
        notifytodayInquiry: UpdateUser?.notifytodayInquiry || 0,
        notifytodayEnrollment: UpdateUser?.notifytodayEnrollment || 0,
        notifytodayEmployee: UpdateUser?.notifytodayEmployee || 0,
        notifytodayCollection: UpdateUser?.notifytodayCollection || 0,
        notifytodayExpense: UpdateUser?.notifytodayExpense || 0,
        notifytodayIncome: UpdateUser?.notifytodayIncome || 0,
        notifytodayPurchase: UpdateUser?.notifytodayPurchase || 0,
        notifytodaySell: UpdateUser?.notifytodaySell || 0,
        notifytodayStock: UpdateUser?.notifytodayStock || 0,
        notifytodayDue: UpdateUser?.notifytodayDue || 0,
        notifytotalmember: UpdateUser?.notifytotalmember || 0,
        notifytotalActivemember: UpdateUser?.notifytotalActivemember || 0,
        notifytotalInActivemember: UpdateUser?.notifytotalInActivemember || 0,
        notifyexpiringMemberBeforeDays:
          UpdateUser?.notifyexpiringMemberBeforeDays || 0,
        notifyexpiringMemberBeforeDayInterval:
          UpdateUser?.notifyexpiringMemberBeforeDayInterval || 0,
        notifyexpiredMemberAfterDays:
          UpdateUser?.notifyexpiredMemberAfterDays || 0,
        notifyexpiredMemberAfterDayInterval:
          UpdateUser?.notifyexpiredMemberAfterDayInterval || 0,
        notifyisBranchReceiveNotification:
          UpdateUser?.notifyisBranchReceiveNotification || 0,
        notifyisCompanyReceiveNotification:
          UpdateUser?.notifyisCompanyReceiveNotification || 0,
        notifyisSMSNotification: UpdateUser?.notifyisSMSNotification || 0,
        notifyisEmailNotification: UpdateUser?.notifyisEmailNotification || 0,
        notifyExpiredPackage: UpdateUser?.notifyExpiredPackage || 0,
        notifyExpiringPackage: UpdateUser?.notifyExpiringPackage || 0,
        notifyisWhatsAPPNotification:
          UpdateUser?.notifyisWhatsAPPNotification || 0,
        smsCompany: UpdateUser?.smsCompany || "",
        smsUsername: UpdateUser?.smsUsername || "",
        smsPassword: UpdateUser?.smsPassword || "",
        smsSenderID: UpdateUser?.smsSenderID || "",
        smsStatus: UpdateUser?.smsStatus || "",
        smsSupplementStatus: UpdateUser?.smsSupplementStatus || "",
        iBranchSettingUUID:
          UpdateUser?.iBranchSettingUUID || ActiveBranchSettingUUID,
        branchID: ActiveBranchID || "",
        branchUUID: ActiveBranchUUID || "",
        courrencyID: UpdateUser?.iCountryCurrencyID || "",
        dataFormate: UpdateUser?.idateFormate || "",
        timeFormate: UpdateUser?.itimeFormate || "",
        qrAttendanceLogType: UpdateUser?.displayQRAttendanceLogType || "",
        minSuppProductNotify: UpdateUser?.minSuppProductNotify || "",
        membershipFreezeShargeable:
          UpdateUser?.membershipFreezeChargeable || "",
        invoiceNotes: UpdateUser?.InvoiceNotes || "",
        invoiceSupplementNotes: UpdateUser?.suppInvocieNotes || "",
        invoiceprefx: UpdateUser?.inVoicePrefix || "",
        expiredPackageDays: UpdateUser?.expiredListInDays || "",
        whatsAppCompany: UpdateUser?.whatsAppCompany || "",
        whatsAppUsername: UpdateUser?.whatsUsername || "",
        whatsaAppPassword: UpdateUser?.whatsaAppPassword || "",
        whatsAppBalToken: UpdateUser?.whatsAppBalToken || "",
        whatsAppStatus: UpdateUser?.whatsAppStatus || "",
        whatsAppSupplementStatus: UpdateUser?.whatsAppSupplementStatus || "",
        SMTPUserName: UpdateUser?.SMTPUserName || "",
        SMTPPassword: UpdateUser?.SMTPPassword || "",
        SMTPHost: UpdateUser?.SMTPHost || "",
        SMTPFromEmail: UpdateUser?.SMTPFromEmail || "",
        SMTPPort: UpdateUser?.SMTPPort || "",
        SMTPSecure: UpdateUser?.SMTPSecure || "",
        SMTPrequireTLS: UpdateUser?.SMTPrequireTLS || "",
        SMTPStatus: UpdateUser?.SMTPStatus || "",
        SMTPSupplementStatus: UpdateUser?.SMTPSupplementStatus || "",
      };
      const response = await UpdateCompany(formData);
      if (response.status === 200) {
        detailsCompany();
        // setsmsData(response);
        setSuccessResponse(true);
        setErrorResponse(false);
        setErr("");
      } else {
        setSuccessResponse(false);
        setErrorResponse(true);
        setErr(response?.data?.replyMsg);
        if (Array.isArray(response?.data?.replyMsg)) {
          let er = response?.data?.replyMsg;
          setErr(er);
        }
      }
    },
  });
  return (
    <div className="container form">
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.MEMBER",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalMember"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalMember || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.ACTIVEMEMBER",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalActiveMember"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalActiveMember || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.INACTIVEMEMBER",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalInactiveMember"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalInactiveMember || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.EXPIRY",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodayExpiry"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodayExpiry || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.ALLEXPIREPACKAGE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashAllExpiredPackage"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashAllExpiredPackage || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.ENROLLED",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodayEnrolled"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodayEnrolled || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.EMPBIRTH",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodayEMPBirthday"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodayEMPBirthday || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.INVOICE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodayInvocie"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodayInvocie || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.TINVOICE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalInvoice"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalInvoice || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.DUEAMOUNT",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalDueAmt"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalDueAmt || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.MEMERBIRTH",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodayMemBirtdhay"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodayMemBirtdhay || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.EXPENSE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodayExpense"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodayExpense || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.EMPLOYEE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodayEmployee"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodayEmployee || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.TOTALEXPENSE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalExpense"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalExpense || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>

          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.TOTALPROFIT",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalProfit"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalProfit || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.ENQUIRY",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodayEnquiry"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodayEnquiry || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.FOLLOWUP",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodayFollowup"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodayFollowup || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.PURCHASE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodayPurchase"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodayPurchase || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.TOTALPURCHASE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalPurchase"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalPurchase || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.CLEARINGAMOUNT",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalClearingAmount"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalClearingAmount || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.SELL",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodaySell"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodaySell || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "COMMON.DUESELLAMOUNT",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashtotalsellDueAmt"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashtotalsellDueAmt || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.TOTALSELL",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalSell"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalSell || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.LOWSTOCK",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashlowstock"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashlowstock || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.ENROLLEDAMOUNT",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashEnrolledAmount"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashEnrolledAmount || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.STOCKVALUE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashStockValue"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashStockValue || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.SELLPURCHASEPRICE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashtSellPurchaseValue"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashtSellPurchaseValue || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.TSELLPAYMENTINVOICE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashtodayPOSAmtInvoice"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashtodayPOSAmtInvoice || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.SELLPAYMENTINVOICE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashtotalPOSAmtInvoice"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashtotalPOSAmtInvoice || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "TITLE.DASHBOARD.MEMBERPROFIT",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalMemberProfit"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalMemberProfit || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "TITLE.DASHBOARD.POSPROFIT",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalPOSProfit"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalPOSProfit || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "TITLE.DASHBOARD.PROFITTOBILL",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashProfitFromBill"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashProfitFromBill || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "TITLE.DASHBOARD.PROFITPOSTOBILL",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashPOSProfitFromBill"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashPOSProfitFromBill || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "TITLE.DASHBOARD.TPOSEXPENSE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTodayPosExpense"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTodayPosExpense || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "TITLE.DASHBOARD.TOTALPOSEXPENSE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="dashTotalPOSExpense"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.dashTotalPOSExpense || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
        </div>

        {(Settingedit === 1 && userType === "employee") ||
        userType === "admin" ||
        userType === "channelPartner" ? (
          <button type="submit" className="btn btn-primary btn-block m-4">
            {intl.formatMessage({
              id: "MODULE.PACKAGEPERIOD.SAVEBUTTON",
            })}
          </button>
        ) : (
          ""
        )}
      </Form>
    </div>
  );
};

export default DashboardSetting;
