import { useFormik } from "formik";
// import "./setting.css";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { UpdateCompany } from "../redux/settingCRUD";
import data from "../../../../config.json";
import Select from "react-select";
const NotificationSetting = ({
  UpdateUser,
  ActiveBranchUUID,
  ActiveBranchID,
  detailsCompany,
  setSuccessResponse,
  setErrorResponse,
  setErr,
  ActiveBranchSettingUUID,
  CountryCode,
  Settingedit,
}: any) => {
  const intl = useIntl();

  let data1: any = localStorage?.getItem("activeUserType");
  const userType = JSON.parse(data1);

  const Schemas = Yup.object().shape({
    notifyreceiverMobilenoCountryCode: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({ id: "VALIDATION.PROFILE.COUNTRY_CODE.REQUIRED" })
      ),
    notifyreceiverMobileNos: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "VALIDATION.MOBILENO" })),
    notifyreceiverWhatsAPPCountryCode: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({ id: "VALIDATION.PROFILE.COUNTRY_CODE.REQUIRED" })
      ),
    notifyreceiverWhatsAPPMobileNos: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "NOTIFICATION.WHATSAPP.NO.REQ" })),
    notifyreceiverEmailIDs: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.COMPANY.EMAIL" })),
  });

  const initialState = {
    notifyreceiverMobilenoCountryCode:
      UpdateUser?.notifyreceiverMobilenoCountryCode
        ? UpdateUser?.notifyreceiverMobilenoCountryCode
        : "",
    notifyreceiverMobileNos: UpdateUser?.notifyreceiverMobileNos
      ? UpdateUser?.notifyreceiverMobileNos
      : "",
    notifyreceiverWhatsAPPCountryCode:
      UpdateUser?.notifyreceiverWhatsAPPCountryCode
        ? UpdateUser?.notifyreceiverWhatsAPPCountryCode
        : "",
    notifyreceiverWhatsAPPMobileNos: UpdateUser?.notifyreceiverWhatsAPPMobileNos
      ? UpdateUser?.notifyreceiverWhatsAPPMobileNos
      : "",
    notifyreceiverEmailIDs: UpdateUser?.notifyreceiverEmailIDs
      ? UpdateUser?.notifyreceiverEmailIDs
      : "",
    notifytodayExpire: UpdateUser?.notifytodayExpire ? true : false,
    notifytodayBirthday: UpdateUser?.notifytodayBirthday ? true : false,
    notifytodayAnniversary: UpdateUser?.notifytodayAnniversary ? true : false,
    notifytodayFollowup: UpdateUser?.notifytodayFollowup ? true : false,
    notifytodayPresent: UpdateUser?.notifytodayPresent ? true : false,
    notifytodayInquiry: UpdateUser?.notifytodayInquiry ? true : false,
    notifytodayEnrollment: UpdateUser?.notifytodayEnrollment ? true : false,
    notifytodayEmployee: UpdateUser?.notifytodayEmployee ? true : false,
    notifytodayCollection: UpdateUser?.notifytodayCollection ? true : false,
    notifytodayExpense: UpdateUser?.notifytodayExpense ? true : false,
    notifytodayIncome: UpdateUser?.notifytodayIncome ? true : false,
    notifytodayPurchase: UpdateUser?.notifytodayPurchase ? true : false,
    notifytodaySell: UpdateUser?.notifytodaySell ? true : false,
    notifytodayStock: UpdateUser?.notifytodayStock ? true : false,
    notifytodayDue: UpdateUser?.notifytodayDue ? true : false,
    notifytotalmember: UpdateUser?.notifytotalmember ? true : false,
    notifytotalActivemember: UpdateUser?.notifytotalActivemember ? true : false,
    notifyExpiredPackage: UpdateUser?.notifyExpiredPackage ? true : false,
    notifyExpiringPackage: UpdateUser?.notifyExpiringPackage ? true : false,
    notifytotalInActivemember: UpdateUser?.notifytotalInActivemember
      ? true
      : false,
    notifyexpiringMemberBeforeDays:
      UpdateUser?.notifyexpiringMemberBeforeDays || "",
    notifyexpiringMemberBeforeDayInterval:
      UpdateUser?.notifyexpiringMemberBeforeDayInterval || "",
    notifyexpiredMemberAfterDays:
      UpdateUser?.notifyexpiredMemberAfterDays || "",
    notifyexpiredMemberAfterDayInterval:
      UpdateUser?.notifyexpiredMemberAfterDayInterval || "",
    notifyisBranchReceiveNotification:
      UpdateUser?.notifyisBranchReceiveNotification ? true : false,
    notifyisCompanyReceiveNotification:
      UpdateUser?.notifyisCompanyReceiveNotification ? true : false,
    notifyisSMSNotification: UpdateUser?.notifyisSMSNotification ? true : false,
    notifyisEmailNotification: UpdateUser?.notifyisEmailNotification
      ? true
      : false,
    notifyisWhatsAPPNotification: UpdateUser?.notifyisWhatsAPPNotification
      ? true
      : false,
  };
  // console.log(UpdateUser, "UpdateUser");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,

    // validationSchema: Schemas,

    onSubmit: async (values) => {
      //   console.log(values, "valee");
      // setsmsData(values);
      const formData = {
        notifyreceiverMobilenoCountryCode:
          values?.notifyreceiverMobilenoCountryCode || "",
        notifyreceiverMobileNos: values?.notifyreceiverMobileNos || "",
        notifyreceiverWhatsAPPCountryCode:
          values?.notifyreceiverWhatsAPPCountryCode || "",
        notifyreceiverWhatsAPPMobileNos:
          values?.notifyreceiverWhatsAPPMobileNos || "",
        notifyreceiverEmailIDs: values?.notifyreceiverEmailIDs || "",
        notifytodayExpire: values?.notifytodayExpire ? 1 : 0,
        notifytodayBirthday: values?.notifytodayBirthday ? 1 : 0,
        notifytodayAnniversary: values?.notifytodayAnniversary ? 1 : 0,
        notifytodayFollowup: values?.notifytodayFollowup ? 1 : 0,
        notifytodayPresent: values?.notifytodayPresent ? 1 : 0,
        notifytodayInquiry: values?.notifytodayInquiry ? 1 : 0,
        notifytodayEnrollment: values?.notifytodayEnrollment ? 1 : 0,
        notifytodayEmployee: values?.notifytodayEmployee ? 1 : 0,
        notifytodayCollection: values?.notifytodayCollection ? 1 : 0,
        notifytodayExpense: values?.notifytodayExpense ? 1 : 0,
        notifytodayIncome: values?.notifytodayIncome ? 1 : 0,
        notifytodayPurchase: values?.notifytodayPurchase ? 1 : 0,
        notifytodaySell: values?.notifytodaySell ? 1 : 0,
        notifytodayStock: values?.notifytodayStock ? 1 : 0,
        notifytodayDue: values?.notifytodayDue ? 1 : 0,
        notifytotalmember: values?.notifytotalmember ? 1 : 0,
        notifytotalActivemember: values?.notifytotalActivemember ? 1 : 0,
        notifytotalInActivemember: values?.notifytotalInActivemember ? 1 : 0,
        notifyexpiringMemberBeforeDays:
          values?.notifyexpiringMemberBeforeDays || 0,
        notifyexpiringMemberBeforeDayInterval:
          values?.notifyexpiringMemberBeforeDayInterval || 0,
        notifyexpiredMemberAfterDays: values?.notifyexpiredMemberAfterDays || 0,
        notifyexpiredMemberAfterDayInterval:
          values?.notifyexpiredMemberAfterDayInterval || 0,
        notifyisBranchReceiveNotification:
          values?.notifyisBranchReceiveNotification ? 1 : 0,
        notifyisCompanyReceiveNotification:
          values?.notifyisCompanyReceiveNotification ? 1 : 0,
        notifyisSMSNotification: values?.notifyisSMSNotification ? 1 : 0,
        notifyisEmailNotification: values?.notifyisEmailNotification ? 1 : 0,
        notifyExpiringPackage: values?.notifyExpiringPackage ? 1 : 0,
        notifyExpiredPackage: values?.notifyExpiredPackage ? 1 : 0,
        notifyisWhatsAPPNotification: values?.notifyisWhatsAPPNotification
          ? 1
          : 0,
        dashTotalMember: UpdateUser?.dashTotalMember || 0,
        dashTotalActiveMember: UpdateUser?.dashTotalActiveMember || 0,
        dashTotalInactiveMember: UpdateUser?.dashTotalInactiveMember || 0,
        dashTodayExpiry: UpdateUser?.dashTodayExpiry || 0,
        dashTotalInvoice: UpdateUser?.dashTotalInvoice || 0,
        dashTodayEnrolled: UpdateUser?.dashTodayEnrolled || 0,
        dashTodayEMPBirthday: UpdateUser?.dashTodayEMPBirthday || 0,
        dashTodayInvocie: UpdateUser?.dashTodayInvocie || 0,
        dashTotalDueAmt: UpdateUser?.dashTotalDueAmt || 0,
        dashTodayMemBirtdhay: UpdateUser?.dashTodayMemBirtdhay || 0,
        dashTodayExpense: UpdateUser?.dashTodayExpense || 0,
        dashTodayEmployee: UpdateUser?.dashTodayEmployee || 0,
        dashTotalExpense: UpdateUser?.dashTotalExpense || 0,
        dashAllExpiredPackage: UpdateUser?.dashAllExpiredPackage || 0,
        dashTotalProfit: UpdateUser?.dashTotalProfit || 0,
        dashTodayEnquiry: UpdateUser?.dashTodayEnquiry || 0,
        dashTodayFollowup: UpdateUser?.dashTodayFollowup || 0,
        dashTodayPurchase: UpdateUser?.dashTodayPurchase || 0,
        dashTotalPurchase: UpdateUser?.dashTotalPurchase || 0,
        dashTotalClearingAmount: UpdateUser?.dashTotalClearingAmount || 0,
        dashTodaySell: UpdateUser?.dashTodaySell || 0,
        dashTotalSell: UpdateUser?.dashTotalSell || 0,
        dashtotalsellDueAmt: UpdateUser?.dashtotalsellDueAmt || 0,
        dashlowstock: UpdateUser?.dashlowstock || 0,
        dashEnrolledAmount: UpdateUser?.dashEnrolledAmount || 0,
        dashStockValue: UpdateUser?.dashStockValue || 0,
        dashtSellPurchaseValue: UpdateUser?.dashtSellPurchaseValue || 0,
        dashtodayPOSAmtInvoice: UpdateUser?.dashtodayPOSAmtInvoice || 0,
        dashtotalPOSAmtInvoice: UpdateUser?.dashtotalPOSAmtInvoice || 0,
        dashTotalMemberProfit: UpdateUser?.dashTotalMemberProfit || 0,
        dashTotalPOSProfit: UpdateUser?.dashTotalPOSProfit || 0,
        dashPOSProfitFromBill: UpdateUser?.dashPOSProfitFromBill || 0,
        dashTodayPosExpense: UpdateUser?.dashTodayPosExpense || 0,
        dashTotalPOSExpense: UpdateUser?.dashTotalPOSExpense || 0,
        smsCompany: UpdateUser?.smsCompany || "",
        smsUsername: UpdateUser?.smsUsername || "",
        smsPassword: UpdateUser?.smsPassword || "",
        smsSenderID: UpdateUser?.smsSenderID || "",
        smsStatus: UpdateUser?.smsStatus || "",
        smsSupplementStatus: UpdateUser?.smsSupplementStatus || "",
        iBranchSettingUUID:
          UpdateUser?.iBranchSettingUUID || ActiveBranchSettingUUID,
        branchID: ActiveBranchID || "",
        branchUUID: ActiveBranchUUID || "",
        courrencyID: UpdateUser?.iCountryCurrencyID || "",
        dataFormate: UpdateUser?.idateFormate || "",
        timeFormate: UpdateUser?.itimeFormate || "",
        qrAttendanceLogType: UpdateUser?.displayQRAttendanceLogType || "",
        minSuppProductNotify: UpdateUser?.minSuppProductNotify || "",
        membershipFreezeShargeable:
          UpdateUser?.membershipFreezeChargeable || "",
        invoiceNotes: UpdateUser?.InvoiceNotes || "",
        invoiceSupplementNotes: UpdateUser?.suppInvocieNotes || "",
        invoiceprefx: UpdateUser?.inVoicePrefix || "",
        expiredPackageDays: UpdateUser?.expiredListInDays || "",
        whatsAppCompany: UpdateUser?.whatsAppCompany || "",
        whatsAppUsername: UpdateUser?.whatsUsername || "",
        whatsaAppPassword: UpdateUser?.whatsaAppPassword || "",
        whatsAppBalToken: UpdateUser?.whatsAppBalToken || "",
        whatsAppStatus: UpdateUser?.whatsAppStatus || "",
        whatsAppSupplementStatus: UpdateUser?.whatsAppSupplementStatus || "",
        SMTPUserName: UpdateUser?.SMTPUserName || "",
        SMTPPassword: UpdateUser?.SMTPPassword || "",
        SMTPHost: UpdateUser?.SMTPHost || "",
        SMTPFromEmail: UpdateUser?.SMTPFromEmail || "",
        SMTPPort: UpdateUser?.SMTPPort || "",
        SMTPSecure: UpdateUser?.SMTPSecure || "",
        SMTPrequireTLS: UpdateUser?.SMTPrequireTLS || "",
        SMTPStatus: UpdateUser?.SMTPStatus || "",
        SMTPSupplementStatus: UpdateUser?.SMTPSupplementStatus || "",
      };
      const response = await UpdateCompany(formData);
      if (response.status === 200) {
        detailsCompany();
        // setsmsData(response);
        setSuccessResponse(true);
        setErrorResponse(false);
        setErr("");
      } else {
        setSuccessResponse(false);
        setErrorResponse(true);
        setErr(response?.data?.replyMsg);
        if (Array.isArray(response?.data?.replyMsg)) {
          let er = response?.data?.replyMsg;
          setErr(er);
        }
      }
    },
  });
  return (
    <div className="container form">
      <Form onSubmit={formik.handleSubmit}>
        <div className="row d-none">
          <div className="col-md-6">
            <div className="row">
              <h3>{intl.formatMessage({ id: "COMMON.SMS" })}</h3>
              <div className="col-4">
                <div className="mb-10">
                  <label className=" form-label">
                    {intl.formatMessage({
                      id: "NOTIFICATION.MOBILE.COUNTRYCODE",
                    })}
                  </label>

                  <Select
                    name="notifyreceiverMobilenoCountryCode"
                    className="basic-single"
                    value={{
                      value: formik.values.notifyreceiverMobilenoCountryCode,
                      label: formik.values.notifyreceiverMobilenoCountryCode,
                    }}
                    onChange={(options) =>
                      formik.setFieldValue(
                        "notifyreceiverMobilenoCountryCode",
                        options?.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    options={CountryCode?.map((element: any) => ({
                      value: element.countryDialCode,
                      label: `${element.countryDialCode} (${element.countryName})`,
                    }))}
                  />

                  {formik.errors.notifyreceiverMobilenoCountryCode && (
                    <div className="error mt-2">
                      {formik.touched.notifyreceiverMobilenoCountryCode &&
                        formik.errors.notifyreceiverMobilenoCountryCode}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-8">
                <div className="mb-10">
                  <label className=" form-label">
                    {intl.formatMessage({
                      id: "NOTIFICATION.MOBILE.NO",
                    })}
                  </label>

                  <input
                    type="text"
                    name="notifyreceiverMobileNos"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.notifyreceiverMobileNos}
                  />
                  <p className="text-info ">
                    <i className="fa fa-circle-info text-info me-2"></i>
                    <span>
                      {intl.formatMessage({
                        id: "NOTIFICATION.COMMASEPARATED",
                      })}
                    </span>
                  </p>
                  {formik.errors.notifyreceiverMobileNos && (
                    <div className="error mt-2">
                      {formik.touched.notifyreceiverMobileNos &&
                        formik.errors.notifyreceiverMobileNos}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <h3>{intl.formatMessage({ id: "COMMON.WHATSAPP" })}</h3>
              <div className="col-4">
                <div className="mb-10">
                  <label className=" form-label">
                    {intl.formatMessage({
                      id: "NOTIFICATION.MOBILE.COUNTRYCODE",
                    })}
                  </label>

                  <Select
                    name="notifyreceiverWhatsAPPCountryCode"
                    className="basic-single"
                    value={{
                      value: formik.values.notifyreceiverWhatsAPPCountryCode,
                      label: formik.values.notifyreceiverWhatsAPPCountryCode,
                    }}
                    onChange={(options) =>
                      formik.setFieldValue(
                        "notifyreceiverWhatsAPPCountryCode",
                        options?.value
                      )
                    }
                    options={CountryCode?.map((element: any) => ({
                      value: element.countryDialCode,
                      label: `${element.countryDialCode} (${element.countryName})`,
                    }))}
                    onBlur={formik.handleBlur}
                  />

                  {formik.errors.notifyreceiverWhatsAPPCountryCode && (
                    <div className="error mt-2">
                      {formik.touched.notifyreceiverWhatsAPPCountryCode &&
                        formik.errors.notifyreceiverWhatsAPPCountryCode}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-8">
                <div className="mb-10">
                  <label className=" form-label">
                    {intl.formatMessage({
                      id: "NOTIFICATION.WHATSAPP.NO",
                    })}
                  </label>

                  <input
                    type="text"
                    name="notifyreceiverWhatsAPPMobileNos"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.notifyreceiverWhatsAPPMobileNos}
                    onBlur={formik.handleBlur}
                  />
                  <p className="text-info ">
                    <i className="fa fa-circle-info text-info me-2"></i>
                    <span>
                      {intl.formatMessage({
                        id: "NOTIFICATION.COMMASEPARATED",
                      })}
                    </span>
                  </p>
                  {formik.errors.notifyreceiverWhatsAPPMobileNos && (
                    <div className="error mt-2">
                      {formik.touched.notifyreceiverWhatsAPPMobileNos &&
                        formik.errors.notifyreceiverWhatsAPPMobileNos}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-2 d-none">
          <h3>{intl.formatMessage({ id: "COMMON.EMAIL" })}</h3>
          <div className="col-md-8 ">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.EMAIL",
                })}
              </label>

              <input
                type="text"
                name="notifyreceiverEmailIDs"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.notifyreceiverEmailIDs}
                onBlur={formik.handleBlur}
              />
              <p className="text-info ">
                <i className="fa fa-circle-info text-info me-2"></i>
                <span>
                  {intl.formatMessage({ id: "NOTIFICATION.COMMASEPARATED" })}
                </span>
              </p>
              {formik.errors.notifyreceiverEmailIDs && (
                <div className="error mt-2">
                  {formik.touched.notifyreceiverEmailIDs &&
                    formik.errors.notifyreceiverEmailIDs}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-6 ">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.EXPIRY",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayExpire"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayExpire || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.SETTING.BIRTHDAY",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayBirthday"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayBirthday || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.SETTING.ANNIVERSARY",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayAnniversary"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayAnniversary || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 ">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.FOLLOWUP",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayFollowup"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayFollowup || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.SETTING.PRESENT",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayPresent"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayPresent || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.ENQUIRY",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayInquiry"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayInquiry || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.ENROLLED",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayEnrollment"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayEnrollment || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.EMPLOYEE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayEmployee"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayEmployee || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.SETTING.COLLECTION",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayCollection"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayCollection || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.EXPENSE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayExpense"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayExpense || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.SETTING.INCOME",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayIncome"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayIncome || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.PURCHASE",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayPurchase"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayPurchase || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.SELL",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodaySell"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodaySell || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.SETTING.STOCK",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayStock"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayStock || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.DUEAMOUNT",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytodayDue"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytodayDue || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.MEMBER",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytotalmember"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytotalmember || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.ACTIVEMEMBER",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytotalActivemember"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytotalActivemember || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "DASHBOARD.SETTING.INACTIVEMEMBER",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifytotalInActivemember"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifytotalInActivemember || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>

          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.SETTING.BRACH",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifyisBranchReceiveNotification"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={
                    formik.values.notifyisBranchReceiveNotification || false
                  }
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.SETTING.COMPANY",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifyisCompanyReceiveNotification"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={
                    formik.values.notifyisCompanyReceiveNotification || false
                  }
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.SETTING.SMS",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifyisSMSNotification"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifyisSMSNotification || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.SETTING.EMAIL",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifyisEmailNotification"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifyisEmailNotification || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-md-3 col-6 d-none">
            <div className="mb-10">
              <label className=" form-label">
                {intl.formatMessage({
                  id: "NOTIFICATION.SETTING.WHATSAPP",
                })}
              </label>
              <div className="form-check form-switch ms-3">
                <input
                  type="checkbox"
                  name="notifyisWhatsAPPNotification"
                  className="form-check-input w-45px h-30px"
                  onChange={formik.handleChange}
                  checked={formik.values.notifyisWhatsAPPNotification || false}
                />
              </div>
              {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
            </div>
          </div>
        </div>
        {/* <div className="row">
        border border-1 border-info p-2 rounded
          <h3>Expiry Membership Notify</h3>
        </div> */}
        <div className="row ">
          <div className="col-6 ">
            <div className="col-md-6 ">
              <div className="mb-10">
                <label className=" form-label">
                  {intl.formatMessage({
                    id: "NOTIFICATION.EXPIRED.PACKAGE",
                  })}
                </label>
                <div className="form-check form-switch ms-3">
                  <input
                    type="checkbox"
                    name="notifyExpiredPackage"
                    className="form-check-input w-45px h-30px"
                    onChange={formik.handleChange}
                    checked={formik.values.notifyExpiredPackage || false}
                  />
                </div>
                {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-10">
                  <label className=" form-label">
                    {intl.formatMessage({
                      id: "NOTIFICATION.SETTING.NOTIFYEXPIRYAFTER",
                    })}
                  </label>

                  <input
                    type="text"
                    name="notifyexpiredMemberAfterDays"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.notifyexpiredMemberAfterDays}
                  />

                  {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-10">
                  <label className=" form-label">
                    {intl.formatMessage({
                      id: "NOTIFICATION.SETTING.NOTIFYEXPIRYAFTERINTERVAL",
                    })}
                  </label>

                  <input
                    type="text"
                    name="notifyexpiredMemberAfterDayInterval"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.notifyexpiredMemberAfterDayInterval}
                  />

                  {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 ">
            <div className="col-md-6">
              <div className="mb-10">
                <label className=" form-label">
                  {intl.formatMessage({
                    id: "NOTIFICATION.EXPIRING.PACKAGE",
                  })}
                </label>
                <div className="form-check form-switch ms-3">
                  <input
                    type="checkbox"
                    name="notifyExpiringPackage"
                    className="form-check-input w-45px h-30px"
                    onChange={formik.handleChange}
                    checked={formik.values.notifyExpiringPackage || false}
                  />
                </div>
                {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-10">
                  <label className=" form-label">
                    {intl.formatMessage({
                      id: "NOTIFICATION.SETTING.NOTIFYEXPIREBEFORE",
                    })}
                  </label>

                  <input
                    type="text"
                    name="notifyexpiringMemberBeforeDays"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.notifyexpiringMemberBeforeDays}
                  />

                  {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="mb-10">
                  <label className=" form-label">
                    {intl.formatMessage({
                      id: "NOTIFICATION.SETTING.NOTIFYEXPIRYBEFOREINTERVAL",
                    })}
                  </label>
                  <input
                    type="text"
                    name="notifyexpiringMemberBeforeDayInterval"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.notifyexpiringMemberBeforeDayInterval}
                  />

                  {/* {formik.errors.BiometricUserDelete && (
                <div className="error mt-2">
                  {formik.touched.BiometricUserDelete &&
                    formik.errors.BiometricUserDelete}
                </div>
              )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {(Settingedit === 1 && userType === "employee") ||
        userType === "admin" ||
        userType === "channelPartner" ? (
          <button type="submit" className="btn btn-primary btn-block m-4">
            {intl.formatMessage({
              id: "MODULE.PACKAGEPERIOD.SAVEBUTTON",
            })}
          </button>
        ) : (
          ""
        )}
      </Form>
    </div>
  );
};

export default NotificationSetting;
