/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/DateRangePicker";

import { getDashboard } from "./redux/dashboardCRUD";
import { Button, Col, Container, Row, Form, Card } from "react-bootstrap";
import DashboardCard from "./DashboardCard";
import { useFormik } from "formik";
import moment from "moment";
import DashboardMember from "../../memberModules/Memberdashboard/DashboardMember";
import EnquiryModal from "./enquirymodal/EnquiryModal";
import { title } from "process";
import { Link } from "react-router-dom";

// import {
//   MixedWidget2,
//   MixedWidget10,
//   MixedWidget11,
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget5,
//   ListsWidget6,
//   TablesWidget5,
//   TablesWidget10,
//   MixedWidget8,
// } from "../../../_metronic/partials/widgets";

const DashboardPage: FC = () => {
  const intl = useIntl();
  const admin: any = localStorage.getItem("ActiveBranch");
  const abranch = JSON.parse(admin);
  const activebranchUUID = abranch?.iBranchUUID;
  const dateFormat = abranch?.idateFormate || "YYYY-MM-DD";
  const senddateFormat = "YYYY-MM-DD";
  const currency = abranch?.currencySymbol || "₹";

  const date = moment().format(senddateFormat);
  const defaultdate = new Date();

  const [value, setValue] = useState<any>([defaultdate, defaultdate]);
  const [apiresponse, setapiresponse] = useState<any>();
  const [isUpdated, setIsUpdated] = useState<any>(false);

  const initialState = {
    startDate: date,
    endDate: date,
  };
  const [filterValues, setFilterValues] = useState(initialState);

  const dashboard = async () => {
    const queryString = new URLSearchParams(filterValues).toString();
    const response = await getDashboard(activebranchUUID, queryString);
    // console.log(response?.data?.data, "dashboard");
    if (response?.status === 200) {
      // setIsUpdated(true);
      setapiresponse(response?.data?.data);
    } else {
      // console.log(response?.data?.replyMsg);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: (values) => {
      // console.log(values, "date");
      setFilterValues(values);
    },
  });

  const handleDateReset = (values: any) => {
    setValue([defaultdate, defaultdate]);
    formik.setFieldValue("startDate", date);
    formik.setFieldValue("endDate", date);
    // setIsUpdated(false);
  };

  const handleChange = (values: any) => {
    setValue(values);
    // console.log(values,)
    if (values && values.length !== 0) {
      formik.setFieldValue(
        "startDate",
        moment(values[0]).format(senddateFormat)
      );
      formik.setFieldValue("endDate", moment(values[1]).format(senddateFormat));
    }
  };

  const handleReset = () => {
    setValue([defaultdate, defaultdate]);
    formik.resetForm();
    // handleResetFilter();
    setFilterValues(initialState);
    // setIsUpdated(false);
    // getMembers();
  };

  useEffect(() => {
    dashboard();
  }, [filterValues]);

  const sell = intl.formatMessage({ id: "DASHBOARD.SELL" });
  const SellInvoice = intl.formatMessage({ id: "DASHBOARD.POSAMOUNT" });
  const sellPurchaseValue = intl.formatMessage({
    id: "DASHBOARD.SELLPURCHASEVALUE",
  });
  const purchase = intl.formatMessage({ id: "DASHBOARD.PURCHASE" });
  const stockValue = intl.formatMessage({ id: "DASHBOARD.STOCKVALUE" });
  const purchase_clearAmount = intl.formatMessage({ id: "DASHBOARD.CLEARAMT" });
  const enquiry = intl.formatMessage({ id: "DASHBOARD.ENQUIRY" });
  const member = intl.formatMessage({ id: "DASHBOARD.MEMBER" });
  const expiry = intl.formatMessage({ id: "DASHBOARD.EXPIRY" });
  const followup = intl.formatMessage({ id: "DASHBOARD.FOLLOWUP" });
  const enrolled = intl.formatMessage({ id: "DASHBOARD.ENROLLED" });
  const enrolledAmt = intl.formatMessage({ id: "DASHBOARD.ENROLLED.AMT" });
  const employee = intl.formatMessage({ id: "DASHBOARD.EMPLOYEE" });
  const empbirth = intl.formatMessage({ id: "DASHBOARD.EMPLOYEEBIRTHDAY" });
  const membirth = intl.formatMessage({ id: "DASHBOARD.MEMBERBIRTHDAY" });
  const invoice = intl.formatMessage({ id: "DASHBOARD.INVOICE" });
  const dueamount = intl.formatMessage({ id: "DASHBOARD.DUEAMOUNT" });
  const expense = intl.formatMessage({ id: "DASHBOARD.EXPENSE" });
  const posexpense = intl.formatMessage({ id: "TITLE.DASHBOARD.POSEXPENSE" });
  const Profit = intl.formatMessage({ id: "DASHBOARD.PROFIT" });
  const Today = intl.formatMessage({ id: "DASHBOARD.TODAY" });
  const between = intl.formatMessage({ id: "DASHBOARD.BETWEEN" });
  const total = intl.formatMessage({ id: "DASHBOARD.TOTAL" });
  const lowStock = intl.formatMessage({ id: "DASHBOARD.LOWSTOCK" });
  const MemberProfit = intl.formatMessage({
    id: "TITLE.DASHBOARD.MEMBERPROFIT",
  });
  const POSProfit = intl.formatMessage({
    id: "TITLE.DASHBOARD.POSPROFIT",
  });
  const POSProfitOnly = intl.formatMessage({
    id: "TITLE.DASHBOARD.POSPROFITONLY",
  });
  const allExpiredPackage = intl.formatMessage({
    id: "DASHBOARD.ALL.EXPIREPACKAGE",
  });
  const activeMember = intl.formatMessage({
    id: "DASHBOARD.MEMBER.ACTIVE",
  });
  const inActiveMember = intl.formatMessage({
    id: "DASHBOARD.MEMBER.INACTIVE",
  });

  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [cardTitle, setCardTitle] = useState("");
  const [isFrom, setIsFrom] = useState(false);

  const handleShowModal = (
    startDate: any,
    endDate: any,
    title: any,
    isFrom: any = false
  ) => {
    setSelectedDate({ startDate, endDate });
    setCardTitle(title);
    setShowModal(true);
    // console.log("isFrom ", isFrom);
    setIsFrom(isFrom ? true : false);
  };

  // console.log(apiresponse);

  return (
    <>
      <EnquiryModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedDate={selectedDate}
        cardTitle={cardTitle}
        isFrom={isFrom}
      />
      <Container fluid className="rounded" style={{ background: "#FFFFFF" }}>
        <Card className="w-100">
          <Card.Body className="py-3">
            <Form onSubmit={formik.handleSubmit}>
              <Row className="my-4">
                {" "}
                <Col md={3} className="mb-3">
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label">
                      {intl.formatMessage({
                        id: "MODULE.MEMBERS.DATERANGE",
                      })}
                    </label>

                    <DateRangePicker
                      value={value}
                      onChange={handleChange}
                      format="MM/dd/yyyy"
                      placeholder="Select Date Range"
                      placement="right"
                      style={{ margin: "2px" }}
                      onClean={handleDateReset}
                    />
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Button
                    type="submit"
                    variant="primary"
                    className="me-3 mr-4 "
                    // onClick={(e) => handleSearchClick(e)}
                    // disabled={isLoading}
                  >
                    {intl.formatMessage({
                      id: "COMMON.APPLY",
                    })}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleReset}
                    className="me-3 "
                  >
                    {intl.formatMessage({
                      id: "COMMON.RESET",
                    })}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <Row className="my-4 ">
          {apiresponse?.dashboardPermission?.dashTotalMember ||
          apiresponse?.dashboardPermission?.dashTotalActiveMember ||
          apiresponse?.dashboardPermission?.dashTotalInactiveMember ||
          apiresponse?.dashboardPermission?.dashTotalInvoice ||
          apiresponse?.dashboardPermission?.dashTodayEnrolled ||
          apiresponse?.dashboardPermission?.dashTodayEMPBirthday ||
          apiresponse?.dashboardPermission?.dashTodayInvocie ||
          apiresponse?.dashboardPermission?.dashTotalDueAmt ||
          apiresponse?.dashboardPermission?.dashTodayMemBirtdhay ||
          apiresponse?.dashboardPermission?.dashTodayExpense ||
          apiresponse?.dashboardPermission?.dashTodayEmployee ||
          apiresponse?.dashboardPermission?.dashTotalExpense ||
          apiresponse?.dashboardPermission?.dashAllExpiredPackage ||
          apiresponse?.dashboardPermission?.dashTodayEnquiry ||
          apiresponse?.dashboardPermission?.dashTodayFollowup ||
          apiresponse?.dashboardPermission?.dashEnrolledAmount ||
          apiresponse?.dashboardPermission?.dashTodayExpiry ? (
            <h3>{`${member}`}</h3>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTotalMember ? (
            <Col md={3}>
              <Link
                to={`/members/list-members?startDate=${formik.values.startDate}&dateType=join&endDate=${formik.values.endDate}`}
              >
                <div
                  className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                  // onClick={() =>
                  //   handleShowModal(
                  //     formik.values.startDate,
                  //     formik.values.endDate,
                  //     "Active Member",
                  //     true
                  //   )
                  // }
                >
                  <DashboardCard
                    title=""
                    date={`${total} ${member}`}
                    subtitle={apiresponse?.totalFixedMember || 0}
                    color="success"
                  />
                </div>
              </Link>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTotalActiveMember ? (
            <Col md={3}>
              <div
                className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Active Member",
                    true
                  )
                }
              >
                <DashboardCard
                  title=""
                  date={`${total} ${activeMember}`}
                  subtitle={apiresponse?.totalactiveMember || 0}
                  color="success"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTotalInactiveMember ? (
            <Col md={3}>
              <div
                className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "InActive member",
                    true
                  )
                }
              >
                <DashboardCard
                  title=""
                  date={`${total} ${inActiveMember}`}
                  subtitle={apiresponse?.totalInactiveMember || 0}
                  color="warning"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayEmployee ? (
            <Col md={3}>
              <div className="bg-light-primary  py-5 px-2  rounded-2  mb-7">
                <Link
                  to={`employees/employee/list?startDate=${formik.values.startDate}&dateType=join&endDate=${formik.values.endDate}`}
                >
                  <DashboardCard
                    title=""
                    date={
                      formik.values.startDate === date &&
                      formik.values.endDate === date
                        ? `${Today} ${employee}`
                        : ` ${employee} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                    }
                    subtitle={apiresponse?.totalEmployee || 0}
                    color="primary"
                    slug="Employees"
                  />
                </Link>
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayExpiry ? (
            <Col md={3}>
              <div
                className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer "
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Expire Package"
                  )
                }
              >
                <DashboardCard
                  title=""
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${expiry}`
                      : ` ${expiry} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.totalExpiredPackage || 0}
                  color="warning"
                  slug="Expired packages "
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashAllExpiredPackage ? (
            <Col md={3}>
              <div
                className="bg-light-danger py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "All Expire Package",
                    true
                  )
                }
              >
                <DashboardCard
                  title=""
                  date={` ${allExpiredPackage}`}
                  subtitle={apiresponse?.allExpiredPackage || 0}
                  color="danger"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayEnrolled ? (
            <Col md={3}>
              <div className="bg-light-success py-5 px-2  rounded-2  mb-7 ">
                <DashboardCard
                  title=" "
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${enrolled}`
                      : `${enrolled} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.totalEnrolledPackage || 0}
                  color="success"
                  slug="Enrolled Packages"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashEnrolledAmount ? (
            <Col md={3}>
              <div className="bg-light-success py-5 px-2  rounded-2  mb-7 ">
                <DashboardCard
                  title={currency}
                  date={` ${total} ${enrolledAmt}`}
                  subtitle={apiresponse?.totalEnrolledAmt || 0}
                  color="success"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayEMPBirthday ? (
            <Col md={3}>
              <div
                className="bg-light-danger  py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Today Employee Birthday"
                  )
                }
              >
                <DashboardCard
                  title=""
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${empbirth}`
                      : `${empbirth} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.totalEmployeeBirthday || 0}
                  color="danger"
                  slug="Employees Birthday"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayInvocie ? (
            <Col md={3}>
              <div
                className="bg-light-danger py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Today Invoice"
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${invoice}`
                      : `${invoice} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.totalReceipt || 0}
                  color="danger"
                  slug="Invoices"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTotalInvoice ? (
            <Col md={3}>
              <div
                className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Invoice"
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${invoice}`}
                  subtitle={apiresponse?.totalFixedReceipt || 0}
                  color="warning"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayInvocie ? (
            <Col md={3}>
              <div
                className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Remaing Balance"
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${Today} ${dueamount}`}
                  subtitle={apiresponse?.totalFixedDueAmt || 0}
                  color="success"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayMemBirtdhay ? (
            <Col md={3}>
              <div
                className="bg-light-primary py-5 px-2  rounded-2  mb-7cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Today Member Birthday"
                  )
                }
              >
                <DashboardCard
                  title=""
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${membirth}`
                      : `${membirth} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.totalMemberBirthDay || 0}
                  color="primary"
                  slug="Member's Birthday "
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayExpense ? (
            <Col md={3}>
              <div
                className="bg-light-danger py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Today Expense"
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${expense}`
                      : `${expense} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.totalexpense || 0}
                  color="danger"
                  slug="Expenses"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTotalExpense ? (
            <Col md={3}>
              <div
                className="bg-light-primary py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Total Expense"
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${expense}`}
                  subtitle={apiresponse?.totalFixedExpense || 0}
                  color="primary"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayEnquiry ? (
            <Col md={3}>
              <div
                className="bg-light-primary  py-5 px-2  rounded-2  mb-7 cursor-pointer "
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Enquiry Details"
                  )
                }
              >
                <DashboardCard
                  title=""
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${enquiry}`
                      : `${enquiry} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.totalEnquiry || 0}
                  color="primary"
                  slug="Enquiries "
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayFollowup ? (
            <Col md={3}>
              <div
                className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "FollowUps"
                  )
                }
              >
                <DashboardCard
                  title=""
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${followup}`
                      : `${followup} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.totalFollowup || 0}
                  color="warning"
                  slug="Follouw-ups "
                />
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>

        <Row>
          {apiresponse?.dashboardPermission?.dashTotalMemberProfit ? (
            <h3>{intl.formatMessage({ id: "PROFIT.MEMBER" })}</h3>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashProfitFromBill ? (
            apiresponse?.dashboardPermission?.dashEnrolledAmount ? (
              <Col md={3}>
                <div className="bg-light-success py-5 px-2  rounded-2  mb-7 ">
                  <DashboardCard
                    title={currency}
                    date={`${enrolledAmt}`}
                    subtitle={apiresponse?.totalEnrolledAmt || 0}
                    color="success"
                  />
                </div>
              </Col>
            ) : (
              ""
            )
          ) : apiresponse?.dashboardPermission?.dashTotalInvoice ? (
            <Col md={3}>
              <div
                className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Invoice"
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${invoice}`}
                  subtitle={apiresponse?.totalFixedReceipt || 0}
                  color="warning"
                />
              </div>
            </Col>
          ) : (
            ""
          )}

          {apiresponse?.dashboardPermission?.dashTotalExpense ? (
            <Col md={3}>
              <div
                className="bg-light-primary py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Total Expense"
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${expense}`}
                  subtitle={apiresponse?.totalFixedExpense || 0}
                  color="primary"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTotalMemberProfit ? (
            <Col md={3}>
              <div
                className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Member Profit",
                    apiresponse?.dashboardPermission?.dashProfitFromBill === 1
                      ? true
                      : false
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`  ${MemberProfit}`}
                  subtitle={
                    apiresponse?.dashboardPermission?.dashProfitFromBill
                      ? Number(apiresponse?.totalEnrolledAmt || 0) -
                        Number(apiresponse?.totalFixedExpense || 0)
                      : Number(apiresponse?.totalFixedReceipt || 0) -
                        Number(apiresponse?.totalFixedExpense || 0)
                  }
                  color="warning"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>

        <Row>
          {/* pos  */}
          {apiresponse?.dashboardPermission?.dashTotalPurchase ||
          apiresponse?.dashboardPermission?.dashTotalClearingAmount ||
          apiresponse?.dashboardPermission?.dashTodaySell ||
          apiresponse?.dashboardPermission?.dashTotalSell ||
          apiresponse?.dashboardPermission?.dashtotalsellDueAmt ||
          apiresponse?.dashboardPermission?.dashlowstock ||
          apiresponse?.dashboardPermission?.dashStockValue ||
          apiresponse?.dashboardPermission?.dashtSellPurchaseValue ||
          apiresponse?.dashboardPermission?.dashTotalPOSExpense ||
          apiresponse?.dashboardPermission?.dashTodayPosExpense ||
          apiresponse?.dashboardPermission?.dashtodayPOSAmtInvoice ||
          apiresponse?.dashboardPermission?.dashtodayPOSAmtInvoice ||
          apiresponse?.dashboardPermission?.dashtotalPOSAmtInvoice ||
          apiresponse?.dashboardPermission?.dashTodayPurchase ? (
            <h3>{intl.formatMessage({ id: "COMMON.MYPOS" })}</h3>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayPurchase ? (
            <Col md={3}>
              <div
                className="bg-light-danger py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Today Purchase",
                    true
                  )
                }
              >
                <DashboardCard
                  title=""
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${purchase}`
                      : `${purchase} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.todayPurchase || 0}
                  color="danger"
                  slug="todayPurchase "
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTotalPurchase ? (
            <Col md={3}>
              <div
                className="bg-light-danger py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Total Purchase",
                    true
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${purchase}`}
                  subtitle={apiresponse?.totalpurchase || 0}
                  color="danger"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashStockValue ? (
            <Col md={3}>
              <div
                className="bg-light-danger py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Total Stock Value",
                    true
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${stockValue}`}
                  subtitle={apiresponse?.totalStockValue || 0}
                  color="danger"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashlowstock ? (
            <Col md={3}>
              <div
                className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Low Stock",
                    true
                  )
                }
              >
                <DashboardCard
                  title={""}
                  date={`  ${lowStock}`}
                  subtitle={""}
                  color="warning"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTotalClearingAmount ? (
            <Col md={3}>
              <div
                className="bg-light-danger py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Total ClearAmount",
                    true
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${purchase_clearAmount}`}
                  subtitle={apiresponse?.totalClearAmount || 0}
                  color="danger"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodaySell ? (
            <Col md={3}>
              <div
                className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Today Sell",
                    true
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${sell}`
                      : `${purchase} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.todaySellAmount || 0}
                  color="success"
                  slug="Today Sell"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTotalSell ? (
            <Col md={3}>
              <div
                className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Total Sell",
                    true
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${sell}`}
                  subtitle={apiresponse?.totalSellAmount || 0}
                  color="success"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashtSellPurchaseValue ? (
            <Col md={3}>
              <div
                className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Total Sell Purchase Value",
                    true
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${sellPurchaseValue}`}
                  subtitle={apiresponse?.totalSellPurchaseValue || 0}
                  color="success"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashtodayPOSAmtInvoice ? (
            <Col md={3}>
              <div
                className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Today Sell Invoice",
                    true
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${SellInvoice}`
                      : `${purchase} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.todaySellAmount || 0}
                  color="success"
                  slug="Today Sell Invoice"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashtotalPOSAmtInvoice ? (
            <Col md={3}>
              <div
                className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Total Sell Invoice",
                    true
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${SellInvoice}`}
                  subtitle={apiresponse?.totalPOSAmtInvoice || 0}
                  color="success"
                  slug="Today Sell Invoice"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashtotalsellDueAmt ? (
            <Col md={3}>
              <div
                className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Due Sell Amount",
                    true
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={` ${sell} ${dueamount}`}
                  subtitle={apiresponse?.dueSellAmount || 0}
                  color="success"
                />
              </div>
            </Col>
          ) : (
            ""
          )}

          {apiresponse?.dashboardPermission?.dashTotalPOSExpense ? (
            <Col md={3}>
              <div
                className="bg-light-primary py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Total Pos Expense"
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${posexpense}`}
                  subtitle={apiresponse?.totalPOSExpense || 0}
                  color="primary"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTodayPosExpense ? (
            <Col md={3}>
              <div
                className="bg-light-primary py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Today Pos Expense"
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${posexpense}`
                      : `${posexpense} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.TodayPOSExpense || 0}
                  color="primary"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          {apiresponse?.dashboardPermission?.dashTotalPOSProfit ? (
            <h3>{intl.formatMessage({ id: "PROFIT.POS" })} </h3>
          ) : (
            ""
          )}

          {apiresponse?.dashboardPermission?.dashPOSProfitFromBill ? (
            apiresponse?.dashboardPermission?.dashTotalPOSProfit ? (
              <Col md={3}>
                <div className="bg-light-success py-5 px-2  rounded-2  mb-7 ">
                  <DashboardCard
                    title={currency}
                    date={` ${POSProfitOnly}`}
                    subtitle={apiresponse?.totalPOSProfit || 0}
                    color="success"
                  />
                </div>
              </Col>
            ) : (
              ""
            )
          ) : apiresponse?.dashboardPermission?.dashtotalPOSAmtInvoice ? (
            <>
              <Col md={3}>
                <div
                  className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                  onClick={() =>
                    handleShowModal(
                      formik.values.startDate,
                      formik.values.endDate,
                      "Total Sell Invoice",
                      true
                    )
                  }
                >
                  <DashboardCard
                    title={currency}
                    date={`${total} ${SellInvoice}`}
                    subtitle={apiresponse?.totalPOSAmtInvoice || 0}
                    color="success"
                    slug="Today Sell Invoice"
                  />
                </div>
              </Col>
              <Col md={3}>
                <div
                  className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                  onClick={() =>
                    handleShowModal(
                      formik.values.startDate,
                      formik.values.endDate,
                      "Total Sell Purchase Value",
                      true
                    )
                  }
                >
                  <DashboardCard
                    title={currency}
                    date={`${total} ${sellPurchaseValue}`}
                    subtitle={apiresponse?.totalSellPurchaseValue || 0}
                    color="success"
                  />
                </div>
              </Col>
            </>
          ) : (
            ""
          )}
          {apiresponse?.dashboardPermission?.dashTotalPOSExpense ? (
            <Col md={3}>
              <div
                className="bg-light-primary py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Total Pos Expense"
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${posexpense}`}
                  subtitle={apiresponse?.totalPOSExpense || 0}
                  color="primary"
                />
              </div>
            </Col>
          ) : (
            ""
          )}

          {apiresponse?.dashboardPermission?.dashTotalPOSProfit ? (
            <Col md={3}>
              <div
                className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Pos Profit",
                    apiresponse?.dashboardPermission?.dashPOSProfitFromBill ===
                      1
                      ? true
                      : false
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${POSProfit}`}
                  subtitle={
                    apiresponse?.dashboardPermission?.dashPOSProfitFromBill
                      ? (apiresponse?.totalPOSProfit || 0) -
                        Number(apiresponse?.totalPOSExpense || 0)
                      : (apiresponse?.totalPOSAmtInvoice || 0) -
                        Number(apiresponse?.totalSellPurchaseValue || 0) -
                        Number(apiresponse?.totalPOSExpense || 0)
                  }
                  color="warning"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          {apiresponse?.dashboardPermission?.dashTotalProfit ? (
            <h3>{`${total} ${Profit}`} </h3>
          ) : (
            ""
          )}

          {apiresponse?.dashboardPermission?.dashTotalProfit ? (
            <Col md={3}>
              <div
                className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
                onClick={() =>
                  handleShowModal(
                    formik.values.startDate,
                    formik.values.endDate,
                    "Total Profit",
                    apiresponse?.dashboardPermission?.dashProfitFromBill
                  )
                }
              >
                <DashboardCard
                  title={currency}
                  date={`${total} ${Profit}`}
                  subtitle={
                    apiresponse?.dashboardPermission?.dashProfitFromBill
                      ? Number(apiresponse?.totalEnrolledAmt || 0) +
                        Number(apiresponse?.totalPOSProfit || 0) -
                        Number(apiresponse?.totalFixedExpense || 0)
                      : Number(apiresponse?.totalFixedReceipt || 0) +
                        Number(apiresponse?.totalPOSAmtInvoice || 0) -
                        Number(apiresponse?.totalFixedExpense || 0)
                  }
                  color="success"
                />
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Container>
    </>
  );
};

const DashboardWrapper: FC = () => {
  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      {UserType !== "member" ? <DashboardPage /> : <DashboardMember />}
    </>
  );
};

export { DashboardWrapper };

<>
  {/* begin::Row */}
  {/* <div className="row gy-5 g-xl-8">
  
  {/* <div className="col-xxl-4">
    <MixedWidget2
      className="card-xl-stretch mb-xl-8"
      chartColor="danger"
      chartHeight="200px"
      strokeColor="#cb1e46"
    />
  </div> */}
  {/* <div className="col-xxl-4">
    <ListsWidget5 className="card-xxl-stretch" />
  </div> */}
  {/* <div className="col-xxl-4">
    <MixedWidget10
      className="card-xxl-stretch-50 mb-5 mb-xl-8"
      chartColor="primary"
      chartHeight="150px"
    />
    <MixedWidget11
      className="card-xxl-stretch-50 mb-5 mb-xl-8"
      chartColor="primary"
      chartHeight="175px"
    />
  </div> 
</div> */}
  {/* end::Row */}

  {/* begin::Row */}
  {/* <div className="row gy-5 gx-xl-8">
  <div className="col-xxl-4">
    <ListsWidget3 className="card-xxl-stretch mb-xl-3" />
  </div>
  <div className="col-xl-8">
    <TablesWidget10 className="card-xxl-stretch mb-5 mb-xl-8" />
  </div>
</div> */}
  {/* end::Row */}

  {/* begin::Row */}
  {/* <div className="row gy-5 g-xl-8">
  <div className="col-xl-4">
    <ListsWidget2 className="card-xl-stretch mb-xl-8" />
  </div>
  <div className="col-xl-4">
    <ListsWidget6 className="card-xl-stretch mb-xl-8" />
  </div>
  <div className="col-xl-4">
    <ListsWidget4 className="card-xl-stretch mb-5 mb-xl-8" items={5} />
    {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
  {/* </div>
</div> */}
  {/* end::Row */}

  {/* <div className="row g-5 gx-xxl-8">
  <div className="col-xxl-4">
    <MixedWidget8
      className="card-xxl-stretch mb-xl-3"
      chartColor="success"
      chartHeight="150px"
    />
  </div>
  <div className="col-xxl-8">
    <TablesWidget5 className="card-xxl-stretch mb-5 mb-xxl-8" />
  </div>
</div> */}
</>;
