import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { setTimeout } from "timers";
import swal from "sweetalert";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "react-phone-input-2/lib/style.css";
import Dummy from "../../../../../loading_assests/dummy.png";
import "./style.css";
import {
  GetPackageDetails,
  ActiveTax,
  enquiryDetails,
  CreateMember,
  UpdateMembers,
} from "../../redux/MembersCRUD";
// import { PhoneInputProps } from "react-phone-input-2";
import { useIntl } from "react-intl";

const data: any = localStorage.getItem("CountryCurrencycode");
const CountryCode = JSON.parse(data);
const BranchModal = ({
  show,
  setShow,
  getMembers,
  UpdateUser,
  setUpdateUser,
  isUpdate,
  setisUpdate,
  activeEnqList,
  activeEmpList,
  lastMembershipId,
  activeOccList,
  activePackageList,
  activeBatchList,
  baseURL,
  gender,
  maritalStatus,
  error,
  setError,
  UserType,
  paymodeOpt,
  from,
  setFrom,
  isProfile,
}: any) => {
  const imageRef = useRef<HTMLInputElement | null>(null);
  const intl = useIntl();

  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  let ActiveBranch;
  let ActiveBranchID = "";
  let ActiveBranchUUID = "";
  let ActiveCountryCode = "";
  let ActiveCountry = "";
  if (selectedBranch) {
    ActiveBranch = JSON.parse(selectedBranch);
    if (ActiveBranch) {
      ActiveBranchID = ActiveBranch?.iBranchID || "";
      ActiveBranchUUID = ActiveBranch?.iBranchUUID || "";
      ActiveCountryCode = ActiveBranch?.countryDialCode || "";
      ActiveCountry = ActiveBranch?.countryName || "";
    }
  }

  let empdetail: any = localStorage.getItem("employeeDetails");
  let employeeType: any = JSON.parse(empdetail);
  let employee = {};
  if (UserType === "employee" && employeeType !== null) {
    employee = {
      value: employeeType?.employee_id,
      label: `${employeeType?.name} (${
        employeeType?.vCountryCode + employeeType?.vMobile
      })`,
    };
  }
  const [errors, setErrors] = useState<any>({});
  const [updatePackagePrice, setUpdatePackagePrice] = useState<boolean>(true);
  const [selectedMemberType, setSelectedMemberType] = useState<string>("spot");
  const [selectedEnqId, setSelectedEnqID] = useState<any>();
  const [selectedEnqData, setSelectedEnqData] = useState<any>();
  const [name, setName] = useState<any>("");
  const [memberID, setMemberId] = useState<any>();
  const [Mobile, setMobile] = useState<any>("");
  const [hMobile, setHMobile] = useState<any>("");
  const [selectedMartial, setSelectedMartial] = useState<any>("");
  const [selectedEmpID, setSelectedEmpID] = useState<any>(employee);
  const [selectedGender, setSelectedGender] = useState<any>({
    value: "",
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
  });
  const [dob, setDob] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [selectedOccupation, setSelectedOccupation] = useState<any>({
    value: "",
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
  });
  const [selectedPackage, setSelectedPackage] = useState<any>({
    value: "",
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
  });
  const [selectedDiscount, setSelectedDiscount] = useState<any>({
    value: "",
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
  });
  const [selectedProgrammeUUID, setSelectedProgrammeUUId] = useState<any>({});
  const [selectedPackageDetail, setSelectedPackageDetail] = useState<any>({});
  const [registrationFees, setregistrationFees] = useState<number>(0);
  const [submitted, setSubmitted] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [withOutTaxRadio, setwithOutTaxRadio] = useState<any>(false);
  const [withTaxRadio, setwithTaxRadio] = useState<any>(false);
  const [isCheckedRegFee, setIsCheckedRegFee] = useState<boolean>();
  const [discountType, setDiscountType] = useState<any>({
    value: 1,
    label: "None",
  });
  const [fixDiscount, setFixDiscount] = useState<number>(0);
  const [taxExcluded, setTaxExcluded] = useState<boolean>();
  const [taxIncluded, setTaxIncluded] = useState<boolean>(false);
  const [tax, setTax] = useState<any>([]);
  const [taxValue, setTaxValue] = useState<number>();
  const [fee, setFee] = useState<number>(0);
  const [expiryDate, setexpiryDate] = useState<any>();
  const [startDate, setstartDate] = useState<any>();
  const [dateFormat, setdateFormat] = useState<any>("YYYY-MM-DD");
  const [programFee, setProgramFee] = useState<any>();
  const [payableFee, setPayableFee] = useState<any>();
  const [taxNo, setTaxNo] = useState<any>("");
  const [doj, setDoj] = useState<any>("");
  const [status, setStatus] = useState<any>("1");
  const [doe, setDoe] = useState<any>("");
  // const [taxDetails, setTaxDetails] = useState<any>([]);
  const [batchid, setBatchID] = useState<any>({});
  const [discountValue, setDiscountValue] = useState<number>(0);
  const [countryC, setCountryC] = useState<any>({
    value: ActiveCountryCode || "",
    label: ActiveCountryCode
      ? `${ActiveCountryCode} (${ActiveCountry})`
      : intl.formatMessage({ id: "COMMON.SELECT" }),
  });
  const [countryCH, setCountryCH] = useState<any>({
    value: ActiveCountryCode || "",
    label: ActiveCountryCode
      ? `${ActiveCountryCode} (${ActiveCountry})`
      : intl.formatMessage({ id: "COMMON.SELECT" }),
  });
  const initialDate = moment().format("YYYY-MM-DD");
  const date = new Date();
  const initialTime: string = moment(date).format("HH:mm:ss");
  const time: string = moment(initialTime, "HH:mm:ss").format("HH:mm");
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [taxDetails, setTaxDetails] = useState<any>([]);
  const [selectImage, setSelectImage] = useState<any>();
  const [showImage, setShowImage] = useState<any>();
  const [remarks, setRemarks] = useState<any>("");
  const [dueAmount, setDueAmount] = useState<number>(0);
  const [paymentDate, setPaymentDate] = useState<any>(initialDate);
  const [paymentTime, setPaymentTime] = useState<any>(time);
  const [nextPaymentDate, setNextPaymentDate] = useState<any>(initialDate);
  const [selectPaymentMode, setSelectPaymentMode] = useState({
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
    value: "",
  });
  const [formError, setFormError] = useState<any>({});
  const [isValid, setIsValid] = useState<any>(false);

  //define discount type
  const discountTypeOptions = [
    { value: 1, label: "None" },
    { value: 2, label: "Complementry" },
    { value: 3, label: "Fix Discount" },
  ];
  //find options by discount type
  const findDiscountLabel = (value: number) => {
    const option = discountTypeOptions.find((option) => option.value === value);
    return option ? option.label : null;
  };
  useEffect(() => {
    if (selectedEnqData) {
      setCountryC({
        label: selectedEnqData?.countryCode,
        value: selectedEnqData?.countryCode,
      });
      setCountryCH({
        label: selectedEnqData?.homeCountryCode,
        value: selectedEnqData?.homeCountryCode,
      });
      setSelectedGender({
        label: selectedEnqData?.eGender,
        value: selectedEnqData?.eGender,
      });
      setSelectedOccupation({
        value: selectedEnqData?.iOccupationID,
        label: selectedEnqData?.OccupationName,
      });
      setSelectedEmpID({
        value: selectedEnqData?.employeeID,
        label: selectedEnqData?.employeeName,
      });
      // formik.setFieldValue("enquiryID", selectedEnqData?.iEnquiryID);
      // formik.setFieldValue("name", selectedEnqData?.name);
      // formik.setFieldValue("employeeID", selectedEnqData?.employeeID);
      setName(selectedEnqData?.name);
      setMemberId(selectedEnqData?.memberID);
      setMobile(selectedEnqData?.mobileNo);
      setHMobile(selectedEnqData?.homePhoneNo);
      setEmail(selectedEnqData?.vPEmail);
    }
  }, [selectedEnqData]);
  // console.log("isUpdate===", isUpdate);
  console.log("UpdateUser===", UpdateUser);
  useEffect(() => {
    if (isUpdate && UpdateUser) {
      setName(UpdateUser?.name);
      setMobile(UpdateUser?.mobileNo);
      setMemberId(UpdateUser?.membershipID);
      setHMobile(UpdateUser?.homePhoneNo);
      setDob(UpdateUser?.dtDOB?.split("T")[0]);
      setEmail(UpdateUser?.vEmail);
      setAddress(UpdateUser?.vAddress);
      setShowImage(`${baseURL}${UpdateUser?.vPhoto}`);
      setSelectedMemberType(UpdateUser?.iEnquiryID ? "Enquiry" : "spot");
      setCountryC({
        value: UpdateUser?.countryCode,
        label: UpdateUser?.countryCode,
      });
      setCountryCH({
        value: UpdateUser?.homeCountryCode,
        label: UpdateUser?.homeCountryCode,
      });
      setSelectedEmpID({
        value: UpdateUser?.employeeID,
        label: UpdateUser?.employeeName,
      });
      setSelectedGender({
        value: UpdateUser?.eGender,
        label: UpdateUser?.eGender,
      });
      if (UpdateUser?.eMartial !== null) {
        setSelectedMartial({
          value: UpdateUser?.eMartial,
          label: UpdateUser?.eMartial,
        });
      }

      setSelectedOccupation({
        value: UpdateUser?.iOccupationID,
        label: UpdateUser?.OccupationName,
      });
      setSelectedPackage({
        value: UpdateUser?.packageID,
        label: UpdateUser?.packageName,
      });
      setBatchID({
        value: UpdateUser?.iBatchID,
        label: UpdateUser?.batchName,
      });
      setSelectedEnqID({
        value: UpdateUser?.iEnquiryID,
        label: UpdateUser?.iEnquiryID,
      });

      if (UpdateUser?.taxlist && UpdateUser?.taxlist?.length != 0) {
        setwithTaxRadio(true);
        setwithOutTaxRadio(false);
        setTaxIncluded(true);
        setTax(UpdateUser?.taxlist);
        setTaxNo(UpdateUser?.taxNo);
      } else {
        setwithOutTaxRadio(true);
        setwithTaxRadio(false);
        setTaxValue(0);
        setTaxIncluded(false);
        setTaxExcluded(true);
        setTax([]);
        setTaxNo("");
      }
      if (UpdateUser?.discountType == 3) {
        setFixDiscount(Number(UpdateUser?.discountAmt?.split(" ")[0]));
      }
      const result = discountTypeOptions?.find((item: any) => {
        return item?.value === UpdateUser?.discountType;
      });
      if (result) {
        console.log(result, "discoutn");
        setDiscountType({
          value: result?.value,
          label: result?.label,
        });
      }

      setSelectedProgrammeUUId(UpdateUser?.packageUUID);
      setCheckbox(UpdateUser?.registrationFeeAllow);
      setIsCheckedRegFee(UpdateUser?.registrationFeeAllow);
      //update the registration fee
      UpdateUser?.registrationFeeAllow == 1
        ? setregistrationFees(UpdateUser?.registrationFee)
        : setregistrationFees(0);

      setDiscountValue(Number(UpdateUser?.discountAmt?.split(" ")[0]));
      setProgramFee(UpdateUser?.packageFee);
      setDiscountType({
        value: UpdateUser?.discountType,
        label: findDiscountLabel(UpdateUser?.discountType),
      });
      setFee(Number(UpdateUser?.feeWithoutTax));
      setPayableFee(UpdateUser?.payableFee);
      setexpiryDate(UpdateUser?.dtExpirydate?.split("T")[0]);
      setStatus(UpdateUser?.eStatus);
    } else {
      // setSelectedEmpID({
      //   value: "",
      //   label: "Select Employee",
      // });
      setSelectedGender({
        value: "",
        label: intl.formatMessage({ id: "COMMON.SELECT" }),
      });
      setSelectedMartial({
        value: "",
        label: intl.formatMessage({ id: "COMMON.SELECT" }),
      });
      setSelectedOccupation({
        value: "",
        label: intl.formatMessage({ id: "COMMON.SELECT" }),
      });
      setSelectedPackage({
        value: "",
        label: intl.formatMessage({ id: "COMMON.SELECT" }),
      });
      setBatchID({
        value: "",
        label: intl.formatMessage({ id: "COMMON.SELECT" }),
      });
      setDiscountType({
        value: "",
        label: intl.formatMessage({ id: "COMMON.SELECT" }),
      });
    }
  }, [isUpdate, UpdateUser]);
  // console.log("discountAmt", discountValue);

  const handleSubmitMethod = async (e: any) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("branchID", ActiveBranchID);
    formData.append("membertype", selectedMemberType || "");
    formData.append("employeeID", selectedEmpID?.value || "");
    formData.append("occupationID", selectedOccupation?.value || "");
    formData.append("name", name || "");
    formData.append("memberID", memberID || "");
    formData.append("gender", selectedGender?.value || "");
    formData.append("dob", dob || "");
    formData.append("maritalStatus", selectedMartial.value || "");
    formData.append("address", address || "");
    formData.append("enquiryID", selectedEnqId?.value || "");
    formData.append("countryCode", countryC?.value || "");
    formData.append("mobileNo", Mobile || "");
    formData.append("homeCountryCode", countryCH?.value || "");
    formData.append("homeNumber", hMobile || "");
    formData.append("email", email || "");
    formData.append("packageUUID", selectedProgrammeUUID || "");
    formData.append(
      "withTaxStatus",
      withTaxRadio ? "1" : withOutTaxRadio ? "0" : "0"
    );
    formData.append("taxno", taxNo || "");
    formData.append("taxdetails", JSON.stringify(tax));
    formData.append("dateofJoining", doj || "");
    formData.append("dateofStarting", startDate || "");
    formData.append("batchID", batchid?.value || "");
    formData.append("registrationFeeAllow", checkbox ? "1" : "0");
    formData.append("discountType", discountType?.value);
    formData.append("discount", discountValue?.toString() || "0");
    formData.append("status", status === "1" || status === 1 ? "1" : "0");
    formData.append(
      "taxType",
      withTaxRadio ? "1" : withOutTaxRadio ? "0" : "0"
    );
    formData.append("paymentMode", selectPaymentMode.value || "");
    formData.append("amount", dueAmount.toString() || "");
    formData.append("paymentDate", paymentDate || "");
    formData.append("paymentTime", paymentTime || "");
    formData.append("remarks", remarks || "");
    formData.append("nextPaymentDate", nextPaymentDate || "");

    if (selectImage) {
      formData.append("profilePhoto", selectImage);
    }
    if (isValid) {
      if (isUpdate) {
        try {
          formData.append("memberUUID", UpdateUser?.iSpotUUID);
          formData.append("EnrollUUID", UpdateUser?.iEnrolUUID);
          const response = await UpdateMembers(formData);
          if (response?.status === 200) {
            swal(response?.data?.replyMsg, { icon: "success" });
            setSelectImage(null);
            setShow(false);
            getMembers();
            setregistrationFees(0);
            setProgramFee(0);
            setPayableFee(0);
            setDiscountType({
              value: "",
              label: intl.formatMessage({ id: "COMMON.SELECT" }),
            });
            setwithTaxRadio(false);
            setwithOutTaxRadio(false);
            setFee(0);
            handleResetDrop();
          } else {
            swal(response?.data?.replyMsg, { icon: "error" });
          }
        } catch (error: any) {
          console.log("Caught 400 Error:", error.response.data);
          swal(
            error.response.data?.replyMsg || "An error occurred while updating",
            { icon: "error" }
          );
        }
      } else {
        setSubmitted(true);
        const response = await CreateMember(formData);
        if (response?.status === 400) {
          setErrors(response?.data);
        }
        if (response?.status === 200) {
          swal(response?.data?.replyMsg, { icon: response?.data?.replyCode });

          setSelectedPackage({
            value: "",
            label: intl.formatMessage({ id: "COMMON.SELECT" }),
          });
          setErrors("");
          setSelectImage(null);
          setregistrationFees(0);
          setProgramFee(0);
          // setSubmitted(false);
          setPayableFee(0);
          setDiscountType({
            value: "",
            label: intl.formatMessage({ id: "COMMON.SELECT" }),
          });
          setwithTaxRadio(false);
          setwithOutTaxRadio(false);
          setSelectedProgrammeUUId("");
          setFee(0);
          // console.log("fees", fee);
          // action.resetForm();
          handleResetDrop();

          if (from) {
            setFrom(true);
          } else {
            getMembers();
          }

          setSelectedGender({
            value: "",
            label: intl.formatMessage({ id: "COMMON.SELECT" }),
          });
          setShow(false);
        } else {
          swal(response?.data?.replyMsg, { icon: "error" });
        }
      }
    }
  };

  useEffect(
    () => validForm(),
    [
      name,
      Mobile,
      countryC,
      selectedGender,
      selectedPackage,
      discountType,
      dob,
      startDate,
      doj,
      email,
      withOutTaxRadio,
      withTaxRadio,
      taxNo,
      selectedMemberType,
      selectedEnqId,
      selectPaymentMode,
      dueAmount,
    ]
  );

  const validForm = () => {
    let valid = true;
    const newErrors: any = {};
    // if (!memberID) {
    //   newErrors.memberID = intl.formatMessage({
    //     id: "MODULE.MEMBER.REQUIRED.MEMBERID",
    //   });
    //   valid = false;
    // }
    if (!name) {
      newErrors.name = intl.formatMessage({
        id: "MODULE.MEMBER.REQUIRED.NAME",
      });
      valid = false;
    }
    if (!Mobile) {
      newErrors.mobile = intl.formatMessage({
        id: "MODULE.MEMBER.REQUIRED.MOBILE",
      });
      valid = false;
    }
    if (!countryC.value) {
      newErrors.countryCode = intl.formatMessage({
        id: "MODULE.ENQUIRY.REQCOUNTRY",
      });
      valid = false;
    }

    if (!selectedGender.value) {
      newErrors.gender = intl.formatMessage({ id: "MODULE.ENQUIRY.REQGENDER" });
      valid = false;
    }
    if (!selectedPackage.value) {
      newErrors.package = intl.formatMessage({
        id: "MODULE.ENQUIRY.REQUIREDpackage",
      });
      valid = false;
    }
    if (!discountType.value) {
      newErrors.discountType = intl.formatMessage({
        id: "MEMBER.REQUIED.DISCOUNTTYPE",
      });
      valid = false;
    }
    if (!dob) {
      newErrors.dob = intl.formatMessage({
        id: "MODULE.MEMBER.REQUIRED.DATE_OF_BIRTH",
      });
      valid = false;
    }
    if (!email) {
      newErrors.email = intl.formatMessage({
        id: "MODULE.MEMBER.REQUIRED.EMAIL",
      });
      valid = false;
    } else if (email.includes(" ")) {
      newErrors.email = intl.formatMessage({
        id: "MODULE.MEMBER.INVALID.EMAIL.SPACES",
      });
      valid = false;
    } else if (!/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      newErrors.email = intl.formatMessage({
        id: "MODULE.MEMBER.INVALID.EMAIL.FORMAT",
      });
      valid = false;
    }

    if (!doj) {
      newErrors.doj = intl.formatMessage({
        id: "MODULE.MEMBER.REQUIRED.DATEOFJOINING",
      });
      valid = false;
    }

    if (!startDate) {
      newErrors.dos = intl.formatMessage({
        id: "MODULE.FROZONDATE.STARTINGDATE",
      });
      valid = false;
    }
    if (!withOutTaxRadio && !withTaxRadio) {
      newErrors.tax = intl.formatMessage({
        id: "MODULE.MEMBER.REQUIRED.TAXTYPE",
      });
      valid = false;
    }
    if (
      (!taxNo && withTaxRadio) ||
      (!taxNo && withTaxRadio && UpdateUser?.noOfInvoiceCreated === 0)
    ) {
      newErrors.taxNo = intl.formatMessage({
        id: "MODULE.MEMBER.REQUIRED.TAXNO",
      });
      valid = false;
    }
    if (selectedMemberType !== "spot" && !selectedEnqId?.value) {
      newErrors.enqID = intl.formatMessage({
        id: "MODULE.MEMBER.REQUIRED.ENQUIRY_ID",
      });
      valid = false;
    }
    if (dueAmount > 0 && !selectPaymentMode?.value) {
      newErrors.payMode = intl.formatMessage({
        id: "REQUIRED.INVOICE.PAYMENTMODE",
      });
      valid = false;
    }
    if (dueAmount > 0 && !paymentDate) {
      newErrors.payDate = intl.formatMessage({
        id: "MODULE.MEMBER.REQUIED.PAYDATE",
      });
      valid = false;
    }

    setFormError(newErrors); // Clear previous errors
    setIsValid(valid);
    // console.log(newErrors, "Error");
  };

  //Reset the DropDownform
  const handleResetDrop = () => {
    setSelectedEmpID({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    setSelectedGender({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    setSelectedMartial({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    setSelectedOccupation({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    setSelectedMemberType("spot");
    setSelectImage("");
    setBatchID({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    setDiscountType({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    setProgramFee(0);
    setFixDiscount(0);
    setDiscountValue(0);
    setFee(0);
    setwithOutTaxRadio(false);
    setwithTaxRadio(false);
    setTaxIncluded(false);
    setTaxExcluded(false);
    setTaxValue(0);
    setPayableFee(0);
    setSelectedEnqData("");
    if (isUpdate && !isProfile) {
      setUpdateUser("");
      setisUpdate(false);
    } else {
      if (isUpdate) {
        setisUpdate(false);
      }
    }
    setSelectedEnqID("");
    setPaymentDate(initialDate);
    setPaymentTime(time);
    setDueAmount(0);
    setSelectPaymentMode({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    setRemarks("");
    setNextPaymentDate(initialDate);
    setName("");
    setMemberId("");
    setEmail("");
    setMobile("");
    setHMobile("");
    setAddress("");
    setDob("");
    setTaxNo("");
    // formik.resetForm();
  };
  // console.log("fees", fee);
  //call the function

  // console.log(formik.values.taxno, formik.values.taxType, "tax error");
  // console.log("selectedId", selectedProgrammeUUID);
  // console.log(formik.values, "dshdsjkds");
  const handleClose = () => {
    setProgramFee(0);
    setSubmitted(false);
    setPayableFee(0);
    setSelectedPackage({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    setDiscountType({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    setwithTaxRadio(false);
    setwithOutTaxRadio(false);
    setSelectedProgrammeUUId("");
    setFee(0);
    setregistrationFees(0);
    setSelectedMemberType("spot");
    setSelectedEnqID("");
    handleResetDrop();
    setShow(false);
    if (isUpdate && !isProfile) {
      console.log(isProfile, "is Profile");
      setUpdateUser("");
      setisUpdate(false);
      // setUpdateUser([]);
    } else {
      if (isUpdate) {
        setisUpdate(false);
      }
    }
  };

  //set membertype Change
  const handleMemberTypeChane = (Event: any) => {
    setSelectedMemberType(Event.target.value);
    if (Event.target.value === "spot") {
      setSelectedEnqData("");
    }
  };

  //set enquiry ID
  const handleEnquiryID = async (Event: any) => {
    if (Event.value) {
      const [enquiryId, enquiryUUId] = Event.value?.split(" ");
      setSelectedEnqID({ value: enquiryId, label: Event.label });
      const response = await enquiryDetails(ActiveBranchID, enquiryUUId);
      const data = response?.data?.data;
      // console.log(data, formik.values, "selected enquiry");
      // enqObject(response?.data?.data, enquiryId);
      setSelectedEnqData(response?.data?.data);
    }

    // console.log("activeEnquiry uid", Event.value);
    // enqObject(activeEnqList, selectedEnqId);
    // setSelectedEnqID(Event.value);
  };
  // console.log(selectedEnqData, "resultttttt");
  //start get enquiry list
  // useEffect(() => {
  //   const result = enqObject(activeEnqList, selectedEnqId);
  //   setSelectedEnqData(result);
  // }, [selectedEnqId]);
  // const enqObject = (activeEnqList: any, selectedEnqId: any) => {
  //   return activeEnqList?.find((obj: any) => obj.iEnquiryID === selectedEnqId);
  // };
  //end get enquiry list

  //start set martial status
  const handleMaritalStatus = (Event: any) => {
    setSelectedMartial({
      value: Event.value,
      label: Event.value,
    });
  };
  //end set martial status

  //start set enployee id
  const handleEmpChange = (Event: any) => {
    if (Event) {
      setSelectedEmpID({
        value: Event.value,
        label: Event.label,
      });
    } else {
      setSelectedEmpID({
        value: "",
        label: intl.formatMessage({ id: "COMMON.SELECT" }),
      });
    }
  };
  //end set enployee id

  //start set gender
  const handleGenderChange = (Event: any) => {
    setSelectedGender({
      value: Event.value,
      label: Event.label,
    });
  };
  //end set gender

  //start set occupation id
  const handleOccupationChange = (Event: any) => {
    setSelectedOccupation({
      value: Event.value,
      label: Event.label,
    });
  };
  //end set occupation id

  //start handle package
  const handlePackageChange = async (Event: any) => {
    // formik.setFieldValue("packageUUID", Event.value);
    //console.log("programme uuid", Event.value);
    setSelectedPackage({
      value: Event.value,
      label: Event.label,
    });
    setSelectedProgrammeUUId(Event.value);
    setUpdatePackagePrice(false);
    setDiscountType({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    setDiscountValue(0);
    setFixDiscount(0);
    setFee(0);
    // console.log("uniqueid", selectedProgrammeUUID);
    setCheckbox(true);
    setwithOutTaxRadio(false);
    setwithTaxRadio(false);
    setTaxIncluded(false);
    setTax([]);
    setTaxValue(0);
    setPayableFee(0);
    // await getPackageDetails(ActiveBranchID, Event.value);
  };

  //end handle package

  useEffect(() => {
    if (
      selectedProgrammeUUID &&
      Object.keys(selectedProgrammeUUID).length !== 0
    ) {
      getPackageDetails(ActiveBranchID, selectedProgrammeUUID);
    }

    console.log(selectedProgrammeUUID, "selectedprogram");
    // formik.setFieldValue("discountType", 1); //
  }, [selectedProgrammeUUID, ActiveBranchID]);

  //start get package details
  const getPackageDetails = async (branchID: any, iProgramUUID: any) => {
    const response = await GetPackageDetails(branchID, iProgramUUID);
    setSelectedPackageDetail(response?.data?.data);
    // formik.setFieldValue("registrationFeeAllow", 1);
    setProgramFee(response?.data?.data?.fProgramFees);
    const registrationFees = response?.data?.data?.fEnFees || 0;
    const programFee = response?.data?.data?.fProgramFees || 0;
    const result = registrationFees + programFee;
    if (!checkbox) {
      setFee(programFee);
    }

    // console.log("resultfees", result);
    if (!isUpdate) {
      setDiscountType({ value: 1, label: "None" });
      setDiscountValue(0);
      setFee(result);
    }
    setregistrationFees(registrationFees);

    // formik.setFieldValue("discountType", 1); //

    setIsCheckedRegFee(false);
    getTodayDate(response?.data?.data?.iDays);
  };
  //end get package details

  // useEffect(() => {
  //   // calculateFeeWithputTax();
  // }, [isCheckedRegFee, discountType, fixDiscount]);

  //start calculate fee with tax
  const calculateFeeWithputTax = () => {
    const registrationFee = isCheckedRegFee
      ? 0
      : selectedPackageDetail?.fEnFees;
    const packageFee = selectedPackageDetail?.fProgramFees || 0;
    const discount: any = discountValue;
    const result = registrationFee + packageFee - discount;
    if (isUpdate && UpdateUser && updatePackagePrice) {
      // setDiscountValue(UpdateUser?.discountAmt);
      setFee(UpdateUser?.feeWithoutTax);
      // console.log("fes", UpdateUser?.feeWithoutTax);
    } else {
      setFee(Number(result));
    }
    // console.log("resultfees", registrationFee, packageFee, discount);
  };
  //end calculate fee with tax

  //start handle allow registration fee
  const handleChangeCheckbox = (Event: any) => {
    const isChecked = Event.target.checked;
    if (isChecked) {
      // formik.setFieldValue("registrationFeeAllow", 1);
      setIsCheckedRegFee(true);
      if (isUpdate && UpdateUser) {
        setregistrationFees(UpdateUser?.registrationFee);
        setFee(UpdateUser?.packageFee + UpdateUser?.registrationFee);
      } else {
        setregistrationFees(selectedPackageDetail?.fEnFees);
        setFee(
          selectedPackageDetail?.fEnFees + selectedPackageDetail?.fProgramFees
        );
      }
    } else {
      setIsCheckedRegFee(false);
      setregistrationFees(0);
      // formik.setFieldValue("registrationFeeAllow", 0);
      // setFee(selectedPackageDetail?.fProgramFees);
      if (isUpdate && UpdateUser) {
        // setregistrationFees(0);
        setFee(UpdateUser?.feeWithoutTax);
      } else {
        setregistrationFees(0);
        setFee(selectedPackageDetail?.fProgramFees);
      }
    }
    setCheckbox(Event.target.checked);
    setFixDiscount(0);
    setDiscountValue(0);
    setDiscountType({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    //const registrationFee = isCheckedRegFee
    //? 0
    // : selectedPackageDetail?.fEnFees;

    // const packageFee = selectedPackageDetail?.fProgramFees;
    // setFee(registrationFee + packageFee);
    //if (discountType.value == 1) {
    // setDiscountType({ value: 1, label: "None" });
    // }
    setwithOutTaxRadio(false);
    setwithTaxRadio(false);
    setTaxIncluded(false);
    setTax([]);
    setTaxValue(0);
    setPayableFee(0);
  };
  // //end handle allow registration fee
  // const handleDiscountType2 = (Event: any) => {
  //   if (Event.label === "None") {
  //     setDiscountType({ value: Event.value, label: Event.label });
  //   } else if (Event.label === "Fix Discount") {
  //     setDiscountType({ value: Event.value, label: Event.label });
  //   }
  //   // complimentary
  //   else {
  //     setDiscountType({ value: Event.value, label: Event.label });
  //   }
  //   setwithOutTaxRadio(false);
  //   setwithTaxRadio(false);
  //   setTaxIncluded(false);
  //   setTax([]);
  //   setTaxValue(0);
  //   setPayableFee(0);
  // };
  //start handle discount type
  const handleDiscountType = (Event: any) => {
    // formik.setFieldValue("discountType", Event.value);
    setUpdatePackagePrice(false);
    let totalFeeAmt = registrationFees + programFee;

    // console.log("check", registrationFees, programFee, totalFeeAmt);
    if (Event.label === "None") {
      setDiscountType({ value: Event.value, label: Event.label });
      setDiscountValue(0);
      setFixDiscount(0);

      if (checkbox) {
        setFee(totalFeeAmt);
      } else {
        setFee(programFee);
      }
      // formik.setFieldValue("discount", 0);
    } else if (Event.label === "Fix Discount") {
      setDiscountType({ value: Event.value, label: Event.label });
      // setFixDiscount(0);
      // setDiscountValue(0);
      // calculateFeeWithputTax();
      let totalFeeAmt = registrationFees + programFee;
      if (checkbox) {
        setFee(totalFeeAmt);
      } else {
        setFee(programFee);
      }
      // console.log("discountB", registrationFees, programFee);
      // setFee(totalFeeAmt);
      // setFee(totalFeeAmt);
    } else {
      setDiscountType({ value: 2, label: "Complementry" });

      let result = registrationFees + programFee;
      if (checkbox) {
        setDiscountValue(result);
      } else {
        setDiscountValue(programFee);
      }
      // console.log("fdafdss", result, registrationFees, programFee);
      // console.log("complimentry", result);
      // console.log("jsduk", discountValue);
      // formik.setFieldValue("discount", result);
      setFee(0);
      setFixDiscount(0);

      // formik.setFieldValue("discount", 0);
    }
    setwithOutTaxRadio(false);
    setwithTaxRadio(false);
    setTaxIncluded(false);
    setTax([]);
    setTaxValue(0);
    setPayableFee(0);
  };
  //end handle discount type

  //start handle fix discount type
  const handleFixDiscount = (Event: any) => {
    setwithOutTaxRadio(false);
    setwithTaxRadio(false);
    setUpdatePackagePrice(true);
    setFixDiscount(Event.target.value);
    setDiscountValue(Number(Event.target.value));
    // console.log("handleFixDiscount", Event.target.value);
    // formik.setFieldValue("discount", Number(Event.target.value));
    // formik.setFieldValue("discount", Number(Event.target.value));

    let registrationFee = 0;
    if (isCheckedRegFee && selectedPackageDetail?.fEnFees) {
      registrationFee = selectedPackageDetail?.fEnFees;
    } else {
      registrationFee = registrationFees;
    }

    const packageFee = selectedPackageDetail?.fProgramFees;
    setFee(registrationFees + programFee - Event.target.value);

    if (Event.target.value > registrationFee + packageFee) {
      swal("Discount amount is not greater than total fee amont", {
        icon: "error",
      });
      // setFixDiscount(0);
      // setDiscountValue(0);
    }
    if (Event.target.value < 0) {
      swal("Discount amount is not less than zero", {
        icon: "error",
      });
      // setFixDiscount(0);
      // setDiscountValue(0);
    }
    // setFee(Number(registrationFee + packageFee) - Number(Event.target.value));
  };

  //end handle fix discount type

  //start handle tax excluded
  const handleTaxExcluded = (Event: any) => {
    setTaxIncluded(false);
    setTaxValue(0);
    setPayableFee(fee);
    // setDueAmount(fee);
    setwithTaxRadio(false);
    setwithOutTaxRadio(true);
    setTax([]);
    setTaxNo("");
    // formik.setFieldValue("taxType", false);
    // formik.setFieldValue("withTaxStatus", 0);
    // formik.setFieldValue("taxdetails", "");
    // formik.setFieldValue("taxno", "");
  };
  //end handle tax excluded

  //start define tax incliude function
  const handleTaxIncluded = () => {
    setTaxIncluded(true);
    setwithTaxRadio(true);
    setwithOutTaxRadio(false);
    // formik.setFieldValue("taxType", true);
    // formik.setFieldValue("withTaxStatus", 1);
  };
  //end define tax incliude function

  useEffect(() => {
    if (taxIncluded) {
      activeTax(ActiveBranchUUID);

      //  calculateTaxValue();
    }
  }, [taxIncluded]);
  useEffect(() => {
    if (tax && tax.length > 0) {
      calculateTaxValue();
    }
  }, [tax, taxIncluded]);

  //start define active tax function
  const activeTax = async (branchUUID: any) => {
    try {
      const response = await ActiveTax(branchUUID);
      const taxData = response?.data?.data;
      // console.log(JSON.stringify(taxData), "fsadjhkdsaf");
      setTax(taxData);
      // formik.setFieldValue("taxdetails", JSON.stringify(taxData));
    } catch (error) {
      // console.error("Error fetching active tax:", error);
    }
  };
  //end define active tax function

  //start calculateTaxValue function
  const calculateTaxValue = () => {
    let total = 0;
    tax.forEach((item: any) => {
      total += (item.rate * fee) / 100;
    });
    total = Math.floor(total);
    setTaxValue(total);
    let payableFees = fee + total;
    setPayableFee(payableFees);
    // setDueAmount(payableFees);
  };
  //end calculateTaxValue function

  //start getTodayDate function
  const getTodayDate = (days: any) => {
    const newDate = moment(new Date()).format(dateFormat);
    let todayDate = newDate;
    // formik.setFieldValue("dateofJoining", newDate);
    setDoj(newDate);
    if (startDate) {
      todayDate = startDate;
    }
    // formik.setFieldValue("dateofStarting", todayDate);
    setstartDate(todayDate);
    const newExpiredDate = moment(todayDate)
      .add(days, "days")
      .format(dateFormat);
    setexpiryDate(newExpiredDate);
  };
  //end getTodayDate function

  //start handle start date change
  const handledStartDateChange = (Event: any) => {
    const selectedStartedDate = Event.target.value;
    // formik.setFieldValue("dateofStarting", selectedStartedDate);
    setstartDate(Event.target.value);
    let packageDays = selectedPackageDetail?.iDays;
    // console.log("packageDays===", packageDays);
    const newExpiredDate = moment(selectedStartedDate)
      .add(packageDays, "days")
      .format(dateFormat);
    // console.log("newExpiredDate===", newExpiredDate);
    setexpiryDate(newExpiredDate);
  };
  //end handle start date change

  //start handle batch ID
  const handleBatchID = (Event: any) => {
    // Check if the event is null (i.e., when the user clears the selection)
    if (Event) {
      // formik.setFieldValue("batchID", Event.value); // Set the selected value in Formik
      setBatchID({
        value: Event.value,
        label: Event.label,
      });
    } else {
      // Handle the case when the selection is cleared
      // formik.setFieldValue("batchID", ""); // Optionally set to an empty string or null
      setBatchID(null); // Clear the local state
    }
  };
  // console.log("submitted", submitted);

  //start handle photo
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setSelectImage(file);
    }
  };

  const handleImageClick = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  };

  const handleImageRemove = () => {
    // console.log("deleted");
    setSelectImage(null);
    if (imageRef.current) {
      imageRef.current.value = ""; // Clear the file input
    }
  };
  const sno = intl.formatMessage({ id: "MODULE.MEMBER.SNO" });
  const taxname = intl.formatMessage({ id: "MODULE.MEMBER.TAXNAME" });
  const amount = intl.formatMessage({ id: "MODULE.MEMBER.AMOUNT" });

  const handleAmountchange = (Event: any) => {
    setDueAmount(Event.target.value);
    // formik.setFieldValue("amount", Event.target.value);
    if (Number(Event.target.value) > payableFee) {
      swal("Payment amount is not greater than due fee amount", {
        icon: "error",
      });
      // formik.setFieldValue("amount", payableFee);
      setDueAmount(payableFee);
    }
  };

  const handlePaymentDateChange = (Event: any) => {
    setPaymentDate(Event.target.value);
    // formik.setFieldValue("paymentDate", Event.target.value);
    // formik.setFieldValue("nextPaymentData", Event.target.value);

    // const date = new Date(Event.target.value);
  };
  const handleTimeChange = (Event: any) => {
    setPaymentTime(Event.target.value);
    // formik.setFieldValue("paymentTime", Event.target.value);
  };

  const handlePaymentModeChange = (Event: any) => {
    //   formik.setFieldValue("packageUUID", Event.value);
    // formik.setFieldValue("paymentMode", Event.value);
    setSelectPaymentMode({
      value: Event.value,
      label: Event.label,
    });
  };
  const handleRemarkChange = (Event: any) => {
    setRemarks(Event.target.value);
    // formik.setFieldValue("remarks", Event.target.value);
  };
  console.log(lastMembershipId);
  return (
    <>
      {/* {isLoading && <Loading />} */}
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-custom"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isUpdate === true
              ? `${intl.formatMessage({
                  id: "MODULE.MEMBER.UPDATEMEMBER",
                })}`
              : `${intl.formatMessage({
                  id: "MODULE.MEMBER.CREATEMEMBER",
                })}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <form onSubmit={handleSubmitMethod}>
            <div className="d-flex flex-column">
              <div>
                {/* begin:: First name*/}
                <div className="row mb-7">
                  <div className="col-md-4">
                    <div className="mb-3">
                      {/* Flexbox container for label and badge */}
                      <div className="d-flex align-items-baseline justify-content-between">
                        <label htmlFor="memberID" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.MEMBERIDLEVEL",
                          })}
                        </label>
                        <span className="badge bg-warning text-dark">
                          Last Membership ID : {lastMembershipId}
                        </span>
                      </div>
                      <input
                        type="text"
                        id="memberID"
                        name="memberID"
                        className="form-control"
                        value={memberID}
                        onChange={(e) => {
                          setMemberId(e.target.value);
                        }}
                      />
                      {/* {formError.memberID && (
                        <div className="text-danger">{formError.memberID}</div>
                      )} */}
                    </div>
                  </div>
                </div>

                <div className="row mb-7">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="status" className="required form-label">
                        {intl.formatMessage({ id: "MODULE.MEMBERS.ENQTYPE" })}
                      </label>
                      <select
                        id="membertype"
                        name="membertype"
                        className="form-select"
                        onChange={(e) => handleMemberTypeChane(e)}
                        //  onBlur={formik.handleBlur("membertype")}
                        value={selectedMemberType}
                        disabled={UpdateUser?.iEnquiryID ? true : false}
                      >
                        <option value="Enquiry">Enquiry</option>
                        <option value="spot">spot</option>
                      </select>
                    </div>
                  </div>
                  {selectedMemberType === "Enquiry" && (
                    <div
                      className={`col-md-4 ${
                        UpdateUser?.iEnquiryID && isUpdate ? "d-none" : ""
                      } `}
                    >
                      <label
                        htmlFor="department_name"
                        className="required form-label"
                      >
                        {intl.formatMessage({ id: "MODULE.MEMBERS.ENQNAME" })}
                      </label>
                      <Select
                        className=" basic-single"
                        id="enquiryID"
                        name="enquiryID"
                        value={selectedEnqId}
                        options={activeEnqList.map((item: any) => ({
                          value: `${item?.iEnquiryID} ${item.iEnquiryUUID}`,
                          label: `${item?.name} (${item?.countryCode}${item?.mobileNo}) `,
                        }))}
                        onChange={(event) => handleEnquiryID(event)}
                      />
                      {/* {selectedMemberType === "Enquiry" &&
                        formik.touched.enquiryID &&
                        formik.errors.enquiryID && (
                          <div className="text-danger">
                            {formik.errors.enquiryID}
                          </div>
                        )} */}
                      {formError.enqID && (
                        <div className="text-danger">{formError.enqID}</div>
                      )}
                    </div>
                  )}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="department_name"
                        className="required form-label"
                      >
                        {intl.formatMessage({ id: "MODULE.MEMBERS.NAME" })}
                      </label>

                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        // value={formik.values.name}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}

                        // onChange={(e) => handleName(e)}
                        //  disabled={selectedEnqData}
                      />

                      {formError.name && (
                        <div className="text-danger">{formError.name}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-md-2">
                    <label className="required form-label">
                      {intl.formatMessage({ id: "COMMON.COUNTRYCODE" })}
                    </label>
                    <Select
                      className="basic-single"
                      name="countryCode"
                      id="countryCode"
                      value={countryC}
                      //  value={formik.values.countryCode}
                      onChange={(e) => {
                        setCountryC({ value: e.value, label: e.label });
                        // formik.setFieldValue("countryCode", e.value);
                      }}
                      options={CountryCode?.map((element: any) => ({
                        value: element.countryDialCode,
                        label: `${element.countryDialCode} (${element.countryName})`,
                      }))}
                    />
                    {/* {CountryCode.map((element: any, index: any) => {
                        return (
                          <option value={element.countryDialCode} key={index}>
                            {element.countryDialCode} ({element.countryName})
                          </option>
                        );
                      })}
                    </select> */}
                    {formError.countryCode && (
                      <div className="text-danger">{formError.countryCode}</div>
                    )}
                  </div>
                  <div className="col-md-2">
                    <label className="required form-label">
                      {intl.formatMessage({ id: "COMMON.MOBILE" })}
                    </label>
                    <input
                      type="tel"
                      name="mobileNo"
                      placeholder={intl.formatMessage({
                        id: "COMMON.ENTER MOBILE NUMBER",
                      })}
                      className="form-control "
                      onChange={(e) => {
                        setMobile(e.target.value);
                        // formik.setFieldValue("mobileNo", e.target.value);
                      }}
                      value={Mobile}
                    />

                    {formError.mobile && (
                      <div className="text-danger">{formError.mobile}</div>
                    )}
                    <div>
                      {errors.replyKey === "mobileno" && (
                        <div className="text-danger">
                          {errors.replyKey === "mobileno"
                            ? errors.replyMsg
                            : error.mobileNo}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <label className=" form-label">
                      {intl.formatMessage({ id: "MODULE.MEMBERS.HOMECC" })}
                    </label>
                    <Select
                      className="basic-single "
                      name="homeCountryCode"
                      id="homeCountryCode"
                      value={countryCH}
                      onChange={(e) => {
                        setCountryCH({ value: e.value, label: e.label });
                        // formik.setFieldValue("countryCode", e.value);
                      }}
                      options={CountryCode?.map((element: any) => ({
                        value: element.countryDialCode,
                        label: `${element.countryDialCode} (${element.countryName})`,
                      }))}
                    />
                    {/* {CountryCode.map((element: any, index: any) => {
                        return (
                          <option value={element.countryDialCode} key={index}>
                            {element.countryDialCode} ({element.countryName})
                          </option>
                        );
                      })}
                    </select> */}
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">
                      {intl.formatMessage({ id: "COMMON.PHONENUMBER" })}
                    </label>
                    <input
                      type="tel"
                      name="homeNumber"
                      placeholder={intl.formatMessage({
                        id: "MODULE.ENQUIRY.HOMEPHNO",
                      })}
                      className="form-control"
                      onChange={(e) => {
                        setHMobile(e.target.value);
                        // formik.setFieldValue("homeNumber", e.target.value);
                      }}
                      value={hMobile}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="department_name" className=" form-label">
                      {intl.formatMessage({
                        id: "MDOULE.MEMBERS.EMPLOYEE",
                      })}
                    </label>

                    <Select
                      className=" basic-single"
                      id="employeeID"
                      name="employeeID"
                      value={selectedEmpID}
                      options={activeEmpList?.map((item: any) => ({
                        value: item.iEmployeeID,
                        label: `${item.vEFirstname} (${item.vEmpCountryCode}-${item.vEPhoneno})`,
                      }))}
                      onChange={(e) => handleEmpChange(e)}
                      isClearable
                    />
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="gender" className="required form-label">
                        {intl.formatMessage({ id: "COMMON.GENDER" })}
                      </label>
                      <Select
                        id="gender"
                        name="gender"
                        className="basic-single"
                        value={selectedGender}
                        options={gender?.map((item: any) => ({
                          value: item.key,
                          label: item.value,
                        }))}
                        onChange={(e) => handleGenderChange(e)}
                      />
                      {/* {formik.touched.gender && formik.errors.gender && (
                        <div className="text-danger">
                          {formik.errors.gender}
                        </div>
                      )} */}
                      {formError.gender && (
                        <div className="text-danger">{formError.gender}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor="department_name"
                      className="required form-label"
                    >
                      {intl.formatMessage({ id: "MODULE.MEMBERS.DOB" })}
                    </label>
                    <input
                      type="date"
                      id="dob"
                      name="dob"
                      className="form-control"
                      value={dob}
                      onChange={(e) => {
                        setDob(e.target.value);
                        // formik.setFieldValue("dob", e.target.value);
                      }}
                      // onChange={(e) => handledobChange(e)}
                    />
                    {formError.dob && (
                      <div className="text-danger">{formError.dob}</div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="martialStatus" className="form-label">
                        {intl.formatMessage({ id: "COMMON.MARITALSTATUS" })}
                      </label>

                      <Select
                        id="maritalStatus"
                        name="maritalStatus"
                        value={selectedMartial}
                        options={maritalStatus?.map((item: any) => ({
                          value: item?.key,
                          label: item?.value,
                        }))}
                        onChange={(e) => handleMaritalStatus(e)}
                      />
                      {/* {formik.touched.maritalStatus &&
                        formik.errors.maritalStatus && (
                          <div className="text-danger">
                            {formik.errors.maritalStatus}
                          </div>
                        )} */}
                    </div>
                  </div>
                </div>

                <div className="row mb-7">
                  <div className="col-md-4">
                    <label className="required form-label">
                      {intl.formatMessage({ id: "COMMON.EMAIL" })}
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder={intl.formatMessage({
                        id: "MODULE.ENQUIRY.ENTEREMAIL",
                      })}
                      className="form-control"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        // formik.setFieldValue("email", e.target.value);
                      }}
                      value={email}
                    />
                    {formError.email && (
                      <div className="text-danger">{formError.email}</div>
                    )}
                    {/* <div className="error mt-2">
                      {formik.touched.email && formik.errors.email}
                    </div>
                    <div>
                      {errors.replyKey === "email" || error.email ? (
                        <div className="error">
                          {errors.replyKey === "email"
                            ? errors.replyMsg
                            : error.email}
                        </div>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="department_name" className="form-label">
                      {intl.formatMessage({ id: "MODULE.MEMBERS.OCCUPATION" })}
                    </label>
                    <Select
                      className=" basic-single"
                      id="occupationID"
                      name="occupationID"
                      value={selectedOccupation}
                      options={activeOccList.map((item: any) => ({
                        value: item?.iOccupationID,
                        label: item?.occupationName,
                      }))}
                      onChange={(e) => handleOccupationChange(e)}
                    />
                    {/* {formik.touched.occupationID &&
                      formik.errors.occupationID && (
                        <div className="text-danger">
                          {formik.errors.occupationID}
                        </div>
                      )} */}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      {intl.formatMessage({ id: "COMMON.ADDRESS" })}
                    </label>
                    <div className="relative z-20 bg-transparent dark:bg-form-input">
                      <textarea
                        className="form-control form-control-solid mb-3 mb-lg-0"
                        name="address"
                        aria-label="With textarea"
                        placeholder={intl.formatMessage({
                          id: "COMMON.ENTER YOUR ADDRESS",
                        })}
                        onChange={(e) => {
                          setAddress(e.target.value);
                          // formik.setFieldValue("address", e.target.value);
                        }}
                        value={address}
                      ></textarea>

                      {/* {formik.touched.address && formik.errors.address && (
                        <div className="text-danger">
                          {formik.errors.address}
                        </div>
                      )} */}
                    </div>
                    <div className="error">{isUpdate === true && error}</div>
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-lg-2">
                    <div className="card card-custom overlay overflow-hidden">
                      <div className="card-body p-0">
                        <div className="overlay-wrapper">
                          <img
                            src={
                              isUpdate && !selectImage
                                ? UpdateUser?.vPhoto
                                  ? showImage
                                  : Dummy
                                : selectImage
                                ? URL.createObjectURL(selectImage)
                                : Dummy
                              // isUpdate && !showImage
                              //   ? selectImage
                              //     ? URL.createObjectURL(selectImage)
                              //     : showImage
                              //   : selectImage
                              //   ? URL.createObjectURL(selectImage)
                              //   : Dummy
                            }
                            alt=""
                            className="w-100 rounded"
                            width={100}
                            height={100}
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10">
                          <a
                            href="#"
                            className="btn btn-primary btn-shadow"
                            onClick={handleImageClick}
                          >
                            <i className="bi bi-pencil-square fs-4"></i>
                            <input
                              type="file"
                              ref={imageRef}
                              name="memberPhoto"
                              onChange={(e) => handleImageUpload(e)}
                              hidden
                            />
                          </a>
                          <a
                            href="#"
                            className="btn btn-light-primary btn-shadow ms-2"
                            onClick={handleImageRemove}
                          >
                            <i className="bi bi-trash fs-4"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!isProfile && (
                  <>
                    <div>
                      <h1
                        style={{
                          color: "#009ef7",
                          textDecoration: "underline",
                        }}
                      >
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.PACKAGEDETAILS",
                        })}
                      </h1>
                    </div>
                    <div className="row mb-6 mt-7">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            htmlFor="gender"
                            className="required form-label"
                          >
                            {intl.formatMessage({
                              id: "MODULE.MEMBERS.PACKAGE",
                            })}
                          </label>
                          <Select
                            id="packageUUID"
                            name="packageUUID"
                            className="basic-single"
                            value={selectedPackage}
                            options={activePackageList.map((item: any) => ({
                              value: item.iProgramUUID,
                              label: item.vProgram,
                            }))}
                            onChange={(e) => handlePackageChange(e)}
                            isDisabled={
                              isUpdate && UpdateUser?.noOfInvoiceCreated !== 0
                                ? true
                                : false
                            }
                          />

                          {formError.package && (
                            <div className="text-danger">
                              {formError.package}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="gender" className="form-label">
                            {intl.formatMessage({
                              id: "MODULE.MEMBERS.REGISTRATIONFEE",
                            })}
                          </label>

                          <input
                            style={{ marginLeft: "5px" }}
                            type="checkbox"
                            checked={checkbox}
                            onChange={(e) => handleChangeCheckbox(e)}
                          />

                          <input
                            type="number"
                            id="registrationFeeAllow"
                            name="registrationFeeAllow"
                            className="form-control"
                            value={registrationFees}
                            // onChange={formik.handleChange("registrationFeeAllow")}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="gender" className="form-label">
                            {intl.formatMessage({
                              id: "MODULE.MEMBERS.PACKAGEFEE",
                            })}
                          </label>
                          <input
                            type="number"
                            id="programfee"
                            name="programfee"
                            className="form-control"
                            // value={selectedPackageDetail.fProgramFees}
                            // value={formik.values.programfee}
                            value={programFee}
                            // onChange={formik.handleChange("programfee")}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-7">
                      <div className="col-md-4">
                        <label htmlFor="department_name" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.DISCOUNTTYPE",
                          })}
                        </label>
                        <Select
                          className=" basic-single"
                          id="discountType"
                          name="discountType"
                          value={discountType}
                          options={discountTypeOptions.map((item: any) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                          onChange={(e) => handleDiscountType(e)}
                          isDisabled={
                            isUpdate && UpdateUser?.noOfInvoiceCreated !== 0
                              ? true
                              : false
                          }
                        />
                        {formError.discountType && (
                          <div className="text-danger">
                            {formError.discountType}
                          </div>
                        )}
                        {/* {console.log(
                      "discount",
                      formik.touched.discount,
                      formik.errors
                    )} */}
                        {/* {formik.touched.discountType &&
                      formik.errors.discountType && (
                        <div className="text-danger">
                          {formik.errors.discountType}
                        </div>
                      )} */}
                      </div>
                      {discountType.label === "Fix Discount" && (
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="gender" className="form-label">
                              {intl.formatMessage({
                                id: "MODULE.MEMBERS.FIXDISCOUNT",
                              })}
                            </label>
                            <input
                              type="number"
                              id="fixdiscount"
                              name="fixdiscount"
                              className="form-control"
                              value={fixDiscount}
                              onChange={(e) => handleFixDiscount(e)}
                              disabled={
                                isUpdate && UpdateUser?.noOfInvoiceCreated !== 0
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      )}
                      {discountType.label !== "Fix Discount" && (
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="gender" className="form-label">
                              {intl.formatMessage({
                                id: "MODULE.MEMBERS.DISCOUNT",
                              })}
                            </label>
                            <input
                              type="number"
                              id="discount"
                              name="discount"
                              className="form-control"
                              // onChange={formik.handleChange("discount")}
                              value={discountValue}
                              //value={formik.values.discount}
                              disabled
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-md-4 mt-2">
                        <div className="mb-3">
                          <label htmlFor="gender" className="form-label">
                            {intl.formatMessage({
                              id: "MODULE.MEMBERS.FEE",
                            })}
                          </label>
                          <input
                            type="number"
                            id="fee"
                            name="fee"
                            className="form-control"
                            value={fee}
                            // value={formik.values.fee}
                            // onChange={formik.handleChange("fee")}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-7">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            htmlFor="taxType"
                            className="form-label required"
                          >
                            {intl.formatMessage({
                              id: "MODULE.MEMBERS.TAX",
                            })}
                          </label>
                          <div>
                            <input
                              name="taxType"
                              type="radio"
                              id="taxexcluded"
                              checked={withOutTaxRadio}
                              value={withOutTaxRadio}
                              onChange={handleTaxExcluded}
                              disabled={
                                isUpdate && UpdateUser?.noOfInvoiceCreated !== 0
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ marginLeft: "3px" }}>
                              {intl.formatMessage({
                                id: "MODULE.MEMBERS.WITHOUTTAX",
                              })}
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              name="taxType"
                              id="taxincluded"
                              checked={withTaxRadio}
                              value={withTaxRadio}
                              onChange={handleTaxIncluded}
                              disabled={
                                isUpdate && UpdateUser?.noOfInvoiceCreated !== 0
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ marginLeft: "3px" }}>
                              {intl.formatMessage({
                                id: "MODULE.MEMBERS.WITHTAX",
                              })}
                            </label>
                          </div>
                        </div>
                        {formError.tax && (
                          <div className="text-danger">{formError.tax}</div>
                        )}
                        {/* {withOutTaxRadio ||
                      (!withTaxRadio && (
                        <div className="text-danger">Tax is required</div>
                      ))} */}
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="gender" className="form-label">
                            {intl.formatMessage({
                              id: "MODULE.MEMBERS.TAXVALUE",
                            })}
                          </label>
                          <input
                            type="number"
                            id="taxvalue"
                            name="taxvalue"
                            className="form-control"
                            value={taxValue}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="gender" className="form-label">
                            {intl.formatMessage({
                              id: "MODULE.MEMBERS.PAYABLEFEE",
                            })}
                          </label>
                          <input
                            type="number"
                            id="payableFee"
                            name="payableFee"
                            className="form-control"
                            value={payableFee}
                            // value={formik.values.payableFee}
                            // onChange={formik.handleChange("payableFee")}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-7 mt-2 ">
                        {taxIncluded && (
                          <div className="col-md-4 border border-primary">
                            <div className="table-responsive">
                              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                  <tr>
                                    <th className="notVisibleInMobile">
                                      {sno}
                                    </th>
                                    <th className="notVisibleInMobile">
                                      {taxname}
                                    </th>
                                    <th className="notVisibleInMobile">
                                      {amount}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tax.map((item: any, index: any) => (
                                    <tr key={index}>
                                      <td data-label={sno}>{index + 1}</td>
                                      <td
                                        data-label={taxname}
                                      >{`${item.taxName} (${item.rate}%)`}</td>
                                      <td data-label={amount}>
                                        {(item.rate * fee) / 100}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                        {taxIncluded && (
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="gender" className="form-label">
                                {intl.formatMessage({
                                  id: "MODULE.TAX.NUMBER",
                                })}
                              </label>
                              <input
                                type="text"
                                id="taxno"
                                name="taxno"
                                className="form-control"
                                onChange={(e) => setTaxNo(e.target.value)}
                                value={taxNo}
                                // disabled={
                                //   isUpdate && UpdateUser?.noOfInvoiceCreated !== 0
                                //     ? true
                                //     : false
                                // }
                              />
                            </div>
                            {formError.taxNo && (
                              <div className="text-danger">
                                {formError.taxNo}
                              </div>
                            )}
                          </div>
                        )}

                        <div className="col-md-4">
                          <label
                            htmlFor="department_name"
                            className="form-label"
                          >
                            {intl.formatMessage({
                              id: "MODULE.MEMBERS.BATCH",
                            })}
                          </label>

                          <Select
                            className=" basic-single"
                            id="batchID"
                            name="batchID"
                            value={batchid}
                            options={activeBatchList.map((item: any) => ({
                              value: item.iBatchID,
                              label: item.batchName,
                            }))}
                            onChange={(e) => handleBatchID(e)}
                            isClearable // Enable the clear option
                            // isDisabled={
                            //   isUpdate && UpdateUser?.noOfInvoiceCreated !== 0
                            //     ? true
                            //     : false
                            // }
                          />
                        </div>
                      </div>
                      {!isUpdate ? (
                        <div className="row mb-6 mt-7">
                          <div>
                            <h1
                              style={{
                                color: "#009ef7",
                                textDecoration: "underline",
                              }}
                            >
                              {intl.formatMessage({
                                id: "MODULE.INVOICES.PAYMENTDETAILS",
                              })}
                            </h1>
                          </div>{" "}
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="amount" className=" form-label">
                                {intl.formatMessage({
                                  id: "MODULE.MEMBERS.PAYMENTAMOUNT",
                                })}
                              </label>
                              <input
                                type="number"
                                id="amount"
                                name="amount"
                                className="form-control"
                                value={dueAmount}
                                onChange={(e) => handleAmountchange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="mb-3">
                              <label
                                htmlFor="paymentDate"
                                className="required form-label"
                              >
                                {intl.formatMessage({
                                  id: "MODULE.INVOICES.PAYMENTDATE",
                                })}
                              </label>
                              <input
                                type="date"
                                id="paymentDate"
                                name="paymentDate"
                                className="form-control"
                                value={paymentDate}
                                onChange={(e) => {
                                  handlePaymentDateChange(e);
                                }}
                                // onChange={formik.handleChange("programfee")}
                              />
                              {formError.payDate && (
                                <div className="text-danger">
                                  {formError.payDate}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="mb-3">
                              <label
                                htmlFor="paymentTime"
                                className="required form-label"
                              >
                                {intl.formatMessage({
                                  id: "MODULE.INVOICES.PAYMENTTIME",
                                })}
                              </label>
                              <input
                                type="time"
                                id="paymentTime"
                                name="paymentTime"
                                className="form-control"
                                value={paymentTime}
                                onChange={(e) => {
                                  handleTimeChange(e);
                                }}
                                // onChange={formik.handleChange("programfee")}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="paymentMode"
                                className="required form-label"
                              >
                                {intl.formatMessage({
                                  id: "MODULE.RECIEPT.PAYMENTMODE",
                                })}
                              </label>
                              <Select
                                id="paymentMode"
                                name="paymentMode"
                                className="basic-single"
                                value={selectPaymentMode}
                                options={paymodeOpt?.map((item: any) => ({
                                  value: item.ipaymentModeID,
                                  label: item.modeName,
                                }))}
                                onChange={(e) => handlePaymentModeChange(e)}
                              />
                              {formError.payMode && (
                                <div className="text-danger">
                                  {formError.payMode}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="remarks" className="form-label">
                                {intl.formatMessage({
                                  id: "COMMON_REMARK",
                                })}
                              </label>
                              <textarea
                                rows={2}
                                id="remarks"
                                name="remarks"
                                className="form-control"
                                value={remarks}
                                // onChange={formik.handleChange("programfee")}
                                onChange={(e) => {
                                  handleRemarkChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="nextPaymentDate"
                                className="form-label"
                              >
                                {intl.formatMessage({
                                  id: "MODULE.INVOICES.NEXTPAYMENTDATE",
                                })}
                              </label>
                              <input
                                type="date"
                                id="nextPaymentDate"
                                name="nextPaymentDate"
                                className="form-control"
                                value={nextPaymentDate}
                                onChange={(e) => {
                                  setNextPaymentDate(e.target.value);
                                  // formik.setFieldValue(
                                  //   "nextPaymentDate",
                                  //   e.target.value
                                  // );
                                }}
                                // onChange={formik.handleChange("programfee")}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row mb-2 mt-2">
                        <h1
                          style={{
                            color: "#009ef7",
                            textDecoration: "underline",
                          }}
                        >
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.DATEDETAILS",
                          })}
                        </h1>
                        <div
                          className="col-md-3 mb-2"
                          style={{ height: "80px" }}
                        >
                          <label
                            htmlFor="department_name"
                            className="required form-label"
                          >
                            {intl.formatMessage({ id: "MODULE.MEMBERS.DOJ" })}
                          </label>
                          <input
                            type="date"
                            id="dateofJoining"
                            name="dateofJoining"
                            className="form-control"
                            onChange={(e) => setDoj(e.target.value)}
                            value={doj}

                            // onChange={(e) => handledobChange(e)}
                          />
                          {formError.doj && (
                            <div className="text-danger">{formError.doj}</div>
                          )}
                        </div>
                        <div className="col-md-3 mb-2">
                          <label
                            htmlFor="department_name"
                            className="form-label"
                          >
                            {intl.formatMessage({
                              id: "MODULE.MEMBERS.STARTINGDATE",
                            })}
                          </label>
                          <input
                            type="date"
                            id="dateofStarting"
                            name="dateofStarting"
                            className="form-control"
                            //onChange={formik.handleChange("dateofStarting")}
                            value={startDate}
                            onChange={(e) => handledStartDateChange(e)}
                          />
                          {formError.dos && (
                            <div className="text-danger">{formError.dos}</div>
                          )}
                        </div>
                        <div className="col-md-3 mb-2">
                          <label
                            htmlFor="department_name"
                            className="form-label"
                          >
                            {intl.formatMessage({
                              id: "MODULE.MEMBERS.EXPIRYDATE",
                            })}
                          </label>

                          <input
                            type="date"
                            id="dateofStarting"
                            name="dateofexpiry"
                            className="form-control"
                            value={expiryDate}
                            disabled
                          />
                        </div>
                        <div className="col-md-3">
                          <div>
                            <label htmlFor="status" className="form-label">
                              {intl.formatMessage({ id: "COMMON.STATUS" })}
                            </label>
                            <select
                              id="status"
                              name="status"
                              className="form-select"
                              onChange={(e) => setStatus(e.target.value)}
                              value={status}
                            >
                              <option value="1">
                                {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                              </option>
                              <option value="0">
                                {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div>
                          <input
                            type="hidden"
                            id="taxdetails"
                            name="taxdetails"
                            className="form-control"
                            // value={formik.values.name}
                            // value={formik.values.taxdetails}
                            // onChange={formik.handleChange("taxdetails")}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-block mb-2">
              {isUpdate === true
                ? `${intl.formatMessage({
                    id: "MODULE.MEMBER.UPDATE",
                  })}`
                : `${intl.formatMessage({
                    id: "MODULE.MEMBER.CREATE",
                  })}`}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BranchModal;
