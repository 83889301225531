import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_ALL_PAYMENTMODE = `${API_URL}/v1/paymentmode/list`;
export const GET_ACTIVE_PAYMENTMODE = `${API_URL}/v1/paymentmode/listbyBranch`;
export const CREATE_PAYMENTMODE = `${API_URL}/v1/paymentmode/add`;
export const UPDATE_PAYMENTMODE = `${API_URL}/v1/paymentmode/update`;
export const UPDATE_PAYMENTMODE_STATUS = `${API_URL}/v1/paymentmode/update_status`;
export const DELETE_PAYMENTMODE = `${API_URL}/v1/paymentmode/delete`;
// Create a department
export function CreatePaymentMode(body: any) {
  return axios.post(CREATE_PAYMENTMODE, body).catch((error) => {
    return error.response;
  });
}

// Get all departments
export function GetPaymentMode(data: any) {
  return axios.get(`${GET_ACTIVE_PAYMENTMODE}/${data}`).catch((error) => {
    return error.response;
  });
}
export function GetPaymentModeList(data: any) {
  return axios.post(GET_ALL_PAYMENTMODE, data).catch((error) => {
    return error.response;
  });
}

// Update a department
export function UpdatePaymentMode(body: any) {
  return axios.put(UPDATE_PAYMENTMODE, body).catch((error) => {
    return error.response;
  });
}

// Update department status
export function Update_PaymentMode_Status(body: any) {
  return axios.patch(UPDATE_PAYMENTMODE_STATUS, body).catch((error) => {
    return error.response;
  });
}
// Delete department
export function Delete_PaymentMode(body: any) {
  return axios.patch(DELETE_PAYMENTMODE, body).catch((error) => {
    return error.response;
  });
}
