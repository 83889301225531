import React from "react";
import { useIntl } from "react-intl";

const ShowplanDetail = ({
  selectedPlan,
  NotModule,
  currencySymbol,
  extraData,
  roleID,
}: any) => {
  const intl = useIntl();
  const chunkArray = (arr: any[], chunkSize: number) => {
    const result = [];
    for (let i = 0; i < arr?.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };
  console.log(selectedPlan, "dsljka");
  return (
    <div className="tab-content  rounded  bg-light p-2">
      {/*begin::Tab Pane*/}
      <div
        className="tab-pane fade show active"
        id="kt_upgrade_plan_startup"
        style={{
          height: "50vh",
          overflowY: "scroll",
        }}
      >
        {/*begin::Heading*/}
        <div className="text-start list-group    w-100">
          {/* <h2 className="fw-bolder text-dark">What’s in Startup Plan?</h2> */}
          <div className="list-group-item">
            <p className="fw-bold fs-5 text-gray-700 flex-grow-1 ">
              {/* <span className=" ">{selectedPlan?.description}</span> */}
              <div
                dangerouslySetInnerHTML={{ __html: selectedPlan?.description }}
              ></div>
            </p>
            <p className="fw-bold fs-5 text-gray-700 flex-grow-1 text-captitalize ">
              {" "}
              {intl.formatMessage({
                id: "REGISTRATION.PLAN.RENEWAL",
              })}
              <span className="ps-2 ">
                {currencySymbol !== "$"
                  ? currencySymbol +
                    selectedPlan?.reRewalPrice +
                    `/ ${selectedPlan?.days} ${intl.formatMessage({
                      id: "FITNESS.DAYS",
                    })}`
                  : currencySymbol +
                    `${(
                      selectedPlan?.reRewalPrice /
                      extraData?.currentDollarPriceInINR
                    ).toFixed(2)} / ${selectedPlan?.days}  ${intl.formatMessage(
                      {
                        id: "FITNESS.DAYS",
                      }
                    )}`}
              </span>
            </p>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-6">
            <div className="shadow-lg p-3 mb-5 bg-body rounded fs-6 fw-bold">
              {" "}
              <label className="pe-2">
                {" "}
                {intl.formatMessage({
                  id: "MODULE.BRANCH.BRANCH",
                })}{" "}
                :
              </label>
              {selectedPlan?.unlimitedBranch !== 1
                ? selectedPlan?.noOfBrachAllow
                : "Unlimited"}
            </div>
          </div>
          {roleID !== "3" && (
            <div className="col-6">
              <div className="shadow-lg p-3 mb-5 bg-body rounded fs-6 fw-bold">
                <label className="pe-2">
                  {" "}
                  {intl.formatMessage({
                    id: "REGISTRATION.PLAN.MEMBER",
                  })}{" "}
                  :
                </label>
                {selectedPlan?.unlimitedMember !== 1
                  ? selectedPlan?.maxMember
                  : "Unlimited"}
              </div>
            </div>
          )}
          <div className="col-6">
            <div className="shadow-lg p-3 mb-5 bg-body rounded fs-6 fw-bold">
              <label className="pe-2">
                {intl.formatMessage({
                  id: "REGISTRATION.PLAN.ENQUIRY",
                })}{" "}
                :{" "}
              </label>
              {selectedPlan?.unlimitedInquiry !== 1
                ? selectedPlan?.MaxInquiry
                : "Unlimited"}
            </div>
          </div>
        </div>
        {/*end::Heading*/}
        {/*begin::Body*/}
        <div className="pt-2">
          {/*begin::Item*/}
          <h2 className="fw-bolder text-dark">
            {intl.formatMessage({
              id: "REGISTRATION.PLAN.MODULES",
            })}
          </h2>
          <div className="f-flex flex-wrap gap-2">
            {selectedPlan?.modules?.length === 0 ? (
              <div>
                <p>
                  {intl.formatMessage({
                    id: "REGISTRATION.PLAN.NOTMODULES",
                  })}
                </p>
              </div>
            ) : (
              selectedPlan?.modules && (
                <div className="container">
                  {chunkArray(selectedPlan?.modules, 2).map((row, rowIndex) => (
                    <div className="row" key={rowIndex}>
                      {row.map((item: any, colIndex: any) => (
                        <div
                          className="col-6 d-flex align-items-center mb-3"
                          key={colIndex}
                        >
                          <span className="fw-bold fs-5 text-gray-700 flex-grow-1">
                            {item?.moduleName}
                          </span>
                          <span className="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="black"
                              ></rect>
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="black"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      ))}
                    </div>
                  ))}

                  {chunkArray(NotModule, 2).map((row, rowIndex) => (
                    <div className="row" key={rowIndex}>
                      {row.map((item: any, colIndex: any) => (
                        <div
                          className="col-6 d-flex align-items-center mb-3"
                          key={colIndex}
                        >
                          <span className="fw-bold fs-5 text-muted flex-grow-1">
                            {item?.moduleName}
                          </span>
                          <span className="svg-icon svg-icon-1 svg-icon-danger">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="black"
                              ></rect>
                              <rect
                                x="7"
                                y="15.3137"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(-45 7 15.3137)"
                                fill="black"
                              ></rect>
                              <rect
                                x="8.41422"
                                y="7"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(45 8.41422 7)"
                                fill="black"
                              ></rect>
                            </svg>
                          </span>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )
            )}
          </div>

          {/*end::Svg Icon*/}

          {/*end::Item*/}
        </div>
      </div>
      {/*end::Tab Pane*/}
      {/*begin::Tab Pane*/}

      {/*end::Tab Pane*/}
    </div>
  );
};

export default ShowplanDetail;
