import React from "react";
import { Modal, Button, Table, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";

const SaleDetailsModal = ({
  show,
  setShow,
  printData,
  CurrentCurrency,
  currencySymbol,
}: any) => {
  const Intl = useIntl();
  const currency = CurrentCurrency;
  const handleClose = () => {
    setShow(false);
  };
  let totalTax = 0;
  let subtotal = 0;
  // console.log(printData, "PrindData");
  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {Intl.formatMessage({ id: "POS.DETAILS.SALE" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>
          {printData?.saleUserType}{" "}
          {Intl.formatMessage({ id: "COMMON.DETAILS" })}
        </h5>
        <div className="row  ">
          <div className="col-md-4 mb-2">
            <label className="form-label">
              {Intl.formatMessage({ id: "COMMON.NAME" })}
            </label>{" "}
            <div className="form-control">
              {printData?.saleUserType === "Member"
                ? printData?.memberName
                : printData?.customerName}
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">
              {Intl.formatMessage({ id: "COMMON.MOBILE" })}
            </label>{" "}
            <div className="form-control">
              {printData?.saleUserType === "Member"
                ? printData?.memberCountryCode
                : printData?.customberCountryCode}{" "}
              {printData?.saleUserType === "Member"
                ? printData?.memberMobileNo
                : printData?.customberMobileNo}
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">
              {Intl.formatMessage({ id: "COMMON.EMAIL" })}
            </label>{" "}
            <div className="form-control">
              {" "}
              {printData?.saleUserType === "Member"
                ? printData?.memberEmail
                : printData?.customberEmail}
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">
              {Intl.formatMessage({ id: "COMMON.ADDRESS" })}
            </label>{" "}
            <div className="form-control">
              {" "}
              {printData?.saleUserType === "Member"
                ? printData?.memberAddress
                : printData?.customberAddress}
            </div>
          </div>
          {printData?.iEmployeeID ? (
            <div className="col-md-4 mb-2">
              <label className="form-label">
                {Intl.formatMessage({ id: "PAYMENT.SUPPLIER.EMPLOYEE" })}
              </label>{" "}
              <div className="form-control">
                {`${printData?.employeename} (${printData?.employeecountcode}-${printData?.employeemobileno})`}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-4 mb-2">
            <label className="form-label">
              {Intl.formatMessage({
                id: "MODULE.SUPPLIMENT.TAXAPPLICATION",
              })}
            </label>{" "}
            <div className="form-control">
              {" "}
              {printData?.taxesApplicable === 1
                ? Intl.formatMessage({
                    id: "COMMON.YES",
                  })
                : Intl.formatMessage({
                    id: "COMMON.NO",
                  })}
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">
              {Intl.formatMessage({
                id: "MODULE.SUPPLIMENT.TAXINCLUDED",
              })}{" "}
              <span>
                (
                {Intl.formatMessage({
                  id: `${
                    printData?.TaxesWithSellAmt == 1
                      ? "MODULE.MODULE.SUPPLIMENT.TAXINCLUDE"
                      : "MODULE.MODULE.SUPPLIMENT.EXCLUDE"
                  }`,
                })}
                )
              </span>
            </label>{" "}
            <div className="form-control">
              {" "}
              {printData?.TaxesWithSellAmt === 1
                ? Intl.formatMessage({
                    id: "COMMON.YES",
                  })
                : Intl.formatMessage({
                    id: "COMMON.NO",
                  })}
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">
              {Intl.formatMessage({
                id: "MODULE.EXPENSECATEGORY.DATE",
              })}
            </label>{" "}
            <div className="form-control"> {printData?.sell_date}</div>
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">
              {Intl.formatMessage({
                id: "MODULE.SUPPLIMENT.TIME",
              })}
            </label>{" "}
            <div className="form-control"> {printData?.sellTime}</div>
          </div>
        </div>

        <Row className="my-4">
          <Col>
            <h5>
              {`${Intl.formatMessage({
                id: "PRINT.SUPPLIMENT.HEADING.INVOICEDETAILS",
              })}
                    ${
                      printData?.taxesApplicable == 1
                        ? printData?.TaxesWithSellAmt == 1
                          ? "( tax on product )"
                          : "( Tax outside the product )"
                        : ""
                    } `}
            </h5>
            {/* <h6>{`${Intl.formatMessage({
                    id: "MODULE.MEMBERS.RECIPTNO",
                  })}`}</h6> */}
            <Table striped bordered hover responsive>
              <thead>
                <tr className="fw-bolder text-dark text-uppercase">
                  <th className="notVisibleInMobile">{`${Intl.formatMessage({
                    id: "PRINT.SUPPLIMENT.HEADING.NAME",
                  })} `}</th>
                  <th className="notVisibleInMobile">{
                    `${Intl.formatMessage({
                      id: "PRINT.SUPPLIMENT.HEADING.PRICE",
                    })} `
                    // ${printData?.data?.companyData?.vHsnCode}
                  }</th>
                  {/* <th>X</th> */}
                  <th className="notVisibleInMobile">{`${Intl.formatMessage({
                    id: "PRINT.SUPPLIMENT.HEADING.QUANTITY",
                  })} `}</th>

                  {currency == "INR" ? (
                    printData?.taxesApplicable == 1 && (
                      <>
                        {/* <th>+</th> */}
                        <th className="notVisibleInMobile">{`${Intl.formatMessage(
                          {
                            id: "MODULE.SUPPLIMENT.TABLE.CGST",
                          }
                        )} `}</th>
                        {/* <th>+</th> */}
                        <th className="notVisibleInMobile">{`${Intl.formatMessage(
                          {
                            id: "MODULE.SUPPLIMENT.TABLE.IGST",
                          }
                        )} `}</th>
                      </>
                    )
                  ) : (
                    <>
                      {/* <th>+</th> */}
                      <th className="notVisibleInMobile">{`${Intl.formatMessage(
                        {
                          id: "MODULE.SUPPLIMENT.TABLE.GST",
                        }
                      )} `}</th>
                    </>
                  )}
                  {/* <th>=</th> */}
                  <th className="text-center notVisibleInMobile">{`${Intl.formatMessage(
                    {
                      id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                    }
                  )} `}</th>
                </tr>
              </thead>
              <tbody>
                {printData?.sellProducts?.map((item: any, i: any) => {
                  const sprice = item.selll_price;
                  const ogPrice = item.originalSellPrice;
                  const quantity = item.no_of_quantity;
                  const taxPercent = item.taxInPercentage;
                  const half = taxPercent / 2;
                  const halftax = (ogPrice * quantity * half) / 100;
                  const fulltax = halftax * 2;
                  totalTax += fulltax;
                  const total = ogPrice * quantity + fulltax;
                  subtotal +=
                    printData?.TaxesWithSellAmt !== 1
                      ? ogPrice * quantity + fulltax
                      : ogPrice * quantity;

                  return (
                    <tr key={i}>
                      <td
                        className=""
                        data-label={Intl.formatMessage({
                          id: "PRINT.SUPPLIMENT.HEADING.NAME",
                        })}
                      >
                        {item.name}
                      </td>
                      <td
                        className=""
                        data-label={Intl.formatMessage({
                          id: "PRINT.SUPPLIMENT.HEADING.PRICE",
                        })}
                      >
                        {ogPrice}
                      </td>
                      {/* <td className="">X</td> */}
                      <td
                        className=""
                        data-label={Intl.formatMessage({
                          id: "PRINT.SUPPLIMENT.HEADING.QUANTITY",
                        })}
                      >
                        {quantity}
                      </td>
                      {currency == "INR" ? (
                        printData?.taxesApplicable == 1 && (
                          <>
                            {/* <td className="">+</td> */}
                            <td
                              className=""
                              data-label={Intl.formatMessage({
                                id: "MODULE.SUPPLIMENT.TABLE.CGST",
                              })}
                            >{`${halftax} ( ${
                              item?.taxInPercentage / 2
                            } %)`}</td>
                            {/* <td className="">+</td> */}
                            <td
                              className=""
                              data-label={Intl.formatMessage({
                                id: "MODULE.SUPPLIMENT.TABLE.IGST",
                              })}
                            >{`${halftax} ( ${
                              item?.taxInPercentage / 2
                            } %)`}</td>
                          </>
                        )
                      ) : (
                        <>
                          {/* <td>+</td> */}
                          <td
                            data-label={Intl.formatMessage({
                              id: "MODULE.SUPPLIMENT.TABLE.GST",
                            })}
                          >
                            {fulltax}
                          </td>
                        </>
                      )}
                      {/* <td className="">=</td> */}
                      <td
                        className="text-md-center floatRight"
                        data-label={Intl.formatMessage({
                          id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                        })}
                      >
                        {printData?.TaxesWithSellAmt !== 1
                          ? total
                          : ogPrice * quantity}
                      </td>
                    </tr>
                  );
                })}
                <tr className="fw-bolder text-dark ">
                  <td
                    colSpan={
                      currency == "INR"
                        ? printData?.taxesApplicable == 1
                          ? 5
                          : 3
                        : 4
                    }
                    className="text-end pe-5 notVisibleInMobile"
                  >
                    {Intl.formatMessage({
                      id: "MODULE.SUPPLIMENT.TABLE.SUBTOTAL",
                    })}
                  </td>
                  <td
                    colSpan={2}
                    className="text-md-center"
                    data-label={Intl.formatMessage({
                      id: "MODULE.SUPPLIMENT.TABLE.SUBTOTAL",
                    })}
                    // data-label={Intl.formatMessage({
                    //   id: "MODULE.SUPPLIMENT.TABLE.SUBTOTAL",
                    // })}
                  >
                    {subtotal}
                  </td>
                </tr>
                <tr className="fw-bolder text-dark">
                  <td
                    colSpan={
                      currency == "INR"
                        ? printData?.taxesApplicable == 1
                          ? 5
                          : 3
                        : 4
                    }
                    className="text-end pe-5 notVisibleInMobile"
                  >
                    {`${Intl.formatMessage({
                      id: "MODULE.MEMBERS.DISCOUNT",
                    })}  ${
                      printData?.discountPercentage !== 0
                        ? `(${printData?.discountPercentage} %)`
                        : ""
                    }`}
                  </td>
                  <td
                    colSpan={2}
                    className="text-md-center "
                    data-label={`${Intl.formatMessage({
                      id: "MODULE.MEMBERS.DISCOUNT",
                    })}  `}
                  >
                    {printData?.discount}
                  </td>
                </tr>
                {printData?.taxeswithsaleAmount === 1 && (
                  <tr className="fw-bolder text-dark">
                    <td
                      colSpan={
                        currency == "INR"
                          ? printData?.taxesApplicable == 1
                            ? 5
                            : 3
                          : 4
                      }
                      className="text-end pe-5 notVisibleInMobile"
                    >
                      {`${Intl.formatMessage({
                        id: "MODULE.SUPPLIMENT.TABLE.GST",
                      })}  ( + )`}
                    </td>
                    <td
                      colSpan={2}
                      className="text-md-center"
                      data-label={`${Intl.formatMessage({
                        id: "MODULE.SUPPLIMENT.TABLE.GST",
                      })}  `}
                    >
                      {totalTax.toFixed(2)}
                    </td>
                  </tr>
                )}
                <tr className="fw-bolder text-dark">
                  <td
                    colSpan={
                      currency == "INR"
                        ? printData?.taxesApplicable == 1
                          ? 5
                          : 3
                        : 4
                    }
                    className="text-end pe-5 notVisibleInMobile"
                  >
                    {Intl.formatMessage({
                      id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                    })}
                  </td>
                  <td
                    colSpan={2}
                    className="text-md-center"
                    data-label={Intl.formatMessage({
                      id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                    })}
                  >
                    {(
                      printData?.totalSellPrice +
                      totalTax -
                      printData?.discount
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button className="btn btn-primary" onClick={() => handleClose()}>
            {Intl.formatMessage({ id: "COMMON.OKAY" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SaleDetailsModal;
