import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Supplyments from "../../POS/Redux/SupplementRedux";
import { useIntl } from "react-intl";
import { Form, Row, Col, Card, Button, Dropdown } from "react-bootstrap";

import { CSVLink } from "react-csv";
import { KTSVG } from "../../../../_metronic/helpers";
import {
  GetActiveProduct,
  GetSellProductList,
  detailsOfBillGenerate,
} from "../../POS/Redux/SupplementCRUD";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/esm/DateRangePicker";
import moment from "moment";
import Pagination from "../../../modules/GenricComponant/Pagination";
import { exportToXLS } from "../../../modules/GenricComponant/exportToxls/exportToxls";
import { ActiveEmpList } from "../../../modules/members/redux/MembersCRUD";
import PaymentModal from "../../POS/componants/modal/PaymentModal";
import BillPage from "../../POS/componants/modal/BillPage";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: object) => jsPDF;
  }
}

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);
// active branch data taken from local storage
let selectedBranch: any = localStorage.getItem("ActiveBranch");

const ActiveBranch = JSON.parse(selectedBranch);
const ActiveBranchID = ActiveBranch?.[0]?.iBranchID;
const ActiveBranchUUID = ActiveBranch?.[0]?.iBranchUUID;
//console.log('ActiveBranch===',ActiveBranch);
const CurrentCurrency = ActiveBranch?.[0]?.currencyCode;
const currencySymbol = ActiveBranch?.[0]?.currencySymbol;
let myMemberProdile: any = localStorage?.getItem("activeUser");
const MemberProfile: any = myMemberProdile ? JSON.parse(myMemberProdile) : "";
//const MemberUUID = MemberProfile?.member_uuid;
const MemberID = MemberProfile?.member_id;

// let ActiveUser: any = localStorage.getItem("activeUserType");
// const UserType = JSON.parse(ActiveUser);

const MyPurchaseModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const Supplyment = useSelector(
    (state: any) => state?.Supplyments?.Supplyments?.data
  );
  const [sortConfig, setSortConfig] = useState<any>({
    key: " iProductSellDetailID", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  const [dateFormat, setdateFormat] = useState<any>("YYYY-MM-DD");
  // const initialDates = [new Date(), new Date()];
  const [value, setValue] = React.useState<any>();

  //page state
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  let initialState = {
    status: "",
    userType: "Member",
    spotID: MemberID,
    customerID: "",
    employeeID: "",
    sellProductID: "",
    page_limit: pageLimit,
    page_no: 1,
    search: "",
    branchID: ActiveBranchID,
    start_date: "",
    end_date: "",
    order_key: " iProductSellDetailID",
    order_value: "desc",
  };

  // const [show, setShow] = useState<any>(false);
  const [activeCatagory, setActiveCatagory] = useState<any>();

  const [selectTaxApplicable, setSelectTaxApplicable] = useState({
    label: `${intl.formatMessage({
      id: "COMMON.YES",
    })}`,
    value: 1,
  });
  const taxAllow = [
    {
      value: 1,
      label: `${intl.formatMessage({
        id: "COMMON.YES",
      })}`,
    },
    {
      value: 0,
      label: `${intl.formatMessage({
        id: "COMMON.NO",
      })}`,
    },
  ];

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // for error
  // const [error, setError] = useState("");
  const [isFilter, setisFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState(initialState);
  // const [searchValues, setSearchValues] = useState(initialState);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [printData, setPrintData] = useState<any>();
  // const [printShow, setPrintShow] = useState<any>(false);
  const [BillShow, setBillShow] = useState<any>(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [TBLData, setTBLData] = useState<any>([]);
  const [baseURL, setBaseURL] = useState<any>("");
  const [selectData, setSelectData] = useState<any>("");
  // const [paymodeOpt, setPaymodeOpt] = useState<any>([]);
  const [payShow, setPayShow] = useState<any>(false);
  const [InvoiceShow, setInvoiceShow] = useState<any>(false);
  const [title, setTitle] = useState<any>("");
  const [selectEmployee, setSelectEmployee] = useState<any>("");
  const [EmpOpt, setEmpOpt] = useState<any>([]);
  const [selectCategory, setSelectCatagory] = useState<any>({
    value: "",
    label: "select a category",
  });
  const PayAmtColumn = `${intl.formatMessage({
    id: "MODULE.SUPPLIMENT.TABLE.PAYAMT",
  })}`;
  const firstColumn = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.ID",
  })}`;

  const EmployeeColumn = `${intl.formatMessage({
    id: "PAYMENT.SUPPLIER.EMPLOYEE",
  })}`;
  const secondColumn = `${intl.formatMessage({
    id: "MODULE.SUPPLIMENT.TABLE.QUANTITY",
  })}`;
  const thirdColumn = `${intl.formatMessage({
    id: "MODULE.SUPPLIMENT.TABLE.PURCHASEPRICE",
  })}`;
  // const fourthColumn = `${intl.formatMessage({
  //   id: "MODULE.SUPPLIMENT.TABLE.PRICE",
  // })}`;
  const fifthColumn = `${intl.formatMessage({
    id: "MODULE.SUPPLIMENT.TAXAPPLICATION",
  })}`;
  const sixthColumn = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.TAXAMT",
  })}`;
  const seventhColumn = `${intl.formatMessage({
    id: "MODULE.MEMBERS.DISCOUNT",
  })}`;
  const eightColumn = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.FINALAMT",
  })}`;
  const ninthColumn = `${intl.formatMessage({
    id: "POS.SELLPRODUCT.REMAINAMT",
  })}`;
  // const tenthColumn = `${intl.formatMessage({
  //   id: "MODULE.SUPPLIMENT.TABLE.PROFIT",
  // })}`;
  const elevanthColumn = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.DATE",
  })}`;

  const twelthColumn = `${intl.formatMessage({ id: "COMMON.ACTIONS" })}`;

  //download fileName
  const downloadfileName = "SellProduct_" + Date.now();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: (values) => {
      setFilterValues(values);
      setPage(1);
    },
  });
  const getExpenseCategory = useCallback(() => {
    GetSellProductList({
      ...filterValues,
      page_no: page,
      page_limit: pageLimit,
      order_key: sortConfig.key,
      order_value: sortConfig.direction,
    }).then((data: any) => {
      // console.log(data, "data");
      if (data?.status !== 200) {
        setFilteredData([]);
      } else {
        dispatch(Supplyments.actions.GetSupplyMents(data));
        setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
      }
    });
  }, [dispatch, pageLimit, filterValues, page, sortConfig]);

  useEffect(() => {
    getExpenseCategory();
  }, [getExpenseCategory]);

  useEffect(() => {
    setFilteredData(Supplyment?.data);
    handleExports();
  }, [Supplyment?.data]);
  useEffect(() => {
    // getActiveMember(ActiveBranchUUID);
    getActiveProductDetails(ActiveBranchUUID);
    // GetActivePayMethod(ActiveBranchUUID);
    // getActiveCustomerList(ActiveBranchUUID);
    getActiveEmployeeList(ActiveBranchUUID);
  }, []);
  const getActiveEmployeeList = async (branchUUID: any) => {
    const response = await ActiveEmpList(branchUUID);
    // console.log(response, "customer list");
    setEmpOpt(response?.data?.data);
  };

  const getActiveProductDetails = async (branchUUID: any) => {
    const response = await GetActiveProduct(branchUUID);
    // console.log(response, "response");
    // setActiveProduct(response?.data?.data);
    setActiveCatagory(response?.data?.data);
  };
  //handle check box

  const handleReset = () => {
    formik.resetForm();
    setFilterValues(initialState);
    handleDateReset();
    setValue(null);
    setSelectCatagory("");
    // setSelectMember("");
    setSelectEmployee("");
    // setSelectPaymentMode({
    //   label: intl.formatMessage({ id: "COMMON.SELECT" }),
    //   value: "",
    // });
    setSelectTaxApplicable({
      label: `${intl.formatMessage({
        id: "COMMON.YES",
      })}`,
      value: 1,
    });
  };

  const handleExports = () => {
    const tableRows = Supplyment?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        // [secondColumn]: element.sellProducts?.length
        //   ? element.sellProducts
        //       .map((item: any) => item.name)
        //       .filter(Boolean)
        //       .join(", ")
        //   : "",
        [firstColumn]: element.iProductSellDetailID,
        [EmployeeColumn]:
          element.employeename &&
          element.employeename +
            `(${element?.employeecountcode}-${element?.employeemobileno})`,

        [thirdColumn]: element.totalSellPrice,
        [secondColumn]: element.totalQuantity,

        [sixthColumn]: element.taxAmount,
        [eightColumn]: element.finalAmt,
        [seventhColumn]: element.discount,
        [PayAmtColumn]: element.totalPayableAmt,
        [ninthColumn]: element.dueAmount,
        [elevanthColumn]:
          element.sell_date +
          " " +
          moment(element.sellTime, "HH:mm").format("hh:mm A"),
      };
    });

    setTBLData(tableRows);
  };

  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    const titleFontSize = 8;
    const tableFontSize = 5;

    doc.setFontSize(titleFontSize);
    doc.text(`${intl.formatMessage({ id: "MODULE.SELLPRODUCT" })}`, 10, 10);
    const tableRows = Supplyment.data.map((element: any, index: number) => {
      return [
        index + 1,
        element?.iProductSellDetailID,
        element.employeename &&
          element.employeename +
            `(${element?.employeecountcode}-${element?.employeemobileno})`,
        element.totalSellPrice,
        element.totalQuantity,
        element.taxAmount,
        element.finalAmt,
        element.discount,
        element.totalPayableAmt,
        element.dueAmount,
        element.sell_date +
          " " +
          moment(element.sellTime, "HH:mm").format("hh:mm A"),
        /*new Date(element.dtCreated_Date).toLocaleString("en-US", {
          dateStyle: "medium",
          timeStyle: "short",+
        }),*/
      ];
    });
    doc.autoTable({
      head: [
        [
          "#",
          firstColumn,
          EmployeeColumn,
          thirdColumn,
          secondColumn,
          sixthColumn,
          eightColumn,
          seventhColumn,
          PayAmtColumn,
          ninthColumn,
          elevanthColumn,
        ],
      ],
      body: tableRows,
      startY: 50,
      styles: {
        fontSize: tableFontSize,
      },
      columnStyles: {
        0: { columnWidth: 20 },
        // Set custom width for the first column
        // Add more column styles as needed
      },
    });

    doc.save(downloadfileName + ".pdf");
  };

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
    setPage(1);
  };

  const handleRefreshButton = () => {
    // setIsLoading(true);
    // setCheckedRows([]);
    // setPage(1);
    // setPageLimit(pageDefaultValue);
    // setSearchValues(initialState);
    setSortConfig({ key: " iProductSellDetailID", direction: "desc" });
  };

  const handleBillGenerate = async (branchID: any, detailsuuid: any) => {
    const response = await detailsOfBillGenerate(branchID, detailsuuid, "");
    setPrintData(response?.data?.data);
    setBillShow(true);
    setBaseURL(response?.data?.imageBaseURL);
    // console.log(response, "detailsof");
  };

  const handleEmployeeChange = (e: any) => {
    setSelectEmployee({ value: e.value, label: e.label });
    formik.setFieldValue("employeeID", e.value);
  };

  const handletaxApplicable = (Event: any) => {
    setSelectTaxApplicable({ value: Event.value, label: Event.label });
    formik.setFieldValue("taxesApplicable", Event.value);
  };
  const handleChange = (values: any) => {
    setValue(values);
    if (values && values.length !== 0) {
      formik.setFieldValue("start_date", moment(values[0]).format(dateFormat));
      formik.setFieldValue("end_date", moment(values[1]).format(dateFormat));
    }
  };
  const handleSELECTChange = (Event: any) => {
    setSelectCatagory({
      label: Event.label,
      value: Event.value,
    });

    formik.setFieldValue("sellProductID", Event.value);
  };
  const handleDateReset = () => {
    setValue(null);
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
  };
  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };
  const handleShowModal = (title: any) => {
    setTitle(title);
    setPayShow(true);
  };

  return (
    <>
      {/* Filter Section Start of Package Period List */}
      {isFilter && (
        <div className="card my-4">
          <div className="card-header">
            {" "}
            <Card className="w-100">
              <Card.Body className="py-5">
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>
                          {intl.formatMessage({
                            id: "MODULE.SUPPLEMENTPRD.HEADING",
                          })}
                        </Form.Label>
                        <Select
                          name="sellProductID"
                          className="basic-single"
                          onChange={(e) => handleSELECTChange(e)}
                          value={selectCategory}
                          options={activeCatagory?.map((item: any) => ({
                            value: item.iSupplierPurchaseProductID,
                            label: item.prodName,
                          }))}
                        />
                        {/* {activeCatagory?.map((ele: any) => (
                            <option value={ele?.iSupplementProductID}>
                              {ele?.name}
                            </option>
                          ))} */}
                      </Form.Group>
                    </Col>

                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.SUPPLIMENT.DATERANGE",
                          })}
                        </label>

                        <DateRangePicker
                          value={value}
                          onChange={handleChange}
                          format="MM/dd/yyyy"
                          placeholder={intl.formatMessage({
                            id: "COMMON.PLACEHOLDER.DATERANGE",
                          })}
                          placement="auto"
                          style={{ margin: "2px" }}
                          onClean={handleDateReset}
                        />
                      </div>
                    </Col>

                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="employeeID" className="  form-label">
                          {intl.formatMessage({
                            id: "PAYMENT.SUPPLIER.EMPLOYEE",
                          })}
                        </label>
                        <Select
                          id="employeeID"
                          name="employeeID"
                          className="basic-single"
                          value={selectEmployee}
                          options={EmpOpt?.map((item: any) => ({
                            value: item.iEmployeeID,
                            label: `${item.vEFirstname} (${item?.vEmpCountryCode}-${item?.vEPhoneno})`,
                          }))}
                          onChange={(e) => handleEmployeeChange(e)}
                          // isClearable
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-4">
                      <label htmlFor="taxesApplicable" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.SUPPLIMENT.TAXAPPLICATION",
                        })}
                      </label>
                      <Select
                        className="basic-single"
                        name="taxesApplicable"
                        id="taxesApplicable"
                        value={selectTaxApplicable}
                        onChange={(e) => handletaxApplicable(e)}
                        options={taxAllow?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />
                    </Col>
                    <Col md={3} className="mb-4">
                      <Form.Group controlId="search">
                        <Form.Label>
                          {intl.formatMessage({
                            id: "MODULE.EXPENSECATEGORY.SEARCH",
                          })}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder={intl.formatMessage({
                            id: "MODULE.EXPENSECATEGORY.ENTERSEARCH",
                          })}
                          onChange={formik.handleChange}
                          value={formik.values.search}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3} className="d-flex  align-items-center">
                      <Button type="submit" variant="primary" className="me-3 ">
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.APPLY",
                        })}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleReset}
                        className="me-3"
                      >
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.RESET",
                        })}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      {/* Filtsser Section End */}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div
                className="d-flex  flex-wrap gap-1"
                data-kt-user-table-toolbar="base"
              >
                <>
                  <span
                    className="min-w-40px mb-3 d-flex  align-items-center"
                    style={{ color: "#7e8299", fontSize: "14px" }}
                  >
                    {intl.formatMessage({ id: "COMMON.SHOW" })}
                  </span>
                  <div>
                    <select
                      className="form-control form-select form-select-solid me-3 mb-4"
                      value={pageLimit}
                      onChange={(e) => getPageLimit(e.target.value)}
                    >
                      {finalPageLimitArray?.map((item: any, i = 0) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex  flex-wrap gap-1"
              data-kt-user-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-primary me-3"
                onClick={handleRefreshButton}
              >
                {" "}
                {intl.formatMessage({ id: "COMMON.REFRESH" })}
              </button>

              <>
                <button
                  type="button"
                  className="btn btn-primary me-3"
                  onClick={() => setisFilter(!isFilter)}
                >
                  {isFilter
                    ? `${intl.formatMessage({
                        id: "MODULE.BRANCH.HIDEFILTER",
                      })}`
                    : `${intl.formatMessage({
                        id: "MODULE.BRANCH.SHOWFILTER",
                      })}`}
                </button>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="btn btn-light-primary me-3"
                  >
                    <KTSVG
                      path="/media/icons/duotune/files/fil021.svg"
                      className="svg-icon-5 svg-icon-gray-500 me-1"
                    />
                    {intl.formatMessage({ id: "MODULE.BRANCH.EXPORT" })}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4">
                    <CSVLink
                      data={TBLData || []}
                      filename={downloadfileName + ".csv"}
                      className="menu-item px-3"
                    >
                      <span className="px-3">
                        {intl.formatMessage({ id: "COMMON.EXPORTCSV" })}
                      </span>
                    </CSVLink>
                    <Dropdown.Item className="menu-item px-3">
                      <a
                        href="#"
                        className="menu-link px-3"
                        onClick={() => exportToXLS(TBLData, downloadfileName)}
                      >
                        {intl.formatMessage({ id: "COMMON.EXPORTEXCEL" })}
                      </a>
                    </Dropdown.Item>

                    <Dropdown.Item className="menu-item px-3">
                      <a
                        href="#"
                        className="menu-link px-3"
                        onClick={exportToPDF}
                      >
                        {intl.formatMessage({ id: "COMMON.EXPORTPDF" })}
                      </a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* modal start */}
        {/* modal start */}
        <BillPage
          printShow={BillShow}
          setPrintShow={setBillShow}
          printData={printData}
          CurrentCurrency={CurrentCurrency}
          currencySymbol={currencySymbol}
          baseURL={baseURL}
        />
        <PaymentModal
          show={payShow}
          setShow={setPayShow}
          title={title}
          setTitle={setTitle}
          data={selectData}
          InvoiceShow={InvoiceShow}
          setInvoiceShow={setInvoiceShow}
        />
        {/* <SellProductModal
          show={show}
          setShow={setShow}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          activeCatagory={activeMember}
          activeProduct={activeProduct}
          getExpenseCategory={getExpenseCategory}
          CurrentCurrency={CurrentCurrency}
          currencySymbol={currencySymbol}
          paymodeOpt={paymodeOpt}
        />
        
       
        <InvoiceModal
          show={InvoiceShow}
          setShow={setInvoiceShow}
          payShow={payShow}
          setPayShow={setPayShow}
          data={selectData}
          setTitle={setTitle}
          title={title}
          getList={getExpenseCategory}
        />
        <SaleDetailsModal
          show={printShow}
          setShow={setPrintShow}
          printData={printData}
          CurrentCurrency={CurrentCurrency}
          currencySymbol={currencySymbol}
        /> */}
        {/* modal End */}

        {/* begin::Body */}

        <div className="card-body py-3">
          {/* {isLoading && <Loading />} */}
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="iProductSellDetailID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {firstColumn}
                      {sortConfig.key !== "iProductSellDetailID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "iProductSellDetailID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "iProductSellDetailID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-200px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="iEmployeeID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {EmployeeColumn}
                      {sortConfig.key !== "iEmployeeID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "iEmployeeID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "iEmployeeID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  {/* <th className="min-w-100px notVisibleInMobile">
                    {secondColumn}
                  </th> */}

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalSellPrice"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumn}
                      {sortConfig.key !== "totalSellPrice" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalSellPrice" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalSellPrice" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalQuantity"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {secondColumn}
                      {sortConfig.key !== "totalQuantity" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="taxAmount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumn}
                      {sortConfig.key !== "taxAmount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "taxAmount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "taxAmount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="finalAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eightColumn}
                      {sortConfig.key !== "finalAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="discount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumn}
                      {sortConfig.key !== "discount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "discount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "discount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalPayableAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {PayAmtColumn}
                      {sortConfig.key !== "totalPayableAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalPayableAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalPayableAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dueAmount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumn}
                      {sortConfig.key !== "dueAmount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dueAmount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dueAmount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="sell_date"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {elevanthColumn}
                      {sortConfig.key !== "sell_date" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "sell_date" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "sell_date" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {twelthColumn}
                  </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredData && filteredData.length === 0 ? (
                  <tr>
                    <td colSpan={11} className="text-center">
                      {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((element: any, index: number) => {
                    // const isChecked = checkedRows.includes(
                    //   element.iProductSellDetailUUID
                    // );
                    return (
                      <tr key={element.iProductSellDetailUUID} className="fs-5">
                        <td data-label={firstColumn}>
                          {element.iProductSellDetailID}
                        </td>
                        <td data-label={EmployeeColumn}>
                          {element?.employeename ? (
                            <div>
                              {element?.employeename}
                              <a
                                href={`tel:${
                                  element.employeecountcode +
                                  element.employeemobileno
                                }`}
                              >{`(${
                                element.employeecountcode +
                                "-" +
                                element.employeemobileno
                              })`}</a>
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                        {/* <td data-label={secondColumn}>
                          {element.sellProducts?.map(
                            (item: any, index: number) =>
                              index < element.sellProducts.length - 1
                                ? `${item.name}, `
                                : item.name
                          )}
                        </td> */}

                        <td data-label={thirdColumn}>
                          {element.TaxesWithSellAmt == 1
                            ? element.totalSellPrice?.toFixed(2)
                            : element?.totalSellPrice?.toFixed(2)}
                        </td>
                        <td data-label={secondColumn}>
                          {element.totalQuantity}
                        </td>

                        <td data-label={sixthColumn}>
                          {element?.taxAmount?.toFixed(2)}
                        </td>

                        <td data-label={eightColumn}>
                          {element?.finalAmt?.toFixed(2)}
                        </td>
                        <td data-label={seventhColumn}>
                          {element?.discount?.toFixed(2)}
                        </td>
                        <td data-label={PayAmtColumn}>
                          {element?.totalPayableAmt?.toFixed(2)}
                        </td>
                        <td data-label={ninthColumn}>
                          {element?.dueAmount?.toFixed(2)}
                        </td>

                        {/* <td data-label={ExpensepaymentmodeRowAction}>
                          {element.mode_of_payment}
                        </td> */}
                        <td data-label={elevanthColumn}>
                          <div>{element.sell_date}</div>
                          <div>
                            {moment(element.sellTime, "HH:mm").format(
                              "hh:mm A"
                            )}
                          </div>
                        </td>

                        {/* <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex flex-column">
                                  <p className="text-dark fw-bolder text-hover-primary mb-1">
                                    {element.TaxesWithSellAmt == 1
                                      ? intl.formatMessage({ id: "COMMON.YES" })
                                      : intl.formatMessage({
                                          id: "COMMON.NO",
                                        })}
                                  </p>
                                </div>
                              </div>
                            </td> */}

                        {/* <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex flex-column">
                                  <p className="text-dark fw-bolder text-hover-primary mb-1">
                                    {element.iDays}
                                  </p>
                                </div>
                              </div>
                            </td> */}

                        <td data-label={twelthColumn}>
                          <div className="d-flex flex-shrink-0 gap-2 floatRight">
                            <span
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm `}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Payment List"
                              onClick={() => {
                                handleBillGenerate(
                                  ActiveBranchID,
                                  element.iProductSellDetailUUID
                                );
                              }}
                            >
                              <i className="fas fa-money-bill"></i>
                            </span>
                            <span
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm `}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Payment List"
                              onClick={() => {
                                handleShowModal("List");
                                setSelectData(element);
                              }}
                            >
                              <i className="fa-solid fa-receipt me-2"></i>
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="iProductSellDetailID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {firstColumn}
                      {sortConfig.key !== "iProductSellDetailID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "iProductSellDetailID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "iProductSellDetailID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-200px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="iEmployeeID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {EmployeeColumn}
                      {sortConfig.key !== "iEmployeeID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "iEmployeeID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "iEmployeeID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  {/* <th className="min-w-100px notVisibleInMobile">
                    {secondColumn}
                  </th> */}

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalSellPrice"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumn}
                      {sortConfig.key !== "totalSellPrice" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalSellPrice" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalSellPrice" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalQuantity"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {secondColumn}
                      {sortConfig.key !== "totalQuantity" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="taxAmount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumn}
                      {sortConfig.key !== "taxAmount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "taxAmount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "taxAmount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="finalAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eightColumn}
                      {sortConfig.key !== "finalAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="discount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumn}
                      {sortConfig.key !== "discount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "discount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "discount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalPayableAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {PayAmtColumn}
                      {sortConfig.key !== "totalPayableAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalPayableAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalPayableAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dueAmount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumn}
                      {sortConfig.key !== "dueAmount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dueAmount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dueAmount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="sell_date"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {elevanthColumn}
                      {sortConfig.key !== "sell_date" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "sell_date" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "sell_date" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {twelthColumn}
                  </th>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {/* begin:: pagination  */}

          {filteredData && filteredData.length > 0 && (
            <Pagination page={page} totalPages={totalPages} setPage={setPage} />
          )}
          {/* end:: pagination */}
        </div>

        {/* begin::Body */}
      </div>
    </>
  );
};

export default MyPurchaseModal;
