import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../modules/GenricComponant/Pagination";
import permission from "../../../modules/GenricComponant/permissions/permission";
import Dummy from "../../../../loading_assests/dummy.png";
import moment from "moment";
import {
  ActivePaymentMode,
  GetMembers,
} from "../../../modules/finance/modules/invoice/component/redux/invoiceCRUD";
import * as invoice from "../../../modules/finance/modules/invoice/component/redux/invoiceRedux";
import swal from "sweetalert";
import { InvoiceNotification } from "../redux/dashboardCRUD";
import { useFormik } from "formik";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { activeUserList } from "../../../memberModules/finance/invoice/redux/invoiceCRUD";
import { ActivePackageList } from "../../../modules/members/redux/MembersCRUD";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/DateRangePicker";

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const InvoiceModalData = (selectedDate1: any) => {
  const intl = useIntl();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "iReceiptID", // Default sorting column
    direction: "desc", // Default sorting direction
  });
  const dispatch = useDispatch();
  const invoices: any = useSelector(
    (state: any) => state?.invoices?.invoices?.data
  );

  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;
  const activeBranchID = ActiveBranch.iBranchID;

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("Invoices");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });

  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  const [baseURL, setBaseURL] = useState<any>([]);

  const [checkedRows, setCheckedRows] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [filteredData, setFilteredData] = useState<any>([]);
  const [TBLData, setTBLData] = useState<any>([]);
  const [totalInoviceAMT, setTotalInvoiceAMT] = useState<any>("");
  const [dateFormat, setdateFormat] = useState<any>(ActiveBranch?.idateFormate);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectMembershipType, setSelectMembershipType] = useState({
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
    value: "",
  });
  const [selectPaymentMode, setSelectPaymentMode] = useState({
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
    value: "",
  });
  const [selectMember, setSelectedMember] = useState({
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
    value: "",
  });
  const [selectedPackage, setSelectedPackage] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.NAME",
    })}`,
  });
  const [searchText, setSearchText] = useState<any>();
  const [value, setValue] = React.useState<any>();
  const [selectedDate, setSelectedDate] = useState({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.EMPLOYEE.SELECTINITIAL",
    })}`,
  });
  // const option1 = {
  //   label: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE1" }),
  //   value: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE1" }),
  // };
  // const option2 = {
  //   label: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE2" }),
  //   value: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE2" }),
  // };
  // const option3 = {
  //   label: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE3" }),
  //   value: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE3" }),
  // };
  const option4 = {
    label: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE4" }),
    value: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE4" }),
  };
  const option5 = {
    label: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE5" }),
    value: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE5" }),
  };

  const dateOption = [option4, option5];

  const [activeUser, setActiveUser] = useState<any>([]);
  const [payModeOpt, setPayModeOpt] = useState<any>([]);
  const [activePackageList, setActivePackageList] = useState<any>([]);
  const stardDate = selectedDate1?.selectedDate?.startDate;
  const endDate = selectedDate1?.selectedDate?.endDate;
  const date_type = "payment";
  let initialState = {
    branchID: activeBranchID,
    status: "",
    page_limit: pageLimit,
    // page_no: 1,
    paymentMode: "",
    membershipType: "",
    employeeID: "",
    occuptionID: "",
    gender: "",
    maritalStatus: "",
    batchID: "",
    packageUUID: "",
    packageGroupUUID: "",
    search: "",
    start_date: stardDate ? stardDate : "",
    end_date: endDate ? endDate : "",
    date_type: stardDate ? date_type : "",
    order_key: " iReceiptID",
    order_value: "desc",
  };

  const activeMemberShip = [
    {
      value: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE1",
      })}`,
      label: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE1",
      })}`,
    },
    {
      value: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE2",
      })}`,
      label: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE2",
      })}`,
    },
    {
      value: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE3",
      })}`,
      label: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE3",
      })}`,
    },
  ];
  const [isFilter, setisFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState(initialState);

  const firstColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERSHIP.MEMBER",
  })}`;
  const secondColumnTitle = `${intl.formatMessage({
    id: "COMMON.PHOTO",
  })}`;
  const thirdColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGE",
  })}`;
  const fourthColumnTitle = `${intl.formatMessage({
    id: "MODULE.RECIEPT.AMOUNT",
  })}`;
  const fifthColumnTitle = `${intl.formatMessage({
    id: "MODULE.RECIEPT.PAYMENTMODE",
  })}`;
  const sixthColumnTitle = `${intl.formatMessage({
    id: "MODULE.RECIEPT.INVOICENO",
  })}`;
  const seventhColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.RECIEVER",
  })}`;
  const eigthColumnTitle = `${intl.formatMessage({
    id: "MODULE.INVOICES.PAYMENTDATE",
  })}`;
  const ninthColumnTitle = `${intl.formatMessage({
    id: "MODULE.INVOICES.NEXTPAYMENTDATE",
  })}`;

  const tenthColumnTitle = `${intl.formatMessage({
    id: "COMMON.ACTIONS",
  })}`;

  const handleExports = () => {
    const tableRows = invoices?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [firstColumnTitle]: ` ${element.memberName} ${element.MemberCountryCode}- ${element.memnerMobileNo}`,
        [thirdColumnTitle]:
          element.packageName + " " + `(${element.eEnrol_Type})`,
        [fourthColumnTitle]: element.amount,
        [fifthColumnTitle]: element.paymentMode,
        [sixthColumnTitle]: element?.VInvoiceNO,
        [seventhColumnTitle]: element.receiveEemployeeName,
        [eigthColumnTitle]:
          element?.paymentDate +
          `(${moment(element.paymentTime, "HH:mm").format("hh:mm A")}})`,
        [ninthColumnTitle]: element?.dtNext_Payment,
      };
    });

    setTBLData(tableRows);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: async (values, action) => {
      // console.log("valee", values);

      setFilterValues(values);
    },
  });

  const getMembers = useCallback(() => {
    if (UserType === "admin" || view !== 0) {
      setIsLoading(true);
      GetMembers({
        ...filterValues,
        page_no: page,
        page_limit: pageLimit,
        order_key: sortConfig.key,
        order_value: sortConfig.direction,
      }).then((data: any) => {
        // console.log(data, "data");
        if (data?.status !== 200) {
          setFilteredData([]);
          setIsLoading(false);
        } else {
          dispatch(invoice.actions.GetInvoices(data));
          setTotalInvoiceAMT(data?.data?.totalPayment);
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, filterValues, page, sortConfig, view]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    setFilteredData(invoices?.data);
    setBaseURL(invoices?.imageBaseURL);
    handleExports();
  }, [invoices?.data]);

  const handleRowCheckboxChange = (rowId: any) => {
    if (checkedRows.includes(rowId)) {
      setCheckedRows(checkedRows.filter((id: any) => id !== rowId));
    } else {
      setCheckedRows([...checkedRows, rowId]);
    }
  };
  useEffect(() => {
    // getActiveMemberList(ActiveBranchUUID);

    getActivePackageList(ActiveBranchUUID);
    getActiveUserList(ActiveBranchUUID);
    GetActivePayMethod(ActiveBranchUUID);
  }, []);
  const GetActivePayMethod = async (branchUUID: any) => {
    const response = await ActivePaymentMode(branchUUID);
    setPayModeOpt(response?.data?.data);
    // console.log(response?.data, "reponseofactive");
  };
  const getActiveUserList = async (branchUUID: any) => {
    const response = await activeUserList(branchUUID);
    setActiveUser(response?.data?.data);
    // console.log(response?.data, "reponseofactive");
  };

  const getActivePackageList = async (branchUUID: any) => {
    const response = await ActivePackageList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActivePackageList(response?.data?.data);
  };

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
    // console.log("page limit event", values);
    setPage(1);
  };

  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };
  const handleSendNotifications = async (branchID: any, enrolID: any) => {
    // Show a confirmation dialog
    const willSend = await swal({
      title: intl.formatMessage({ id: "COMMON.AREYOUSURE" }),
      text: intl.formatMessage({ id: "COMMON.DOYOUSEND" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SEND" }),
      ], // Cancel and Send buttons
      dangerMode: true, // Optional: Highlights the "Send" button
    });

    // If the user confirms, proceed with the API call
    if (willSend) {
      const obj = { branchID: branchID, iReceiptUUID: enrolID };
      const response = await InvoiceNotification(obj);
      if (response?.status === 200) {
        swal(response?.data?.replyMsg, { icon: "success" });
      } else {
        swal(response?.data?.replyMsg, { icon: "error" });
      }
    } else {
      // Handle the cancel action (optional)
      swal(
        "Cancelled",
        intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" }),
        "info"
      );
    }
  };
  const handleMemberChange = async (Event: any) => {
    setSelectedMember({
      value: Event.value,
      label: Event.label,
    });
    formik.setFieldValue("spotUUID", Event.value);
    // console.log("faiza", Event.value);

    // const response = await enrollListofFreeze(activeBranchID, Event.value);
    // setActivePackageList(response?.data?.data);
    // console.log("ressss", response);
    // handleResetForm();
  };

  const handleDateReset = (values: any) => {
    setValue(null);
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
  };
  const handleChange = (values: any) => {
    setValue(values);
    if (values && values.length !== 0) {
      formik.setFieldValue("start_date", moment(values[0]).format(dateFormat));
      formik.setFieldValue("end_date", moment(values[1]).format(dateFormat));
    }
  };
  const handlesearchChange = (Event: any) => {
    formik.setFieldValue("search", Event.target.value);
    setSearchText(Event.target.value);
  };

  const handlePackageChange = async (Event: any) => {
    formik.setFieldValue("packageUUID", Event.value);

    setSelectedPackage({ value: Event.value, label: Event.label });
    // console.log("dssdsfsdda",iProgramgrpUUID)
    const result = activePackageList?.filter((ele: any) => {
      return ele.iProgramUUID === Event.value;
    });
    if (result.length > 0) {
      const { iProgramUUID, iProgramgrpUUID } = result[0];
      formik.setFieldValue("packageGroupUUID", iProgramgrpUUID);
      formik.setFieldValue("batchID", "");
      // if (Event.value) {
      //   const response = await batchIDAPII(iProgramUUID);
      //   setBatchData(response?.data?.data);
      // }
    }
    // setBatchid({ value: "", label: "select a batch" });
  };
  const handleReset = () => {
    formik.resetForm();
    handleResetFilter();
    setFilterValues(initialState);
    // getMembers();
  };
  const handleResetFilter = () => {
    setSelectedMember({
      value: "",
      label: `${intl.formatMessage({ id: "COMMON.SELECT" })}`,
    });
    setSelectedPackage({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.NAME" })}`,
    });
    setSelectMembershipType({
      value: "",
      label: `${intl.formatMessage({ id: "COMMON.SELECT" })}`,
    });
    setSelectedDate({
      value: "",
      label: `${intl.formatMessage({ id: "COMMON.SELECT" })}`,
    });
    setSelectPaymentMode({
      value: "",
      label: `${intl.formatMessage({ id: "COMMON.SELECT" })}`,
    });
    setValue(null);
    setSearchText("");
  };

  const handleMemberShipType = async (Event: any) => {
    setSelectMembershipType({
      value: Event.value,
      label: Event.label,
    });
    formik.setFieldValue("membershipType", Event.value);
  };
  const handlePaymentModeChange = (Event: any) => {
    //   formik.setFieldValue("packageUUID", Event.value);
    formik.setFieldValue("paymentMode", Event.value);
    setSelectPaymentMode({
      value: Event.value,
      label: Event.label,
    });
    // handlePaymentReset();
  };

  const handleDateChoose = (Event: any) => {
    formik.setFieldValue("date_type", Event.value);
    setSelectedDate({
      value: Event.value,
      label: Event.label,
    });
  };
  const handleRefreshButton = () => {
    setSortConfig({
      key: "iReceiptID",
      direction: "desc",
    });
    setFilterValues(initialState);
    // getMembers();
    setCheckedRows([]);
    setPageLimit(pageDefaultValue);
    // setSearchValue("");
    // setStatus("");
  };

  return (
    <>
      {isFilter && (
        <div className="card my-4">
          <div className="card-header">
            {" "}
            <Card className="w-100">
              <Card.Body className="py-5">
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col md={3} className="mb-5 ">
                      <label htmlFor="spotUUID" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.RENEWAL.MEMBER.ENROLL",
                        })}
                      </label>
                      <Select
                        id="spotUUID"
                        name="spotUUID"
                        className="basic-single"
                        value={selectMember}
                        options={activeUser?.map((item: any) => ({
                          value: item.iSpotUUID,
                          label: `${item.name} (${item.countryCode}-${item.mobileno})`,
                        }))}
                        onChange={(e) => handleMemberChange(e)}
                      />

                      {/* </div> */}
                    </Col>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="packageUUID" className="form-label">
                          {intl.formatMessage({ id: "MODULE.MEMBERS.PACKAGE" })}
                        </label>
                        <Select
                          id="packageUUID"
                          name="packageUUID"
                          className="basic-single"
                          value={selectedPackage}
                          options={activePackageList.map((item: any) => ({
                            value: item.iProgramUUID,
                            label: item.vProgram,
                          }))}
                          onChange={(e) => handlePackageChange(e)}
                        />
                      </div>
                    </Col>

                    <Col md={3} className="mb-5 ">
                      <label htmlFor="membershipType" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBER.MEMBERSHIPTYPE",
                        })}
                      </label>
                      <Select
                        id="membershipType"
                        name="membershipType"
                        className="basic-single"
                        value={selectMembershipType}
                        options={activeMemberShip?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                        onChange={(e) => handleMemberShipType(e)}
                      />

                      {/* </div> */}
                    </Col>
                    <Col>
                      <label htmlFor="paymentMode" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.RECIEPT.PAYMENTMODE",
                        })}
                      </label>
                      <Select
                        id="paymentMode"
                        name="paymentMode"
                        className="basic-single"
                        value={selectPaymentMode}
                        options={payModeOpt?.map((item: any) => ({
                          value: item.ipaymentModeID,
                          label: item.modeName,
                        }))}
                        onChange={(e) => handlePaymentModeChange(e)}
                      />
                    </Col>
                    <Col md={3} className="mb-3">
                      <label htmlFor="date_type" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.EMPLOYEE.DATEOPTION",
                        })}
                      </label>
                      <Select
                        id="date_type"
                        name="date_type"
                        className="basic-single"
                        value={selectedDate}
                        options={dateOption.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                        onChange={(e) => handleDateChoose(e)}
                      />
                    </Col>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.DATERANGE",
                          })}
                        </label>

                        <DateRangePicker
                          value={value}
                          onChange={handleChange}
                          format="MM/dd/yyyy"
                          placeholder={intl.formatMessage({
                            id: "COMMON.PLACEHOLDER.DATERANGE",
                          })}
                          placement="auto"
                          style={{ margin: "2px" }}
                          onClean={handleDateReset}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="search" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.SEARCHLABEL",
                          })}
                        </label>
                        <input
                          className=" form-control"
                          id="search"
                          name="search"
                          value={searchText}
                          onChange={(e) => handlesearchChange(e)}
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* Rest of the form fields */}
                  <Row>
                    <Col
                      md={3}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <Button
                        type="submit"
                        variant="primary"
                        className="me-3 mr-4 "
                        // onClick={(e) => handleSearchClick(e)}
                        disabled={isLoading}
                      >
                        {intl.formatMessage({ id: "COMMON.APPLY" })}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleReset}
                        className="me-3"
                      >
                        {intl.formatMessage({ id: "COMMON.RESET" })}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      <div className={`card mb-5 mb-xl-8`}>
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div
                className="d-flex flex-wrap gap-1"
                data-kt-user-table-toolbar="base"
              >
                <span
                  className="min-w-40px mb-3 d-flex align-items-center"
                  style={{ color: "#7e8299", fontSize: "14px" }}
                >
                  {intl.formatMessage({ id: "COMMON.SHOW" })}
                </span>
                <div>
                  <select
                    className="form-control form-select form-select-solid me-3 mb-4"
                    value={pageLimit}
                    onChange={(e) => getPageLimit(e.target.value)}
                  >
                    {finalPageLimitArray?.map((item: any, i = 0) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <h1 className="text-dark px-2 d-flex align-items-center">
                  {intl.formatMessage({
                    id: "MODULE.INVOICE.TOTAL",
                  })}
                  {totalInoviceAMT}
                </h1>
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex flex-wrap gap-1"
              data-kt-user-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-primary me-3"
                onClick={handleRefreshButton}
              >
                {intl.formatMessage({ id: "COMMON.REFRESH" })}
              </button>

              {checkedRows?.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>

                  {/* This is workable button of adding */}
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className="w-25">
          <span
            className="min-w-40px mb-3 d-flex align-items-center"
            style={{ color: "#7e8299", fontSize: "14px" }}
          >
            {intl.formatMessage({ id: "COMMON.SHOW" })}
          </span>
          <div>
            <select
              className="form-control form-select form-select-solid me-3 mb-4"
              value={pageLimit}
              onChange={(e) => getPageLimit(e.target.value)}
            >
              {finalPageLimitArray?.map((item: any, i = 0) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        <div className="card-body py-3">
          {/* {isLoading && <Loading />} */}
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="memberName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {firstColumnTitle}
                      {sortConfig.key !== "memberName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="packageName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumnTitle}
                      {sortConfig.key !== "packageName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="amount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fourthColumnTitle}
                      {sortConfig.key !== "amount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentMode"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fifthColumnTitle}
                      {sortConfig.key !== "paymentMode" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentMode" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentMode" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="VInvoiceNO"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumnTitle}
                      {sortConfig.key !== "VInvoiceNO" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "VInvoiceNO" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "VInvoiceNO" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="receiveEemployeeName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumnTitle}
                      {sortConfig.key !== "receiveEemployeeName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "receiveEemployeeName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "receiveEemployeeName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentDate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eigthColumnTitle}
                      {sortConfig.key !== "paymentDate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtNext_Payment"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumnTitle}
                      {sortConfig.key !== "dtNext_Payment" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtNext_Payment" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtNext_Payment" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {tenthColumnTitle}
                  </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredData && filteredData?.length === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                    </td>
                  </tr>
                ) : UserType === "employee" && view === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger fs-4">
                      {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((element: any, index: number) => {
                    // setUUID(element.iEnrolUUID);
                    const isChecked = checkedRows.includes(
                      element.iReceiptUUID
                    );
                    // console.log("uidofmember", element);
                    return (
                      <>
                        <tr key={index} className="fs-5">
                          <td data-label={firstColumnTitle}>
                            <div className="d-flex align-items-center border-0 ">
                              <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                {/* <a href="../../demo1/dist/apps/user-management/users/view.html"> */}
                                <div className="symbol-label">
                                  <img
                                    src={
                                      element?.vPhoto
                                        ? `${baseURL}${element.vPhoto}`
                                        : Dummy
                                    }
                                    width={50}
                                    height={50}
                                    // className="w-100"
                                  />
                                </div>
                                {/* </a> */}
                              </div>
                              <div className="d-flex flex-column ">
                                {`${element.memberName}`}
                                <a
                                  href={`tel:${element.MemberCountryCode}-${element.memnerMobileNo}`}
                                >
                                  {`(${element.MemberCountryCode}-${element.memnerMobileNo})`}
                                </a>
                              </div>
                            </div>
                          </td>
                          <td data-label={thirdColumnTitle}>
                            {`${element.packageName} (${element.eEnrol_Type})`}{" "}
                          </td>
                          <td
                            data-label={fourthColumnTitle}
                          >{`${element?.amount} `}</td>
                          <td data-label={fifthColumnTitle}>
                            {" "}
                            {element.paymentMode}
                          </td>
                          <td data-label={sixthColumnTitle}>
                            {element.VInvoiceNO}
                          </td>
                          <td data-label={seventhColumnTitle}>
                            {element.receiveEemployeeName}
                          </td>
                          <td data-label={eigthColumnTitle}>
                            {moment(element.paymentDate).format(dateFormat)} (
                            {moment(element.paymentTime, "HH:mm").format(
                              "hh:mm A"
                            )}
                            )
                          </td>
                          <td data-label={ninthColumnTitle}>
                            {moment(element.dtNext_Payment).format(dateFormat)}
                          </td>
                          <td
                            data-label={intl.formatMessage({
                              id: "COMMON.ACTIONS",
                            })}
                          >
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                handleSendNotifications(
                                  ActiveBranch?.iBranchID,
                                  element.iReceiptUUID
                                );
                              }}
                            >
                              {intl.formatMessage({ id: "COMMON.SEND" })}
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })
                )}
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="memberName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {firstColumnTitle}
                      {sortConfig.key !== "memberName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="packageName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumnTitle}
                      {sortConfig.key !== "packageName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="amount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fourthColumnTitle}
                      {sortConfig.key !== "amount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentMode"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fifthColumnTitle}
                      {sortConfig.key !== "paymentMode" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentMode" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentMode" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="VInvoiceNO"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumnTitle}
                      {sortConfig.key !== "VInvoiceNO" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "VInvoiceNO" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "VInvoiceNO" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="receiveEemployeeName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumnTitle}
                      {sortConfig.key !== "receiveEemployeeName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "receiveEemployeeName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "receiveEemployeeName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentDate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eigthColumnTitle}
                      {sortConfig.key !== "paymentDate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtNext_Payment"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumnTitle}
                      {sortConfig.key !== "dtNext_Payment" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtNext_Payment" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtNext_Payment" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {tenthColumnTitle}
                  </th>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {/* begin:: pagination  */}

          {filteredData && filteredData.length > 0 && (
            <Pagination page={page} totalPages={totalPages} setPage={setPage} />
          )}
          {/* end:: pagination */}
        </div>
      </div>
    </>
  );
};

export default InvoiceModalData;
