import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
// import InvoiceModal from "./InvoiceModal";
// import DetailsModal from "./DetailsModal";
import ListPayment from "../ListPayment";

const PaymentModal = ({
  show,
  setShow,
  data,
  title,
  setTitle,
  InvoiceShow,
  setInvoiceShow,
}: any) => {
  const intl = useIntl();
  const handleClose = () => {
    setShow(false);
  };
  console.log(data, "data");

  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100">
          <div className="d-flex justify-content-between w-100">
            <div>
              {intl.formatMessage({ id: "PURCHASEPRODUCT.LIST.INVOICE" })}
            </div>
            {/* <div>
              {title !== "List" ? (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setTitle("List");
                  }}
                >
                  {intl.formatMessage({ id: "PURCHASEPRODUCT.LIST.INVOICE" })}
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setTitle("Add");
                    setInvoiceShow(true);
                    setShow(false);
                  }}
                >
                  {intl.formatMessage({ id: "PURCHASEPRODUCT.ADD.INVOICE" })}
                </button>
              )}
            </div> */}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListPayment
          data={data}
          setTitle={setTitle}
          title={title}
          InvoiceShow={InvoiceShow}
          setInvoiceShow={setInvoiceShow}
          ListShow={show}
          setListShow={setShow}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentModal;
