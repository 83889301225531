import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_SALEPRODUCT_LIST = `${API_URL}/v1/centermember/my-pos/purchase/list`;
export const GET_PAYMENT_LIST = `${API_URL}/v1/centermember/pospayment/list`;
export const DETAILS_INVOICE_SALE = `${API_URL}/v1/centermember/pospayment/detailsandinvoice`;
// export const ADD_SALEPRODUCT = `${API_URL}/v1/productsell/add`;
export const ACTIVE_MEMBER = `${API_URL}/v1/member/listbyBranch`;
export const Active_PRODUCT = `${API_URL}/v1/purchaseproduct/getallactiveproduct`;
export const DETAIL_UPDATE_SALEPRODUCT = `${API_URL}/v1/centermember/my-pos/puchase/details`;
export const DETAIL_BILL_GENERATE = `${API_URL}/v1/centermember/my-pos/generatebill`;
// export const ACTIVE_EXPENSECATEGORY = `${API_URL}/v1/expensecategory/add`;
// export const UPDATE_SALEPRODUCT = `${API_URL}/v1/productsell/update/`;
// export const UPDATE_EXPENSECATEGORY_STATUS = `${API_URL}/v1/expense/update_status`;
// export const DELETE_SALEPRODUCT = `${API_URL}/v1/productsell/delete`;
export const ACTIVE_CUSTOMER_LIST = `${API_URL}/v1/customer/listbyBranch`;

export function ActiveCustomerList(activebranchuuid: any) {
  return axios
    .get(`${ACTIVE_CUSTOMER_LIST}/${activebranchuuid}`)
    .catch((error) => {
      return error.response;
    });
}
export function GetActiveMember(activebranchuuid: any) {
  return axios.get(`${ACTIVE_MEMBER}/${activebranchuuid}`).catch((error) => {
    return error.response;
  });
}
export function GetActiveProduct(activebranchuuid: any) {
  return axios.get(`${Active_PRODUCT}/${activebranchuuid}`).catch((error) => {
    return error.response;
  });
}
export function detailofSellProduct(activebranchid: any, expensesuuid: any) {
  return axios
    .get(`${DETAIL_UPDATE_SALEPRODUCT}/${activebranchid}/${expensesuuid}`)
    .catch((error) => {
      return error.response;
    });
}
export function detailsOfBillGenerate(
  activebranchid: any,
  detailsUUID: any,
  paymentID: any
) {
  return axios
    .get(
      `${DETAIL_BILL_GENERATE}/${activebranchid}/${detailsUUID}${
        paymentID ? `?productSellpaymentID=${paymentID}` : ""
      }`
    )
    .catch((error) => {
      return error.response;
    });
}

export function GetSellProductList(data: any) {
  return axios.post(GET_SALEPRODUCT_LIST, data).catch((error) => {
    return error.response;
  });
}
export function GetSellerPaymentList(data: any) {
  return axios.post(GET_PAYMENT_LIST, data).catch((error) => {
    return error.response;
  });
}
export function DetailsInvoiceSeller(branch: any, uuid: any) {
  return axios
    .get(`${DETAILS_INVOICE_SALE}/${branch}/${uuid}`)
    .catch((error) => {
      return error.response;
    });
}
