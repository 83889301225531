import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_CUSTOMER_LIST = `${API_URL}/v1/customer/list`;
export const ADD_CUSTOMER = `${API_URL}/v1/customer/add`;
export const UPDATE_CUSTOMER = `${API_URL}/v1/customer/update`;
export const UPDDATE_STATUS = `${API_URL}/v1/customer/update_status`;
export const DELETE_STATUS = `${API_URL}/v1/customer/delete`;
export const DETAILS_CUSTOMER = `${API_URL}/v1/customer/details`;
export const STATES_BASEDON_COUNTRY = `${API_URL}/v1/statelistbasedoncountrycurrencyid`;

export function GetCustomerList(body: any) {
  return axios.post(GET_CUSTOMER_LIST, body).catch((error) => {
    return error.response;
  });
}
export function AddCustomer(body: any) {
  return axios.post(ADD_CUSTOMER, body).catch((error) => {
    return error.response;
  });
}
export function UpdateCustomer(body: any) {
  return axios.put(UPDATE_CUSTOMER, body).catch((error) => {
    return error.response;
  });
}
export function UpdateStatus(body: any) {
  return axios.patch(UPDDATE_STATUS, body).catch((error) => {
    return error.response;
  });
}
export function deleteCustomer(body: any) {
  return axios.patch(DELETE_STATUS, body).catch((error) => {
    return error.response;
  });
}
export function getDetailsOfCustomer(body: any, uuid: any) {
  return axios.get(`${DETAILS_CUSTOMER}/${body}/${uuid}`).catch((error) => {
    return error.response;
  });
}
export function getStateBasedOnCountryID(body: any) {
  return axios.get(`${STATES_BASEDON_COUNTRY}/${body}`).catch((error) => {
    return error.response;
  });
}
