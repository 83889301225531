import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Table, Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { settingDetailsMember } from "../redux/invoiceCRUD";
import { DateFormate } from "../../../../../_metronic/helpers";

const InvoiceBill = ({ printShow, setPrintShow, printData }: any) => {
  const Intl = useIntl();

  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);

  const [notes, setNotes] = useState<any>("");
  const handleClose = () => setPrintShow(false);
  // const [tax, setTax] = useState<any>();
  // console.log(printData, "printsddfsfds");
  // const { data } = printData?.data;
  // printData.map(())
  useEffect(() => {
    getnotesForInvoice(ActiveBranchUUID);
  }, []);
  const getnotesForInvoice = async (branchUUID: any) => {
    const response = await settingDetailsMember(
      UserType !== "member" ? branchUUID : ActiveBranch[0]?.iBranchUUID
    );
    setNotes(response?.data?.data?.InvoiceNotes);
    // console.log(response, "reponseofactive");
  };

  const printRef = useRef<any>();
  const handlePrint = () => {
    const printContent: any = printRef.current.innerHTML;
    const printWindow: any = window.open("", "", "height=800,width=1200");
    printWindow.document.write("<html><head>");
    printWindow.document.write(
      '<link href="https://stackpath.bootstrapcdn.com/bootstrap/5.0.0-alpha1/css/bootstrap.min.css" rel="stylesheet">'
    );

    printWindow.document.write("<title>Print</title><style>");
    // Add your custom styles here
    printWindow.document.write(`
      p{
      font-size:12px;
      }
      .marg { margin: 1rem; }
      .text-end { text-align: right; }
      .rightpara { text-align: right; }
      .alert-primary {
        color: #073984;
        background-color: #cfe2ff;
        border-color: #bbd6fe;
      }
      .lineSpace {
        line-height: 0.1;
      }
    `);
    printWindow.document.write("</style></head><body>");
    printWindow.document.write(printContent);
    printWindow.document.write("</body></html>");
    setTimeout(() => printWindow.print(), 1000);
  };

  const splittedNote = notes?.split("\n");
  // console.log("spliitedd", splittedNote);
  const totalTax: any = parseFloat(
    (
      (printData?.data?.paidamount *
        (printData?.data?.taxeData[0]?.rate +
          printData?.data?.taxeData[1]?.rate +
          printData?.data?.taxeData[2]?.rate)) /
      100
    ).toFixed(2)
  );
  const withoutTax = parseFloat(
    (printData?.data?.paidamount - totalTax).toFixed(2)
  );
  const discount = printData?.data?.discountAmt;
  const startIndex = discount && discount?.indexOf("(") + 1;
  const endIndex = discount && discount?.indexOf("%");
  const percentage = discount && discount?.slice(startIndex, endIndex);
  const discountAmount = (withoutTax * percentage) / 100;
  // ? printData?.data?.discountAmt?.split(" ")[0] > 0
  //     ? withoutTax + discountAmount:
  // {printData?.data?.taxeData.length >= 3

  //     : withoutTax
  //   : printData?.data?.discountAmt?.split(" ")[0] > 0
  //   ? printData?.data?.paidamount + discountAmount
  //   : printData?.data?.paidamount}

  // const principlediscountAfter =
  //   printData?.data?.packageFee +
  //   printData?.data?.registrationFee -
  //   printData?.data?.discountAmt.split(" ")[0];
  // console.log(printData, "printdata");
  return (
    <>
      <Modal
        show={printShow}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`${Intl.formatMessage({
              id: "MODULE.INVOICES",
            })}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body scroll-y mx-5 mx-xl-15 my-7">
          <Container>
            <div ref={printRef} className="marg">
              {/* <Row className="my-4">
                <Col>
                  <h2>Invoice</h2>
                </Col>
              </Row> */}
              <style>
                {`
            .lh-1 {
             line-height: 0.6 !important; /* Sets the line height */
          }
        `}
              </style>
              <div className="row mb-1">
                <div className="col-6">
                  <h2>{`${Intl.formatMessage({
                    id: "MODULE.MEMBERS.RECIPTNO",
                  })} -  ${printData?.data?.invocieSerialNo}`}</h2>
                  <div className="fs-5 fw-bold">
                    {DateFormate(
                      `${printData?.data?.paymentDate}T${printData?.data?.paymentTime}`
                    )}
                  </div>
                </div>
                <div className="col-6 text-start rightpara">
                  <img
                    src={`${printData?.imageBaseURL}/${printData?.data?.companyData?.vImageName}`}
                    width={50}
                    height={50}
                    className="border border-2"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 lh-1">
                  <h5>From</h5>
                  <p>{printData?.data?.companyData?.vCompanyName}</p>
                  <p>{printData?.data?.companyData?.vGymName}</p>

                  <p>
                    {/* {Intl.formatMessage({ id: "INVOICE.CONTACT" })} :-{" "} */}
                    {printData?.data?.companyData?.vCountryCode}{" "}
                    {printData?.data?.companyData?.vPhoneNo}
                  </p>
                  <p>
                    {" "}
                    {/* {Intl.formatMessage({ id: "INVOICE.EMAIL" })}:-{" "} */}
                    {printData?.data?.companyData?.vEmail}
                  </p>
                  <p>
                    {" "}
                    {/* {Intl.formatMessage({ id: "INVOICE.WEBSITE" })}:- */}
                    {printData?.data?.companyData?.vWebsiteName}
                  </p>
                  <p>
                    {" "}
                    {/* {Intl.formatMessage({ id: "INVOICE.GSTNO" })}:- */}
                    {printData?.data?.companyData?.vGstNo}
                  </p>
                  <p
                    style={{
                      whiteSpace: "pre-line",
                      lineHeight: "1.4",
                    }}
                  >
                    {" "}
                    {/* {Intl.formatMessage({ id: "INVOICE.ADDRESS" })} :- */}
                    {printData?.data?.companyData?.tAddress}
                  </p>
                </div>
                <div
                  className="col-6 lh-1 text-end rightpara"
                  style={{ lineHeight: "1.5" }}
                >
                  <h5>To</h5>
                  <p>{printData?.data?.reveiverMember}</p>
                  <p>{printData?.data?.memberEmail}</p>

                  {/* <p>not presenet 5678 Second St</p> */}
                  <p>
                    {printData?.data?.receiverCountryCode} {"  "}
                    {printData?.data?.memnerMobileNo}
                  </p>
                  <p style={{ whiteSpace: "pre-line", lineHeight: "1.5" }}>
                    {printData?.data?.memberAddress}
                  </p>
                </div>
              </div>
              <div className="row ">
                <h3 className="">
                  {Intl.formatMessage({
                    id: "MODULE.RENEWAL.PACKAGE.ENROLLMENTD",
                  })}
                </h3>
                {/* <div className="col-1"></div> */}
                <div className="col-6  border gray-300  border-end-0 border-2">
                  <p>
                    {" "}
                    <span>
                      {`${Intl.formatMessage({
                        id: "MODULE.MEMBERS.ENROLTYPE",
                      })}  -  `}
                    </span>
                    <span>{printData?.data?.eEnrol_Type}</span>
                  </p>
                  <p>
                    {" "}
                    <span>
                      {`${Intl.formatMessage({
                        id: "MODULE.MEMBERS.PACKAGE",
                      })}  -  `}
                    </span>
                    <span>{printData?.data?.packageName}</span>
                  </p>
                </div>
                <div className="col-6 border  border-2">
                  <p>
                    {" "}
                    <span>
                      {`${Intl.formatMessage({
                        id: "MODULE.MEMBERS.REGISTRATIONFEE",
                      })} -  `}
                    </span>
                    <span>{printData?.data?.registrationFee}</span>
                  </p>
                  <p>
                    {" "}
                    <span>
                      {`${Intl.formatMessage({
                        id: "MODULE.MEMBERS.PACKAGEFEE",
                      })}  -  `}
                    </span>
                    <span>{printData?.data?.packageFee}</span>
                  </p>

                  <p>
                    {" "}
                    <span>
                      {`${Intl.formatMessage({
                        id: "MODULE.MEMBERS.DISCOUNT",
                      })}  -  `}
                    </span>
                    <span>{printData?.data?.discountAmt}</span>
                  </p>
                  <hr />
                  <p>
                    {" "}
                    <span>
                      {`${Intl.formatMessage({
                        id: "MODULE.MEMBERS.TOTALPAIDAMOUNT1",
                      })}  (WithTax)-  `}
                    </span>
                    <span>
                      {+printData?.data?.registrationFee +
                        printData?.data?.packageFee -
                        printData?.data?.discountAmt?.split(" ")[0]}
                    </span>
                  </p>
                </div>
              </div>

              <Row className="my-4">
                <Col>
                  <h5>{Intl.formatMessage({ id: "MODULE.INVOICE.ITEM" })} </h5>

                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th className="notVisibleInMobile">
                          {Intl.formatMessage({ id: "MODULE.INVOICE.ITEMs" })}
                        </th>
                        {/* <th>{
                          `${Intl.formatMessage({
                            id: "MODULE.RECIEPT.HSNNO",
                          })} `
                          // ${printData?.data?.companyData?.vHsnCode}
                        }</th> */}
                        <th className="notVisibleInMobile">{`${Intl.formatMessage(
                          {
                            id: "MODULE.RECIEPT.BALNCEAMOUNT",
                          }
                        )} `}</th>
                        <th className="notVisibleInMobile">{`${Intl.formatMessage(
                          {
                            id: "MODULE.RECIEPT.PAIDAMOUNT",
                          }
                        )} `}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          data-label={`${Intl.formatMessage({
                            id: "MODULE.INVOICE.ITEMs",
                          })} `}
                        >
                          {printData?.data?.batchName &&
                            printData?.data?.batchName}
                          {`${printData?.data?.packageName}   ( ${
                            printData?.data?.packageStartDate?.split("T")[0]
                          } to : ${
                            printData?.data?.packageExpireDate?.split("T")[0]
                          } )`}
                        </td>
                        {/* <td>{printData?.data?.companyData?.vHsnCode}</td> */}
                        <td
                          data-label={`${Intl.formatMessage({
                            id: "MODULE.RECIEPT.BALNCEAMOUNT",
                          })} `}
                        >
                          {printData?.data?.remainingAmt}
                        </td>
                        <td
                          data-label={`${Intl.formatMessage({
                            id: "MODULE.RECIEPT.PAIDAMOUNT",
                          })} `}
                        >
                          {printData?.data?.paidamount}
                        </td>
                      </tr>
                      {/* <tr>
                      <td>2</td>
                      <td>Service B</td>
                      <td>1</td>
                      <td>$100.00</td>
                      <td>$100.00</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Service C</td>
                      <td>3</td>
                      <td>$30.00</td>
                      <td>$90.00</td>
                    </tr> */}
                      {/* {printData?.data?.tax?.map((item: any) =>
                        console.log(item, "item")
                      )} */}
                      <tr>
                        <td colSpan={2} className="text-end notVisibleInMobile">
                          {Intl.formatMessage({
                            id: "MODULE.SUPPLIMENT.TABLE.SUBTOTAL",
                          })}
                        </td>
                        <td
                          data-label={`${Intl.formatMessage({
                            id: "MODULE.SUPPLIMENT.TABLE.SUBTOTAL",
                          })} `}
                        >
                          {printData?.data?.taxeData.length >= 3
                            ? withoutTax
                            : printData?.data?.paidamount}
                        </td>
                      </tr>
                      {/* {printData?.data?.discountAmt?.split(" ")[0] > 0 ? (
                        <tr>
                          <td colSpan={3} className="text-end">
                            discount (-)
                          </td>
                          <td>{discountAmount}</td>
                        </tr>
                      ) : (
                        ""
                      )} */}
                      {printData?.data?.taxeData.length >= 3 ? (
                        <tr>
                          <td
                            colSpan={2}
                            className="text-end notVisibleInMobile"
                          >
                            {Intl.formatMessage({
                              id: "MODULE.SUPPLIMENT.TABLE.GST",
                            })}{" "}
                            ({printData?.data?.taxeData[0]?.taxName}-
                            {printData?.data?.taxeData[0]?.rate}%) (
                            {printData?.data?.taxeData[1]?.taxName}-
                            {printData?.data?.taxeData[1]?.rate}%)(
                            {printData?.data?.taxeData[2]?.taxName}-
                            {printData?.data?.taxeData[2]?.rate}%)
                          </td>
                          <td
                            data-label={`${Intl.formatMessage({
                              id: "MODULE.SUPPLIMENT.TABLE.SUBTOTAL",
                            })} `}
                          >
                            {(printData?.data?.paidamount *
                              (printData?.data?.taxeData[0]?.rate +
                                printData?.data?.taxeData[1]?.rate +
                                printData?.data?.taxeData[2]?.rate)) /
                              100}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      <tr>
                        <td
                          colSpan={2}
                          className="text-end fw-bold notVisibleInMobile"
                        >
                          {Intl.formatMessage({
                            id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                          })}
                        </td>
                        <td
                          className="fw-bold"
                          data-label={`${Intl.formatMessage({
                            id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                          })} `}
                        >
                          {printData?.data?.paidamount}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <p className="fs-5 ">{`${Intl.formatMessage({
                  id: "COMMON.NOTE",
                })} `}</p>

                <div className="lh-2">
                  {splittedNote?.map((item: any, i: any) => (
                    <p className="fs-7 lh-2" key={i}>
                      {item}
                    </p>
                  ))}

                  {/* <Col lg={6}>{notes?.split("\n")[1]}</Col> */}
                </div>
                {/* <tr>
                  <td colSpan={3}>{`${Intl.formatMessage({
                    id: "NOTE.3",
                  })} `}</td>
                  <td colSpan={3}>{`${Intl.formatMessage({
                    id: "NOTE.4",
                  })} `}</td>
                </tr> */}

                <Row className="mt-5">
                  <Col>{`${Intl.formatMessage({
                    id: "NOTE.AUTHIRIZE",
                  })} `}</Col>
                  <Col className="text-center">_________________</Col>
                </Row>
              </Row>
            </div>
            <Row className="my-4">
              <Col className="text-md-end">
                {/* <Button variant="primary" className="me-2">
                  Download PDF
                </Button> */}
                <Button onClick={handlePrint} variant="secondary">
                  {Intl.formatMessage({ id: "COMMON.PRINT" })}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvoiceBill;
