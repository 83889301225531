import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_SALE_PAYMENT_LIST = `${API_URL}/v1/productsellpayment/list`;
export const ADD_INVOICE_SALE = `${API_URL}/v1/productsellpayment/add`;
export const UPDATE_INVOICE_SALE = `${API_URL}/v1/productsellpayment/update`;
export const DETAILS_INVOICE_SALE = `${API_URL}/v1/productsellpayment/detailsandinvoice`;
export const DELETE_INVOICE_SALE = `${API_URL}/v1/productsellpayment/delete`;
export const ACTIVE_MEMBER = `${API_URL}/v1/member/listbyBranch`;
export const ACTIVE_CUSTOMER = `${API_URL}/v1/customer/listbyBranch`;
export const ACTIVE_PRODUCTDETAIL_BYID = `${API_URL}/v1/productsell/activeselllistbyid`;

export function AddInvoiceSeller(body: any) {
  return axios.post(ADD_INVOICE_SALE, body).catch((error) => {
    return error.response;
  });
}
export function UpdateInvoiceSeller(body: any) {
  return axios.put(UPDATE_INVOICE_SALE, body).catch((error) => {
    return error.response;
  });
}
export function DetailsInvoiceSeller(branch: any, uuid: any) {
  return axios
    .get(`${DETAILS_INVOICE_SALE}/${branch}/${uuid}`)
    .catch((error) => {
      return error.response;
    });
}
export function GetSellDetailsByUserType(branch: any, membrtype: any, id: any) {
  return axios
    .get(`${ACTIVE_PRODUCTDETAIL_BYID}/${branch}/${membrtype}/${id}`)
    .catch((error) => {
      return error.response;
    });
}
export function getCustomerActiveList(branch: any) {
  return axios.get(`${ACTIVE_CUSTOMER}/${branch}`).catch((error) => {
    return error.response;
  });
}
export function getMemberActiveList(branch: any) {
  return axios.get(`${ACTIVE_MEMBER}/${branch}`).catch((error) => {
    return error.response;
  });
}
export function GetSellerPaymentList(body: any) {
  return axios.post(GET_SALE_PAYMENT_LIST, body).catch((error) => {
    return error.response;
  });
}
export function deleteSellerPayment(body: any) {
  return axios.patch(DELETE_INVOICE_SALE, body).catch((error) => {
    return error.response;
  });
}
