// import React from "react";
import { useIntl } from "react-intl";
// import { WeekBADGES } from "../../../../../_metronic/helpers";
import moment from "moment";

const DietPlansheet = ({ UpdateUser }: any) => {
  const intl = useIntl();
  const firstCOl = intl.formatMessage({ id: "DEIT.FOODCAT" });
  const secondCol = intl.formatMessage({ id: "DEIT.UNIT" });
  const thirdCol = intl.formatMessage({ id: "DEIT.QUANTITY" });
  const FourthCol = intl.formatMessage({ id: "DEIT.DETAILS.CALORIES" });
  const FifthCol = intl.formatMessage({ id: "DEIT.TIME" });
  const SixthCol = intl.formatMessage({ id: "DEIT.REMARKS" });
  // console.log(UpdateUser, "Updateuser");
  return (
    <div>
      {/* <h5>{intl.formatMessage({ id: "DIET.PLAN.DETAILS" })}</h5> */}
      {/* <div className="row">
        <div className="col-md-4">
          <label className="form-label">
            {intl.formatMessage({ id: "DEIT.NAME" })}
          </label>
          <div className="form-control">{UpdateUser?.data?.planName}</div>
        </div>
        <div className="col-md-4">
          {" "}
          <label className="form-label">
            {intl.formatMessage({ id: "DEIT.TARGET" })}
          </label>
          <div className="form-control">{UpdateUser?.data?.targentName}</div>
        </div>
        <div className="col-md-4">
          {" "}
          <label className="form-label">
            {intl.formatMessage({ id: "DEIT.WATERINTAKE" })}
          </label>
          <div className="form-control"> {UpdateUser?.data?.waterInDays}</div>
        </div>
      </div> */}
      {/* {UpdateUser?.data?.EndDate && UpdateUser?.data?.startDate && (
        <div className="row my-2">
          <div className="col-md-4">
            {" "}
            <label className="form-label">
              {intl.formatMessage({ id: "DEIT.ASSIGN.STARTDATE" })}
            </label>
            <div className="form-control">{UpdateUser?.data?.startDate}</div>
          </div>
          <div className="col-md-4">
            {" "}
            <label className="form-label">
              {intl.formatMessage({ id: "DEIT.ASSIGN.ENDDATE" })}
            </label>
            <div className="form-control"> {UpdateUser?.data?.EndDate}</div>
          </div>
        </div>
      )} */}
      {/* <div className="row my-2">
        <label className="form-label fs-4">
          {intl.formatMessage({ id: "DEIT.WEEKDAY" })}
        </label>
        <div className="d-flex flex-wrap gap-2 fs-4">
          {UpdateUser?.data?.DaysInWeek?.filter(
            (day: any) => day.dayStatus
          ).map((day: any, index: number) => {
            return <span key={index}>{WeekBADGES(day?.day, true)}</span>;
          })}
        </div>
      </div> */}
      {/* Display active days */}

      <div className="row my-3">
        {/* Display diet sheet */}
        <h4>{intl.formatMessage({ id: "DIET.SHEET" })}</h4>
        <div className="table-responsive-lg border border-2 rounded p-3">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                <th className="min-w-200px notVisibleInMobile">{firstCOl}</th>
                <th className="min-w-100px notVisibleInMobile">{secondCol}</th>
                <th className="min-w-100px notVisibleInMobile">{thirdCol}</th>
                <th className="min-w-100px notVisibleInMobile">{FourthCol}</th>
                <th className="min-w-100px notVisibleInMobile">{FifthCol}</th>
                <th className="min-w-100px notVisibleInMobile">{SixthCol}</th>
              </tr>
            </thead>
            <tbody>
              {UpdateUser?.dietsheet?.map((item: any, index: number) => (
                <tr key={index}>
                  <td data-label={firstCOl}>{item.foodName}</td>
                  <td data-label={secondCol}>{item.unit}</td>
                  <td data-label={thirdCol}>{item.noOfQuantity}</td>
                  <td data-label={FourthCol}>{item.CalperQuantity}</td>
                  <td data-label={FifthCol}>
                    {moment(item.time, "HH:mm:ss").format("hh:mm A")}
                  </td>
                  <td data-label={SixthCol}>{item.remarks || ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DietPlansheet;
