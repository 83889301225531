/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
// import { PageTitle } from "../../../_metronic/layout/core";
import "rsuite/DateRangePicker/styles/index.css";
// import DateRangePicker from "rsuite/DateRangePicker";
import dayjs from "dayjs";
import { getDashboard } from "./redux/dashboardCRUD";
import { Col, Container, Row } from "react-bootstrap";
import DashboardCard from "./DashboardCard";
// import { useFormik } from "formik";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import DashboardModal from "./Modal/DashboardModal";
import "./dashboard.css";
// import DietPlan from "../member_body_assistment/componant/DietPlan";
// import WorkOut from "../member_body_assistment/componant/WorkOut";
// import BodyPage from "../member_body_assistment/componant/BodyPage";
import { GetMemberDetailsByUUID } from "../member_body_assistment/redux/BodyAssismentCRUD";
import MyDietPlan from "./Componants/DietPlanDashboard/MyDietPlan";
import MyWorkoutPlan from "./Componants/workoutDashboard/MyWorkoutPlan";
import MyMesaurement from "./Componants/myMesaurement/MyMesaurement";

const DashboardMember = () => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState<any>(false);
  // const [selectedDate, setSelectedDate] = useState<any>();
  const [cardTitle, setCardTitle] = useState("");
  const [activeTab, setActiveTab] = useState<"first" | "second" | "third">(
    "third"
  );
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as any;
  // const memberdetails: any = localStorage.getItem("persist:v100-demo1-auth");
  // const memberLogin = JSON.parse(memberdetails);
  // const member1 = JSON.parse(memberLogin?.user);
  console.log(user, "hdsafjlkds");
  const admin: any = localStorage.getItem("ActiveBranch");
  const abranch = JSON.parse(admin);
  // const activebranchUUID = abranch?.iBranchUUID;
  const ActiveBranchID = abranch?.[0]?.iBranchID;
  const memberuuid = user?.member_uuid;
  const dateFormat = abranch?.[0]?.idateFormate || "YYYY-MM-DD";
  const currency = abranch?.[0]?.currencySymbol || "₹";

  const date = moment().format(dateFormat);
  const defaultdate = new Date();
  // const daysOfWeek = [
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  //   "Sunday",
  // ];

  // // Get today's day index (0 for Sunday, 1 for Monday, etc.)
  // const today = new Date();
  // const todayIndex = today.getDay();

  // // Adjust index to match daysOfWeek array (0 for Monday, 6 for Sunday)
  // const adjustedTodayIndex = todayIndex === 0 ? 6 : todayIndex - 1;

  // // Initialize state with all days unchecked except today
  // const [checkedDays, setCheckedDays] = useState(
  //   daysOfWeek.map((_, index) => index === adjustedTodayIndex)
  // );
  const [selectedDate, setSelectedDate] = useState<any>(dayjs());

  // Generate an array of 7 dates centered around the selected date
  const generateWeekDates = (date: any) => {
    const weekDates = [];
    for (let i = -3; i <= 3; i++) {
      weekDates.push(date.add(i, "day"));
    }
    return weekDates;
  };

  const weekDates = generateWeekDates(selectedDate);

  // Handle date selection from the date picker
  const handleDateChange = (event: any) => {
    setSelectedDate(dayjs(event.target.value));
  };

  // Handle date click
  const handleDateClick = (date: any) => {
    setSelectedDate(date);
    // alert(`Selected Date: ${date.format("YYYY-MM-DD")}`);
    console.log(date, date.format("YYYY-MM-DD"), "date");
  };
  // Handle checkbox change

  const [value, setValue] = useState<any>([defaultdate, defaultdate]);
  const [apiresponse, setapiresponse] = useState<any>();
  const [isUpdated, setIsUpdated] = useState<any>(false);
  const [UpdateUser, setUpdateUser] = useState<any>("");
  const initialState = {
    startDate: date,
    endDate: date,
  };
  const [filterValues, setFilterValues] = useState(initialState);

  const dashboard = async () => {
    const queryString = new URLSearchParams(filterValues).toString();

    const response = await getDashboard(memberuuid);
    console.log(response?.data?.data, "dashboard");
    if (response?.status === 200) {
      // setIsUpdated(true);
      setapiresponse(response?.data?.data);
    } else {
      console.log(response?.data?.replyMsg);
    }
  };

  // const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: filterValues,
  //   onSubmit: (values) => {
  //     console.log(values, "date");
  //     setFilterValues(values);
  //   },
  // });

  // const handleDateReset = (values: any) => {
  //   setValue([defaultdate, defaultdate]);
  //   formik.setFieldValue("startDate", date);
  //   formik.setFieldValue("endDate", date);
  //   // setIsUpdated(false);
  // };

  // const handleChange = (values: any) => {
  //   setValue(values);
  //   // console.log(values,)
  //   if (values && values.length !== 0) {
  //     formik.setFieldValue("startDate", moment(values[0]).format(dateFormat));
  //     formik.setFieldValue("endDate", moment(values[1]).format(dateFormat));
  //   }
  // };

  // const handleReset = () => {
  //   setValue([defaultdate, defaultdate]);
  //   formik.resetForm();
  //   // handleResetFilter();
  //   setFilterValues(initialState);
  //   // setIsUpdated(false);
  //   // getMembers();
  // };

  useEffect(() => {
    dashboard();
    getMemberDetails(ActiveBranchID, memberuuid);
  }, [filterValues]);
  const getMemberDetails = async (branchID: any, spotUUID: any) => {
    const response = await GetMemberDetailsByUUID(branchID, spotUUID);
    console.log(response, "Data:::");
    setUpdateUser(response?.data?.data);
    // setBaseURL(response?.data?.imageBaseURL);
    // setInvoiceShow(false);
  };

  const enrolled = intl.formatMessage({ id: "DASHBOARD.MEMBER.ENROLLED" });
  const enrolledAMT = intl.formatMessage({
    id: "DASHBOARD.MEMBER.TOTALENROLMENTAMOUNT",
  });
  const reciept = intl.formatMessage({ id: "DASHBOARD.MEMBER.RECIEPT" });
  const suppliment = intl.formatMessage({ id: "DASHBOARD.MEMBER.POS" });
  const dueamt = intl.formatMessage({ id: "DASHBOARD.MEMBER.DUEAMT" });
  // const enquiry = intl.formatMessage({ id: "DASHBOARD.ENQUIRY" });
  // const member = intl.formatMessage({ id: "DASHBOARD.MEMBER" });
  // const expiry = intl.formatMessage({ id: "DASHBOARD.EXPIRY" });
  // const followup = intl.formatMessage({ id: "DASHBOARD.FOLLOWUP" });
  // const enrolled = intl.formatMessage({ id: "DASHBOARD.ENROLLED" });
  // const employee = intl.formatMessage({ id: "DASHBOARD.EMPLOYEE" });
  // const empbirth = intl.formatMessage({ id: "DASHBOARD.EMPLOYEEBIRTHDAY" });
  // const membirth = intl.formatMessage({ id: "DASHBOARD.MEMBERBIRTHDAY" });
  // const invoice = intl.formatMessage({ id: "DASHBOARD.INVOICE" });
  // const dueamount = intl.formatMessage({ id: "DASHBOARD.DUEAMOUNT" });
  // const expense = intl.formatMessage({ id: "DASHBOARD.EXPENSE" });
  // const Today = intl.formatMessage({ id: "DASHBOARD.TODAY" });
  // const between = intl.formatMessage({ id: "DASHBOARD.BETWEEN" });
  // const total = intl.formatMessage({ id: "DASHBOARD.TOTAL" });
  const handleShowModal = (title: any) => {
    // setSelectedDate({ startDate, endDate });
    setCardTitle(title);
    setShowModal(true);
    // console.log("isFrom ", isFrom);
    // setIsFrom(isFrom);
  };

  // const handleCheckboxChange = (index: any) => {
  //   setCheckedDays((prevCheckedDays) =>
  //     prevCheckedDays.map((checked, i) => (i === index ? !checked : checked))
  //   );
  // };
  return (
    <>
      <DashboardModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedDate={selectedDate}
        cardTitle={cardTitle}
        // isFrom={isFrom}
      />
      <Container fluid className="rounded" style={{ background: "#FFFFFF" }}>
        {/* <div className="d-flex flex-wrap gap-3 fs-4 p-3">
          {daysOfWeek.map((day, index) => (
            <div key={index}>
              <label key={index} className="form-label">
                {day}
              </label>
              <input
                type="checkbox"
                className="form-check"
                checked={checkedDays[index]}
                onChange={() => handleCheckboxChange(index)}
              />
            </div>
          ))}
        </div> */}
        <div className="seven-day-calendar pt-2">
          <div className="navigation">
            <input
              type="date"
              className="form-control"
              value={selectedDate.format("YYYY-MM-DD")}
              onChange={handleDateChange}
            />
          </div>
          <div className="week-view">
            {weekDates.map((date, index) => (
              <div
                key={index}
                className={`day ${
                  date.isSame(selectedDate, "day") ? "selected" : ""
                }`}
                onClick={() => handleDateClick(date)}
              >
                {date.format("DD")}
              </div>
            ))}
          </div>
        </div>
        <div className={`card mb-5 mb-xl-8`}>
          {/* <p className="fs">Purchased Plans</p> */}
          <div className="card-header border-0 pt-6">
            <div className="d-flex w-100 justify-content-start mb-3">
              {/* Tab Navigation */}
              <button
                type="button"
                className={`btn ms-2 ${
                  activeTab === "third" ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => setActiveTab("third")}
              >
                {intl.formatMessage({ id: "BODYASSISMENT.WORKOUT" })}
              </button>
              <button
                type="button"
                className={`btn ms-2 ${
                  activeTab === "second" ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => setActiveTab("second")}
              >
                {intl.formatMessage({ id: "BODYASSISMENT.DEITPLAN" })}
              </button>

              <button
                type="button"
                className={`btn ms-2 ${
                  activeTab === "first" ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => setActiveTab("first")}
              >
                {intl.formatMessage({ id: "BODYASSISMENT.BODYMESEAREMENT" })}
              </button>
            </div>
          </div>
          {activeTab === "third" && (
            <MyWorkoutPlan
              UpdateUser={UpdateUser}
              selectedDate={selectedDate}
            />
          )}

          {activeTab === "second" && (
            <MyDietPlan UpdateUser={UpdateUser} selectedDate={selectedDate} />
          )}
          {activeTab === "first" && (
            <MyMesaurement
              UpdateUser={UpdateUser}
              selectedDate={selectedDate}
            />
          )}
        </div>
        {/* <Card className="w-100">
          <Card.Body className="py-3">
            <Form onSubmit={formik.handleSubmit}>
              <Row className="my-4">
                {" "}
                <Col md={3} className="mb-3">
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label">
                      {intl.formatMessage({
                        id: "MODULE.MEMBERS.DATERANGE",
                      })}
                    </label>

                    <DateRangePicker
                      value={value}
                      onChange={handleChange}
                      format="MM/dd/yyyy"
                      placeholder="Select Date Range"
                      placement="right"
                      style={{ margin: "2px" }}
                      onClean={handleDateReset}
                    />
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Button
                    type="submit"
                    variant="primary"
                    className="me-3 mr-4 "
                    // onClick={(e) => handleSearchClick(e)}
                    // disabled={isLoading}
                  >
                    {intl.formatMessage({
                      id: "COMMON.APPLY",
                    })}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleReset}
                    className="me-3 "
                  >
                    {intl.formatMessage({
                      id: "COMMON.RESET",
                    })}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card> */}
        <Row className="my-4 py-4 ">
          <Col md={3}>
            <div
              className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer "
              onClick={() => handleShowModal("Enrollment")}
            >
              <DashboardCard
                title=""
                date={enrolled}
                subtitle={apiresponse?.totalEnrolledPackage}
                color="warning"
                slug="Enrolled packages "
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-primary  py-5 px-2  rounded-2  mb-7  cursor-pointer"
              onClick={() => handleShowModal("Enrollment")}
            >
              <DashboardCard
                title={currency}
                date={enrolledAMT}
                subtitle={apiresponse?.totalEnrolledAmt || 0}
                color="primary"
                slug="Enquiries "
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-primary  py-5 px-2  rounded-2  mb-7  cursor-pointer"
              onClick={() => handleShowModal("DueAmount")}
            >
              <DashboardCard
                title={currency}
                date={dueamt}
                subtitle={apiresponse?.totalFixedDueAmt}
                color="primary"
                slug="Enquiries "
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer"
              onClick={() => handleShowModal("Receipts")}
            >
              <DashboardCard
                title={currency}
                date={reciept}
                subtitle={apiresponse?.totalReceipt}
                color="warning"
                slug="Follouw-ups "
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
              onClick={() => handleShowModal("MyPurchase")}
            >
              <DashboardCard
                title={currency}
                date={suppliment}
                subtitle={apiresponse?.totalpurchase}
                color="success"
                slug="Enrolled Packages"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardMember;
