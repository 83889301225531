import React, { useEffect, useState } from "react";
import { GetTotalProfit } from "../redux/dashboardCRUD";
import { useIntl } from "react-intl";

const ProfitModal = ({ isProfit }: any) => {
  const intl = useIntl();
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const CurrentCurrency = ActiveBranch?.currencyCode;
  const currencySymbol = ActiveBranch?.currencySymbol;
  const [element, setElement] = useState<any>("");
  const GetProfitDetail = async (branchuuid: any) => {
    const response = await GetTotalProfit(branchuuid);
    if (response?.status === 200) {
      setElement(response?.data?.data);
    }
    // console.log(response, "response");
  };
  useEffect(() => {
    GetProfitDetail(ActiveBranch?.iBranchUUID);
  }, []);
  const expensePos = intl.formatMessage({
    id: "TITLE.DASHBOARD.TOTALPOSEXPENSE",
  });
  const firstRow = intl.formatMessage({ id: "PROFIT.EXPENSE" });
  const secondRow = intl.formatMessage({ id: "PROFIT.ENROLLMENT" });
  const thirdRow = intl.formatMessage({ id: "PROFIT.POSPROFIT" });
  const fourthRow = intl.formatMessage({ id: "PROFIT.PAYABLEPRICE" });
  const fifthRow = intl.formatMessage({ id: "PROFIT.POSAMTINVOICE" });
  const sixthRow = intl.formatMessage({ id: "PROFIT.POSPROFIT" });
  const purchaseRow = intl.formatMessage({ id: "PROFIT.PURCHASEPRICE" });
  const SNO = intl.formatMessage({ id: "COMMON.SNO" });
  const Item = intl.formatMessage({ id: "MODULE.INVOICE.ITEMs" });
  const Amount = intl.formatMessage({ id: "MEMBERSHIP.AMOUNT" });
  return (
    <div className="card-body py-3">
      {/* {isLoading && <Loading />} */}
      <div className="">
        <div className="fs-3  fw-bold">
          <span>
            {intl.formatMessage({ id: "DASHBOARD.SETTING.TOTALPROFIT" })} :
          </span>
          <span>
            {isProfit
              ? Number(element?.totalEnrolledAmt || 0) +
                Number(element?.totalPOSProfit || 0) -
                Number(element?.totalExpense || 0) -
                Number(element?.totalPOSExpense || 0)
              : Number(element?.totalReceipt || 0) +
                Number(element?.totalPOSAmtInvoice || 0) -
                Number(element?.totalExpense || 0) -
                Number(element?.totalPOSExpense || 0)}
          </span>
        </div>
      </div>
      {/* begin::Table container */}
      <div className="table-responsive">
        {/* begin::Table */}
        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          {/* begin::Table head */}
          <thead>
            <tr className="fw-bolder text-muted text-capitalize fs-4">
              <th className="min-w-100px notVisibleInMobile">{SNO}</th>
              <th className="min-w-100px notVisibleInMobile">{Item}</th>
              <th className="min-w-100px notVisibleInMobile">{Amount}</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {!element ? (
              <tr>
                <td colSpan={8} className="text-center">
                  {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                </td>
              </tr>
            ) : (
              <>
                <tr className="fs-5">
                  <td data-label={SNO}>1</td>

                  <td data-label={Item}>{secondRow}</td>
                  <td data-label={Amount}>
                    {isProfit
                      ? element?.totalEnrolledAmt || 0
                      : element?.totalReceipt || 0}
                  </td>
                </tr>
                <tr className="fs-5">
                  <td data-label={SNO}>2</td>

                  <td data-label={Item}>{thirdRow}</td>
                  <td data-label={Amount}>
                    {isProfit
                      ? element?.totalPOSProfit || 0
                      : element?.totalPOSAmtInvoice || 0}
                  </td>
                </tr>
                <tr className="fs-5">
                  <td data-label={SNO}>3</td>

                  <td data-label={Item}>
                    {firstRow} <span className="mx-2">( - )</span>
                  </td>
                  <td data-label={Amount}>{element?.totalExpense || 0}</td>
                </tr>
                <tr className="fs-5">
                  <td data-label={SNO}>4</td>

                  <td data-label={Item}>
                    {expensePos} <span className="mx-2">( - )</span>
                  </td>
                  <td data-label={Amount}>{element.totalPOSExpense}</td>
                </tr>
                {/*  <tr className="fs-5">
                  <td data-label={SNO}>5</td>

                  <td data-label={Item}>{fifthRow}</td>
                  <td data-label={Amount}>{element.totalPOSAmtInvoice}</td>
                </tr>
                <tr className="fs-5">
                  <td data-label={SNO}>6</td>

                  <td data-label={Item}>{sixthRow}</td>
                  <td data-label={Amount}>{element.totalPOSProfit}</td>
                </tr>
                <tr className="fs-5">
                  <td data-label={SNO}>7</td>

                  <td data-label={Item}>{purchaseRow}</td>
                  <td data-label={Amount}>{element.totalPurchasePrice}</td>
                </tr> */}
              </>
            )}

            <tr className="fw-bolder text-muted text-capitalize fs-4">
              <th className="min-w-100px notVisibleInMobile">{SNO}</th>
              <th className="min-w-100px notVisibleInMobile">{Item}</th>
              <th className="min-w-100px notVisibleInMobile">{Amount}</th>
            </tr>
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>

      {/* end:: pagination */}
    </div>
  );
};

export default ProfitModal;
