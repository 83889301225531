import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_LIST_ADDONS = `${API_URL}/v1/memberaddon/list`;

export const Get_LIST_ATTENDENCE_MEMBER = `${API_URL}/v1/centermember/my-invoice`;

export const UPDATE_INVOICE_MEMBER = `${API_URL}/v1/receipt/update/`;
export const UPDATE_ADDON_STATUS = `${API_URL}/v1/memberrenewal/update_status`;
export const ACTIVE_MEMBERS_LIST = `${API_URL}/v1/member/listbyBranch`;

export const INVOICE_PRINT_RECEIPT = `${API_URL}/v1/centermember/invoiceprint`;
export const INVOICE_DUE_AMOUNT = `${API_URL}/v1/receipt/getdueamt`;
export const PACKAGE_DETAILS = `${API_URL}/v1/package/details`;
// export const GET_GENDERS = `${API_URL}/v1/common/genderlist/en`;
// export const GET_MARITAL_STATUS = `${API_URL}/v1/common/martiallist/en`;
// export const ACTIVE_TAX = `${API_URL}/v1/tax/listbyBranch`;
export const DELETE_MEMBER = `${API_URL}/v1/receipt/delete`;
export const MEMBER_ATTENDECE_DETAILS = `${API_URL}/v1/receipt/details`;

export const ADD_INVOICE = `${API_URL}/v1/receipt/add`;
// export const RENEWAL_MEMBER_LIST = `${API_URL}/v1/memberaddon/listbyBranch`;
export const BATCH_BYGROUP_LIST = `${API_URL}/v1/batch/listbypackage`;
export const RENEWAL_MEMBER_PACKAGES = `${API_URL}/v1/memberrenewal/getmembershiplist`;
// export const RENEWAL_MEMBER_Details = `${API_URL}/v1/memberrenewal/getEnrolledddetails`;
// export const RENEWAL_MEMBER_SUBMIT = `${API_URL}/v1/memberrenewal/add`;
// export const UPDATE_RENEWAL_MEMBER_SUBMIT = `${API_URL}/v1/memberrenewal/update`;
// export const MEMBERSHIP_FREEZE = `${API_URL}/v1/memberfreeze/add`;
export const MEMBERSHIP_RENEWAL_MEMBER = `${API_URL}/v1/member/listbyBranch`;
export const SETTING_MEMBER_DETAILS = `${API_URL}/v1/centerMember/setting/details`;
//create user

export function addInvoice(data: any) {
  // console.log("dataAdd", ADDONS_PACKAGES);
  return axios.post(ADD_INVOICE, data).catch((error) => {
    return error.response;
  });
}

export function DeleteMember(body: any) {
  return axios.patch(DELETE_MEMBER, body).catch((error) => {
    return error.response;
  });
}
//

//getting all users
export function GetMembers(payloadData: any) {
  return axios.post(Get_LIST_ATTENDENCE_MEMBER, payloadData);
}
//getting single user

//updating user
export function UpdateInvoice(data: any) {
  return axios.put(UPDATE_INVOICE_MEMBER, data);
}

// updating user status
export function UpdateStatus(data: any) {
  return axios.patch(UPDATE_ADDON_STATUS, data);
}
export function activeUserList(branchUID: any) {
  return axios.get(`${MEMBERSHIP_RENEWAL_MEMBER}/${branchUID}`);
}
// // get active enquiry list
// export function ActiveEnquiryList(branchUUID: any) {
//   return axios.get(`${ACTIVE_ENQUIRY_LIST}/${branchUUID}`);
// }
export function ActiveMemberList(branchUUID: any) {
  return axios.get(`${ACTIVE_MEMBERS_LIST}/${branchUUID}`);
}
export function PrintRecipt(branchID: any, iProgramUUID: any) {
  return axios.get(`${INVOICE_PRINT_RECEIPT}/${branchID}/${iProgramUUID}`);
}
export function enrolmentDueAmount(branchID: any, iProgramUUID: any) {
  return axios.get(`${INVOICE_DUE_AMOUNT}/${branchID}/${iProgramUUID}`);
}

export function enrollListofRenewal(branchID: any, branchUID: any) {
  return axios.get(`${RENEWAL_MEMBER_PACKAGES}/${branchID}/${branchUID}`);
}

export function getMemberDetailsByRecipt(branchID: any, spotUUID: any) {
  return axios.get(`${MEMBER_ATTENDECE_DETAILS}/${branchID}/${spotUUID}`);
}
export function batchIDAPII(ProgramgrpUUID: any) {
  return axios.get(`${BATCH_BYGROUP_LIST}/${ProgramgrpUUID}`);
}
export function settingDetailsMember(ProgramgrpUUID: any) {
  return axios.get(`${SETTING_MEMBER_DETAILS}/${ProgramgrpUUID}`);
}
