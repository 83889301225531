import { useEffect, useState } from "react";

import { useIntl } from "react-intl";
// import { GetBodyMeasuremnent } from "../../../member_body_assistment/redux/BodyAssismentCRUD";
import { getmeasurementBydate } from "../../redux/dashboardCRUD";
// import ListMeasurement from "./bodyMeasurement/ListMeasurement";
// import Measurement from "./bodyMeasurement/measurement/Measurement";
// import ListModal from "./bodyMeasurement/modal/ListModal";
// import { GetChartDetails } from "../redux/BodyAssismentCRUD";

const MyMesaurement = ({ UpdateUser, selectedDate }: any) => {
  const intl = useIntl();
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  // const ActiveBranchUUID = ActiveBranch?.[0]?.iBranchUUID;
  const activeBranchID = ActiveBranch?.[0]?.iBranchID;
  const [show, setShow] = useState<boolean>(false);
  const [Addshow, setAddShow] = useState<boolean>(false);
  console.log(UpdateUser, "Body Page");
  const [data, setData] = useState<any>("");

  useEffect(() => {
    if (UpdateUser?.iSpotUUID) {
      detailsChartApiForGraph();
    }
  }, [UpdateUser, selectedDate]);

  const detailsChartApiForGraph = async () => {
    const response = await getmeasurementBydate(
      activeBranchID,
      UpdateUser?.iSpotID,
      selectedDate.format("YYYY-MM-DD")
    );
    if (response?.data?.data) {
      setData(response?.data?.data);
    } else {
      setData("");
    }
    console.log(response, data, "Graph");
  };

  return (
    <div>
      {/* <ListModal
        UpdateUser={UpdateUser}
        activeEmpList={activeEmpList}
        show={show}
        setShow={setShow}
      /> */}

      <div className="container mt-4 border-top border-2 p-3">
        <div className="d-flex justify-content-between ">
          {/* <div className="d-flex gap-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShow(true)}
            >
              {intl.formatMessage({ id: "BODYASSISMENT.LIST.BTN" })}
            </button>
          </div> */}
        </div>
        <div>
          {data ? (
            <div>
              {" "}
              <div className="row">
                <h3 className="text-start">
                  {" "}
                  {intl.formatMessage({ id: "BODYASSISMENT.BODYMESEAREMENT" })}
                </h3>
                {/* Member Details */}
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="form-label">
                      {intl.formatMessage({ id: "MEASUREMENT.MEMBER" })}
                    </label>
                    <div className="form-control">
                      <div>{`${data?.vFirstname} (${data?.vCountryCode}-${data?.vMobileNo})`}</div>
                      <div>{`${data?.vEmail} `}</div>
                    </div>
                  </div>
                </div>

                {/* Employee Details */}
                <div className="col-md-4">
                  <div className="mb-3">
                    <label className="form-label">
                      {intl.formatMessage({ id: "MEASUREMENT.STAFF" })}
                    </label>
                    <div className="form-control">
                      <div>{data?.EmpName}</div>
                      <div>({data?.vEmpCountryCode + data?.vEPhoneno})</div>
                    </div>
                  </div>
                </div>

                {/* Measurement Fields */}
                {[
                  { label: "MEASUREMENT.HIEGHT", value: data?.Height },
                  { label: "MEASUREMENT.WEIGHT", value: data?.Weight },
                  { label: "MEASUREMENT.NECK", value: data?.Neck },
                  { label: "MEASUREMENT.SHOULDER", value: data?.Shoulder },
                  {
                    label: "MEASUREMENT.CHESTEXTENDED",
                    value: data?.ChestExtended,
                  },
                  {
                    label: "MEASUREMENT.CHESTNORMAL",
                    value: data?.ChestNormal,
                  },
                  { label: "MEASUREMENT.FORARMS", value: data?.Forearm },
                  { label: "MEASUREMENT.BIECEPS", value: data?.Biceps },
                  { label: "MEASUREMENT.WRIST", value: data?.Wrist },
                  { label: "MEASUREMENT.UPPERABS", value: data?.UpperABS },
                  { label: "MEASUREMENT.LOWERABSE", value: data?.LowerABS },
                  { label: "MEASUREMENT.WAIST", value: data?.Waist },
                  { label: "MEASUREMENT.HIP", value: data?.HIP },
                  { label: "MEASUREMENT.THIGH", value: data?.Thigh },
                  { label: "MEASUREMENT.CALVES", value: data?.Calves },
                  { label: "MEASUREMENT.ANKLES", value: data?.Ankles },
                  {
                    label: "MEASUREMENT.BLOODPRESSURE",
                    value: data?.BloodPressure,
                  },
                  {
                    label: "MEASUREMENT.SUGERLEVEL",
                    value: data?.BloodPressure,
                  },
                  { label: "MEASUREMENT.BMI", value: data?.BMI },
                  { label: "MEASUREMENT.BMR", value: data?.BMR },
                  { label: "MEASUREMENT.BODYFAT", value: data?.BodyFat },
                  { label: "MEASUREMENT.VASCULAR", value: data?.Vascular },
                  {
                    label: "MEASUREMENT.MEDICALISSUE",
                    value: data?.MedicalIssue,
                  },
                ].map((field, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="mb-3">
                      <label className="form-label">
                        {intl.formatMessage({ id: field.label })}
                      </label>
                      <div className="form-control">{field.value || "N/A"}</div>
                    </div>
                  </div>
                ))}

                {/* Status */}
                {/* <div className="col-md-4">
            <div className="mb-3">
              <label className="form-label">
                {intl.formatMessage({ id: "MEASUREMENT.STATUS" })}
              </label>
              <div className="form-control">
                {data?.Status === 1
                  ? intl.formatMessage({ id: "MEASUREMENT.STATUS.ACTIVE" })
                  : intl.formatMessage({ id: "MEASUREMENT.STATUS.INACTIVE" })}
              </div>
            </div>
          </div> */}
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center fs-4 text-info">
              {intl.formatMessage({ id: "BODYMEASUREMENT.NOTAVAILABLE" })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyMesaurement;
