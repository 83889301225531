import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_DASHBOARD = `${API_URL}/v1/centermember/dashboard`;
export const GET_MEMBER_WORKOUT_BYDATE = `${API_URL}/v1/centerMember/workoutmember/workoutPlanbydate`;
export const GET_MEMBER_DIETPLAN_BYDATE = `${API_URL}/v1/centerMember/dietplanmember/assigndietplanbydate`;
export const GET_MEMBER_MEASUREMENT_BYDATE = `${API_URL}/v1/centerMember/membermeasurement/measurementbydate`;

export function getDashboard(uuid: any) {
  return axios.get(`${GET_DASHBOARD}/${uuid}`).catch((error) => {
    return error.response;
  });
}
export function getMemberWorkoutAssinedbyDate(
  branch: any,
  memberid: any,
  date: any
) {
  return axios
    .get(`${GET_MEMBER_WORKOUT_BYDATE}/${branch}/${memberid}/${date}`)
    .catch((error) => {
      return error.response;
    });
}
export function getmemberDietPlanAssignByDate(
  branch: any,
  memberid: any,
  date: any
) {
  return axios
    .get(`${GET_MEMBER_DIETPLAN_BYDATE}/${branch}/${memberid}/${date}`)
    .catch((error) => {
      return error.response;
    });
}
export function getmeasurementBydate(branch: any, memberid: any, date: any) {
  return axios
    .get(`${GET_MEMBER_MEASUREMENT_BYDATE}/${branch}/${memberid}/${date}`)
    .catch((error) => {
      return error.response;
    });
}
