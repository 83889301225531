import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetMyBatch: "[GetMyBatch] Action",
};

const initialAuthState: ICenterState = {
  myBatches: undefined,
};

export interface ICenterState {
  myBatches?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["myBatches"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetMyBatch: {
        const myBatches = action.payload?.myBatches;
        return { ...state, myBatches };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetMyBatch: (myBatches: string) => ({
    type: actionTypes.GetMyBatch,
    payload: { myBatches },
  }),
};
