import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { GetFollowUpDetails } from "../../../modules/leads/modules/enquiry/redux/EnquiryCRUD";
import * as FollowUps from "../../../../app/modules/leads/modules/followUp/redux/followUpREDUX";
import Pagination from "../../../modules/GenricComponant/Pagination";
import { GetEnquiryStatus } from "../../../modules/leads/modules/followUp/redux/followUpCRUD";
import permission from "../../../modules/GenricComponant/permissions/permission";
import { FollowupNotification, SendNotification } from "../redux/dashboardCRUD";
import swal from "sweetalert";
const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const FollowUpModalData = (selectedDate: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "dtModified_Date", // Default sorting column
    direction: "desc", // Default sorting direction
  });
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("follow-up");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });

  const [pageLimit, setPageLimit] = useState(pageDefaultValue);

  const followUp = useSelector(
    (state: any) => state?.FollowUps?.FollowUps?.data
  );
  const [checkedRows, setCheckedRows] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [updateFollowUpUser, setUpdateFollowUpUser] = useState<any>({});
  const [filteredData, setFilteredData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowup, setIsfollowup] = useState(false);
  const [TBLData, setTBLData] = useState<any>([]);
  const [filterValues, setFilterValues] = useState("");

  const [show, setShow] = useState<any>(false);
  const handleShow = () => {
    setShow(true);
    setIsfollowup(true);
  };
  const column1 = `${intl.formatMessage({
    id: "MODULE.FOLLOWUP.ENAME",
  })}`;
  const column2 = `${intl.formatMessage({
    id: "MODULE.FOLLOWUP.MOBILE",
  })}`;
  const column3 = `${intl.formatMessage({
    id: "MODULE.FOLLOWUP.DATE",
  })}`;
  const column4 = `${intl.formatMessage({
    id: "MODULE.FOLLOWUP.TIME",
  })}`;
  const column5 = `${intl.formatMessage({
    id: "MODULE.FOLLOWUP.REMARKS",
  })}`;
  const column6 = `${intl.formatMessage({
    id: "COMMON.ACTIONS",
  })}`;

  const getFollowUpDetails = async (branchID: any, followUpUUID: any) => {
    const apiResult = await GetFollowUpDetails(branchID, followUpUUID);
    setUpdateFollowUpUser(apiResult?.data?.data);
  };
  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
  };
  const startDate = selectedDate.selectedDate.startDate;
  const endDate = selectedDate.selectedDate.endDate;
  const getEnquiryType = useCallback(() => {
    if (UserType === "admin" || view !== 0) {
      setIsLoading(true);
      GetEnquiryStatus({
        status: "",
        enquiryID: "",
        page_limit: pageLimit,
        page_no: 1,
        search: "",
        branchID: ActiveBranch.iBranchID,
        start_date: startDate,
        end_date: endDate,
        order_key: "iBranchID",
        order_value: "desc",
      }).then((data: any) => {
        // console.log(data.data.totalRecords, "dataforenqui");
        if (data?.status !== 200) {
          setFilteredData([]);
          setIsLoading(false);
        } else {
          dispatch(FollowUps.actions.GetFollowUp(data));
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, filterValues, page, sortConfig]);

  useEffect(() => {
    getEnquiryType();
  }, [getEnquiryType]);

  useEffect(() => {
    setFilteredData(followUp?.data);
    handleExports();
  }, [followUp?.data]);

  const handleExports = () => {
    const tableRows = followUp?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [column1]: element?.name,
        [column2]: element.vCountryCode + "-" + element.vMobileNo,
        [column3]: element.dtFollowup?.split("T")[0],
        [column4]: element?.vTime,
        [column5]: element?.vFRemarks,
      };
    });

    setTBLData(tableRows);
  };

  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };

  const handleSendNotifications = async (branchID: any, enrolID: any) => {
    // Show a confirmation dialog
    const willSend = await swal({
      title: intl.formatMessage({ id: "COMMON.AREYOUSURE" }),
      text: intl.formatMessage({ id: "COMMON.DOYOUSEND" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SEND" }),
      ], // Cancel and Send buttons
      dangerMode: true, // Optional: Highlights the "Send" button
    });

    // If the user confirms, proceed with the API call
    if (willSend) {
      const obj = { branchID: branchID, enquiryUUID: enrolID };
      const response = await FollowupNotification(obj);
      if (response?.status === 200) {
        swal(response?.data?.replyMsg, { icon: "success" });
      } else {
        swal(response?.data?.replyMsg, { icon: "error" });
      }
    } else {
      // Handle the cancel action (optional)
      swal(
        "Cancelled",
        intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" }),
        "info"
      );
    }
  };

  return (
    <>
      <div className="w-25">
        <span
          className="min-w-40px mb-3 d-flex align-items-center"
          style={{ color: "#7e8299", fontSize: "14px" }}
        >
          {intl.formatMessage({ id: "COMMON.SHOW" })}
        </span>
        <div>
          <select
            className="form-control form-select form-select-solid me-3 mb-4"
            value={pageLimit}
            onChange={(e) => getPageLimit(e.target.value)}
          >
            {finalPageLimitArray?.map((item: any, i = 0) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="card-body py-3">
        {/* {isLoading && <Loading />} */}
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="name"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {column1}
                    {sortConfig.key !== "name" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "name" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "name" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vMobileNo"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {column2}
                    {sortConfig.key !== "vMobileNo" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "vMobileNo" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "vMobileNo" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtFollowup"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {column3}
                    {sortConfig.key !== "dtFollowup" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "dtFollowup" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "dtFollowup" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vTime"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {column4}
                    {sortConfig.key !== "vTime" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "vTime" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "vTime" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vFRemarks"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {column5}
                    {sortConfig.key !== "vFRemarks" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "vFRemarks" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "vFRemarks" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">{column6}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredData && filteredData.length === 0 ? (
                <tr>
                  <td colSpan={8} className="text-center">
                    {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                  </td>
                </tr>
              ) : UserType === "employee" && view === 0 ? (
                <tr>
                  <td colSpan={8} className="text-center text-danger fs-4">
                    {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                  </td>
                </tr>
              ) : (
                filteredData?.map((element: any, index: number) => {
                  const isChecked = checkedRows.includes(element.iFollowupUUID);
                  return (
                    <tr key={index} className="fs-5">
                      <td data-label={column1}>{element.name}</td>
                      <td data-label={column2}>
                        <a
                          href={`tel:${element.vCountryCode}-${element.vMobileNo}`}
                        >
                          {element.vCountryCode}-{element.vMobileNo}
                        </a>
                      </td>
                      <td data-label={column3}>
                        {element.dtFollowup?.split("T")[0]}
                      </td>
                      <td data-label={column4}>{element.vTime}</td>
                      <td data-label={column5}>{element.vFRemarks}</td>
                      <td data-label={column6}>
                        {" "}
                        <div className="d-flex flex-shrink-0 ">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              handleSendNotifications(
                                element?.iBranchID,
                                element.iEnrolID
                              );
                            }}
                          >
                            {intl.formatMessage({ id: "COMMON.SEND" })}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="name"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {column1}
                    {sortConfig.key !== "name" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "name" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "name" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vMobileNo"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {column2}
                    {sortConfig.key !== "vMobileNo" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "vMobileNo" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "vMobileNo" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtFollowup"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {column3}
                    {sortConfig.key !== "dtFollowup" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "dtFollowup" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "dtFollowup" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vTime"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {column4}
                    {sortConfig.key !== "vTime" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "vTime" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "vTime" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="vFRemarks"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {column5}
                    {sortConfig.key !== "vFRemarks" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "vFRemarks" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "vFRemarks" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">{column6}</th>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>

        {filteredData && filteredData.length > 0 && (
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        )}
        {/* end:: pagination */}
      </div>
    </>
  );
};

export default FollowUpModalData;
