import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_MYSTOCK_LIST = `${API_URL}/v1/mystock/list`;

export function GetMyStockList(body: any) {
  return axios.post(GET_MYSTOCK_LIST, body).catch((error) => {
    return error.response;
  });
}
