import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_SALEPRODUCT_LIST = `${API_URL}/v1/productsell/list`;
export const ADD_SALEPRODUCT = `${API_URL}/v1/productsell/add`;
export const ACTIVE_MEMBER = `${API_URL}/v1/member/listbyBranch`;
export const Active_PRODUCT = `${API_URL}/v1/purchaseproduct/getallactiveproduct`;
export const DETAIL_UPDATE_SALEPRODUCT = `${API_URL}/v1/productsell/details`;
export const DETAIL_BILL_GENERATE = `${API_URL}/v1/productsell/generatebill`;
// export const ACTIVE_EXPENSECATEGORY = `${API_URL}/v1/expensecategory/add`;
export const UPDATE_SALEPRODUCT = `${API_URL}/v1/productsell/update/`;
// export const UPDATE_EXPENSECATEGORY_STATUS = `${API_URL}/v1/expense/update_status`;
export const DELETE_SALEPRODUCT = `${API_URL}/v1/productsell/delete`;
export const ACTIVE_CUSTOMER_LIST = `${API_URL}/v1/customer/listbyBranch`;

export function ActiveCustomerList(activebranchuuid: any) {
  return axios
    .get(`${ACTIVE_CUSTOMER_LIST}/${activebranchuuid}`)
    .catch((error) => {
      return error.response;
    });
}
export function GetActiveMember(activebranchuuid: any) {
  return axios.get(`${ACTIVE_MEMBER}/${activebranchuuid}`).catch((error) => {
    return error.response;
  });
}
export function GetActiveProduct(activebranchuuid: any) {
  return axios.get(`${Active_PRODUCT}/${activebranchuuid}`).catch((error) => {
    return error.response;
  });
}
export function detailofSellProduct(activebranchid: any, expensesuuid: any) {
  return axios
    .get(`${DETAIL_UPDATE_SALEPRODUCT}/${activebranchid}/${expensesuuid}`)
    .catch((error) => {
      return error.response;
    });
}
export function detailsOfBillGenerate(
  activebranchid: any,
  detailsUUID: any,
  paymentID: any
) {
  return axios
    .get(
      `${DETAIL_BILL_GENERATE}/${activebranchid}/${detailsUUID}${
        paymentID ? `?productSellpaymentID=${paymentID}` : ""
      }`
    )
    .catch((error) => {
      return error.response;
    });
}

//add a expense modal
export function addSellProduct(data: any) {
  return axios.post(ADD_SALEPRODUCT, data).catch((error) => {
    return error.response;
  });
}
// Get all departments
export function GetSellProductList(data: any) {
  return axios.post(GET_SALEPRODUCT_LIST, data).catch((error) => {
    return error.response;
  });
}

// Update a department
export function UpdateSellProduct(body: any) {
  return axios.put(UPDATE_SALEPRODUCT, body).catch((error) => {
    return error.response;
  });
}

// Delete department
export function DeleteSellProduct(body: any) {
  return axios.patch(DELETE_SALEPRODUCT, body).catch((error) => {
    return error.response;
  });
}
