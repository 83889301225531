import React from "react";
// import { BrowserRouter } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider } from "../_metronic/layout/core";
import AuthInit from "./modules/auth/redux/AuthInit";
import { Routes } from "./routing/Routes";
import "./Style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./modules/GenricComponant/spinner/Loading";
// import AxiosSetupComponent from "../setup/axios/AxiosSetupComponent";

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  return (
    <>
      {/* <BrowserRouter> */}
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Loading />
            <div id="content1">
              <Routes />
            </div>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* </BrowserRouter> */}
    </>
  );
};

export { App };
