import { useState } from "react";
import { useIntl } from "react-intl";
import { QrReader } from "react-qr-reader";
import swal from "sweetalert";

const QrReaderWithRef = ({ onScan }: any) => {
  const intl = useIntl();
  const [isScanning, setIsScanning] = useState<boolean>(true);
  console.log(isScanning, "delay");
  const handleScan = (result: any) => {
    if (result && isScanning) {
      const text = result?.text || result;

      try {
        const parsedText = JSON.parse(text);
        console.log(parsedText, "parsedText");

        if (parsedText?.branchID && parsedText?.iAdminID) {
          onScan(parsedText);
          setIsScanning(false); // Temporarily disable scanning
          setTimeout(() => setIsScanning(true), 1000); // Re-enable after 5 seconds
        } else {
          throw new Error("Invalid QR Code: Missing branchID or iAdminID");
        }
      } catch (error) {
        console.log(error, "error");
        swal(intl.formatMessage({ id: "QR.CODE.INVALID" }), { icon: "error" });
      }
    }
  };

  return (
    <div>
      <h2>{intl.formatMessage({ id: "QR.SCANNER" })}</h2>
      {isScanning && (
        <QrReader
          onResult={handleScan}
          constraints={{ facingMode: "environment" }} // Use rear camera
        />
      )}
    </div>
  );
};

export default QrReaderWithRef;
