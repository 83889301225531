import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const BODY_ASSISMENT = `${API_URL}/v1/centermember/bodymeasurement/list`;
export const CHART_BODYMEASUREMENT = `${API_URL}/v1/centermember/bodymeasurement/detailsforchartbymemberuuid`;
export const ADD_BODY_ASSISMENT = `${API_URL}/v1/bodymeasurement/add`;
export const UPDATE_BODY_ASSISMENT = `${API_URL}/v1/bodymeasurement/update`;
export const DELETE_BODY_ASSISMENT = `${API_URL}/v1/bodymeasurement/delete`;
export const MEMBER_DETAILS = `${API_URL}/v1/centermember/memberdetails`;

export function GetMemberDetailsByUUID(data: any, MemberUUID: any) {
  return axios.get(`${MEMBER_DETAILS}/${data}/${MemberUUID}`).catch((error) => {
    return error.response;
  });
}

//create branch
export function GetBodyMeasuremnent(data: any) {
  return axios.post(BODY_ASSISMENT, data).catch((error) => {
    return error.response;
  });
}
export function GetChartDetails(data: any) {
  return axios.get(`${CHART_BODYMEASUREMENT}/${data}`).catch((error) => {
    return error.response;
  });
}
// export function AddBodyMeasurment(data: any) {
//   return axios.post(ADD_BODY_ASSISMENT, data).catch((error) => {
//     return error.response;
//   });
// }
// export function UpdateBodyMeasurment(data: any) {
//   return axios.put(UPDATE_BODY_ASSISMENT, data).catch((error) => {
//     return error.response;
//   });
// }
// export function deleteBodyMeasurement(data: any) {
//   return axios.put(DELETE_BODY_ASSISMENT, data).catch((error) => {
//     return error.response;
//   });
// }
