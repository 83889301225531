/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
// import EnquiryStatusModal from "./modal/EnquiryStatusModal";

import * as paymentPurchase from "../Redux/PaymentRedux";

import Logo from "../../../.../../../loading_assests/logormbg.png";
import swal from "sweetalert";
import { useIntl } from "react-intl";
import { Form, Row, Col, Card, Button, Dropdown } from "react-bootstrap";

import { CSVLink } from "react-csv";
// import PackagePeriodModal from "./modal/EnquiryStatusModal";
import { KTSVG } from "../../../../_metronic/helpers";
// import {
//   GetEnquiryStatus,
//   Update_EnquiryStatus_Status,
//   Delete_EnquiryStatus,
//   enquiryActiveList,
// } from "../redux/followUpCRUD";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Pagination from "../../../modules/GenricComponant/Pagination";
// import { Direction } from "react-toastify/dist/utils";
// import AddFollowUpModal from "../../enquiry/component/modal/AddFollowUpModal";
import { exportToXLS } from "../../../modules/GenricComponant/exportToxls/exportToxls";
// import { GetFollowUpDetails } from "../../enquiry/redux/EnquiryCRUD";
// import FollowUpModal from "./modal/FollowUpModal";
import permission from "../../../modules/GenricComponant/permissions/permission";
// import DateRangePicker from "rsuite/esm/DateRangePicker";
import moment from "moment";
// import { getActiveSupplier } from "../../purchase_Prodcut/redux/PurchaseProductCRUD";
// import InvoiceModal from "./modal/InvoiceModal";
// import { deletePayment } from "../../supplierPayment/Componants/redux/PaymentCRUD";
// import {
//   deleteSellerPayment,
//   DetailsInvoiceSeller,
//   GetSellerPaymentList,
// } from "../../saleProductPayment/redux/SaleProductPaymentCRUD";
// import InvoicePage from "./InvoicePage";
// import { GetPaymentMode } from "../../../modules/master/modules/paymentMode/redux/PaymentModeCRUD";
import {
  DetailsInvoiceSeller,
  GetSellerPaymentList,
} from "../Redux/SupplementCRUD";
import { GetPaymentMode } from "../../../modules/master/modules/paymentMode/redux/PaymentModeCRUD";
import InvoicePage from "./InvoicePage";
import { ActivePaymentMode } from "../../../modules/finance/modules/invoice/component/redux/invoiceCRUD";
// import PurchaseProductModal from "./Modal/PurchaseProductModal";
// import DetailsModal from "./Modal/DetailsModal";
// import PaymentModal from "./Modal/PaymentModal";
// import PlanModal from "./Modal/PlanModal";
// import PlanModal from "../../../plans/component/PlanModal";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: object) => jsPDF;
  }
}

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const ListPayment = ({
  data,
  setTitle,
  title,
  InvoiceShow,
  setInvoiceShow,
  ListShow,
  setListShow,
}: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "iProductSellAmountID", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  const paymentPurchases = useSelector(
    (state: any) => state?.paymentPurchase?.paymentPurchase?.data
  );

  // active branch data taken from local storage
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchID = ActiveBranch?.[0]?.iBranchID;
  const ActiveBranchUUID = ActiveBranch?.[0]?.iBranchUUID;
  const CurrentCurrency = ActiveBranch?.[0]?.currencyCode;
  const currencySymbol = ActiveBranch?.[0]?.currencySymbol;

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("activities");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });

  const [UpdateUser, setUpdateUser] = useState<any>();
  const [selectPayMode, setSelectPaymentMode] = useState<any>("");
  const [selectCat, setSelectCat] = useState<any>("");
  //   const [error, setError] = useState<any>("");
  //page state
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  let initialState = {
    branchID: ActiveBranchID,
    page_limit: pageLimit,
    productSellDetailID: data?.iProductSellDetailID,
    userType: data?.saleUserType,
    memberID: data?.iSpotID,
    customerID: data?.icustomerID,
    employeeID: "",
    status: "",
    page_no: "1",
    search: "",
    start_date: "",
    end_date: "",
    order_key: "iProductSellAmountID",
    order_value: "desc",
  };

  const [show, setShow] = useState<any>(false);
  const [payShow, setPayShow] = useState<any>(false);
  const [ShowDetails, setShowDetails] = useState<any>(false);
  const [printData, setPrintData] = useState<any>("");
  const [baseURL, setBaseURL] = useState<any>("");
  const [paymentOpt, setPaymodeOpt] = useState<any>([]);
  //   const [title, setTitle] = useState<any>("");
  const handleShow = () => {
    setisUpdate(false);
    // setTitle("Add");
    // setShow(true);
    setTitle("Add");
    setInvoiceShow(true);
    setListShow(false);
  };

  const [isUpdate, setisUpdate] = useState(false);
  //for check box
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [isAllRowsChecked, setIsAllRowsChecked] = useState<any>(false);
  const [branch_id, setBranch_id] = useState<any>("0");
  // for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // for error
  const [error, setError] = useState("");
  const [isFilter, setisFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState(initialState);
  const [searchValues, setSearchValues] = useState(initialState);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [enqOption, setEnqOption] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowup, setIsfollowup] = useState(false);
  const [updateFollowUpUser, setUpdateFollowUpUser] = useState<any>({});
  const [dateFormat, setdateFormat] = useState<any>(
    ActiveBranch?.[0]?.idateFormate
  );
  const initialDates = [new Date(), new Date()];
  const [value, setValue] = React.useState<any>();
  const [TBLData, setTBLData] = useState<any>([]);
  const [Selectdata, setSelectData] = useState<any>([]);

  //define column of listing
  const column1 = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.ID",
  })}`;
  const column2 = `${intl.formatMessage({
    id: "COMMON.NAME",
  })}`;
  const column3 = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.DATE",
  })}`;
  const column4 = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.AMOUNT",
  })}`;
  const column5 = `${intl.formatMessage({
    id: "MODULE.RECIEPT.PAYMENTMODE",
  })}`;
  const column6 = `${intl.formatMessage({
    id: "COMMON_REMARK",
  })}`;
  //   const column7 = `${intl.formatMessage({
  //     id: "POS.PURCHASEPRODUCT.REMAINAMT",
  //   })}`;
  //   const column8 = `${intl.formatMessage({
  //     id: "POS.PURCHASEPRODUCT.TOTALQUANTITY",
  //   })}`;
  const column9 = `${intl.formatMessage({ id: "COMMON.ACTIONS" })}`;

  //download fileName
  const downloadfileName = "SaleProductPaymentList_" + Date.now();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: (values) => {
      setFilterValues(values);

      setPage(1);
    },
  });
  const getSellPayment = useCallback(() => {
    setIsLoading(true);
    GetSellerPaymentList({
      ...filterValues,
      page_no: page,
      page_limit: pageLimit,
      order_key: sortConfig.key,
      order_value: sortConfig.direction,
    }).then((data: any) => {
      // console.log(data.data.totalRecords, "dataforenqui");
      if (data?.status !== 200) {
        setFilteredData([]);
        setIsLoading(false);
      } else {
        dispatch(paymentPurchase?.actions?.GetPaymentPurchase(data));
        setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
        setIsLoading(false);
      }
    });
  }, [dispatch, pageLimit, filterValues, page, sortConfig]);

  useEffect(() => {
    getSellPayment();
  }, [getSellPayment]);

  useEffect(() => {
    setFilteredData(paymentPurchases?.data);
    handleExports();
  }, [paymentPurchases?.data]);

  useEffect(() => {
    SupplierActiveList(ActiveBranchUUID);
  }, []);

  const SupplierActiveList = async (data: any) => {
    const response = await GetPaymentMode(data);
    setEnqOption(response?.data?.data);
  };

  //handle check box

  const handleRowCheckboxChange = (rowId: any, branchId: any) => {
    setBranch_id(branchId);
    if (checkedRows.includes(rowId)) {
      setCheckedRows(checkedRows.filter((id: any) => id !== rowId));
    } else {
      setCheckedRows([...checkedRows, rowId]);
    }
  };
  // handle all check box
  const handleSelectAllCheckboxChange = () => {
    setBranch_id(ActiveBranchID);
    if (isAllRowsChecked) {
      setCheckedRows([]);
      setIsAllRowsChecked(false);
    } else {
      const allRowIds =
        paymentPurchases?.data?.map(
          (element: any) => element.iProductSellAmountUUID
        ) || [];
      //console.log('packageperiods====',packageperiods);
      //console.log('allRowIds====',allRowIds);
      setCheckedRows(allRowIds);
      setIsAllRowsChecked(true);
    }
  };

  const handleReset = () => {
    formik.resetForm();
    setFilterValues(initialState);
    setSelectPaymentMode("");
    setValue(null);
  };

  const handleExports = () => {
    const tableRows = paymentPurchases?.data?.map(
      (element: any, index: number) => {
        return {
          "#": index + 1,
          [column1]: element?.iProductSellAmountID,
          [column3]:
            element.paymentDate &&
            `${element.paymentDate} ${moment(
              element.paymentTime,
              "HH:mm"
            ).format("hh:mm A")}`,
          [column4]: element?.amount,
          [column5]: element?.paymentMethod,
          [column6]: element?.remarks,
        };
      }
    );

    setTBLData(tableRows);
  };

  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    doc.text(`${intl.formatMessage({ id: "PAYMENT.SUPPLIER.PAGE" })}`, 10, 10);
    const tableRows = paymentPurchases.data.map(
      (element: any, index: number) => {
        return [
          element.iProductSellAmountID,

          element.paymentDate &&
            `${element.paymentDate} ${moment(
              element.paymentTime,
              "HH:mm"
            ).format("hh:mm A")}`,
          element.amount,
          element.paymentMethod,
          element.remarks,
        ];
      }
    );
    doc.autoTable({
      head: [[column1, column3, column4, column5, column6]],
      body: tableRows,
      startY: 50,
    });

    doc.save(downloadfileName + ".pdf");
  };

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
  };

  const handleRefreshButton = () => {
    setIsLoading(true);
    setCheckedRows([]);
    setPage(1);
    setPageLimit(pageDefaultValue);
    setSearchValues(initialState);
    setSortConfig({ key: "iProductSellAmountID", direction: "desc" });
  };
  const handlePaymentSuppDetails = async (
    branchID: any,
    iReminderUUID: any
  ) => {
    const apiResult = await DetailsInvoiceSeller(branchID, iReminderUUID);
    // console.log(apiResult, "response");
    setUpdateUser(apiResult?.data?.data);
  };
  const handlePrintDetail = async (branchID: any, memberuid: any) => {
    const response = await DetailsInvoiceSeller(branchID, memberuid);
    setPrintData(response?.data?.data);
    setShowDetails(true);
    setBaseURL(response?.data?.imageBaseURL);
    // console.log(response, "detailsof");
  };
  const handleDateReset = (values: any) => {
    setValue(null);
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
  };
  const handleChange = (values: any) => {
    setValue(values);
    // console.log(values,)
    if (values && values.length !== 0) {
      formik.setFieldValue("start_date", moment(values[0]).format(dateFormat));
      formik.setFieldValue("end_date", moment(values[1]).format(dateFormat));
    }
  };

  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };

  const handleShowModal = (title: any) => {
    setTitle(title);
    setPayShow(true);
  };

  return (
    <>
      {/* Filter Section Start of Package Period List */}
      {isFilter && (
        <div className="card my-4">
          <div className="card-header">
            {" "}
            <Card className="w-100">
              <Card.Body className="py-5">
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col md={3} className="mb-3">
                      {" "}
                      <label htmlFor="paymentModeID" className="form-label">
                        {intl.formatMessage({
                          id: "PAYMENT.SUPPLIER.PAYMODE",
                        })}
                      </label>
                      <Select
                        id="paymentModeID"
                        name="paymentModeID"
                        className="basic-single"
                        // onChange={(e) => handleEnquiryDateChange(e)}
                        // value={selectedEnqDate}
                        onChange={(e) => {
                          setSelectPaymentMode({
                            value: e.value,
                            label: e.label,
                          });
                          formik.setFieldValue("paymentModeID", e.value);
                        }}
                        value={selectPayMode}
                        options={enqOption?.map((item: any) => ({
                          value: item?.ipaymentModeID,
                          label: item?.modeName,
                        }))}
                      />
                    </Col>
                    {/* <Col md={3} className="mb-3">
                      {" "}
                      <label htmlFor="enquiryID" className="form-label">
                        {intl.formatMessage({
                          id: "POS.PURCHASEPRODUCT.SUPPLIER",
                        })}
                      </label>
                      <Select
                        id="enquiryID"
                        name="enquiryID"
                        className="basic-single"
                        // onChange={(e) => handleEnquiryDateChange(e)}
                        // value={selectedEnqDate}
                        onChange={(e) => {
                          setSelectCat({
                            value: e.value,
                            label: e.label,
                          });
                          formik.setFieldValue("supplierID", e.value);
                        }}
                        value={selectCat}
                        options={enqOption?.map((item: any) => ({
                          value: item?.isupplierID,
                          label: item?.supplierName,
                        }))}
                      />
                    </Col> */}
                    {/* <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.DATERANGE",
                          })}
                        </label>

                        <DateRangePicker
                          value={value}
                          onChange={handleChange}
                          format="MM/dd/yyyy"
                          placeholder="Select Date Range"
                          placement="auto"
                          style={{ margin: "2px" }}
                          onClean={handleDateReset}
                        />
                      </div>
                    </Col> */}
                    <Col md={3} className="mb-4">
                      <Form.Group controlId="search">
                        <Form.Label>
                          {intl.formatMessage({
                            id: "MODULE.ENQUIRYSTATUS.SEARCH",
                          })}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder={intl.formatMessage({
                            id: "MODULE.ENQUIRYSTATUS.ENTERSEARCH",
                          })}
                          onChange={formik.handleChange}
                          value={formik.values.search}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={3} className="mb-4">
                      <Form.Group controlId="status">
                        <Form.Label>
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={formik.handleChange}
                          value={formik.values.status}
                        >
                          <option value="">
                            {intl.formatMessage({ id: "COMMON.ALL" })}
                          </option>
                          <option value="1">
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    {/* Rest of the form fields */}
                    <Col
                      md={3}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <Button type="submit" variant="primary" className="me-3 ">
                        {intl.formatMessage({
                          id: "COMMON.APPLY",
                        })}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleReset}
                        className="me-3"
                      >
                        {intl.formatMessage({
                          id: "COMMON.RESET",
                        })}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      {/* Filtsser Section End */}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div
                className="d-flex flex-wrap gap-1"
                data-kt-user-table-toolbar="base"
              >
                {checkedRows?.length > 0 ? (
                  <>
                    <div className="fw-bold me-5">
                      <span className="me-2"> {checkedRows?.length}</span>
                      {intl.formatMessage({ id: "COMMON.SELECTED" })}
                    </div>
                  </>
                ) : (
                  <>
                    <span
                      className="min-w-40px mb-3 d-flex align-items-center"
                      style={{ color: "#7e8299", fontSize: "14px" }}
                    >
                      {intl.formatMessage({ id: "COMMON.SHOW" })}
                    </span>
                    <div>
                      <select
                        className="form-control form-select form-select-solid me-3 mb-4"
                        value={pageLimit}
                        onChange={(e) => getPageLimit(e.target.value)}
                      >
                        {finalPageLimitArray?.map((item: any, i = 0) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex flex-wrap gap-1"
              data-kt-user-table-toolbar="base"
            >
              {checkedRows?.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3 mb-2"
                    onClick={handleRefreshButton}
                  >
                    {" "}
                    {intl.formatMessage({ id: "COMMON.REFRESH" })}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary me-3 mb-2"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                  <Dropdown className="mb-2">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="btn btn-light-primary me-3"
                    >
                      <KTSVG
                        path="/media/icons/duotune/files/fil021.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                      {intl.formatMessage({ id: "MODULE.BRANCH.EXPORT" })}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4">
                      <CSVLink
                        data={TBLData || []}
                        filename={downloadfileName + ".csv"}
                        className="menu-item px-3"
                      >
                        <span className="px-3">
                          {intl.formatMessage({ id: "COMMON.EXPORTCSV" })}
                        </span>
                      </CSVLink>
                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={() => exportToXLS(TBLData, downloadfileName)}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTEXCEL" })}
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={exportToPDF}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTPDF" })}
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* modal start */}
        {/* modal start */}
        {/* <InvoiceModal
          show={show}
          setShow={setShow}
          data={data}
          setTitle={setTitle}
          title={title}
          IsUpdate={isUpdate}
          UpdateUser={UpdateUser}
          getList={getSellPayment}
        />*/}
        <InvoicePage
          printShow={ShowDetails}
          setPrintShow={setShowDetails}
          printData={printData}
          CurrentCurrency={CurrentCurrency}
          currencySymbol={currencySymbol}
          baseURL={baseURL}
        />
        {/* <PurchaseProductModal
          show={show}
          setShow={setShow}
          isUpdate={isUpdate}
          setIsUpdate={setisUpdate}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          getList={getReminder}
        />
        <DetailsModal
          show={ShowDetails}
          setShow={setShowDetails}
          UpdateUser={UpdateUser}
        />
        <PaymentModal
          show={payShow}
          title={title}
          setTitle={setTitle}
          setShow={setPayShow}
          UpdateUser={Selectdata}
        /> */}
        {/* <FollowUpModal
          show={show}
          setShow={setShow}
          isFollowup={isFollowup}
          setIsfollowup={setIsfollowup}
          isUpdate={isUpdate}
          enqOption={enqOption}
          updateFollowUpUser={updateFollowUpUser}
        /> */}
        {/* <FoodItemModal
          show={show}
          setShow={setShow}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          GetList={getReminder}
          error={error}
          setError={setError}
        /> */}
        {/* <Main show={show} setShow={setShow}/> */}

        {/* modal End */}

        {/* begin::Body */}

        <div className="card-body py-3">
          {/* {isLoading && <Loading />} */}
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="iProductSellAmountID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column1}
                      {sortConfig.key !== "iProductSellAmountID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "iProductSellAmountID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "iProductSellAmountID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentDate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column3}
                      {sortConfig.key !== "paymentDate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="amount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column4}
                      {sortConfig.key !== "amount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentModeID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column5}
                      {sortConfig.key !== "paymentModeID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentModeID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentModeID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="remarks"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column6}
                      {sortConfig.key !== "remarks" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "remarks" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "remarks" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">{column9}</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredData && filteredData.length === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                    </td>
                  </tr>
                ) : UserType === "employee" && view === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center text-danger fs-4">
                      {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((element: any, index: number) => {
                    const isChecked = checkedRows.includes(
                      element.iProductSellAmountUUID
                    );
                    return (
                      <tr key={element.iProductSellAmountUUID} className="fs-5">
                        <td data-label={column1}>
                          {element.iProductSellAmountID}
                        </td>

                        <td data-label={column3}>
                          <div>{element.paymentDate}</div>
                          <div>
                            {moment(element.paymentTime, "HH:mm").format(
                              "hh:mm A"
                            )}
                          </div>
                        </td>
                        <td data-label={column4}>{element.amount}</td>
                        <td data-label={column5}>{element.paymentMethod}</td>
                        <td data-label={column6}>{element.remarks}</td>

                        {/* <td data-label={column6}>
                              {StatusBadge(element.eStatus)}
                            </td> */}

                        <td data-label={column9}>
                          <div className="d-flex flex-shrink-0 floatRight gap-2">
                            <span
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                element.iProductSellDetailID ? "" : "d-none"
                              }`}
                              onClick={() => {
                                handlePrintDetail(
                                  element.iBranchID,
                                  element.iProductSellAmountUUID
                                );
                              }}
                            >
                              <i className="bi bi-eye"></i>
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="iProductSellAmountID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column1}
                      {sortConfig.key !== "iProductSellAmountID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "iProductSellAmountID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "iProductSellAmountID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentDate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column3}
                      {sortConfig.key !== "paymentDate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="amount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column4}
                      {sortConfig.key !== "amount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentModeID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column5}
                      {sortConfig.key !== "paymentModeID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentModeID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentModeID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="remarks"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column6}
                      {sortConfig.key !== "remarks" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "remarks" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "remarks" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">{column9}</th>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {/* begin:: pagination  */}

          {filteredData && filteredData.length > 0 && (
            <Pagination page={page} totalPages={totalPages} setPage={setPage} />
          )}
          {/* end:: pagination */}
        </div>

        {/* begin::Body */}
      </div>
    </>
  );
};

export default ListPayment;
