import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { setTimeout } from "timers";
import swal from "sweetalert";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button } from "react-bootstrap";
import Dummy from "../../../../../loading_assests/dummy.png";
import {
  GetPackageDetails,
  ActiveTax,
  ActiveBatchList,
  imageUpload,
} from "../../redux/MembersCRUD";

import { CreateMember, UpdateMembers } from "../../redux/MembersCRUD";
import { PhoneInputProps } from "react-phone-input-2";
import { useIntl } from "react-intl";
// const Intl = useIntl();
// let schema = yup.object().shape({
//   name: yup.string().required("FirstName is Required"),
//   mobileNo: yup.number().required("Mobile No. is Required"),
//   // dob: yup.date().required("date of birth is required"),
//   email: yup
//     .string()
//     .email("Wrong email format")
//     .min(3, "Minimum 3 symbols")
//     .max(50, "Maximum 50 symbols")
//     .required("Email is required"),
//   address: yup.string().required("Address is Required"),
// });

const data: any = localStorage.getItem("CountryCurrencycode");
const CountryCode = JSON.parse(data);
const MemberShipModal = ({
  show,
  setShow,
  getMembers,
  UpdateUser,
  setUpdateUser,
  isUpdate,
  setisUpdate,
  activeEnqList,
  activeEmpList,
  activeOccList,
  activePackageList,
  activeBatchList,
  baseURL,
  gender,
  maritalStatus,
  error,
  setError,
}: any) => {
  const imageRef = useRef<HTMLInputElement | null>(null);
  const intl = useIntl();
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchID = ActiveBranch?.[0]?.iBranchID;
  const ActiveBranchUUID = ActiveBranch?.[0].iBranchUUID;
  const [errors, setErrors] = useState<any>({});
  const [updatePackagePrice, setUpdatePackagePrice] = useState<boolean>(true);
  const [selectedMemberType, setSelectedMemberType] = useState<string>("spot");
  const [selectEnrol, setselectEnrol] = useState<string>("");
  const [selectedEnqId, setSelectedEnqID] = useState<any>();
  const [selectedEnqData, setSelectedEnqData] = useState<any>({});
  const [selectedMartial, setSelectedMartial] = useState<any>({});
  const [selectedEmpID, setSelectedEmpID] = useState<any>({});
  const [selectedGender, setSelectedGender] = useState<any>({});
  const [selectedOccupation, setSelectedOccupation] = useState<any>({});
  const [selectedPackage, setSelectedPackage] = useState<any>({});
  const [selectedDiscount, setSelectedDiscount] = useState<any>({});
  const [selectedProgrammeUUID, setSelectedProgrammeUUId] = useState<any>();
  const [selectedPackageDetail, setSelectedPackageDetail] = useState<any>({});
  const [registrationFees, setregistrationFees] = useState<number>(0);
  const [submitted, setSubmitted] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [withOutTaxRadio, setwithOutTaxRadio] = useState<any>(false);
  const [withTaxRadio, setwithTaxRadio] = useState<any>(false);
  const [isCheckedRegFee, setIsCheckedRegFee] = useState<boolean>();
  const [discountType, setDiscountType] = useState<any>({
    value: 1,
    label: "None",
  });
  const [fixDiscount, setFixDiscount] = useState<number>(0);
  const [taxExcluded, setTaxExcluded] = useState<boolean>();
  const [taxIncluded, setTaxIncluded] = useState<boolean>(false);
  const [tax, setTax] = useState<any>([]);
  const [taxValue, setTaxValue] = useState<number>();
  const [fee, setFee] = useState<number>(0);
  const [expiryDate, setexpiryDate] = useState<any>();
  const [startDate, setstartDate] = useState<any>();
  const [dateFormat, setdateFormat] = useState<any>("YYYY-MM-DD");
  const [programFee, setProgramFee] = useState<any>();
  const [payableFee, setPayableFee] = useState<any>();
  const [batchid, setBatchID] = useState<any>({});
  const [discountValue, setDiscountValue] = useState<number>(0);
  // const [taxDetails, setTaxDetails] = useState<any>([]);
  const [selectImage, setSelectImage] = useState<any>();
  const [showImage, setShowImage] = useState<any>();
  // console.log("errornew", errors);
  // yup validation schema

  let schemaForFollowUp = Yup.object().shape({
    enquiryID: Yup.string().when("membertype", {
      is: (val: any) => val !== "spot",
      then: Yup.string().required(
        `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.ENQUIRY_ID" })}`
      ),
      otherwise: Yup.string().notRequired(),
    }),
    name: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.NAME" })}`
    ),
    mobileNo: Yup.number().required(
      `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.MOBILE" })}`
    ),
    // email: Yup.string()
    //   .email("Wrong email format")
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.EMAIL" })}`
    //   ),
    // address: Yup.string().required(
    //   `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.ADDRESS" })}`
    // ),
    employeeID: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.CONSULTANT" })}`
    ),
    // gender: Yup.string().required(
    //   `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.GENDER" })}`
    // ),
    dob: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.DATE_OF_BIRTH" })}`
    ),
    packageUUID: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.PACKAGES" })}`
    ),
    taxType: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.TAXTYPE" })}`
    ),
    dateofJoining: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.MEMBER.REQUIRED.DATEOFJOINING" })}`
    ),
    dateofStarting: Yup.string().required(
      `${intl.formatMessage({ id: "MODULE.FROZONDATE.STARTINGDATE" })}`
    ),
  });
  //create member value
  let createValue = {
    branchID: ActiveBranchID,
    membertype: "spot",
    employeeID: "",
    occupationID: "",
    name: selectedEnqData ? selectedEnqData.name : "",
    gender: selectedEnqData ? selectedEnqData.eGender : "",
    dob: "",
    maritalStatus: "",
    address: "",
    enquiryID: selectedEnqId ? selectedEnqId : "",
    countryCode: selectedEnqData ? selectedEnqData.countryCode : "+91",
    mobileNo: selectedEnqData ? selectedEnqData.mobileNo : "",
    homeCountryCode: "+91",
    homeNumber: selectedEnqData ? selectedEnqData.homePhoneNo : "",
    email: selectedEnqData ? selectedEnqData.vPEmail : "",
    packageUUID: "",
    withTaxStatus: "",
    taxno: "",
    //  taxdetails: [{ taxUUID: "1fad74a1-d0e3-4fea-b4f3-1d4e47658995", rate: 9 }],
    taxdetails: "",
    dateofJoining: "",
    dateofStarting: "",
    batchID: "",
    registrationFeeAllow: "",
    discountType: 1,
    discount: 0,
    status: "1",
    taxType: "",
  };

  //update member value
  let updateValue = {
    memberUUID: UpdateUser.iSpotUUID,
    membertype: UpdateUser?.iEnquiryID ? "Enquiry" : "spot",
    EnrollUUID: UpdateUser.iEnrolUUID,
    branchID: ActiveBranchID,
    employeeID: UpdateUser.employeeID,
    occupationID: UpdateUser.iOccupationID,
    name: UpdateUser.name,
    gender: UpdateUser.eGender,
    dob: UpdateUser?.dtDOB?.split("T")[0],
    maritalStatus: UpdateUser.eMartial,
    address: UpdateUser.vAddress,
    enquiryID: UpdateUser.iEnquiryID,
    countryCode: UpdateUser.countryCode,
    mobileNo: UpdateUser.mobileNo,
    homeCountryCode: UpdateUser.homeCountryCode,
    homeNumber: UpdateUser.homePhoneNo,
    email: UpdateUser.vEmail,
    packageUUID: UpdateUser?.packageUUID,
    withTaxStatus: UpdateUser?.taxlist?.length != 0 ? 1 : 0,
    taxno: UpdateUser.taxNo,
    taxdetails: UpdateUser.enrollTaxesList,
    dateofJoining: UpdateUser?.dtJoin?.split("T")[0],
    dateofStarting: UpdateUser?.dtStartingdate,
    batchID: UpdateUser.iBatchID,
    registrationFeeAllow: UpdateUser?.registrationFeeAllow,
    discountType: UpdateUser?.discountType,
    discount: Number(UpdateUser?.discountAmt?.split(" ")[0]),
    status: UpdateUser?.eStatus,
  };
  //   console.log("discounts", UpdateUser);

  //define discount type
  const discountTypeOptions = [
    { value: 1, label: "None" },
    { value: 2, label: "Complementry" },
    { value: 3, label: "Fix Discount" },
  ];
  //find options by discount type
  const findDiscountLabel = (value: number) => {
    const option = discountTypeOptions.find((option) => option.value === value);
    return option ? option.label : null;
  };
  useEffect(() => {
    if (selectedEnqData) {
      setSelectedGender({
        label: selectedEnqData.eGender,
        value: selectedEnqData.eGender,
      });
      setSelectedOccupation({
        label: selectedEnqData?.employeeID,
        value: selectedEnqData.OccupationName,
      });
      setSelectedEmpID({
        label: selectedEnqData?.iOccupationID,
        value: selectedEnqData.employeeName,
      });
    }
  }, [selectedEnqData]);
  // console.log("isUpdate===", isUpdate);
  // console.log("UpdateUser===", UpdateUser);
  useEffect(() => {
    if (isUpdate && UpdateUser) {
      setShowImage(`${baseURL}${UpdateUser?.vPhoto}`);
      setSelectedMemberType(UpdateUser?.iEnquiryID ? "Enquiry" : "spot");
      setselectEnrol(UpdateUser?.eEnrol_Type);
      setSelectedEmpID({
        value: UpdateUser?.employeeID,
        label: UpdateUser?.employeeName,
      });
      setSelectedGender({
        value: UpdateUser?.eGender,
        label: UpdateUser?.eGender,
      });
      setSelectedMartial({
        value: UpdateUser?.eMartial,
        label: UpdateUser?.eMartial,
      });
      setSelectedOccupation({
        value: UpdateUser?.iOccupationID,
        label: UpdateUser?.OccupationName,
      });
      setSelectedPackage({
        value: UpdateUser?.packageID,
        label: UpdateUser?.packageName,
      });
      setBatchID({
        value: UpdateUser?.iBatchID,
        label: UpdateUser?.batchName,
      });
      setSelectedEnqID(UpdateUser?.iEnquiryID);

      if (UpdateUser?.taxNo) {
        setwithTaxRadio(true);
        setwithOutTaxRadio(false);
        setTaxIncluded(true);
        setTax(UpdateUser?.enrollTaxesList);
      } else {
        setwithOutTaxRadio(true);
        setwithTaxRadio(false);
        setTaxValue(0);
        setTaxIncluded(false);
        setTaxExcluded(true);
        setTax([]);
      }
      if (UpdateUser?.discountType == 3) {
        setFixDiscount(Number(UpdateUser?.discountAmt?.split(" ")[0]));
      }
      formik.setFieldValue("packageUUID", UpdateUser?.packageUUID);
      setSelectedProgrammeUUId(UpdateUser?.packageUUID);
      setCheckbox(UpdateUser?.registrationFeeAllow);
      setIsCheckedRegFee(UpdateUser?.registrationFeeAllow);
      //update the registration fee
      UpdateUser?.registrationFeeAllow == 1
        ? setregistrationFees(UpdateUser?.registrationFee)
        : setregistrationFees(0);

      setDiscountValue(Number(UpdateUser?.discountAmt?.split(" ")[0]));
      setProgramFee(UpdateUser?.packageFee);
      setDiscountType({
        value: UpdateUser?.discountType,
        label: findDiscountLabel(UpdateUser?.discountType),
      });
      setFee(Number(UpdateUser?.feeWithoutTax));
      setPayableFee(UpdateUser?.payableFee);
      setexpiryDate(UpdateUser?.dtExpirydate);
      setstartDate(UpdateUser?.dtStartingdate);
    } else {
      setSelectedEmpID({
        value: "",
        label: "Select Employee",
      });
      setSelectedGender({
        value: "",
        label: "Select Gender",
      });
      setSelectedMartial({
        value: "",
        label: "Select Martial",
      });
      setSelectedOccupation({
        value: "",
        label: "Select Occuption",
      });
      setSelectedPackage({
        value: "",
        label: "Select Package",
      });
      setBatchID({
        value: "",
        label: "Select Batch",
      });
      setDiscountType({ value: "", label: "select Discount Type" });
    }
  }, [isUpdate, UpdateUser]);
  // console.log("discountAmt", discountValue);
  //Reset the DropDownform
  const handleResetDrop = () => {
    setSelectedEmpID({
      value: "",
      label: "Select Employee",
    });
    setSelectedGender({
      value: "",
      label: "Select Gender",
    });
    setSelectedMartial({
      value: "",
      label: "Select Martial",
    });
    setSelectedOccupation({
      value: "",
      label: "Select Occuption",
    });
    setSelectedMemberType("spot");
    setSelectImage("");

    setDiscountType({ value: "", label: "select Discount Type" });
    setProgramFee(0);
    setDiscountType({ value: "", label: "select a discount" });
    setFixDiscount(0);
    setDiscountValue(0);
    setFee(0);
    setwithOutTaxRadio(false);
    setwithTaxRadio(false);
    setTaxIncluded(false);
    setTaxExcluded(false);
    setTaxValue(0);
    setPayableFee(0);

    formik.setFieldValue("packageUUID", "");
    formik.setFieldValue("batchID", "");
  };
  // console.log("fees", fee);
  //call the function
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isUpdate ? updateValue : createValue,
    // validationSchema: schemaForFollowUp,
    validationSchema: !isUpdate ? schemaForFollowUp : "",

    onSubmit: async (values, action) => {
      // console.log("val", values);
      const formData = new FormData();
      const key = Object.keys(values);
      key.forEach((item: any) =>
        formData.append(item, values[item as keyof typeof values])
      );
      if (selectImage) {
        formData.append("profilePhoto", selectImage);
      }
      if (isUpdate) {
        // console.log("calling from update");

        const response = await UpdateMembers(formData);

        if (response.status === 200) {
          swal(response?.data?.replyMsg, { icon: "success" });
          setSelectImage(null);
          setShow(false);
          getMembers();

          setregistrationFees(0);
          setProgramFee(0);

          setPayableFee(0);
          setDiscountType({ value: "", label: "select Discount Type" });
          setwithTaxRadio(false);
          setwithOutTaxRadio(false);
          setFee(0);
          handleResetDrop();
          action.resetForm();
        }
      } else {
        setSubmitted(true);
        // try {

        const response = await CreateMember(formData);
        // console.log("calling from createmember", response);
        if (response.status === 400) {
          setErrors(response?.data);
        }
        if (response.status === 200) {
          // if (selectImage) {
          //   const memberuid = response?.data?.data.memberUUID;
          //   const formdata = new FormData();
          //   formdata.append("memberUUID", memberuid);
          //   formdata.append("branchID", ActiveBranchID);
          //   formdata.append("profilePhoto", selectImage);
          //   const imageresult = await imageUpload(formdata);
          //   if (imageresult.status == 200) {
          //     swal(imageresult?.data?.replyMsg, {
          //       icon: imageresult?.data?.replyCode,
          //     });
          //   } else {
          //     swal(response?.data?.replyMsg, {
          //       icon: response?.data?.replyCode,
          //     });
          //   }
          // }

          swal(response?.data?.replyMsg, { icon: response?.data?.replyCode });
          formik.resetForm();
          setShow(false);

          setSelectedPackage({
            value: "",
            label: "Select Package",
          });
          setErrors("");
          setSelectImage(null);
          setregistrationFees(0);
          setProgramFee(0);
          // setSubmitted(false);
          setPayableFee(0);
          setDiscountType({ value: "", label: "select a discount" });
          setwithTaxRadio(false);
          setwithOutTaxRadio(false);
          setSelectedProgrammeUUId({});
          setFee(0);
          // console.log("fees", fee);
          // action.resetForm();
          handleResetDrop();
          getMembers();

          setSelectedGender({
            value: "",
            label: "Select Gender",
          });
        }
        // } catch (err) {
        //   console.log("error12", err);
        // }
      }

      // if (response.status === 200) {

      // }
    },
  });
  // console.log("selectedId", selectedProgrammeUUID);
  // console.log(formik.values, "dshdsjkds");
  const handleClose = () => {
    setShow(false);

    setProgramFee(0);
    setSubmitted(false);
    setPayableFee(0);
    setDiscountType({ value: "", label: "select a discount" });
    setwithTaxRadio(false);
    setwithOutTaxRadio(false);
    setSelectedProgrammeUUId({});
    setFee(0);
    handleResetDrop();
    setregistrationFees(0);
    setSelectedMemberType("spot");
    setUpdateUser("");
    setSelectedEnqID("");
  };

  //set membertype Change
  const handleMemberTypeChane = (Event: any) => {
    formik.setFieldValue("membertype", Event.target.value);
    setSelectedMemberType(Event.target.value);
    if (Event.target.value === "spot") {
      setSelectedEnqData("");
    }
  };

  //set enquiry ID
  const handleEnquiryID = async (Event: any) => {
    setSelectedEnqID(Event.value);
    if (Event.value) {
      const [enquiryId, enquiryUUId] = Event.value?.split(" ");
      formik.setFieldValue("enquiryID", enquiryId);
      //   const response = await enquiryDetails(ActiveBranchID, enquiryUUId);
      //   console.log(response, "selected enquiry");
      // enqObject(response?.data?.data, enquiryId);
      //   setSelectedEnqData(response?.data?.data);
    }

    // console.log("activeEnquiry uid", Event.value);
    // enqObject(activeEnqList, selectedEnqId);
    setSelectedEnqID(Event.value);
  };
  console.log(selectedEnqData, "resultttttt");
  //start get enquiry list
  useEffect(() => {
    const result = enqObject(activeEnqList, selectedEnqId);
    setSelectedEnqData(result);
  }, [selectedEnqId]);
  const enqObject = (activeEnqList: any, selectedEnqId: any) => {
    return activeEnqList?.find((obj: any) => obj.iEnquiryID === selectedEnqId);
  };
  //end get enquiry list

  //start set martial status
  const handleMaritalStatus = (Event: any) => {
    formik.setFieldValue("maritalStatus", Event.value);
    setSelectedMartial({
      value: Event.value,
      label: Event.value,
    });
  };
  //end set martial status

  //start set enployee id
  const handleEmpChange = (Event: any) => {
    formik.setFieldValue("employeeID", Event.value);
    setSelectedEmpID({
      value: Event.value,
      label: Event.label,
    });
  };
  //end set enployee id

  //start set gender
  const handleGenderChange = (Event: any) => {
    formik.setFieldValue("gender", Event.value);
    setSelectedGender({
      value: Event.value,
      label: Event.label,
    });
  };
  //end set gender

  //start set occupation id
  const handleOccupationChange = (Event: any) => {
    formik.setFieldValue("occupationID", Event.value);
    setSelectedOccupation({
      value: Event.value,
      label: Event.label,
    });
  };
  //end set occupation id

  //start handle package
  const handlePackageChange = async (Event: any) => {
    formik.setFieldValue("packageUUID", Event.value);
    //console.log("programme uuid", Event.value);
    setSelectedPackage({
      value: Event.value,
      label: Event.label,
    });
    setSelectedProgrammeUUId(Event.value);
    setUpdatePackagePrice(false);
    setDiscountType({ value: 1, label: "None" });
    // console.log("uniqueid", selectedProgrammeUUID);
    setCheckbox(true);
    setwithOutTaxRadio(false);
    setwithTaxRadio(false);
    setTaxIncluded(false);
    setTax([]);
    setTaxValue(0);
    setPayableFee(0);
    // await getPackageDetails(ActiveBranchID, Event.value);
  };

  //end handle package

  //   useEffect(() => {
  //     getPackageDetails(ActiveBranchID, selectedProgrammeUUID);

  //     // formik.setFieldValue("discountType", 1); //
  //   }, [selectedProgrammeUUID, ActiveBranchID]);

  //start get package details
  //   const getPackageDetails = async (branchID: any, iProgramUUID: any) => {
  //     // const response = await GetPackageDetails(branchID, iProgramUUID);
  //     setSelectedPackageDetail(response?.data?.data);
  //     formik.setFieldValue("registrationFeeAllow", 1);
  //     setProgramFee(response?.data?.data?.fProgramFees);
  //     const registrationFees = response?.data?.data?.fEnFees || 0;
  //     const programFee = response?.data?.data?.fProgramFees || 0;
  //     const result = registrationFees + programFee;
  //     setFee(result);
  //     // console.log("resultfees", result);
  //     setDiscountType({ value: 1, label: "None" });
  //     setregistrationFees(registrationFees);

  //     formik.setFieldValue("discountType", 1); //

  //     setDiscountValue(0);
  //     setIsCheckedRegFee(false);
  //     getTodayDate(response?.data?.data?.iDays);
  //   };
  //end get package details

  useEffect(() => {
    // calculateFeeWithputTax();
  }, [isCheckedRegFee, discountType, fixDiscount]);

  //start calculate fee with tax
  const calculateFeeWithputTax = () => {
    const registrationFee = isCheckedRegFee
      ? 0
      : selectedPackageDetail?.fEnFees;
    const packageFee = selectedPackageDetail?.fProgramFees || 0;
    const discount: any = discountValue;
    const result = registrationFee + packageFee - discount;
    if (isUpdate && UpdateUser && updatePackagePrice) {
      // setDiscountValue(UpdateUser?.discountAmt);
      setFee(UpdateUser?.feeWithoutTax);
      // console.log("fes", UpdateUser?.feeWithoutTax);
    } else {
      setFee(Number(result));
    }
    // console.log("resultfees", registrationFee, packageFee, discount);
  };
  //end calculate fee with tax

  //start handle allow registration fee
  const handleChangeCheckbox = (Event: any) => {
    const isChecked = Event.target.checked;
    if (isChecked) {
      formik.setFieldValue("registrationFeeAllow", 1);
      setIsCheckedRegFee(true);
      if (isUpdate && UpdateUser) {
        setregistrationFees(UpdateUser?.registrationFee);
        setFee(UpdateUser?.packageFee + UpdateUser?.registrationFee);
      } else {
        setregistrationFees(selectedPackageDetail?.fEnFees);
        setFee(
          selectedPackageDetail?.fEnFees + selectedPackageDetail?.fProgramFees
        );
      }
    } else {
      setIsCheckedRegFee(false);
      setregistrationFees(0);
      formik.setFieldValue("registrationFeeAllow", 0);
      setFee(selectedPackageDetail?.fProgramFees);
      if (isUpdate && UpdateUser) {
        // setregistrationFees(0);
        setFee(UpdateUser?.feeWithoutTax);
      } else {
        setregistrationFees(0);
        setFee(selectedPackageDetail?.fProgramFees);
      }
    }
    setCheckbox(Event.target.checked);
    setFixDiscount(0);
    setDiscountValue(0);
    //const registrationFee = isCheckedRegFee
    //? 0
    // : selectedPackageDetail?.fEnFees;

    // const packageFee = selectedPackageDetail?.fProgramFees;
    // setFee(registrationFee + packageFee);
    //if (discountType.value == 1) {
    setDiscountType({ value: 1, label: "None" });
    // }
    setwithOutTaxRadio(false);
    setwithTaxRadio(false);
    setTaxIncluded(false);
    setTax([]);
    setTaxValue(0);
    setPayableFee(0);
  };
  // //end handle allow registration fee
  // const handleDiscountType2 = (Event: any) => {
  //   if (Event.label === "None") {
  //     setDiscountType({ value: Event.value, label: Event.label });
  //   } else if (Event.label === "Fix Discount") {
  //     setDiscountType({ value: Event.value, label: Event.label });
  //   }
  //   // complimentary
  //   else {
  //     setDiscountType({ value: Event.value, label: Event.label });
  //   }
  //   setwithOutTaxRadio(false);
  //   setwithTaxRadio(false);
  //   setTaxIncluded(false);
  //   setTax([]);
  //   setTaxValue(0);
  //   setPayableFee(0);
  // };
  //start handle discount type
  const handleDiscountType = (Event: any) => {
    formik.setFieldValue("discountType", Event.value);
    setUpdatePackagePrice(false);
    let totalFeeAmt = registrationFees + programFee;
    // console.log("check", registrationFees, programFee, totalFeeAmt);
    if (Event.label === "None") {
      setDiscountType({ value: Event.value, label: Event.label });
      setDiscountValue(0);
      setFixDiscount(0);

      setFee(totalFeeAmt);
      formik.setFieldValue("discount", 0);
    } else if (Event.label === "Fix Discount") {
      setDiscountType({ value: Event.value, label: Event.label });
      // setFixDiscount(0);
      // setDiscountValue(0);
      // calculateFeeWithputTax();
      let totalFeeAmt = registrationFees + programFee;
      // console.log("discountB", registrationFees, programFee);
      setFee(totalFeeAmt);
      // setFee(totalFeeAmt);
    } else {
      setDiscountType({ value: 2, label: "Complementry" });

      let result = registrationFees + programFee;
      setDiscountValue(result);
      // console.log("fdafdss", result, registrationFees, programFee);
      // console.log("complimentry", result);
      // console.log("jsduk", discountValue);
      formik.setFieldValue("discount", result);
      setFee(0);
      setFixDiscount(0);

      // formik.setFieldValue("discount", 0);
    }
    setwithOutTaxRadio(false);
    setwithTaxRadio(false);
    setTaxIncluded(false);
    setTax([]);
    setTaxValue(0);
    setPayableFee(0);
  };
  //end handle discount type

  //start handle fix discount type
  const handleFixDiscount = (Event: any) => {
    setwithOutTaxRadio(false);
    setwithTaxRadio(false);
    setUpdatePackagePrice(true);
    setFixDiscount(Event.target.value);
    setDiscountValue(Number(Event.target.value));
    // console.log("handleFixDiscount", Event.target.value);
    formik.setFieldValue("discount", Number(Event.target.value));

    let registrationFee = 0;
    if (isCheckedRegFee && selectedPackageDetail?.fEnFees) {
      registrationFee = selectedPackageDetail?.fEnFees;
    } else {
      registrationFee = registrationFees;
    }

    const packageFee = selectedPackageDetail?.fProgramFees;
    setFee(registrationFees + programFee - Event.target.value);

    if (Event.target.value > registrationFee + packageFee) {
      swal("Discount amount is not greater than total fee amont", {
        icon: "error",
      });
      // setFixDiscount(0);
      // setDiscountValue(0);
    }
    // setFee(Number(registrationFee + packageFee) - Number(Event.target.value));
  };

  //end handle fix discount type

  //start handle tax excluded
  const handleTaxExcluded = (Event: any) => {
    setTaxIncluded(false);
    setTaxValue(0);
    setPayableFee(fee);
    setwithTaxRadio(false);
    setwithOutTaxRadio(true);
    setTax([]);
    formik.setFieldValue("taxType", false);
    formik.setFieldValue("withTaxStatus", 0);
  };
  //end handle tax excluded

  //start define tax incliude function
  const handleTaxIncluded = () => {
    setTaxIncluded(true);
    setwithTaxRadio(true);
    setwithOutTaxRadio(false);
    formik.setFieldValue("taxType", false);
    formik.setFieldValue("withTaxStatus", 1);
  };
  //end define tax incliude function

  useEffect(() => {
    if (taxIncluded) {
      activeTax(ActiveBranchUUID);

      //  calculateTaxValue();
    }
  }, [taxIncluded]);
  useEffect(() => {
    if (tax && tax.length > 0) {
      calculateTaxValue();
    }
  }, [tax, taxIncluded]);

  //start define active tax function
  const activeTax = async (branchUUID: any) => {
    try {
      const response = await ActiveTax(branchUUID);
      const taxData = response?.data?.data;
      // console.log(JSON.stringify(taxData), "fsadjhkdsaf");
      setTax(taxData);
      formik.setFieldValue("taxdetails", JSON.stringify(taxData));
    } catch (error) {
      // console.error("Error fetching active tax:", error);
    }
  };
  //end define active tax function

  //start calculateTaxValue function
  const calculateTaxValue = () => {
    let total = 0;
    tax.forEach((item: any) => {
      total += (item.rate * fee) / 100;
    });
    total = Math.floor(total);
    setTaxValue(total);
    let payableFees = fee + total;
    setPayableFee(payableFees);
  };
  //end calculateTaxValue function

  //start getTodayDate function
  const getTodayDate = (days: any) => {
    const newDate = moment(new Date()).format(dateFormat);
    let todayDate = newDate;
    formik.setFieldValue("dateofJoining", newDate);
    if (startDate) {
      todayDate = startDate;
    }
    formik.setFieldValue("dateofStarting", todayDate);
    setstartDate(todayDate);
    const newExpiredDate = moment(todayDate)
      .add(days, "days")
      .format(dateFormat);
    setexpiryDate(newExpiredDate);
  };
  //end getTodayDate function

  //start handle start date change
  const handledStartDateChange = (Event: any) => {
    const selectedStartedDate = Event.target.value;
    formik.setFieldValue("dateofStarting", selectedStartedDate);
    setstartDate(Event.target.value);
    let packageDays = selectedPackageDetail?.iDays;
    // console.log("packageDays===", packageDays);
    const newExpiredDate = moment(selectedStartedDate)
      .add(packageDays, "days")
      .format(dateFormat);
    // console.log("newExpiredDate===", newExpiredDate);
    setexpiryDate(newExpiredDate);
  };
  //end handle start date change

  //start handle batch ID
  const handleBatchID = (Event: any) => {
    formik.setFieldValue("batchID", Event.value);
    setBatchID({
      value: Event.value,
      label: Event.label,
    });
  };
  // console.log("submitted", submitted);

  //start handle photo
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setSelectImage(file);
    }
  };

  const handleImageClick = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  };

  const handleImageRemove = () => {
    // console.log("deleted");
    setSelectImage(null);
    if (imageRef.current) {
      imageRef.current.value = ""; // Clear the file input
    }
  };
  const sno = intl.formatMessage({ id: "MODULE.MEMBER.SNO" });
  const taxname = intl.formatMessage({ id: "MODULE.MEMBER.TAXNAME" });
  const amount = intl.formatMessage({ id: "MODULE.MEMBER.AMOUNT" });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({
              id: "MODULE.MEMBERS.PACKAGEDETAILS",
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body scroll-y mx-5 mx-xl-15 my-7">
          <form onSubmit={formik.handleSubmit}>
            <div className="d-flex flex-column scroll-y me-n7 pe-7">
              <div className="p-6.5">
                {/* begin:: First name*/}
                <div className="row mb-7">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="status" className="required form-label">
                        {intl.formatMessage({ id: "MODULE.MEMBERS.ENQTYPE" })}
                      </label>
                      <select
                        id="membertype"
                        name="membertype"
                        className="form-select"
                        onChange={(e) => handleMemberTypeChane(e)}
                        //  onBlur={formik.handleBlur("membertype")}
                        value={selectedMemberType}
                        disabled
                      >
                        <option value="Enquiry">Enquiry</option>
                        <option value="spot">spot</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="status" className="required form-label">
                        {intl.formatMessage({ id: "MODULE.MEMBERS.ENROLTYPE" })}
                      </label>
                      <input
                        type="text"
                        id="membertype"
                        name="membertype"
                        className="form-control"
                        // onChange={(e) => handleMemberTypeChane(e)}
                        //  onBlur={formik.handleBlur("membertype")}
                        value={selectEnrol}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h1 style={{ color: "#009ef7", textDecoration: "underline" }}>
                    {intl.formatMessage({
                      id: "MODULE.MEMBERS.PACKAGEDETAILS",
                    })}
                  </h1>
                </div>
                <div className="row mb-6 mt-7">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="gender" className="required form-label">
                        {intl.formatMessage({ id: "MODULE.MEMBERS.PACKAGE" })}
                      </label>
                      <Select
                        id="packageUUID"
                        name="packageUUID"
                        className="basic-single"
                        value={selectedPackage}
                        options={activePackageList.map((item: any) => ({
                          value: item.iProgramUUID,
                          label: item.vProgram,
                        }))}
                        onChange={(e) => handlePackageChange(e)}
                        isDisabled
                      />
                      {formik.touched.packageUUID &&
                        formik.errors.packageUUID && (
                          <div className="text-danger">
                            {formik.errors.packageUUID}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="gender" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.REGISTRATIONFEE",
                        })}
                      </label>

                      <input
                        style={{ marginLeft: "5px" }}
                        type="checkbox"
                        checked={checkbox}
                        onChange={(e) => handleChangeCheckbox(e)}
                        disabled
                      />

                      <input
                        type="number"
                        id="registrationFeeAllow"
                        name="registrationFeeAllow"
                        className="form-control"
                        value={registrationFees}
                        // onChange={formik.handleChange("registrationFeeAllow")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="gender" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.PACKAGEFEE",
                        })}
                      </label>
                      <input
                        type="number"
                        id="programfee"
                        name="programfee"
                        className="form-control"
                        // value={selectedPackageDetail.fProgramFees}
                        // value={formik.values.programfee}
                        value={programFee}
                        onChange={formik.handleChange("programfee")}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-md-4">
                    <label htmlFor="department_name" className="form-label">
                      {intl.formatMessage({
                        id: "MODULE.MEMBERS.DISCOUNTTYPE",
                      })}
                    </label>
                    <Select
                      className=" basic-single"
                      id="discountType"
                      name="discountType"
                      value={discountType}
                      options={discountTypeOptions.map((item: any) => ({
                        value: item.value,
                        label: item.label,
                      }))}
                      onChange={(e) => handleDiscountType(e)}
                      isDisabled
                    />
                    {/* {console.log(
                      "discount",
                      formik.touched.discount,
                      formik.errors
                    )} */}
                    {/* {formik.touched.discountType &&
                      formik.errors.discountType && (
                        <div className="text-danger">
                          {formik.errors.discountType}
                        </div>
                      )} */}
                  </div>
                  {discountType.label === "Fix Discount" && (
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.FIXDISCOUNT",
                          })}
                        </label>
                        <input
                          type="number"
                          id="fixdiscount"
                          name="fixdiscount"
                          className="form-control"
                          value={fixDiscount}
                          onChange={(e) => handleFixDiscount(e)}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {discountType.label !== "Fix Discount" && (
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.DISCOUNT",
                          })}
                        </label>
                        <input
                          type="number"
                          id="discount"
                          name="discount"
                          className="form-control"
                          onChange={formik.handleChange("discount")}
                          value={discountValue}
                          //value={formik.values.discount}
                          disabled
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-md-4 mt-2">
                    <div className="mb-3">
                      <label htmlFor="gender" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.FEE",
                        })}
                      </label>
                      <input
                        type="number"
                        id="fee"
                        name="fee"
                        className="form-control"
                        value={fee}
                        // value={formik.values.fee}
                        onChange={formik.handleChange("fee")}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="taxType" className="form-label required">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.TAX",
                        })}
                      </label>
                      <div>
                        <input
                          name="taxType"
                          type="radio"
                          id="taxexcluded"
                          checked={withOutTaxRadio}
                          value={withOutTaxRadio}
                          onChange={handleTaxExcluded}
                          disabled
                        />
                        <label style={{ marginLeft: "3px" }}>
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.WITHOUTTAX",
                          })}
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="taxType"
                          id="taxincluded"
                          checked={withTaxRadio}
                          value={withTaxRadio}
                          onChange={handleTaxIncluded}
                          disabled
                        />
                        <label style={{ marginLeft: "3px" }}>
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.WITHTAX",
                          })}
                        </label>
                      </div>
                    </div>
                    {withOutTaxRadio ||
                      (!withTaxRadio && (
                        <div className="text-danger">Tax is required</div>
                      ))}
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="gender" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.TAXVALUE",
                        })}
                      </label>
                      <input
                        type="number"
                        id="taxvalue"
                        name="taxvalue"
                        className="form-control"
                        value={taxValue}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="gender" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.PAYABLEFEE",
                        })}
                      </label>
                      <input
                        type="number"
                        id="payableFee"
                        name="payableFee"
                        className="form-control"
                        value={payableFee}
                        // value={formik.values.payableFee}
                        onChange={formik.handleChange("payableFee")}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-7 mt-2 ">
                    {taxIncluded && (
                      <div className="col-md-4 border border-primary">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr>
                                <th className="notVisibleInMobile">{sno}</th>
                                <th className="notVisibleInMobile">
                                  {taxname}
                                </th>
                                <th className="notVisibleInMobile">{amount}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tax.map((item: any, index: any) => (
                                <tr key={index}>
                                  <td data-label={sno}>{index + 1}</td>
                                  <td
                                    data-label={taxname}
                                  >{`${item.taxName} (${item.rate}%)`}</td>
                                  <td data-label={amount}>
                                    {(item.rate * fee) / 100}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    {taxIncluded && (
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="gender" className="form-label">
                            {intl.formatMessage({
                              id: "MODULE.TAX.NUMBER",
                            })}
                          </label>
                          <input
                            type="text"
                            id="taxno"
                            name="taxno"
                            className="form-control"
                            onChange={formik.handleChange("taxno")}
                            value={formik.values.taxno}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-md-4">
                      <label htmlFor="department_name" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.BATCH",
                        })}
                      </label>

                      <Select
                        className=" basic-single"
                        id="batchID"
                        name="batchID"
                        value={batchid}
                        options={activeBatchList?.map((item: any) => ({
                          value: item.iBatchID,
                          label: item.batchName,
                        }))}
                        onChange={(e) => handleBatchID(e)}
                        isDisabled
                      />
                    </div>
                  </div>
                  <div className="row mb-7 mt-2">
                    <h1
                      style={{ color: "#009ef7", textDecoration: "underline" }}
                    >
                      {intl.formatMessage({
                        id: "MODULE.MEMBERS.DATEDETAILS",
                      })}
                    </h1>
                    <div className="col-md-4 " style={{ height: "80px" }}>
                      <label
                        htmlFor="department_name"
                        className="required form-label"
                      >
                        {intl.formatMessage({ id: "MODULE.MEMBERS.DOJ" })}
                      </label>
                      <input
                        type="date"
                        id="dateofJoining"
                        name="dateofJoining"
                        className="form-control"
                        onChange={formik.handleChange("dateofJoining")}
                        value={formik?.values?.dateofJoining}
                        disabled
                        // onChange={(e) => handledobChange(e)}
                      />
                      {formik.touched.dateofJoining &&
                        formik.errors.dateofJoining && (
                          <div className="error">
                            {formik.errors.dateofJoining}
                          </div>
                        )}
                    </div>
                    <div className="col-md-4 " style={{ height: "80px" }}>
                      <label htmlFor="department_name" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.STARTINGDATE",
                        })}
                      </label>
                      <input
                        type="date"
                        id="dateofStarting"
                        name="dateofStarting"
                        className="form-control"
                        //onChange={formik.handleChange("dateofStarting")}
                        value={startDate}
                        onChange={(e) => handledStartDateChange(e)}
                        disabled
                      />
                      {formik.touched.dateofStarting &&
                        formik.errors.dateofStarting && (
                          <div className="text-danger">
                            {formik.errors.dateofStarting}
                          </div>
                        )}
                    </div>
                    <div className="col-md-4 ">
                      <label htmlFor="department_name" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.EXPIRYDATE",
                        })}
                      </label>

                      <input
                        type="date"
                        id="dateofStarting"
                        name="dateofexpiry"
                        className="form-control"
                        value={expiryDate}
                        disabled
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="form-select"
                          onChange={formik.handleChange("status")}
                          onBlur={formik.handleBlur("status")}
                          value={formik.values.status}
                          disabled
                        >
                          <option value="1">
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div>
                      <input
                        type="hidden"
                        id="taxdetails"
                        name="taxdetails"
                        className="form-control"
                        // value={formik.values.name}
                        value={formik.values.taxdetails}
                        onChange={formik.handleChange("taxdetails")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MemberShipModal;
