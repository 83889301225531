import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_ALL_POSEXPENSE = `${API_URL}/v1/posexpense/list`;
export const ADD_POSEXPENSE = `${API_URL}/v1/posexpense/add`;
export const CREATE_POSEXPENSECATEGORY = `${API_URL}/v1/posexpensecategory/listbyBranch`;
export const DETAIL_POSUPDATE_EXPENSE = `${API_URL}/v1/posexpense/details`;
export const ACTIVE_POSEXPENSECATEGORY = `${API_URL}/v1/posexpensecategory/add`;
export const UPDATE_POSEXPENSECATEGORY = `${API_URL}/v1/posexpense/update/`;
export const UPDATE_POSEXPENSECATEGORY_STATUS = `${API_URL}/v1/posexpense/update_status`;
export const DELETE_POSEXPENSECATEGORY = `${API_URL}/v1/posexpense/delete`;
// Create a department
export function CreatePosExpenseCategory(body: any) {
  return axios.post(CREATE_POSEXPENSECATEGORY, body).catch((error) => {
    return error.response;
  });
}
export function activePosExpenseCategoryList(activebranchuuid: any) {
  return axios
    .get(`${CREATE_POSEXPENSECATEGORY}/${activebranchuuid}`)
    .catch((error) => {
      return error.response;
    });
}
export function detailofUpdatePosExpense(
  activebranchid: any,
  expensesuuid: any
) {
  return axios
    .get(`${DETAIL_POSUPDATE_EXPENSE}/${activebranchid}/${expensesuuid}`)
    .catch((error) => {
      return error.response;
    });
}

//add a expense modal
export function addPosExpense(data: any) {
  return axios.post(ADD_POSEXPENSE, data).catch((error) => {
    return error.response;
  });
}
// Get all departments
export function GetPosExpenses(data: any) {
  return axios.post(GET_ALL_POSEXPENSE, data).catch((error) => {
    return error.response;
  });
}

// Update a department
export function UpdatePosExpenseCategory(body: any) {
  return axios.put(UPDATE_POSEXPENSECATEGORY, body).catch((error) => {
    return error.response;
  });
}

// Update department status
export function Update_PosExpenseCategory_Status(body: any) {
  return axios.patch(UPDATE_POSEXPENSECATEGORY_STATUS, body).catch((error) => {
    return error.response;
  });
}
// Delete department
export function Delete_PosExpenseCategory(body: any) {
  return axios.patch(DELETE_POSEXPENSECATEGORY, body).catch((error) => {
    return error.response;
  });
}
