import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_ALL_MEMBERS = `${API_URL}/v1/member/list`;
export const GET_ALL_MEMBERS_PACKAGES = `${API_URL}/v1/member/packagelistbyUUID`;
export const CREATE_MEMBERS = `${API_URL}/v1/member/add`;
export const CREATE_MEMBERS_IMAGE = `${API_URL}/v1/member/uploadimages`;
export const UPDATE_MEMBERS = `${API_URL}/v1/member/update`;
export const UPDATE_MEMBER_STATUS = `${API_URL}/v1/member/update_status`;
export const ACTIVE_ENQUIRY_LIST = `${API_URL}/v1/enquiry/listbyBranch`;
export const ACTIVE_EMPLOYEE_LIST = `${API_URL}/v1/employee/listbyBranch`;
export const ACTIVE_OCCUPATION_LIST = `${API_URL}/v1/occupation/listbyBranch`;
export const ACTIVE_BATCH_LIST = `${API_URL}/v1/batch/listbyBranch`;
export const ACTIVE_BATCH_LIST_BYPACKAGE = `${API_URL}/v1/batch/listbyBranch`;
export const ACTIVE_PACKAGE_LIST = `${API_URL}/v1/package/listbyBranch`;
// export const ALL_PACKAGE_LIST = `${API_URL}/v1/package/list`;
export const PACKAGE_DETAILS = `${API_URL}/v1/package/details`;
export const GET_GENDERS = `${API_URL}/v1/common/genderlist`;
export const GET_MARITAL_STATUS = `${API_URL}/v1/common/martiallist`;
export const ACTIVE_TAX = `${API_URL}/v1/tax/listbyBranch`;
export const DELETE_ADDONS = `${API_URL}/v1/member/delete`;
export const MEMBER_DETAILS = `${API_URL}/v1/member/details`;
export const ADDONS_PACKAGES = `${API_URL}/v1/memberaddon/add`;
export const RENEWAL_MEMBER_LIST = `${API_URL}/v1/memberaddon/listbyBranch`;
export const BATCH_BYGROUP_LIST = `${API_URL}/v1/batch/listbypackage`;
export const FREEZE_MEMBER_DETAILS = `${API_URL}/v1/memberrenewal/getEnrolledddetails`;
export const RENEWAL_MEMBER_PACKAGES = `${API_URL}/v1/memberrenewal/getmembershiplist`;
export const RENEWAL_MEMBER_Details = `${API_URL}/v1/memberrenewal/getEnrolledddetails`;
export const RENEWAL_MEMBER_SUBMIT = `${API_URL}/v1/memberrenewal/add`;
export const MEMBERSHIP_FREEZE = `${API_URL}/v1/memberfreeze/add`;
export const MEMBERSHIP_FREEZE_PACKAGES = `${API_URL}/v1/memberrenewal/getmembershiplist`;
export const MEMBERSHIP_RENEWAL_MEMBER = `${API_URL}/v1/member/listbyBranch`;
export const ENQUIRY_DETAILS = `${API_URL}/v1/enquiry/details`;
export const MEMBER_DELETESINGLE = `${API_URL}/v1/member/deletesingle`;
export const GET_LAST_MEMBERSHIP_ID = `${API_URL}/v1/member/getlastmembershipid`;

//create user
export function CreateMember(data: any) {
  console.log("errorrr", data);
  return axios.post(CREATE_MEMBERS, data).catch((error) => {
    return error.response;
  });
}

export function imageUpload(data: any) {
  console.log("errorrr", data);
  return axios.post(CREATE_MEMBERS_IMAGE, data).catch((error) => {
    return error.response;
  });
}
export function getLastMemebershipId(branchUID: any){
  return axios.get(`${GET_LAST_MEMBERSHIP_ID}/${branchUID}`);
}
export function enrollListofFreeze(branchID: any, branchUID: any) {
  return axios.get(`${MEMBERSHIP_FREEZE_PACKAGES}/${branchID}/${branchUID}`);
}
export function activeUserList(branchUID: any) {
  return axios.get(`${MEMBERSHIP_RENEWAL_MEMBER}/${branchUID}`);
}
export function Renewal(data: any) {
  console.log("dataAdd", RENEWAL_MEMBER_SUBMIT);
  return axios.post(RENEWAL_MEMBER_SUBMIT, data).catch((error) => {
    return error.response;
  });
}
export function AddOns(data: any) {
  console.log("dataAdd", ADDONS_PACKAGES);
  return axios.post(ADDONS_PACKAGES, data).catch((error) => {
    return error.response;
  });
}

export function addFreezeMembership(data: any) {
  console.log("dataAdd", MEMBERSHIP_FREEZE);
  return axios.post(MEMBERSHIP_FREEZE, data).catch((error) => {
    return error.response;
  });
}
export function DeleteMember(body: any) {
  return axios.patch(DELETE_ADDONS, body).catch((error) => {
    return error.response;
  });
}
export function DeleteSingleMember(body: any) {
  return axios.patch(MEMBER_DELETESINGLE, body).catch((error) => {
    return error.response;
  });
}
//

//getting all users
export function GetMembers(payloadData: any) {
  return axios.post(GET_ALL_MEMBERS, payloadData).catch((err) => {
    return err.response;
  });
}
export function packageList(payloadData: any) {
  return axios.post(GET_ALL_MEMBERS_PACKAGES, payloadData).catch((err) => {});
}
//getting single user

//updating user
export function UpdateMembers(data: any) {
  return axios.put(UPDATE_MEMBERS, data);
}

// updating user status
export function UpdateStatus(data: any) {
  return axios.patch(UPDATE_MEMBER_STATUS, data);
}
// get active enquiry list
export function ActiveEnquiryList(branchUUID: any) {
  return axios.get(`${ACTIVE_ENQUIRY_LIST}/${branchUUID}`);
}
export function ActiveEmpList(branchUUID: any) {
  return axios.get(`${ACTIVE_EMPLOYEE_LIST}/${branchUUID}`);
}
export function ActiveOccupationList(branchUUID: any) {
  return axios.get(`${ACTIVE_OCCUPATION_LIST}/${branchUUID}`);
}
export function ActiveBatchList(branchUUID: any) {
  return axios.get(`${ACTIVE_BATCH_LIST}/${branchUUID}`);
}
export function ActivePackageList(branchUUID: any) {
  return axios.get(`${ACTIVE_PACKAGE_LIST}/${branchUUID}`);
}

export function ActiveTax(branchUUID: any) {
  return axios.get(`${ACTIVE_TAX}/${branchUUID}`);
}
export function batchIDAPI(packageUUID: any) {
  return axios.get(`${ACTIVE_TAX}/${packageUUID}`);
}
// export function AllPackageList(data: any) {
//   return axios.post(ALL_PACKAGE_LIST, data);
// }
export function GetGenders(lang: any) {
  return axios.get(`${GET_GENDERS}/${lang}`).catch((error) => {
    return error.response;
  });
}
export function GetMaritalStatus(lang: any) {
  return axios.get(`${GET_MARITAL_STATUS}/${lang}`);
}
export function enquiryDetails(branchID: any, iProgramUUID: any) {
  return axios.get(`${ENQUIRY_DETAILS}/${branchID}/${iProgramUUID}`);
}
export function GetPackageDetails(branchID: any, iProgramUUID: any) {
  return axios.get(`${PACKAGE_DETAILS}/${branchID}/${iProgramUUID}`);
}
export function GetMemberDetailsFreeze(branchID: any, iProgramUUID: any) {
  return axios.get(`${FREEZE_MEMBER_DETAILS}/${branchID}/${iProgramUUID}`);
}
export function GetMemberDetailsRenewal(branchUID: any) {
  return axios.get(`${RENEWAL_MEMBER_LIST}/${branchUID}`);
}
// export function GetMemberDetailsFreeze(branchUID: any) {
//   return axios.get(`${FREEZE_MEMBER_DETAILS}/${branchUID}`);
// }
export function enrollListofRenewal(branchID: any, branchUID: any) {
  return axios.get(`${RENEWAL_MEMBER_PACKAGES}/${branchID}/${branchUID}`);
}
export function enrollDetailsofRenewal(branchID: any, branchUID: any) {
  return axios.get(`${RENEWAL_MEMBER_Details}/${branchID}/${branchUID}`);
}

export function GetMemberDetails(branchID: any, spotUUID: any) {
  return axios.get(`${MEMBER_DETAILS}/${branchID}/${spotUUID}`);
}
export function batchIDAPII(ProgramgrpUUID: any) {
  return axios.get(`${BATCH_BYGROUP_LIST}/${ProgramgrpUUID}`);
}
