import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import permission from "../../../modules/GenricComponant/permissions/permission";
import { StatusBadge } from "../../../../_metronic/helpers";
import {
  GetEnquiryDetails,
  GetEnquiry,
} from "../../../modules/leads/modules/enquiry/redux/EnquiryCRUD";
import moment from "moment";
import Pagination from "../../../modules/GenricComponant/Pagination";
import { useFormik } from "formik";
import * as packageRedux from "../../../modules/leads/modules/enquiry/redux/EnquiryRedux";
import { EnquiryNotification } from "../redux/dashboardCRUD";
import swal from "sweetalert";

const EnquiryModalData = (selectedDate: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "dtModified_Date", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  const showPagelimit = process.env.REACT_APP_PAGELIMIT;
  const finalPageLimitArray: any = showPagelimit?.split(",");
  const pageDefaultValue = Number(finalPageLimitArray[0]);

  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const packages = useSelector((state: any) => state?.package?.packages?.data);

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("enquiry");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });

  const [showEN, setShowEN] = useState<any>(false);
  // const [filterValues, setFilterValues] = useState({});
  const [isUpdate, setisUpdate] = useState(false);
  const [followUpData, setFollowUpData] = useState<any>([]);
  const [enqUUIDFWP, setEnqUUIDFWP] = useState<any>();
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [branch_id, setBranch_id] = useState<any>("0");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [TBLData, setTBLData] = useState<any>([]);
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  const [UpdateUser, setUpdateUser] = useState<any>({});

  // const initialState = useMemo(() => {
  //   return {
  //     status: "",
  //     page_limit: pageLimit,
  //     page_no: 1,
  //     search: "",
  //     branchID: ActiveBranch.iBranchID,
  //     start_date: date?.date?.startDate || "",
  //     end_date: date?.date?.endDate || "",
  //     order_key: "dtModified_Date",
  //     order_value: "desc",
  //   };
  // }, [date?.date?.startDate,date?.date?.endDate ])

  //define column of listing

  const firstColumnTitle = `${intl.formatMessage({
    id: "MODULE.ENQUIRY.NAME",
  })}`;
  const secondColumnTitle = `${intl.formatMessage({
    id: "MODULE.ENQUIRY.MOBILE",
  })}`;
  const thirdColumnTitle = `${intl.formatMessage({
    id: "MODULE.ENQUIRY.OCC",
  })}`;
  const fourthColumnTitle = `${intl.formatMessage({
    id: "MODULE.ENQUIRY.EMPLOYEE",
  })}`;
  const fifthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.GENDER",
  })}`;
  const sixthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGE",
  })}`;
  const seventhColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGEFEE",
  })}`;
  const dataANDTimeColumn = `${intl.formatMessage({
    id: "MODULE.ENQUIRY.DATEANDTIME",
  })}`;
  const enquiryTypeColumn = `${intl.formatMessage({
    id: "MODULE.ENQUIRY.TYPE1",
  })}`;
  const enquiryStatusColumn = `${intl.formatMessage({
    id: "MODULE.ENQUIRY.ENQSTATUS1",
  })}`;
  const enquiryActionsColumn = `${intl.formatMessage({
    id: "COMMON.ACTIONS",
  })}`;
  const eigthColumnTitle = `${intl.formatMessage({ id: "COMMON.STATUS" })}`;
  const ninthColumnTitle = `${intl.formatMessage({ id: "COMMON.ACTIONS" })}`;

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
    setPage(1);
  };

  const startDate = selectedDate.selectedDate.startDate;
  const endDate = selectedDate.selectedDate.endDate;

  // console.log(startDate)
  // console.log(endDate)

  // const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: initialState,
  //   onSubmit: (values) => {
  //     setFilterValues(values);
  //     setPage(1);
  //   },
  // });

  // const getPackages = useCallback(() => {
  //   if (UserType === "admin" || view !== 0) {
  //     setIsLoading(true);
  //     GetEnquiry({
  //       status: "",
  //       page_limit: pageLimit,
  //       page_no: 1,
  //       search: "",
  //       branchID: ActiveBranch.iBranchID,
  //       start_date: startDate || "",
  //       end_date: endDate || "",
  //       order_key: "dtModified_Date",
  //       order_value: "desc",
  //     }).then((data: any) => {
  //       if (data?.status !== 200) {
  //         setFilteredData([]);
  //         setIsLoading(false);
  //       } else {
  //         dispatch(packageRedux.actions.GetPackages(data));
  //         setEnqUUIDFWP(data?.data?.data?.[0]?.enquiryTypeUUID);
  //         setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
  //         setIsLoading(false);
  //       }
  //     });
  //   }
  // }, [pageLimit, startDate, endDate, page, sortConfig, view]);

  const getPackages = useCallback(() => {
    if (UserType === "admin" || view !== 0) {
      setIsLoading(true);
      GetEnquiry({
        status: "",
        page_limit: pageLimit,
        page_no: 1,
        search: "",
        branchID: ActiveBranch.iBranchID,
        start_date: startDate || "",
        end_date: endDate || "",
        order_key: "dtModified_Date",
        order_value: "desc",
      }).then((data: any) => {
        if (data?.status !== 200) {
          setFilteredData([]);
          setIsLoading(false);
        } else {
          dispatch(packageRedux?.actions?.GetPackages(data));
          setEnqUUIDFWP(data?.data?.data?.[0]?.enquiryTypeUUID);
          // console.log(data, "sfdsd");
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, startDate, endDate, page, sortConfig, view]);

  useEffect(() => {
    getPackages();
  }, [getPackages]);

  // useEffect(() => {
  //   if (UserType === "admin") {
  //     setIsLoading(true);
  //     GetEnquiry({
  //       status: "",
  //       page_limit: pageLimit,
  //       page_no: 1,
  //       search: "",
  //       branchID: ActiveBranch.iBranchID,
  //       start_date: startDate,
  //       end_date: endDate || "",
  //       order_key: "dtModified_Date",
  //       order_value: "desc",
  //     }).then((data: any) => {
  //       if (data?.status !== 200) {
  //         setFilteredData([]);
  //         setIsLoading(false);
  //       } else {
  //         dispatch(packageRedux.actions.GetPackages(data));
  //         setEnqUUIDFWP(data?.data?.data?.[0]?.enquiryTypeUUID);
  //         // console.log(data, "sfdsd");
  //         setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
  //         setIsLoading(false);
  //       }
  //     });
  //   }
  // }, [startDate, endDate]);

  const handleExports = () => {
    const tableRows = packages?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [firstColumnTitle]: element?.name,
        [secondColumnTitle]: element?.countryCode + "-" + element.mobileNo,
        [thirdColumnTitle]: element?.OccupationName,
        [fourthColumnTitle]: element?.employeeName,
        [fifthColumnTitle]: element?.eGender,
        [sixthColumnTitle]: element?.packageName,
        [seventhColumnTitle]: element?.packageFee,
        [dataANDTimeColumn]:
          element.enquiryJoinDate + " " + element.dtCreated_Date?.split("T")[1],
        [enquiryTypeColumn]: element?.enquiryType,
        [enquiryStatusColumn]: element?.enquiryStatus,
        [eigthColumnTitle]:
          element?.eStatus === 1
            ? intl.formatMessage({ id: "COMMON.ACTIVE" })
            : intl.formatMessage({ id: "COMMON.INACTIVE" }),
      };
    });

    setTBLData(tableRows);
  };
  useEffect(() => {
    handleExports();
    setFilteredData(packages?.data);
  }, [packages?.data]);

  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };

  const getEnquiryDetails = async (branchID: any, enquiryUUID: any) => {
    const apiResult = await GetEnquiryDetails(branchID, enquiryUUID);
    setUpdateUser(apiResult?.data?.data);
    setFollowUpData(apiResult?.data?.data?.followupList);
  };

  const handleUpdate = (enquiryUUID: any, branchID: any) => {
    getEnquiryDetails(branchID, enquiryUUID);
  };
  const handleRowCheckboxChange = (rowId: any, branchId: any) => {
    setBranch_id(branchId);
    if (checkedRows.includes(rowId)) {
      setCheckedRows(checkedRows.filter((id: any) => id !== rowId));
    } else {
      setCheckedRows([...checkedRows, rowId]);
    }
  };

  const handleSendNotifications = async (branchID: any, enrolID: any) => {
    // Show a confirmation dialog
    const willSend = await swal({
      title: intl.formatMessage({ id: "COMMON.AREYOUSURE" }),
      text: intl.formatMessage({ id: "COMMON.DOYOUSEND" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SEND" }),
      ], // Cancel and Send buttons
      dangerMode: true, // Optional: Highlights the "Send" button
    });

    // If the user confirms, proceed with the API call
    if (willSend) {
      const obj = { branchID: branchID, enrollmentID: enrolID };
      const response = await EnquiryNotification(obj);
      if (response?.status === 200) {
        swal(response?.data?.replyMsg, { icon: "success" });
      } else {
        swal(response?.data?.replyMsg, { icon: "error" });
      }
    } else {
      // Handle the cancel action (optional)
      swal(
        "Cancelled",
        intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" }),
        "info"
      );
    }
  };
  return (
    <>
      <div className="w-25">
        <span
          className="min-w-40px mb-3 d-flex align-items-center"
          style={{ color: "#7e8299", fontSize: "14px" }}
        >
          {intl.formatMessage({ id: "COMMON.SHOW" })}
        </span>
        <div>
          <select
            className="form-control form-select form-select-solid me-3 mb-4"
            value={pageLimit}
            onChange={(e) => getPageLimit(e.target.value)}
          >
            {finalPageLimitArray?.map((item: any, i = 0) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="card-body py-3">
        {/* {isLoading && <Loading />} */}
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                {/* <th className=" mobilewidth">
                  <div className="mobileViewalign w-100">
                    <div className=" selectAll ">
                      {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                    </div>
                    <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="1"
                        data-kt-check="true"
                        data-kt-check-target=".widget-9-check"
                        checked={isAllRowsChecked}
                        onChange={handleSelectAllCheckboxChange}
                      />
                    </div>
                  </div>
                </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="name"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {firstColumnTitle}
                    {sortConfig.key !== "name" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "name" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "name" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="mobileNo"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {secondColumnTitle}
                    {sortConfig.key !== "mobileNo" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "mobileNo" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "mobileNo" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="OccupationName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {thirdColumnTitle}
                    {sortConfig.key !== "OccupationName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "OccupationName" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "OccupationName" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="employeeName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fourthColumnTitle}
                    {sortConfig.key !== "employeeName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "employeeName" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "employeeName" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eGender"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fifthColumnTitle}
                    {sortConfig.key !== "eGender" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "eGender" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "eGender" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {sixthColumnTitle}
                    {sortConfig.key !== "packageName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "packageName" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "packageName" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageFee"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {seventhColumnTitle}
                    {sortConfig.key !== "packageFee" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "packageFee" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "packageFee" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="enquiryJoinDate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {dataANDTimeColumn}
                    {sortConfig.key !== "enquiryJoinDate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "enquiryJoinDate" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "enquiryJoinDate" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="enquiryType"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {enquiryTypeColumn}
                    {sortConfig.key !== "enquiryType" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "enquiryType" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "enquiryType" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="enquiryStatus"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {enquiryStatusColumn}
                    {sortConfig.key !== "enquiryStatus" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "enquiryStatus" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "enquiryStatus" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>

                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eStatus"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {eigthColumnTitle}
                    {sortConfig.key !== "eStatus" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "eStatus" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "eStatus" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  {enquiryActionsColumn}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            <tbody>
              {filteredData && filteredData.length === 0 ? (
                <tr>
                  <td colSpan={10} className="text-center">
                    {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                  </td>
                </tr>
              ) : UserType === "employee" && view === 0 ? (
                <tr>
                  <td colSpan={10} className="text-center text-danger fs-4">
                    {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                  </td>
                </tr>
              ) : (
                filteredData?.map((element: any, index: number) => {
                  const isChecked = checkedRows.includes(element.iEnquiryUUID);
                  return (
                    <tr key={index} className="fs-5">
                      {/* <td
                          data-label={intl.formatMessage({
                            id: "COMMON.SELECT",
                          })}
                        >
                          <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                            <input
                              className="form-check-input widget-9-check"
                              type="checkbox"
                              value="1"
                              checked={isChecked}
                              onChange={() =>
                                handleRowCheckboxChange(
                                  element.iEnquiryUUID,
                                  element.iBranchID
                                )
                              }
                            />
                          </div>
                        </td> */}
                      <td data-label={firstColumnTitle}>
                        <a
                          href="#"
                          onClick={() => {
                            setisUpdate(true);
                            handleUpdate(
                              element.iEnquiryUUID,
                              element.iBranchID
                            );
                            setShowEN(true);
                          }}
                        >
                          {element.name}
                        </a>
                      </td>

                      <td data-label={secondColumnTitle}>
                        <a
                          href={`tel:${element.countryCode}${element.mobileNo}`}
                        >
                          {element.mobileNo &&
                            `${element.countryCode}-
                                ${element.mobileNo}`}
                        </a>
                      </td>
                      <td data-label={thirdColumnTitle}>
                        {element.OccupationName}
                      </td>
                      <td data-label={fourthColumnTitle}>
                        {element.employeeName}
                        {/* {new Date(element.enquiryJoinDate).toLocaleString(
                                "en-US",
                                {
                                  dateStyle: "medium",
                                }
                              )} */}
                      </td>
                      <td data-label={fifthColumnTitle}>{element.eGender}</td>
                      <td data-label={sixthColumnTitle}>
                        {element.packageName}
                      </td>

                      <td data-label={seventhColumnTitle}>
                        {element.packageFee}
                      </td>
                      <td data-label={dataANDTimeColumn}>
                        {`    ${
                          element.enquiryJoinDate?.split("T")[0]
                        }  ${moment(element.dtCreated_Date).format("hh:mm A")}`}
                      </td>
                      {/* {console.log(
                            element.dtCreated_Date?.split("T")[1],
                            "dssdfds"
                          )} */}
                      <td data-label={enquiryTypeColumn}>
                        {element.enquiryType}
                      </td>
                      <td data-label={enquiryStatusColumn}>
                        {element.enquiryStatus}
                      </td>
                      <td data-label={eigthColumnTitle}>
                        {StatusBadge(element.eStatus)}
                      </td>
                      <td data-label={enquiryActionsColumn}>
                        <div className="d-flex flex-shrink-0 ">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              handleSendNotifications(
                                element?.iBranchID,
                                element.iEnrolID
                              );
                            }}
                          >
                            {intl.formatMessage({ id: "COMMON.SEND" })}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                {/* <th className=" mobilewidth">
                  <div className="mobileViewalign w-100">
                    <div className=" selectAll ">
                      {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                    </div>
                    <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="1"
                        data-kt-check="true"
                        data-kt-check-target=".widget-9-check"
                        checked={isAllRowsChecked}
                        onChange={handleSelectAllCheckboxChange}
                      />
                    </div>
                  </div>
                </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="name"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {firstColumnTitle}
                    {sortConfig.key !== "name" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "name" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "name" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="mobileNo"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {secondColumnTitle}
                    {sortConfig.key !== "mobileNo" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "mobileNo" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "mobileNo" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="OccupationName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {thirdColumnTitle}
                    {sortConfig.key !== "OccupationName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "OccupationName" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "OccupationName" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="employeeName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fourthColumnTitle}
                    {sortConfig.key !== "employeeName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "employeeName" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "employeeName" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eGender"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fifthColumnTitle}
                    {sortConfig.key !== "eGender" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "eGender" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "eGender" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {sixthColumnTitle}
                    {sortConfig.key !== "packageName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "packageName" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "packageName" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageFee"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {seventhColumnTitle}
                    {sortConfig.key !== "packageFee" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "packageFee" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "packageFee" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="enquiryJoinDate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {dataANDTimeColumn}
                    {sortConfig.key !== "enquiryJoinDate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "enquiryJoinDate" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "enquiryJoinDate" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="enquiryType"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {enquiryTypeColumn}
                    {sortConfig.key !== "enquiryType" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "enquiryType" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "enquiryType" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="enquiryStatus"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {enquiryStatusColumn}
                    {sortConfig.key !== "enquiryStatus" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "enquiryStatus" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "enquiryStatus" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eStatus"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {eigthColumnTitle}
                    {sortConfig.key !== "eStatus" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "eStatus" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "eStatus" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  {enquiryActionsColumn}
                </th>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>

        {filteredData && filteredData.length > 0 && (
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        )}
        {/* end:: pagination */}
      </div>
    </>
  );
};

export default EnquiryModalData;
