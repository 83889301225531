/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import * as invoice from "./redux/invoiceRedux";
import { CSVLink } from "react-csv";
import { Form, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import swal from "sweetalert";
import { useFormik } from "formik";
import { KTSVG } from "../../../../_metronic/helpers";

// import Dummy from "../../../../../loading_assests/dummy.png";
// import Logo from "../../../../../loading_assests/logormbg.png";
import * as UserremainingBalances from "../../finance/remainingbalance/redux/remaingBalanceREDUX";
import { ActivePackageList } from "../../../modules/members/redux/MembersCRUD";
import {
  GetMembers,
  UpdateStatus,
  DeleteMember,
  ActiveMemberList,
  activeUserList,
} from "../../finance/remainingbalance/redux/remainingBalanceCRUD";
// redux/MembersCRUD";
// import MemberModal from "./modal/MemberModal";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/DateRangePicker";
import { useIntl } from "react-intl";
// import { Search } from "../../../../_metronic/partials";
// import FreezeModal from "./modal/FreezeModal";
import Select from "react-select";
import moment from "moment";
import Pagination from "../../../modules/GenricComponant/Pagination";
// import InvoiceModal from "./modal/InvoiceModal";
// import AddUpdateRenewalModal from "./modal/AddUpdateRenewalModal";
// import AddOnUpdateModal from "./modal/AddOnUpdateModal";
import { jsPDF } from "jspdf";
import { exportToXLS } from "../../../modules/GenricComponant/exportToxls/exportToxls";
// import Loading from "../../../modules/GenricComponant/loading/Loading";
import { GetPackageDetails } from "../../MemberShip/redux/MembersCRUD";
import MemberShipModal from "../../MemberShip/componants/modal/MemberShipModal";
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: object) => jsPDF;
  }
}

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const DueAmountModal = () => {
  const intl = useIntl();
  const [sortConfig, setSortConfig] = useState<any>({
    key: " iEnrolID", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchUUID = ActiveBranch?.[0]?.iBranchUUID;
  const activeBranchID = ActiveBranch?.[0]?.iBranchID;

  const dispatch = useDispatch();
  const UserremainingBalance: any = useSelector(
    (state: any) => state?.UserremainingBalances?.UserremainingBalances?.data
  );
  // console.log("reduxxxx", UserremainingBalance);

  // const [UUID, setUUID] = useState<any>();
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  // const [baseURL, setBaseURL] = useState<any>([]);
  const [totalDueAMT, setTotalDueAMT] = useState<any>("");
  const [UpdateUser, setUpdateUser] = useState<any>({});
  const [selectMembershipType, setSelectMembershipType] = useState({
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
    value: "",
  });
  const [selectedPackage, setSelectedPackage] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.NAME",
    })}`,
  });
  // const [selectConsultant, setSelectedConsultant] = useState<any>({
  //   value: "",
  //   label: `${intl.formatMessage({
  //     id: "MODULE.SELECTPACKAGE.CONSULTANT",
  //   })}`,
  // });
  // const [selectedOccupation, setSelectedOccupation] = useState<any>({
  //   value: "",
  //   label: `${intl.formatMessage({
  //     id: "MODULE.SELECTPACKAGE.OCCUPATION",
  //   })}`,
  // });
  // const [selectMember, setSelectMember] = useState<any>({
  //   value: "",
  //   label: `${intl.formatMessage({
  //     id: "MODULE.SELECTPACKAGE.MEMBER",
  //   })}`,
  // });
  // const [selectedGender, setSelectedGender] = useState<any>({
  //   value: "",
  //   label: `${intl.formatMessage({
  //     id: "MODULE.SELECTPACKAGE.GENDER",
  //   })}`,
  // });

  // const [selectedMarital, setSelectedMarital] = useState<any>({
  //   value: "",
  //   label: `${intl.formatMessage({
  //     id: "MODULE.SELECTPACKAGE.MARITAL",
  //   })}`,
  // });
  // const [batchData, setBatchData] = useState<any>();
  // const [batchid, setBatchid] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  // const [activityStatus, setActivityStatus] = useState<any>({
  //   value: "",
  //   label: `${intl.formatMessage({
  //     id: "MODULE.SELECTPACKAGE.ACTIVITYSTATUS",
  //   })}`,
  // });
  const [dateFormat, setdateFormat] = useState<any>("YYYY-MM-DD");
  // const initialDates = [new Date(), new Date()];
  const [value, setValue] = React.useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [activeMemberList, setActiveMemberList] = useState<any>([]);
  // const [activeUser, setActiveUser] = useState<any>([]);
  let initialState = {
    branchID: activeBranchID,
    page_limit: 10,
    page_no: 1,
    spotID: "",
    enrollID: "",
    membershipType: "",
    packageID: "",
    packageGroupID: "",
    search: "",
    start_date: "",
    end_date: "",
    order_key: " iEnrolID",
    order_value: "desc",
  };
  const activeMemberShip = [
    {
      value: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE1",
      })}`,
      label: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE1",
      })}`,
    },
    {
      value: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE2",
      })}`,
      label: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE2",
      })}`,
    },
    {
      value: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE3",
      })}`,
      label: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE3",
      })}`,
    },
  ];
  const [selectedDate, setSelectedDate] = useState({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.EMPLOYEE.SELECTINITIAL",
    })}`,
  });
  const option1 = {
    label: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE1" }),
    value: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE1" }),
  };
  const option2 = {
    label: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE2" }),
    value: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE2" }),
  };
  const option3 = {
    label: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE3" }),
    value: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE3" }),
  };
  const dateOption = [option1, option2, option3];
  // const [updateModalShow, setUpdateModalShow] = useState<boolean>(false);
  // const [addModalShow, setAddModalShow] = useState<boolean>(false);
  // const [renewalShow, setRenewalShow] = useState<boolean>(false);
  // const [addFreezeShow, setAddFreezeShow] = useState<boolean>(false);

  // const [memberUID, setMemberUID] = useState<any>();
  const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);
  //props states
  const [isUpdate, setisUpdate] = useState(false);
  // const [activeEnqList, setActiveEnqList] = useState<any>([]);
  const [activeEmpList, setActiveEmpList] = useState<any>([]);
  const [activeOccList, setActiveOccList] = useState<any>([]);
  // const [branch_id, setBranch_id] = useState<any>("0");
  const [activePackageList, setActivePackageList] = useState<any>([]);
  const [gender, setGender] = useState<any>([]);
  const [maritalStatus, setMaritalStatus] = useState<any>([]);
  // const [activeBatchList, setActiveBatchList] = useState<any>([]);
  //for check box
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [isAllRowsChecked, setIsAllRowsChecked] = useState<any>(false);
  //const [filteredData, setfilteredData] = useState<any>([]);
  // for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // for error
  // const [error, setError] = useState("");
  const [isFilter, setisFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState(initialState);
  // const [searchValue, setSearchValue] = useState<string>();
  // const [status, setStatus] = useState<any>();
  const [filteredData, setFilteredData] = useState<any>([]);
  const [TBLData, setTBLData] = useState<any>([]);
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  // selected user
  // const [selecteduser, setselecteduser] = useState<any>({});
  //option for status
  // const StatusOption = [
  //   {
  //     value: 1,
  //     label: `${intl.formatMessage({
  //       id: "MODULE.SELECTPACKAGE.ACTIVE",
  //     })}`,
  //   },
  //   {
  //     value: 0,
  //     label: `${intl.formatMessage({
  //       id: "MODULE.SELECTPACKAGE.INACTIVE",
  //     })}`,
  //   },
  // ];
  // define rows for listing
  const firstColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.MEMBER",
  })}`;
  const secondColumnTitle = `${intl.formatMessage({
    id: "COMMON.PHOTO",
  })}`;
  const thirdColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBER.MEMBERSHIPTYPE",
  })}`;
  const fourthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGE",
  })}`;
  const fifthColumnTitle = `${intl.formatMessage({
    id: "MODULE.EMPLOYEE.joinDate",
  })}`;
  const sixthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.STARTDATE",
  })}`;
  const seventhColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.ENDDATE",
  })}`;
  const eigthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBER.TOTALFEES",
  })}`;
  const ninthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBER.REMAININGBALANCE",
  })}`;

  // const tenthColumnTitle = `${intl.formatMessage({
  //   id: "COMMON.STATUS",
  // })}`;
  // const eleventhColumnTitle = `${intl.formatMessage({
  //   id: "COMMON.ACTIONS",
  // })}`;
  // const twelthColumnTitle = `${intl.formatMessage({
  //   id: "MODULE.MEMBER.PACKAGEGROUP",
  // })}`;
  // const thirteenthColumnTitle = `${intl.formatMessage({
  //   id: "COMMON.RENEWALDATE",
  // })}`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: async (values) => {
      // console.log("valee", values);
      setIsLoading(true);
      setFilterValues(values);
      // setPage(1);
      //   const response = await GetMembers({
      //     ...values,
      //     page_no: page,
      //   });
      //   setFilteredData(response?.data?.data);
      //   // console.log("responsed", response);
      //   if (response) {
      //     setIsLoading(false);
      //   }
      //   // ;
      //   // filterValues = { ...filterValues, values };
      //   // action.resetForm();
      // },
    },
  });
  // console.log("formikvalues", formik.values);
  const getMembers = useCallback(() => {
    setIsLoading(true);
    GetMembers({
      ...filterValues,
      page_no: page,
      page_limit: pageLimit,
      order_key: sortConfig.key,
      order_value: sortConfig.direction,
    }).then((data: any) => {
      // console.log(data, "data");

      dispatch(UserremainingBalances.actions.GetUserRemainingBalances(data));
      setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
      setIsLoading(false);
    });
  }, [dispatch, pageLimit, filterValues, page, sortConfig]);

  /*   useEffect(() => {
    getMembers();
  }, [pageLimit]);
 */
  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    setFilteredData(UserremainingBalance?.data);
    // setBaseURL(UserremainingBalance?.imageBaseURL);
    setTotalDueAMT(UserremainingBalance?.totalOutstandingAmt);
    handleExports();
  }, [UserremainingBalance?.data]);
  // console.log(invoices, "dhundh");
  /*   const getMembers = async () => {
    const response = await GetMembers({
      page_limit: pageLimit,
      order_key: " iSpotID",
      order_value: "desc",
      Search: searchValue,
      status: status,
    });
    console.log("members responseeee", response?.data?.data);
    setfilteredData(response?.data?.data);
  };
 */
  const downloadfileName = `RemainingBalance_${Date.now()}`;

  const handleExports = () => {
    const tableRows = UserremainingBalance?.data?.map(
      (element: any, index: number) => {
        return {
          "#": index + 1,
          [thirdColumnTitle]: element.eEnrol_Type,
          [fourthColumnTitle]: element.packageName,
          [fifthColumnTitle]: element?.dtJoin,
          [sixthColumnTitle]: element?.dtStartingdate,
          [seventhColumnTitle]: element?.dtExpirydate,
          [eigthColumnTitle]: element.tatalpayableAmt,
          [ninthColumnTitle]: element.fDue,
        };
      }
    );

    setTBLData(tableRows);
  };

  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    doc.text(intl.formatMessage({ id: "COMMON.REMAINGBALNCE" }), 10, 10);
    const tableRows = UserremainingBalance.data.map(
      (element: any, index: number) => {
        return [
          index + 1,
          element.eEnrol_Type,
          element.packageName,
          element.dtJoin,
          element.dtStartingdate,
          element.dtExpirydate,
          element.tatalpayableAmt,
          element.fDue,
        ];
      }
    );
    doc.autoTable({
      head: [
        [
          "#",

          thirdColumnTitle,
          fourthColumnTitle,
          fifthColumnTitle,
          sixthColumnTitle,
          seventhColumnTitle,
          eigthColumnTitle,
          ninthColumnTitle,
        ],
      ],
      body: tableRows,
      startY: 50,
    });

    doc.save(downloadfileName + "pdf");
  };

  // const getMemberDetails = async (branchID: any, spotUUID: any) => {
  //   const response = await GetAddOnMemberDetails(branchID, spotUUID);
  //   // setUpdateUser(response?.data?.data);
  //   console.log("updateresponse", response?.data?.data);
  // };

  useEffect(() => {
    getActiveMemberList(ActiveBranchUUID);
    // getActiveEnquiryList(ActiveBranchUUID);
    getActivePackageList(ActiveBranchUUID);
    getActiveUserList(ActiveBranchUUID);
  }, []);
  const getActiveUserList = async (branchUUID: any) => {
    const response = await activeUserList(branchUUID);
    // setActiveUser(response?.data?.data);
    // console.log(response?.data, "reponseofactive");
  };

  const getActiveMemberList = async (branchUUID: any) => {
    const response = await ActiveMemberList(branchUUID);
    // console.log(response?.data, "faizl", branchUUID);
    // setActiveMemberList(response?.data?.data);
  };

  //end get active enquiry list

  //start get active package list
  const getActivePackageList = async (branchUUID: any) => {
    const response = await ActivePackageList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActivePackageList(response?.data?.data);
  };
  //end get active package list

  //start get active gender list

  //end get active marital list

  const UpdateUserStatus = (prop: any) => {
    swal({
      title: `${intl.formatMessage({ id: "COMMON.AREYOUSURE" })}`,
      text: intl.formatMessage({ id: "COMMON.NOTPERFORMOPERATION" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SUBMIT" }),
      ], //["Cancel", "Submit"],
      dangerMode: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        const Update_user = {
          branchID: activeBranchID,
          uuid_array: checkedRows,
          select_type: "",
          unchecked_uuid_array: [],
          status: prop.status,
        };
        if (prop.status !== "2") {
          UpdateStatus(Update_user)
            .then((data: any) => {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });

              getMembers();
            })
            .catch(() => {
              // swal("Somthing Went Wrong!");
              swal(
                `${intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" })}`
              );
            });
        } else {
          DeleteMember(Update_user)
            .then((data: any) => {
              //swal(`Proof! Selected Users Is ${prop.message}!`, {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });
              //getting all roled again
              getMembers();
            })
            .catch(() => {
              swal(intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" }));
            });
        }
      } else {
        swal(`${intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" })}`);
      }
    });
  };

  //define page limit
  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
    // console.log("page limit event", values);
    setPage(1);
  };
  //handle check box
  const handleRowCheckboxChange = (rowId: any) => {
    if (checkedRows.includes(rowId)) {
      setCheckedRows(checkedRows.filter((id: any) => id !== rowId));
    } else {
      setCheckedRows([...checkedRows, rowId]);
    }
  };
  // handle all check box
  const handleSelectAllCheckboxChange = (Event: any) => {
    if (isAllRowsChecked) {
      setCheckedRows([]);
      setIsAllRowsChecked(false);
    } else {
      const allRowIds = filteredData?.map(
        (element: any) => element?.iEnrolUUID
      );
      setCheckedRows(allRowIds);
      setIsAllRowsChecked(true);
    }
  };
  const selectPageHandler = (selectedPage: any) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPages &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  const handleReset = () => {
    formik.resetForm();
    handleResetFilter();
    setFilterValues(initialState);

    // getMembers();
  };

  const handleRefreshButton = () => {
    setFilterValues(initialState);
    getMembers();
    setCheckedRows([]);
    setPageLimit(pageDefaultValue);
    setSortConfig({ key: " iEnrolID", direction: "desc" });
    // setSearchValue("");
    // setStatus("");
  };

  const handleSearchClick = (Event: any) => {
    getMembers();
  };
  //console.log("updateUser", UpdateUser);
  const handleResetFilter = () => {
    // setSelectMember({
    //   value: "",
    //   label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.MEMBER" })}`,
    // });
    setSelectedPackage({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.NAME" })}`,
    });

    setSelectMembershipType({
      value: "",
      label: `${intl.formatMessage({ id: "COMMON.SELECT" })}`,
    });
    setSelectedDate({
      value: "",
      label: `${intl.formatMessage({ id: "COMMON.SELECT" })}`,
    });
    setValue(null);
    setSearchText("");
    // setSelectedOccupation({
    //   value: "",
    //   label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.OCCUPATION" })}`,
    // });
  };
  // const handleMemberChange = (Event: any) => {
  //   formik.setFieldValue("spotID", Event.value);
  //   // setSelectMember({ value: Event.value, label: Event.label });
  //   // setSelectedPackage({
  //   //   value: "",
  //   //   label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.NAME" })}`,
  //   // });
  //   // setBatchid({ value: "", label: "select a batch" });
  // };

  const handlePackageChange = async (Event: any) => {
    formik.setFieldValue("packageID", Event.value);

    setSelectedPackage({ value: Event.value, label: Event.label });
    // console.log("dssdsfsdda",iProgramgrpUUID)
  };
  const handleMemberShipType = async (Event: any) => {
    setSelectMembershipType({
      value: Event.value,
      label: Event.label,
    });
    formik.setFieldValue("membershipType", Event.value);
  };
  const handleDateChoose = (Event: any) => {
    formik.setFieldValue("date_type", Event.value);
    setSelectedDate({
      value: Event.value,
      label: Event.label,
    });
  };
  // const handleEmpChange = (Event: any) => {
  //   formik.setFieldValue("employeeID", Event.value);
  //   setSelectedConsultant({
  //     value: Event.value,
  //     label: Event.label,
  //   });
  // };
  const handleDateReset = (values: any) => {
    setValue(null);
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
  };
  const handleChange = (values: any) => {
    setValue(values);
    if (values && values.length !== 0) {
      formik.setFieldValue("start_date", moment(values[0]).format(dateFormat));
      formik.setFieldValue("end_date", moment(values[1]).format(dateFormat));
    }
  };

  const handlesearchChange = (Event: any) => {
    formik.setFieldValue("search", Event.target.value);
    setSearchText(Event.target.value);
  };

  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };

  // const formattedValue =
  //   value && value.length === 2
  //     ? `${moment(value[0]).format("MM/DD/YYYY")} - ${moment(value[1]).format(
  //         "MM/DD/YYYY"
  //       )}`
  //     : "";
  // console.log("memberlist");
  const getmemberShip = async (spotUUID: any) => {
    const response = await GetPackageDetails(spotUUID);

    setUpdateUser(response?.data?.data[0]);

    // setInvoiceShow(false);
  };
  return (
    <>
      {/* Filter Section Start */}
      {isFilter && (
        <div className="card my-4">
          <div className="card-header">
            {" "}
            <Card className="w-100">
              <Card.Body className="py-5">
                <Form onSubmit={formik.handleSubmit}>
                  {/* <Col md={4} className="mb-4">
                      <Form.Group controlId="search">
                        <Form.Label>Search by Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder="Enter name"
                          onChange={(e) => setSearchValue(e.target.value)}
                          value={searchValue}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={3} className="mb-4">
                      <Form.Group controlId="status">
                        <Form.Label>
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}
                        >
                          <option value="">
                            {intl.formatMessage({ id: "COMMON.ALL" })}
                          </option>
                          <option value="1">
                            {" "}
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {" "}
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col> */}
                  <Row>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="packageUUID" className="form-label">
                          {intl.formatMessage({ id: "MODULE.MEMBERS.PACKAGE" })}
                        </label>
                        <Select
                          id="packageUUID"
                          name="packageUUID"
                          className="basic-single"
                          value={selectedPackage}
                          options={activePackageList.map((item: any) => ({
                            value: item.iProgramID,
                            label: item.vProgram,
                          }))}
                          onChange={(e) => handlePackageChange(e)}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-5 ">
                      <label htmlFor="membershipType" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBER.MEMBERSHIPTYPE",
                        })}
                      </label>
                      <Select
                        id="membershipType"
                        name="membershipType"
                        className="basic-single"
                        value={selectMembershipType}
                        options={activeMemberShip?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                        onChange={(e) => handleMemberShipType(e)}
                      />
                    </Col>

                    {/* <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="spotID" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERSHIP.MEMBER",
                          })}
                        </label>
                        <Select
                          id="spotID"
                          name="spotID"
                          className="basic-single"
                          value={selectMember}
                          options={activeMemberList?.map((item: any) => ({
                            value: item.iSpotID,
                            label: item.name,
                          }))}
                          onChange={(e) => handleMemberChange(e)}
                        />
                      </div>
                    </Col> */}
                    <Col md={3} className="mb-3">
                      <label htmlFor="date_type" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.EMPLOYEE.DATEOPTION",
                        })}
                      </label>
                      <Select
                        id="date_type"
                        name="date_type"
                        className="basic-single"
                        value={selectedDate}
                        options={dateOption.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                        onChange={(e) => handleDateChoose(e)}
                      />
                    </Col>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.DATERANGE",
                          })}
                        </label>

                        <DateRangePicker
                          value={value}
                          onChange={handleChange}
                          format="MM/dd/yyyy"
                          placeholder="Select Date Range"
                          placement="auto"
                          style={{ margin: "2px" }}
                          onClean={handleDateReset}
                        />
                      </div>
                    </Col>
                    {/* <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="batch" className="form-label">
                          {intl.formatMessage({ id: "MODULE.MEMBERS.BATCH" })}
                        </label>
                        <Select
                          className=" basic-single"
                          id="batchID"
                          name="batchID"
                          value={batchid}
                          options={batchData?.map((item: any) => ({
                            value: item.iBatchID,
                            label: item.batchName,
                          }))}
                          onChange={(e) => handleBatchID(e)}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="employeeID" className="form-label">
                          {intl.formatMessage({
                            id: "MDOULE.MEMBERS.EMPLOYEE",
                          })}
                        </label>
                        <Select
                          className=" basic-single"
                          id="employeeID"
                          name="employeeID"
                          value={selectConsultant}
                          options={activeEmpList.map((item: any) => ({
                            value: item.iEmployeeID,
                            label: item.vEFirstname,
                          }))}
                          onChange={(e) => handleEmpChange(e)}
                        />
                      </div>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="search" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.SEARCHLABEL",
                          })}
                        </label>
                        <input
                          className=" form-control"
                          id="search"
                          name="search"
                          placeholder={intl.formatMessage({
                            id: "MODULE.EXPIREPACKAGE.SEARCHLABEL",
                          })}
                          value={searchText}
                          onChange={(e) => handlesearchChange(e)}
                        />
                      </div>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <Button
                        type="submit"
                        variant="primary"
                        className="me-3 mr-4 w-25"
                        // onClick={(e) => handleSearchClick(e)}
                        disabled={isLoading}
                      >
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleReset}
                        className="me-3 w-25"
                      >
                        Reset
                      </Button>
                    </Col>
                    {/* <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({ id: "COMMON.GENDER" })}
                        </label>
                        <Select
                          id="gender"
                          name="gender"
                          className="basic-single"
                          value={selectedGender}
                          options={gender.map((item: any) => ({
                            value: item.key,
                            label: item.value,
                          }))}
                          onChange={(e) => handleGenderChange(e)}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="martialStatus" className="form-label">
                          {intl.formatMessage({ id: "COMMON.MARITALSTATUS" })}
                        </label>

                        <Select
                          id="maritalStatus"
                          name="maritalStatus"
                          value={selectedMarital}
                          options={maritalStatus.map((item: any) => ({
                            value: item?.key,
                            label: item?.value,
                          }))}
                          onChange={(e) => handleMaritalStatus(e)}
                        />
                      </div>
                    </Col>

                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </label>
                        <Select
                          id="status"
                          name="status"
                          className="basic-single"
                          onChange={(e) => handleStatusChange(e)}
                          // onBlur={formik.handleBlur("status")}
                          value={activityStatus}
                          options={StatusOption.map((item: any) => ({
                            value: item?.value,
                            label: item?.label,
                          }))}
                        />
                        
                      </div>
                    </Col> */}
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      {/* Filtsser Section End */}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        {/* begin::Header */}
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div
                className="d-flex  flex-wrap gap-1"
                data-kt-user-table-toolbar="base"
              >
                {checkedRows?.length > 0 ? (
                  <>
                    <div className="fw-bold me-5">
                      <span className="me-2"> {checkedRows?.length}</span>
                      {intl.formatMessage({ id: "COMMON.SELECTED" })}
                    </div>

                    <>
                      <button
                        type="button"
                        className="btn btn-success me-3"
                        onClick={() =>
                          UpdateUserStatus({
                            status: "1",
                            message: "Activated",
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className="btn btn-light-warning me-3"
                        onClick={() =>
                          UpdateUserStatus({
                            status: "0",
                            message: " Inactivated",
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger me-3"
                        onClick={() =>
                          UpdateUserStatus({
                            status: "2",
                            message: "deleted",
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.DELETE" })}
                      </button>
                    </>
                  </>
                ) : (
                  <>
                    <span
                      className="min-w-40px mb-3 d-flex  align-items-center"
                      style={{ color: "#7e8299", fontSize: "14px" }}
                    >
                      {intl.formatMessage({ id: "COMMON.SHOW" })}
                    </span>
                    <div>
                      <select
                        className="form-control form-select form-select-solid me-3 mb-4"
                        value={pageLimit}
                        onChange={(e) => getPageLimit(e.target.value)}
                      >
                        {finalPageLimitArray?.map((item: any, i = 0) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <h1 className="text-dark px-5 mt-2">
                      {intl.formatMessage({
                        id: "MODULE.EXPENSECATEGORY.OUTSTANDING",
                      })}
                      {totalDueAMT}
                    </h1>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex  flex-wrap gap-1"
              data-kt-user-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-primary me-3"
                onClick={handleRefreshButton}
              >
                {intl.formatMessage({ id: "COMMON.REFRESH" })}
              </button>

              {checkedRows?.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>

                  <Button
                    variant="primary"
                    onClick={() => {
                      // handleShow();
                      // setisUpdate(false);
                    }}
                  >
                    {intl.formatMessage({
                      id: "MODULE.DEPARTMENT.ADDDEPARTMENT",
                    })}
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="btn btn-light-primary me-3"
                    >
                      <KTSVG
                        path="/media/icons/duotune/files/fil021.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                      {intl.formatMessage({ id: "MODULE.BRANCH.EXPORT" })}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4">
                      <CSVLink
                        data={TBLData || []}
                        filename={downloadfileName + ".csv"}
                        className="menu-item px-3"
                      >
                        <span className="px-3">
                          {intl.formatMessage({ id: "COMMON.EXPORTCSV" })}
                        </span>
                      </CSVLink>
                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={() => exportToXLS(TBLData, downloadfileName)}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTEXCEL" })}
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={exportToPDF}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTPDF" })}
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* This is workable button of adding */}

                  {/* <Button
                    variant="primary"
                    onClick={() => {
                      // handleShow();
                      // setisUpdate(false);
                      setMemberUID(null);
                      setShow(true);
                    }}
                  >
                    {intl.formatMessage({
                      id: "MODULE.MEMBER.ADDONS.ADD",
                    })}
                  </Button> */}
                </>
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* modal start */}
        {/* modal start */}

        <MemberShipModal
          show={show}
          setShow={setShow}
          getMembers={getMembers}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          activeEmpList={activeEmpList}
          activeOccList={activeOccList}
          activePackageList={activePackageList}
          gender={gender}
          maritalStatus={maritalStatus}
          UpdatUser={UpdateUser}
        />

        {/* //Addons model */}
        {/* <AddOnsModel
          addModalShow={addModalShow}
          setAddModalShow={setAddModalShow}
          activeMemberList={activeMemberList}
          activePackageList={activePackageList}
          activeBatchList={activeBatchList}
          memberUID={memberUID}
          getMembers={getMembers}
        /> */}
        {/* <AddOnUpdateModal
          updateModalShow={updateModalShow}
          setUpdateModalShow={setUpdateModalShow}
          activeMemberList={activeMemberList}
          UpdateUser={UpdateUser}
          activePackageList={activePackageList}
          activeBatchList={activeBatchList}
          memberUID={memberUID}
          getMembers={getMembers}
        /> */}
        {/* <InvoiceModal
          show={show}
          setShow={setShow}
          activeUser={activeUser}
          activePackageList={activePackageList}
          activeBatchList={activeBatchList}
          activeEmpList={activeEmpList}
        /> */}
        {/* <RenewalModal
          renewalShow={renewalShow}
          setRenewalShow={setRenewalShow}
          memberUID={memberUID}
          activeUser={activeUser}
          activePackageList={activePackageList}
          activeBatchList={activeBatchList}
        /> */}
        {/* <AddUpdateRenewalModal
          updateModalShow={updateModalShow}
          setUpdateModalShow={setUpdateModalShow}
          memberUID={memberUID}
          activeUser={activeUser}
          activePackageList={activePackageList}
          activeBatchList={activeBatchList}
          UpdateUser={UpdateUser}
          getMembers={getMembers}
        /> */}
        {/* <FreezeModal
          addFreezeShow={addFreezeShow}
          setAddFreezeShow={setAddFreezeShow}
          memberUID={memberUID}
          activePackageList={activePackageList}
          activeBatchList={activeBatchList}
        /> */}
        {/* {console.log("freezee", addFreezeShow)} */}
        {/* <Main show={show} setShow={setShow}/> */}
        {/* modal End */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Table container */}
          {/* {isLoading && <Loading />} */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="memberName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {firstColumnTitle}
                      {sortConfig.key !== "memberName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="memberName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {secondColumnTitle}
                      {sortConfig.key !== "memberName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="eEnrol_Type"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumnTitle}
                      {sortConfig.key !== "eEnrol_Type" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "eEnrol_Type" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "eEnrol_Type" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="packageName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fourthColumnTitle}
                      {sortConfig.key !== "packageName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtJoin"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fifthColumnTitle}
                      {sortConfig.key !== "dtJoin" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtJoin" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtJoin" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtStartingdate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumnTitle}
                      {sortConfig.key !== "dtStartingdate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtStartingdate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtStartingdate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtExpirydate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumnTitle}
                      {sortConfig.key !== "dtExpirydate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtExpirydate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtExpirydate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="tatalpayableAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eigthColumnTitle}
                      {sortConfig.key !== "tatalpayableAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "tatalpayableAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "tatalpayableAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="fDue"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumnTitle}
                      {sortConfig.key !== "fDue" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "fDue" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "fDue" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  {/* <th className="min-w-100px">{tenthColumnTitle}</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredData && filteredData?.length === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      No data found
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((element: any, index: number) => {
                    // setUUID(element.iEnrolUUID);
                    const isChecked = checkedRows.includes(element.iEnrolUUID);
                    // console.log("uidofmember", element);
                    return (
                      <tr key={index} className="fs-5">
                        {/* <td data-label={firstColumnTitle}>
                            <div className="d-flex align-items-center border-0">
                              <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                <div className="symbol-label">
                                  <img
                                    src={
                                      element.vPhoto
                                        ? `${baseURL}${element.vPhoto}`
                                        : Dummy
                                    }
                                    width={50}
                                    height={50}
                                    className="w-100"
                                  />
                                </div>
                              </div>

                              <div className="d-flex flex-column">
                                {element.memberName}
                                <span>
                                  <a
                                    href={`tel:${
                                      element.MemberCountryCode +
                                      element.memnerMobileNo
                                    }`}
                                  >
                                    {element.MemberCountryCode}-
                                    {element.memnerMobileNo}
                                  </a>
                                </span>
                              </div>
                            </div>
                          </td> */}
                        {/* <td data-label={firstColumnTitle}>
                            {element.memberName}
                            <a
                              href={`tel:${element.MemberCountryCode}-${element.memnerMobileNo}`}
                            >{`${element.MemberCountryCode}-${element.memnerMobileNo}`}</a>
                          </td>
                          <td data-label={secondColumnTitle}>
                            {element.vPhoto && (
                              <img
                                src={`${baseURL}${element.vPhoto}`}
                                width={50}
                                height={50}
                              />
                            )}
                          </td> */}
                        <td data-label={thirdColumnTitle}>
                          {element.eEnrol_Type}
                        </td>
                        <td data-label={fourthColumnTitle}>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              setShow(true);
                              // setMemberUID(element.iEnrolUUID);
                              getmemberShip(element.iEnrolUUID);
                              setisUpdate(true);
                            }}
                            title="click for package"
                          >
                            {element.packageName}
                          </a>
                        </td>
                        <td data-label={fifthColumnTitle}>
                          {element.dtJoin?.split("T")[0]}
                        </td>
                        <td data-label={sixthColumnTitle}>
                          {element.dtStartingdate?.split("T")[0]}
                        </td>
                        <td data-label={seventhColumnTitle}>
                          {element.dtExpirydate?.split("T")[0]}
                        </td>
                        <td data-label={eigthColumnTitle}>
                          {element.tatalpayableAmt}
                        </td>
                        <td data-label={ninthColumnTitle}>{element.fDue}</td>
                        {/* <td>
                            {new Date(element.dtExpirydate).toLocaleString(
                              "en-US",
                              {
                                dateStyle: "medium",
                              }
                            )}
                          </td> */}
                        {/* <td> {StatusBadge(element.eStatus)}</td> */}
                      </tr>
                    );
                  })
                )}
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  {/* <th className="min-w-100px notVisibleInMobile">
                   <div
                     className="d-flex justify-content-between cursor-pointer"
                     data-key="memberName"
                     onClick={(e) => {
                       handleSort(e);
                     }}
                   >
                     {firstColumnTitle}
                     {sortConfig.key !== "memberName" ? (
                       <div>
                         <i className="bi bi-arrow-down-up"></i>
                       </div>
                     ) : (
                       <div>
                         <i
                           className={`bi bi-arrow-up  ${
                             sortConfig.key == "memberName" &&
                             sortConfig.direction == "asc"
                               ? "d-block text-primary fw-bold fs-5"
                               : "d-none"
                           }`}
                         ></i>
                         <i
                           className={`bi bi-arrow-down  ${
                             sortConfig.key == "memberName" &&
                             sortConfig.direction == "desc"
                               ? "d-block text-primary fw-bold fs-5"
                               : "d-none"
                           }`}
                         ></i>
                       </div>
                     )}
                   </div>
                 </th> */}
                  {/* <th className="min-w-100px notVisibleInMobile">
                   <div
                     className="d-flex justify-content-between cursor-pointer"
                     data-key="memberName"
                     onClick={(e) => {
                       handleSort(e);
                     }}
                   >
                     {secondColumnTitle}
                     {sortConfig.key !== "memberName" ? (
                       <div>
                         <i className="bi bi-arrow-down-up"></i>
                       </div>
                     ) : (
                       <div>
                         <i
                           className={`bi bi-arrow-up  ${
                             sortConfig.key == "memberName" &&
                             sortConfig.direction == "asc"
                               ? "d-block text-primary fw-bold fs-5"
                               : "d-none"
                           }`}
                         ></i>
                         <i
                           className={`bi bi-arrow-down  ${
                             sortConfig.key == "memberName" &&
                             sortConfig.direction == "desc"
                               ? "d-block text-primary fw-bold fs-5"
                               : "d-none"
                           }`}
                         ></i>
                       </div>
                     )}
                   </div>
                 </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="eEnrol_Type"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumnTitle}
                      {sortConfig.key !== "eEnrol_Type" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "eEnrol_Type" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "eEnrol_Type" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="packageName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fourthColumnTitle}
                      {sortConfig.key !== "packageName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtJoin"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fifthColumnTitle}
                      {sortConfig.key !== "dtJoin" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtJoin" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtJoin" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtStartingdate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumnTitle}
                      {sortConfig.key !== "dtStartingdate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtStartingdate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtStartingdate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtExpirydate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumnTitle}
                      {sortConfig.key !== "dtExpirydate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtExpirydate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtExpirydate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="tatalpayableAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eigthColumnTitle}
                      {sortConfig.key !== "tatalpayableAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "tatalpayableAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "tatalpayableAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="fDue"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumnTitle}
                      {sortConfig.key !== "fDue" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "fDue" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "fDue" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  {/* <th className="min-w-100px">{tenthColumnTitle}</th> */}
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {/* begin:: pagination  */}
          {/* <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination">
                  <li className="page-item previous">
                    <a
                      className={`page-link page-text me-5 paginate_button page-item previous ${
                        page === 1 ? "disable" : ""
                      } `}
                      style={{ cursor: "pointer" }}
                      onClick={() => selectPageHandler(page - 1)}
                    >
                      Previous
                    </a>
                  </li>
                  {[...Array(totalPages)].map((_, i) => {
                    return (
                      <li
                        className={
                          page === i + 1 ? "page-item active" : "page-item"
                        }
                        onClick={() => selectPageHandler(i + 1)}
                        key={i}
                      >
                        <a className="page-link" style={{ cursor: "pointer" }}>
                          {i + 1}
                        </a>
                      </li>
                    );
                  })}

                  <li
                    className={`paginate_button page-item next ${
                      page === totalPages ? "disable" : ""
                    } `}
                  >
                    <a
                      className="page-link page-text"
                      style={{ cursor: "pointer" }}
                      onClick={() => selectPageHandler(page + 1)}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
          {/* end:: pagination */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  );
};

export default DueAmountModal;
