/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
// import EnquiryStatusModal from "./modal/EnquiryStatusModal";

import * as purchaseProduct from "../../../modules/newPos/modules/purchase_Prodcut/redux/purchaseProductRedux";

// import Logo from "../../../.../../../loading_assests/logormbg.png";
import swal from "sweetalert";
import { useIntl } from "react-intl";
import { Form, Row, Col, Card, Button, Dropdown } from "react-bootstrap";

import { CSVLink } from "react-csv";
// import PackagePeriodModal from "./modal/EnquiryStatusModal";
import {
  // DateFormate,
  KTSVG,
  // StatusBadge,
  // WeekBADGES,
} from "../../../../_metronic/helpers";
// import {
//   GetEnquiryStatus,
//   Update_EnquiryStatus_Status,
//   Delete_EnquiryStatus,
//   enquiryActiveList,
// } from "../redux/followUpCRUD";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Pagination from "../../../modules/GenricComponant/Pagination";
// import { Direction } from "react-toastify/dist/utils";
// import AddFollowUpModal from "../../enquiry/component/modal/AddFollowUpModal";
import { exportToXLS } from "../../../modules/GenricComponant/exportToxls/exportToxls";
// import { GetFollowUpDetails } from "../../enquiry/redux/EnquiryCRUD";
// import FollowUpModal from "./modal/FollowUpModal";
import permission from "../../../modules/GenricComponant/permissions/permission";
// import DateRangePicker from "rsuite/esm/DateRangePicker";
import moment from "moment";
import {
  deletePurchaseProduct,
  getActiveSupplier,
  getDetailsOfPurchaseProduct,
  // deleteReminder,
  // getDetailsOfReminder,
  GetPurchaseProductList,
  // GetDietPlanList,GetPurchaseProductList
  UpdateStatus,
} from "../../../modules/newPos/modules/purchase_Prodcut/redux/PurchaseProductCRUD";
// import PurchaseProductModal from "./Modal/PurchaseProductModal";
// import DetailsModal from "./Modal/DetailsModal";
// import PaymentModal from "./Modal/PaymentModal";
// import InvoiceModal from "./Modal/InvoiceModal";
// import PlanModal from "./Modal/PlanModal";
// import PlanModal from "../../../plans/component/PlanModal";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: object) => jsPDF;
  }
}

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const ListPurchaseProduct = ({ selectedDate }: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "dtModified_Date", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  const purchaseProducts = useSelector(
    (state: any) => state?.purchaseProduct?.purchaseProduct?.data
  );

  // active branch data taken from local storage
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("purchaseproduct");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });

  //   const [UpdateUser, setUpdateUser] = useState<any>();
  const [selectSupplier, setSelectSupplier] = useState<any>("");
  const [SupplierOpt, setSupplierOpt] = useState<any>([]);
  //   const [error, setError] = useState<any>("");
  //page state
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);

  const startDate = selectedDate?.startDate;
  const endDate = selectedDate?.endDate;
  console.log(selectedDate, "purchasemodal");
  let initialState = {
    branchID: ActiveBranch.iBranchID,
    page_limit: pageLimit,
    status: "",
    page_no: "1",
    search: "",
    start_date: startDate ? startDate : "",
    end_date: endDate ? endDate : "",
    order_key: "dtModified_Date",
    order_value: "desc",
  };

  //   const [show, setShow] = useState<any>(false);
  const [payShow, setPayShow] = useState<any>(false);
  //   const [InvoiceShow, setInvoiceShow] = useState<any>(false);
  //   const [ShowDetails, setShowDetails] = useState<any>(false);
  //   const [Detailshow, setDetailsShow] = useState<any>(false);
  //   const [title, setTitle] = useState<any>("");
  //   const handleShow = () => {
  //     setShow(true);
  //     setIsfollowup(true);
  //   };

  //   const [isUpdate, setisUpdate] = useState(false);
  //for check box
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [isAllRowsChecked, setIsAllRowsChecked] = useState<any>(false);
  const [branch_id, setBranch_id] = useState<any>("0");
  // for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // for error
  //   const [error, setError] = useState("");
  const [isFilter, setisFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState(initialState);
  const [searchValues, setSearchValues] = useState(initialState);
  const [filteredData, setFilteredData] = useState<any>([]);
  //   const [enqOption, setEnqOption] = useState<any>([]);
  //   const [isLoading, setIsLoading] = useState(false);
  //   const [isFollowup, setIsfollowup] = useState(false);
  //   const [updateFollowUpUser, setUpdateFollowUpUser] = useState<any>({});
  const [dateFormat, setdateFormat] = useState<any>(ActiveBranch?.idateFormate);
  const initialDates = [new Date(), new Date()];
  const [value, setValue] = React.useState<any>();
  const [TBLData, setTBLData] = useState<any>([]);
  //   const [Selectdata, setSelectData] = useState<any>([]);

  //define column of listing
  const column1 = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.ID",
  })}`;

  const column2 = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.SUPPLIER",
  })}`;
  const column3 = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.DATE",
  })}`;
  const column4 = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.AMOUNT",
  })}`;
  const column5 = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.TAXAMT",
  })}`;
  const column6 = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.FINALAMT",
  })}`;
  const column7 = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.REMAINAMT",
  })}`;
  const column8 = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.TOTALQUANTITY",
  })}`;
  const column10 = `${intl.formatMessage({
    id: "MODULE.SUPPLEMENTPRD.HEADING",
  })}`;
  const column9 = `${intl.formatMessage({ id: "COMMON.ACTIONS" })}`;

  //download fileName
  const downloadfileName = "purchase_" + Date.now();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: (values) => {
      setFilterValues(values);

      setPage(1);
    },
  });
  const getReminder = useCallback(() => {
    if (UserType === "admin" || view !== 0 || UserType === "channelPartner") {
      // setIsLoading(true);
      GetPurchaseProductList({
        ...filterValues,
        page_no: page,
        page_limit: pageLimit,
        order_key: sortConfig.key,
        order_value: sortConfig.direction,
      }).then((data: any) => {
        // console.log(data.data.totalRecords, "dataforenqui");
        if (data?.status !== 200) {
          setFilteredData([]);
          // setIsLoading(false);
        } else {
          dispatch(purchaseProduct?.actions?.GetPurchaseProduct(data));
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          // setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, filterValues, page, sortConfig, view]);

  useEffect(() => {
    getReminder();
  }, [getReminder, payShow]);

  useEffect(() => {
    setFilteredData(purchaseProducts?.data);
    handleExports();
  }, [purchaseProducts?.data]);

  useEffect(() => {
    enquiryList(ActiveBranch.iBranchUUID);
    GetActiveSupplierList(ActiveBranch.iBranchUUID);
  }, []);

  const GetActiveSupplierList = async (branchUUID: any) => {
    const response = await getActiveSupplier(branchUUID);
    setSupplierOpt(response?.data?.data);
    // setTableData(response?.data?.data);
  };
  const enquiryList = async (data: any) => {
    // const response = await enquiryActiveList(data);
    // setEnqOption(response?.data?.data);
  };
  // update Role Status
  const UpdateUserStatus = (prop: any) => {
    swal({
      title: `${intl.formatMessage({ id: "COMMON.AREYOUSURE" })}`,
      text: intl.formatMessage({ id: "COMMON.NOTPERFORMOPERATION" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SUBMIT" }),
      ], //["Cancel", "Submit"],
      dangerMode: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        const Update_user = {
          branchID: branch_id,
          uuid_array: checkedRows,
          select_type: "",
          unchecked_uuid_array: [],
          status: prop.status,
        };
        if (prop.status !== "2") {
          UpdateStatus(Update_user)
            .then((data: any) => {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });
              setCheckedRows([]);
              setIsAllRowsChecked(false);
              //getting all roled again
              getReminder();
            })
            .catch(() => {
              // swal("Somthing Went Wrong!");
              swal(
                `${intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" })}`
              );
            });
        } else {
          deletePurchaseProduct(Update_user)
            .then((data: any) => {
              //swal(`Proof! Selected Users Is ${prop.message}!`, {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });
              //getting all roled again
              setCheckedRows([]);
              setIsAllRowsChecked(false);
              getReminder();
            })
            .catch(() => {
              swal(intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" }));
            });
        }
      } else {
        swal(`${intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" })}`);
      }
    });
  };

  //handle check box

  const handleRowCheckboxChange = (rowId: any, branchId: any) => {
    setBranch_id(branchId);
    if (checkedRows.includes(rowId)) {
      setCheckedRows(checkedRows.filter((id: any) => id !== rowId));
    } else {
      setCheckedRows([...checkedRows, rowId]);
    }
  };
  // handle all check box
  const handleSelectAllCheckboxChange = () => {
    setBranch_id(ActiveBranch.iBranchID);
    if (isAllRowsChecked) {
      setCheckedRows([]);
      setIsAllRowsChecked(false);
    } else {
      const allRowIds =
        purchaseProducts?.data?.map(
          (element: any) => element.isupplierProductUUID
        ) || [];
      //console.log('packageperiods====',packageperiods);
      //console.log('allRowIds====',allRowIds);
      setCheckedRows(allRowIds);
      setIsAllRowsChecked(true);
    }
  };

  const handleReset = () => {
    formik.resetForm();
    setFilterValues(initialState);
    setSelectSupplier("");
    setValue(null);
  };

  const handleExports = () => {
    const tableRows = purchaseProducts?.data?.map(
      (element: any, index: number) => {
        return {
          [column1]: element?.isupplierPurchaseDetailID,
          [column2]:
            element.supplierName &&
            ` ${element.supplierName} (${element.countryCode}-${element.mobileno})`,
          [column3]:
            element.purchaseDate &&
            `${element.purchaseDate} ${moment(
              element.PurchaseTime,
              "HH:mm"
            ).format("hh:mm A")}`,
          [column8]: element?.totalQuantity,
          [column10]: element.sellProducts?.length
            ? element.sellProducts
                .map((item: any) => item.ProductName)
                .filter(Boolean)
                .join(", ")
            : "",
          [column4]: element?.totalPurchasePrice,
          [column5]: element?.taxesAmount,
          [column6]: element?.finalAmt,
          [column7]: element?.remainingAmt,
        };
      }
    );

    setTBLData(tableRows);
  };

  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    doc.text(`${intl.formatMessage({ id: "MODULE.FOLLOWUP" })}`, 10, 10);
    const tableRows = purchaseProducts.data.map(
      (element: any, index: number) => {
        return [
          element.isupplierPurchaseDetailID,
          element.supplierName &&
            ` ${element.supplierName} (${element.countryCode}-${element.mobileno})`,
          element.purchaseDate &&
            `${element.purchaseDate} ${moment(
              element.PurchaseTime,
              "HH:mm"
            ).format("hh:mm A")}`,
          element.totalQuantity,
          element.sellProducts?.length
            ? element.sellProducts
                .map((item: any) => item.ProductName)
                .filter(Boolean)
                .join(", ")
            : "",
          element.totalPurchasePrice,
          element.taxesAmount,
          element.finalAmt,
          element.remainingAmt,
          /*new Date(element.dtCreated_Date).toLocaleString("en-US", {
          dateStyle: "medium",
          timeStyle: "short",
        }),*/
        ];
      }
    );
    doc.autoTable({
      head: [
        [
          column1,
          column2,
          column3,
          column8,
          column10,
          column4,
          column5,
          column6,
          column7,
        ],
      ],
      body: tableRows,
      startY: 50,
    });

    doc.save(downloadfileName + ".pdf");
  };

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
  };

  const handleRefreshButton = () => {
    // setIsLoading(true);
    setCheckedRows([]);
    setPage(1);
    setPageLimit(pageDefaultValue);
    setSearchValues(initialState);
    setSortConfig({ key: "dtModified_Date", direction: "desc" });
    // GetEnquiryStatus({
    //   ...searchValues,
    //   page_no: 1,
    //   page_limit: pageDefaultValue,
    //   order_key: sortConfig.key,
    //   order_value: sortConfig.direction,
    // }).then((data: any) => {
    //   dispatch(GetFollowUp.actions.GetPackagePeriods(data));
    //   setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
    //   setIsLoading(false);
    // });
  };
  const handlePurchaseProductDetails = async (
    branchID: any,
    iReminderUUID: any
  ) => {
    const apiResult = await getDetailsOfPurchaseProduct(
      branchID,
      iReminderUUID
    );
    // console.log(apiResult, "response");
    // setUpdateUser(apiResult?.data?.data);
  };
  const handleDateReset = (values: any) => {
    setValue(null);
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
  };
  const handleChange = (values: any) => {
    setValue(values);
    // console.log(values,)
    if (values && values.length !== 0) {
      formik.setFieldValue("start_date", moment(values[0]).format(dateFormat));
      formik.setFieldValue("end_date", moment(values[1]).format(dateFormat));
    }
  };

  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };

  //   const handleShowModal = (title: any) => {
  //     setTitle(title);
  //     setPayShow(true);
  //   };
  const handleSelectSupplierChange = async (e: any) => {
    setSelectSupplier({ value: e.value, label: e.label });
    formik.setFieldValue("supplierID", e.value);
  };
  return (
    <>
      {/* Filter Section Start of Package Period List */}
      {isFilter && (
        <div className="card my-4">
          <div className="card-header">
            <Card className="w-100">
              <Card.Body className="py-5">
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col md={3} className="mb-3">
                      <label htmlFor="enquiryID" className="form-label">
                        {intl.formatMessage({
                          id: "POS.PURCHASEPRODUCT.SUPPLIER",
                        })}
                      </label>
                      <Select
                        className="basic-single w-100"
                        name={`supplierID`}
                        value={selectSupplier}
                        onChange={(e) => handleSelectSupplierChange(e)}
                        options={SupplierOpt?.map((item: any) => {
                          // console.log(item, "aray");
                          return {
                            value: item?.isupplierID,
                            label: item?.supplierName,
                          };
                        })}
                      />
                    </Col>
                    {/* <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.DATERANGE",
                          })}
                        </label>

                        <DateRangePicker
                          value={value}
                          onChange={handleChange}
                          format="MM/dd/yyyy"
                          placeholder="Select Date Range"
                          placement="auto"
                          style={{ margin: "2px" }}
                          onClean={handleDateReset}
                        />
                      </div>
                    </Col> */}
                    <Col md={3} className="mb-4">
                      <Form.Group controlId="search">
                        <Form.Label>
                          {intl.formatMessage({
                            id: "MODULE.ENQUIRYSTATUS.SEARCH",
                          })}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder={intl.formatMessage({
                            id: "MODULE.ENQUIRYSTATUS.ENTERSEARCH",
                          })}
                          onChange={formik.handleChange}
                          value={formik.values.search}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={3} className="mb-4">
                      <Form.Group controlId="status">
                        <Form.Label>
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={formik.handleChange}
                          value={formik.values.status}
                        >
                          <option value="">
                            {intl.formatMessage({ id: "COMMON.ALL" })}
                          </option>
                          <option value="1">
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    {/* Rest of the form fields */}
                    <Col
                      md={3}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <Button type="submit" variant="primary" className="me-3 ">
                        {intl.formatMessage({
                          id: "COMMON.APPLY",
                        })}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleReset}
                        className="me-3"
                      >
                        {intl.formatMessage({
                          id: "COMMON.RESET",
                        })}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      {/* Filtsser Section End */}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div
                className="d-flex flex-wrap gap-1"
                data-kt-user-table-toolbar="base"
              >
                {checkedRows?.length > 0 ? (
                  <>
                    <div className="fw-bold me-5">
                      <span className="me-2"> {checkedRows?.length}</span>
                      {intl.formatMessage({ id: "COMMON.SELECTED" })}
                    </div>
                  </>
                ) : (
                  <>
                    <span
                      className="min-w-40px mb-3 d-flex align-items-center"
                      style={{ color: "#7e8299", fontSize: "14px" }}
                    >
                      {intl.formatMessage({ id: "COMMON.SHOW" })}
                    </span>
                    <div>
                      <select
                        className="form-control form-select form-select-solid me-3 mb-4"
                        value={pageLimit}
                        onChange={(e) => getPageLimit(e.target.value)}
                      >
                        {finalPageLimitArray?.map((item: any, i = 0) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex flex-wrap gap-1"
              data-kt-user-table-toolbar="base"
            >
              {checkedRows?.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3 mb-2"
                    onClick={handleRefreshButton}
                  >
                    {intl.formatMessage({ id: "COMMON.REFRESH" })}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary me-3 mb-2"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                  <Dropdown className="mb-2">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="btn btn-light-primary me-3"
                    >
                      <KTSVG
                        path="/media/icons/duotune/files/fil021.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                      {intl.formatMessage({ id: "MODULE.BRANCH.EXPORT" })}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4">
                      <CSVLink
                        data={TBLData || []}
                        filename={downloadfileName + ".csv"}
                        className="menu-item px-3"
                      >
                        <span className="px-3">
                          {intl.formatMessage({ id: "COMMON.EXPORTCSV" })}
                        </span>
                      </CSVLink>
                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={() => exportToXLS(TBLData, downloadfileName)}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTEXCEL" })}
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={exportToPDF}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTPDF" })}
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* modal start */}
        {/* modal start */}

        {/* <PurchaseProductModal
          show={show}
          setShow={setShow}
          isUpdate={isUpdate}
          setIsUpdate={setisUpdate}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          getList={getReminder}
        />
        <DetailsModal
          show={ShowDetails}
          setShow={setShowDetails}
          UpdateUser={UpdateUser}
        />
        <PaymentModal
          show={payShow}
          setShow={setPayShow}
          title={title}
          setTitle={setTitle}
          data={Selectdata}
          InvoiceShow={InvoiceShow}
          setInvoiceShow={setInvoiceShow}
        />
        <InvoiceModal
          show={InvoiceShow}
          setShow={setInvoiceShow}
          payShow={payShow}
          setPayShow={setPayShow}
          data={Selectdata}
          setTitle={setTitle}
          title={title}
          getList={getReminder}
        /> */}
        {/* <FollowUpModal
          show={show}
          setShow={setShow}
          isFollowup={isFollowup}
          setIsfollowup={setIsfollowup}
          isUpdate={isUpdate}
          enqOption={enqOption}
          updateFollowUpUser={updateFollowUpUser}
        /> */}
        {/* <FoodItemModal
          show={show}
          setShow={setShow}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          GetList={getReminder}
          error={error}
          setError={setError}
        /> */}
        {/* <Main show={show} setShow={setShow}/> */}

        {/* modal End */}

        {/* begin::Body */}

        <div className="card-body py-3">
          {/* {isLoading && <Loading />} */}
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="isupplierPurchaseDetailID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column1}
                      {sortConfig.key !== "isupplierPurchaseDetailID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "isupplierPurchaseDetailID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "isupplierPurchaseDetailID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-200px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="supplerID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column2}
                      {sortConfig.key !== "supplerID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "supplerID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "supplerID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="purchaseDate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column3}
                      {sortConfig.key !== "purchaseDate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "purchaseDate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "purchaseDate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalQuantity"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column8}
                      {sortConfig.key !== "totalQuantity" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">{column10}</th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalPurchasePrice"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column4}
                      {sortConfig.key !== "totalPurchasePrice" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalPurchasePrice" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalPurchasePrice" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="taxesAmount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column5}
                      {sortConfig.key !== "taxesAmount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "taxesAmount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "taxesAmount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="finalAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column6}
                      {sortConfig.key !== "finalAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="remainingAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column7}
                      {sortConfig.key !== "remainingAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "remainingAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "remainingAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">{column9}</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredData && filteredData.length === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                    </td>
                  </tr>
                ) : UserType === "employee" && view === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center text-danger fs-4">
                      {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((element: any, index: number) => {
                    const isChecked = checkedRows.includes(
                      element.isupplierProductUUID
                    );
                    return (
                      <tr key={index} className="fs-5">
                        <td data-label={column1}>
                          {element.isupplierPurchaseDetailID}
                        </td>
                        <td data-label={column2}>
                          <div>{element?.supplierName}</div>
                          {/* <div className="d-flex gap-1">
                            <div>{element.contactPerson}</div>
                          </div> */}
                          <div>
                            <a
                              href={`tel:${
                                element.countryCode + "-" + element.mobileno
                              }`}
                            >
                              ({element.countryCode + "-" + element.mobileno})
                            </a>
                          </div>
                        </td>
                        <td data-label={column3}>
                          <div>{element.purchaseDate}</div>
                          <div>
                            {moment(element.PurchaseTime, "HH:mm").format(
                              "hh:mm A"
                            )}
                          </div>
                        </td>
                        <td data-label={column8}>{element.totalQuantity}</td>{" "}
                        <td data-label={column10}>
                          <div
                            className="text-primary cursor-pointer"
                            // onClick={() => {
                            //   setShowDetails(true);
                            //   handlePurchaseProductDetails(
                            //     element?.iBranchID,
                            //     element.isupplierPurchaseDetailUUID
                            //   );
                            // }}
                          >
                            {element.sellProducts?.[0]?.ProductName}...
                          </div>
                        </td>
                        <td data-label={column4}>
                          {element.totalPurchasePrice}
                        </td>
                        <td data-label={column5}>{element.taxesAmount}</td>
                        <td data-label={column6}>{element.finalAmt}</td>
                        <td data-label={column7}>{element.remainingAmt}</td>
                        {/* <td data-label={column6}>
                              {StatusBadge(element.eStatus)}
                            </td> */}
                        <td data-label={column9}>
                          {/* <div className="d-flex flex-shrink-0 floatRight gap-2">
                            <span
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                view === 0 &&
                                UserType === "employee" &&
                                "d-none"
                              }`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Payment List"
                              onClick={() => {
                                handleShowModal("List");
                                setSelectData(element);
                              }}
                            >
                              <i className="fa-solid fa-receipt me-2"></i>
                            </span>
                            <span
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                view === 0 &&
                                UserType === "employee" &&
                                "d-none"
                              }`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Add Payment"
                              onClick={() => {
                                setInvoiceShow(true);
                                setSelectData(element);
                              }}
                            >
                              <i className="fa-solid fa-credit-card"></i>
                            </span>
                            <span
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                view === 0 &&
                                UserType === "employee" &&
                                "d-none"
                              }`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Details Of Purchase"
                              onClick={() => {
                                setShowDetails(true);
                                handlePurchaseProductDetails(
                                  element?.iBranchID,
                                  element.isupplierPurchaseDetailUUID
                                );
                              }}
                            >
                              <i className="bi bi-eye"></i>
                            </span>
                            <a
                              href="#g"
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                edit === 0 &&
                                UserType === "employee" &&
                                "d-none"
                              }`}
                              title="Update Purchase"
                              onClick={() => {
                                setisUpdate(true);
                                handlePurchaseProductDetails(
                                  element.iBranchID,
                                  element.isupplierPurchaseDetailUUID
                                );
                                setShow(true);
                              }}
                            >
                              <KTSVG
                                path="/media/icons/duotune/art/art005.svg"
                                className="svg-icon-3"
                              />
                            </a>
                          </div> */}
                        </td>
                      </tr>
                    );
                  })
                )}
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="isupplierPurchaseDetailID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column1}
                      {sortConfig.key !== "isupplierPurchaseDetailID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "isupplierPurchaseDetailID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "isupplierPurchaseDetailID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-200px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="supplerID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column2}
                      {sortConfig.key !== "supplerID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "supplerID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "supplerID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="purchaseDate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column3}
                      {sortConfig.key !== "purchaseDate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "purchaseDate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "purchaseDate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalQuantity"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column8}
                      {sortConfig.key !== "totalQuantity" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalQuantity" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">{column10}</th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="totalPurchasePrice"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column4}
                      {sortConfig.key !== "totalPurchasePrice" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "totalPurchasePrice" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "totalPurchasePrice" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="taxesAmount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column5}
                      {sortConfig.key !== "taxesAmount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "taxesAmount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "taxesAmount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="finalAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column6}
                      {sortConfig.key !== "finalAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "finalAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-50px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="remainingAmt"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column7}
                      {sortConfig.key !== "remainingAmt" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "remainingAmt" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "remainingAmt" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">{column9}</th>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {/* begin:: pagination  */}

          {filteredData && filteredData.length > 0 && (
            <Pagination page={page} totalPages={totalPages} setPage={setPage} />
          )}
          {/* end:: pagination */}
        </div>

        {/* begin::Body */}
      </div>
    </>
  );
};

export default ListPurchaseProduct;
