/* eslint-disable jsx-a11y/anchor-is-valid */
// import "./expenseList.css";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
// import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

// import ExpenseCategoryModal from "../../expenceCategory/component/modal/ExpenseCategoryModal";

import * as PosExpenses from "../../../modules/newPos/modules/expense/Redux/ExpenseRedux";

// import Logo from "../../../../../../../src/loading_assests/logormbg.png";
import swal from "sweetalert";
import { useIntl } from "react-intl";
import { Form, Row, Col, Card, Button, Dropdown } from "react-bootstrap";

import { CSVLink } from "react-csv";
// import PackagePeriodModal from "./modal/ExpenseCategoryModal";
import { KTSVG, StatusBadge } from "../../../../_metronic/helpers";
import {
  GetPosExpenses,
  Update_PosExpenseCategory_Status,
  Delete_PosExpenseCategory,
  activePosExpenseCategoryList,
  detailofUpdatePosExpense,
} from "../../../modules/newPos/modules/expense/Redux/ExpenseCRUD";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
// import ExpenseModal from "./modal/PosExpenseModal";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/esm/DateRangePicker";
import moment from "moment";
import Pagination from "../../../modules/GenricComponant/Pagination";
import Select from "react-select";
import { exportToXLS } from "../../../modules/GenricComponant/exportToxls/exportToxls";
import permission from "../../../modules/GenricComponant/permissions/permission";
// import Loading from "../../../../GenricComponant/loading/Loading";
import { ActivePaymentMode } from "../../../modules/finance/modules/invoice/component/redux/invoiceCRUD";
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: object) => jsPDF;
  }
}

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const PosExpenseModal = ({ selectedDate }: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "iposExpenseID", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  const PosExpense = useSelector(
    (state: any) => state?.PosExpenses?.PosExpenses?.data
  );

  // active branch data taken from local storage
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchID = ActiveBranch.iBranchID;
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("expense");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });

  const [dateFormat, setdateFormat] = useState<any>("YYYY-MM-DD");
  const initialDates = [new Date(), new Date()];
  const [value, setValue] = React.useState<any>();

  const [UpdateUser, setUpdateUser] = useState<any>({
    expenseCatUUID: "",
    branchId: "",
    name: "",
    Status: "",
  });

  //page state
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);

  const startDate = selectedDate?.startDate;
  const endDate = selectedDate?.endDate;
  let initialState = {
    status: "",
    expenseCatID: "",
    page_limit: pageLimit,
    page_no: 1,
    search: "",
    branchID: ActiveBranch.iBranchID,
    start_date: startDate ? startDate : "",
    end_date: endDate ? endDate : "",
    order_key: "iposExpenseID",
    order_value: "desc",
  };

  const [show, setShow] = useState<any>(false);
  const [activeCatagory, setActiveCatagory] = useState<any>();
  const handleShow = () => setShow(true);

  const [isUpdate, setisUpdate] = useState(false);
  //for check box
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [isAllRowsChecked, setIsAllRowsChecked] = useState<any>(false);
  const [branch_id, setBranch_id] = useState<any>("0");
  // for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // for error
  // const [error, setError] = useState("");
  const [isFilter, setisFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState(initialState);
  // const [searchValues, setSearchValues] = useState(initialState);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [selectCatagory, setSelectCatagory] = useState<any>({
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
    value: "",
  });
  const [totalexp, setTotalexp] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  // const [isActive, setIsActive] = useState<any>();
  const [TBLData, setTBLData] = useState<any>([]);
  const [paymodeOpt, setPayModeOpt] = useState<any>([]);
  const [selectPaymentMode, setSelectPaymentMode] = useState({
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
    value: "",
  });

  // const paymentModeOptions = [
  //   {
  //     value: "Cash",
  //     label: intl.formatMessage({ id: "MODULE.PAYNTMODE.CASH" }),
  //   },
  //   {
  //     value: "Cheque",
  //     label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.CHEQUE" }),
  //   },
  //   {
  //     value: "DemandDraft",
  //     label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.DD" }),
  //   },
  //   {
  //     value: "DirectDeposit",
  //     label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.DDEPOSIT" }),
  //   },
  //   {
  //     value: "Online",
  //     label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.ONLINET" }),
  //   },
  // ];
  //define column of listing

  const ExpenseCatRowTitle = `${intl.formatMessage({
    id: "MODULE.EXPENSECATEGORY.TITLE",
  })}`;
  // const ExpenseIdRowTitle = `${intl.formatMessage({
  //   id: "MODULE.MEMBERS.MEMBERID",
  // })}`;
  // const ExpenseCatRowStatus = `${intl.formatMessage({ id: "COMMON.STATUS" })}`;
  const ExpensepaidToRowAction = `${intl.formatMessage({
    id: "MODULE.EXPENSECATEGORY.PAIDTO",
  })}`;
  const ExpenseAmountRowAction = `${intl.formatMessage({
    id: "MODULE.EXPENSECATEGORY.AMOUNT",
  })}`;
  // const ExpenseExpenseRowAction = `${intl.formatMessage({
  //   id: "MODULE.EXPENSECATEGORY.DATE",
  // })}`;
  const ExpenseBillRowAction = `${intl.formatMessage({
    id: "MODULE.EXPENSECATEGORY.BILLNO",
  })}`;
  const ExpenseRemarkRowAction = `${intl.formatMessage({
    id: "COMMON_REMARK",
  })}`;
  const ExpensepaymentmodeRowAction = `${intl.formatMessage({
    id: "MODULE.RECIEPT.PAYMENTMODE",
  })}`;
  const ExpenseDateRowAction = `${intl.formatMessage({
    id: "MODULE.EXPENSECATEGORY.DATE",
  })}`;
  const ExpenseCatRowAction = `${intl.formatMessage({ id: "COMMON.ACTIONS" })}`;
  const ExpenseCatRowChequeandDate = `${intl.formatMessage({
    id: "MODULE.EXPENSECATEGORY.CHEQUE",
  })}`;
  const ExpenseCatRowBank = `${intl.formatMessage({
    id: "MODULE.EXPENSECATEGORY.BANKNAME",
  })}`;

  //download fileName
  const downloadfileName = "PosExpense_" + Date.now();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: (values) => {
      setFilterValues(values);

      setPage(1);
    },
  });
  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };

  const getExpenseCategory = useCallback(() => {
    if (UserType === "admin" || view !== 0 || UserType === "channelPartner") {
      setIsLoading(true);
      GetPosExpenses({
        ...filterValues,
        page_no: page,
        page_limit: pageLimit,
        order_key: sortConfig.key,
        order_value: sortConfig.direction,
      }).then((data: any) => {
        // console.log(data, "data");
        if (data?.status !== 200) {
          setFilteredData([]);
          setIsLoading(false);
        } else {
          dispatch(PosExpenses.actions.GetPosExpenses(data));
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, filterValues, page, sortConfig, view]);

  useEffect(() => {
    getExpenseCategory();
  }, [getExpenseCategory]);

  useEffect(() => {
    setFilteredData(PosExpense?.data);
    setTotalexp(PosExpense?.totalExpense);
    handleExports();
  }, [PosExpense?.data]);
  useEffect(() => {
    getActiveList(ActiveBranchUUID);
    GetActivePayMethod(ActiveBranchUUID);
  }, []);
  const GetActivePayMethod = async (branchUUID: any) => {
    const response = await ActivePaymentMode(branchUUID);
    setPayModeOpt(response?.data?.data);
    // console.log(response?.data, "reponseofactive");
  };
  const getActiveList = async (branchUUID: any) => {
    const response = await activePosExpenseCategoryList(branchUUID);
    console.log(response, "response");
    setActiveCatagory(response?.data?.data);
  };
  // update Role Status
  const UpdateUserStatus = (prop: any) => {
    swal({
      title: `${intl.formatMessage({ id: "COMMON.AREYOUSURE" })}`,
      text: intl.formatMessage({ id: "COMMON.NOTPERFORMOPERATION" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SUBMIT" }),
      ], //["Cancel", "Submit"],
      dangerMode: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        const Update_user = {
          branchID: branch_id,
          uuid_array: checkedRows,
          select_type: "",
          unchecked_uuid_array: [],
          status: prop.status,
        };
        if (prop.status !== "2") {
          Update_PosExpenseCategory_Status(Update_user)
            .then((data: any) => {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });
              setCheckedRows([]);
              setIsAllRowsChecked(false);
              //getting all roled again
              getExpenseCategory();
            })
            .catch(() => {
              // swal("Somthing Went Wrong!");
              swal(
                `${intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" })}`
              );
            });
        } else {
          Delete_PosExpenseCategory(Update_user)
            .then((data: any) => {
              //swal(`Proof! Selected Users Is ${prop.message}!`, {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });
              //getting all roled again
              setCheckedRows([]);
              setIsAllRowsChecked(false);
              getExpenseCategory();
            })
            .catch(() => {
              swal(intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" }));
            });
        }
      } else {
        swal(`${intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" })}`);
      }
    });
  };

  //handle check box

  const handleRowCheckboxChange = (rowId: any, branchId: any) => {
    setBranch_id(branchId);
    // console.log(checkedRows, "idd");
    if (checkedRows.includes(rowId)) {
      setCheckedRows(checkedRows.filter((id: any) => id !== rowId));
    } else {
      setCheckedRows([...checkedRows, rowId]);
    }
  };
  // handle all check box
  const handleSelectAllCheckboxChange = () => {
    setBranch_id(ActiveBranch.iBranchID);
    if (isAllRowsChecked) {
      setCheckedRows([]);
      setIsAllRowsChecked(false);
    } else {
      const allRowIds =
        PosExpense?.data?.map((element: any) => element.iposExpenseUUID) || [];
      //console.log('packageperiods====',packageperiods);
      //console.log('allRowIds====',allRowIds);
      setCheckedRows(allRowIds);
      setIsAllRowsChecked(true);
    }
  };
  const selectPageHandler = (selectedPage: any) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPages &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };
  const handleReset = () => {
    setSelectPaymentMode({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });
    setSelectCatagory({
      value: "",
      label: intl.formatMessage({ id: "COMMON.SELECT" }),
    });

    handleDateReset();
    formik.resetForm();
    setFilterValues(initialState);
  };

  const handleExports = () => {
    const tableRows = PosExpense?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [ExpenseCatRowTitle]: element.catName,
        [ExpensepaidToRowAction]: element.paid_to,
        [ExpenseAmountRowAction]: element.amount,
        [ExpenseDateRowAction]: element.expenseDate,
        [ExpensepaymentmodeRowAction]: element?.mode_of_payment,
        // [ExpenseCatRowBank]: element.cheque_no + " " + element.cheque_date,
        // [ExpenseBillRowAction]: element?.bank_name,
        // [ExpenseRemarkRowAction]: element?.bill_no,
        [ExpenseRemarkRowAction]: element.remarks,
      };
    });

    setTBLData(tableRows);
  };
  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    const titleFontSize = 9;
    const tableFontSize = 6;

    doc.setFontSize(titleFontSize);
    doc.text(
      `${intl.formatMessage({ id: "MODULE.EXPENSECATEGORY.EXPENSE" })}`,
      10,
      10
    );
    const tableRows = PosExpense.data.map((element: any, index: number) => {
      return [
        index + 1,
        element.catName,
        element.paid_to,
        element.amount,
        element.expenseDate,
        element.mode_of_payment,
        element.remarks,

        /*new Date(element.dtCreated_Date).toLocaleString("en-US", {
          dateStyle: "medium",
          timeStyle: "short",
        }),*/
      ];
    });
    doc.autoTable({
      head: [
        [
          "#",
          ExpenseCatRowTitle,
          ExpensepaidToRowAction,
          ExpenseAmountRowAction,
          ExpenseDateRowAction,
          ExpensepaymentmodeRowAction,
          ExpenseRemarkRowAction,
        ],
      ],
      body: tableRows,
      startY: 50,
      styles: {
        fontSize: tableFontSize,
      },
      columnStyles: {
        0: { columnWidth: 20 },
        // Set custom width for the first column
        // Add more column styles as needed
      },
    });

    doc.save(downloadfileName + ".pdf");
  };

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
    setPage(1);
  };

  const handleRefreshButton = () => {
    // setIsLoading(true);
    // setCheckedRows([]);
    // setPage(1);
    setPageLimit(pageDefaultValue);
    setFilterValues(initialState);
    // GetExpenses({
    //   ...searchValues,
    //   page_no: 1,
    //   page_limit: pageDefaultValue,
    //   order_key: "iExpenseID",
    //   order_value: sortConfig.direction,
    // }).then((data: any) => {
    //   dispatch(Expenses.actions.GetExpenses(data));
    //   setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
    //   setIsLoading(false);
    // });
  };

  const getDetailsForUpdate = async (branchID: any, memberuid: any) => {
    const response = await detailofUpdatePosExpense(branchID, memberuid);
    setUpdateUser(response?.data?.data);
    // console.log(response, "detailsof");
  };
  const handleChange = (values: any) => {
    setValue(values);
    if (values && values.length !== 0) {
      formik.setFieldValue("start_date", moment(values[0]).format(dateFormat));
      formik.setFieldValue("end_date", moment(values[1]).format(dateFormat));
    }
  };
  const handleDateReset = () => {
    setValue(null);
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
  };
  const handlePaymentModeChange = (Event: any) => {
    //   formik.setFieldValue("packageUUID", Event.value);
    formik.setFieldValue("paymentMode", Event.value);
    setSelectPaymentMode({
      value: Event.value,
      label: Event.label,
    });
    // handlePaymentReset();
  };

  const handleCatagoryChange = (Event: any) => {
    setSelectCatagory({
      value: Event.value,
      label: Event.label,
    });
    // console.log(Event.target.value, "ljdsf");
    formik.setFieldValue("expenseCatID", Event.value);
  };
  return (
    <>
      {/* Filter Section Start of Package Period List */}
      {isFilter && (
        <div className="card my-4">
          <div className="card-header">
            {" "}
            <Card className="w-100">
              <Card.Body className="py-5">
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>
                          {intl.formatMessage({
                            id: "MODULE.EXPENSECATEGORY.LIST",
                          })}
                        </Form.Label>
                        <Select
                          className="basic-single"
                          onChange={(e: any) => handleCatagoryChange(e)}
                          value={selectCatagory}
                          options={activeCatagory?.map((ele: any) => ({
                            value: ele?.iposExpenseCatID,
                            label: ele?.name,
                          }))}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <label htmlFor="paymentMode" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.RECIEPT.PAYMENTMODE",
                        })}
                      </label>
                      <Select
                        id="paymentMode"
                        name="paymentMode"
                        className="basic-single"
                        value={selectPaymentMode}
                        options={paymodeOpt?.map((item: any) => ({
                          value: item.ipaymentModeID,
                          label: item.modeName,
                        }))}
                        onChange={(e) => handlePaymentModeChange(e)}
                      />
                    </Col>

                    {/* Rest of the form fields */}

                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.EXPENSECATEGORY.DATERANGE",
                          })}
                        </label>

                        <DateRangePicker
                          value={value}
                          onChange={handleChange}
                          format="MM/dd/yyyy"
                          placeholder={intl.formatMessage({
                            id: "COMMON.PLACEHOLDER.DATERANGE",
                          })}
                          placement="auto"
                          style={{ margin: "2px" }}
                          onClean={handleDateReset}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-4">
                      <Form.Group controlId="search">
                        <Form.Label>
                          {intl.formatMessage({
                            id: "MODULE.EXPENSECATEGORY.SEARCH1",
                          })}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder={intl.formatMessage({
                            id: "MODULE.EXPENSECATEGORY.ENTERSEARCH",
                          })}
                          onChange={formik.handleChange}
                          value={formik.values.search}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      md={3}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <Button type="submit" variant="primary" className="me-3 ">
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.APPLY",
                        })}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleReset}
                        className="me-3"
                      >
                        {intl.formatMessage({
                          id: "MODULE.EXPENSECATEGORY.RESET",
                        })}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      {/* Filtsser Section End */}

      <div className={`card mb-5 mb-xl-8 `}>
        {/* begin::Header */}

        <div className="card-header border-0 pt-3">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div
                className="d-flex  flex-wrap gap-1"
                data-kt-user-table-toolbar="base"
              >
                {checkedRows?.length > 0 ? (
                  <>
                    <div className="fw-bold me-5">
                      <span className="me-2"> {checkedRows?.length}</span>
                      {intl.formatMessage({ id: "COMMON.SELECTED" })}
                    </div>

                    <>
                      <button
                        type="button"
                        className={`btn btn-success me-3 d-none  ${
                          UserType === "employee" && edit === 0 && "d-none"
                        }`}
                        onClick={() =>
                          UpdateUserStatus({
                            status: "1",
                            message: `${intl.formatMessage({
                              id: "COMMON.ACTIVATED",
                            })}`,
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className={`btn btn-light-warning me-3 d-none ${
                          UserType === "employee" && edit === 0 && "d-none"
                        }`}
                        onClick={() =>
                          UpdateUserStatus({
                            status: "0",
                            message: `${intl.formatMessage({
                              id: "COMMON.INACTIVATED",
                            })}`,
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className={`btn btn-danger me-3 ${
                          del === 0 && UserType === "employee" && "d-none"
                        }`}
                        onClick={() =>
                          UpdateUserStatus({
                            status: "2",
                            message: `${intl.formatMessage({
                              id: "COMMON.DELETE",
                            })}`,
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.DELETE" })}
                      </button>
                    </>
                  </>
                ) : (
                  <div className="d-flex align-items-center">
                    <span
                      className="min-w-40px mb-3 d-flex  align-items-center"
                      style={{ color: "#7e8299", fontSize: "14px" }}
                    >
                      {intl.formatMessage({ id: "COMMON.SHOW" })}
                    </span>
                    <div>
                      <select
                        className="form-control form-select form-select-solid me-3 mb-4"
                        value={pageLimit}
                        onChange={(e) => getPageLimit(e.target.value)}
                      >
                        {finalPageLimitArray?.map((item: any, i = 0) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <h1 className="mx-2 fs-4 ">
                      {intl.formatMessage({
                        id: "MODULE.EXPENSECATEGORY.TOTALEXP",
                      })}
                      {totalexp}
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex  flex-wrap gap-1"
              data-kt-user-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-primary me-3"
                onClick={handleRefreshButton}
              >
                {" "}
                {intl.formatMessage({ id: "COMMON.REFRESH" })}
              </button>
              {checkedRows?.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="btn btn-light-primary me-3"
                    >
                      <KTSVG
                        path="/media/icons/duotune/files/fil021.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                      {intl.formatMessage({ id: "MODULE.BRANCH.EXPORT" })}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4">
                      <CSVLink
                        data={TBLData || []}
                        filename={downloadfileName + ".csv"}
                        className="menu-item px-3"
                      >
                        <span className="px-3">
                          {intl.formatMessage({ id: "COMMON.EXPORTCSV" })}
                        </span>
                      </CSVLink>
                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={() => exportToXLS(TBLData, downloadfileName)}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTEXCEL" })}
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={exportToPDF}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTPDF" })}
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* modal start */}
        {/* modal start */}
        {/* <ExpenseModal
          show={show}
          setShow={setShow}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          activeCatagory={activeCatagory}
          getExpenseCategory={getExpenseCategory}
          paymodeOpt={paymodeOpt}
        /> */}
        {/* <Main show={show} setShow={setShow}/> */}

        {/* modal End */}

        {/* begin::Body */}

        <div className="card-body py-3">
          {/* {isLoading && <Loading />} */}
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className=" mobilewidth">
                    <div className="mobileViewalign w-100">
                      <div className=" selectAll ">
                        {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                      </div>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                          checked={isAllRowsChecked}
                          onChange={() => handleSelectAllCheckboxChange()}
                        />
                      </div>
                    </div>
                  </th>
                  {/* <th className="min-w-100px">{ExpenseIdRowTitle}</th> */}
                  <th className="min-w-100px notVisibleInMobile ">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="catName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseCatRowTitle}
                      {sortConfig.key !== "catName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "catName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "catName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paid_to"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      <div>{ExpensepaidToRowAction}</div>
                      {sortConfig.key !== "paid_to" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paid_to" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paid_to" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="amount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      <div> {ExpenseAmountRowAction}</div>
                      {sortConfig.key !== "amount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="expenseDate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseDateRowAction}
                      {sortConfig.key !== "expenseDate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "expenseDate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "expenseDate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="mode_of_payment"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpensepaymentmodeRowAction}
                      {sortConfig.key !== "mode_of_payment" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "mode_of_payment" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "mode_of_payment" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="cheque_no"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseCatRowChequeandDate}
                      {sortConfig.key !== "cheque_no" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "cheque_no" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "cheque_no" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="bank_name"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseCatRowBank}
                      {sortConfig.key !== "bank_name" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "bank_name" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "bank_name" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="bill_no"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseBillRowAction}
                      {sortConfig.key !== "bill_no" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "bill_no" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "bill_no" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="remarks"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseRemarkRowAction}
                      {sortConfig.key !== "remarks" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "remarks" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "remarks" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  {/* <th className="min-w-100px notVisibleInMobile">
                      {ExpenseCatRowStatus}
                    </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    {ExpenseCatRowAction}
                  </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredData && filteredData.length === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                    </td>
                  </tr>
                ) : UserType === "employee" && view === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center text-danger fs-4">
                      {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((element: any, index: number) => {
                    const isChecked = checkedRows.includes(
                      element.iposExpenseUUID
                    );
                    return (
                      <tr key={element.iposExpenseUUID} className="fs-5">
                        <td
                          data-label={intl.formatMessage({
                            id: "COMMON.SELECT",
                          })}
                        >
                          <div className="form-check form-check-sm form-check-custom form-check-solid selectOPtion">
                            <input
                              className="form-check-input widget-9-check"
                              type="checkbox"
                              value="1"
                              checked={isChecked}
                              onChange={() =>
                                handleRowCheckboxChange(
                                  element.iposExpenseUUID,
                                  element.iBranchID
                                )
                              }
                            />
                          </div>
                        </td>
                        {/* <td data-label={ExpenseIdRowTitle}>
                              {element.iExpenseID}
                            </td> */}
                        <td data-label={ExpenseCatRowTitle}>
                          {element.catName}
                        </td>
                        <td data-label={ExpensepaidToRowAction}>
                          {element.paid_to}
                        </td>
                        <td data-label={ExpenseAmountRowAction}>
                          {element.amount}
                        </td>
                        <td data-label={ExpenseDateRowAction}>
                          {element.expenseDate.split("T")[0]}
                        </td>
                        <td data-label={ExpensepaymentmodeRowAction}>
                          {element.mode_of_payment}
                        </td>
                        {/* <td data-label={ExpenseCatRowChequeandDate}>
                          {`${element?.cheque_no ? element?.cheque_no : ""} ${
                            element?.cheque_date ? element?.cheque_date : ""
                          }`}
                        </td>
                        <td data-label={ExpenseCatRowBank}>
                          {element.bank_name}
                        </td>

                        <td data-label={ExpenseBillRowAction}>
                          {element.bill_no}
                        </td> */}
                        <td data-label={ExpenseRemarkRowAction}>
                          {element.remarks}
                        </td>

                        {/* <td data-label={ExpenseCatRowStatus}>
                              {StatusBadge(element.eStatus)}
                            </td> */}
                        <td data-label={ExpenseCatRowAction}>
                          <div className="d-flex flex-shrink-0 floatRight">
                            <a
                              href="#"
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                edit === 0 &&
                                UserType === "employee" &&
                                "d-none"
                              }`}
                              onClick={() => {
                                getDetailsForUpdate(
                                  ActiveBranchID,
                                  element.iposExpenseUUID
                                );
                                setisUpdate(true);
                                setShow(true);
                              }}
                            >
                              <KTSVG
                                path="/media/icons/duotune/art/art005.svg"
                                className="svg-icon-3"
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className=" mobilewidth">
                    <div className="mobileViewalign w-100">
                      <div className=" selectAll ">
                        {intl.formatMessage({ id: "COMMON.SELECTALL" })}
                      </div>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                          checked={isAllRowsChecked}
                          onChange={() => handleSelectAllCheckboxChange()}
                        />
                      </div>
                    </div>
                  </th>
                  {/* <th className="min-w-100px">{ExpenseIdRowTitle}</th> */}
                  <th className="min-w-100px notVisibleInMobile ">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="catName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseCatRowTitle}
                      {sortConfig.key !== "catName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "catName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "catName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paid_to"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      <div>{ExpensepaidToRowAction}</div>
                      {sortConfig.key !== "paid_to" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paid_to" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paid_to" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="amount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      <div> {ExpenseAmountRowAction}</div>
                      {sortConfig.key !== "amount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="expenseDate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseDateRowAction}
                      {sortConfig.key !== "expenseDate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "expenseDate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "expenseDate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="mode_of_payment"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpensepaymentmodeRowAction}
                      {sortConfig.key !== "mode_of_payment" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "mode_of_payment" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "mode_of_payment" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="cheque_no"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseCatRowChequeandDate}
                      {sortConfig.key !== "cheque_no" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "cheque_no" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "cheque_no" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="bank_name"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseCatRowBank}
                      {sortConfig.key !== "bank_name" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "bank_name" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "bank_name" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="bill_no"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseBillRowAction}
                      {sortConfig.key !== "bill_no" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "bill_no" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "bill_no" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="remarks"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ExpenseRemarkRowAction}
                      {sortConfig.key !== "remarks" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "remarks" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "remarks" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  {/* <th className="min-w-100px notVisibleInMobile">
                      {ExpenseCatRowStatus}
                    </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    {ExpenseCatRowAction}
                  </th>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {/* begin:: pagination  */}
          {/* <div className="row">
              <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
              <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                <div id="kt_table_users_paginate">
                  <ul className="pagination">
                    <li className="page-item previous">
                      <a
                        className={`page-link page-text me-5 paginate_button page-item previous ${
                          page === 1 ? "disable" : ""
                        } `}
                        style={{ cursor: "pointer" }}
                        onClick={() => selectPageHandler(page - 1)}
                      >
                        {intl.formatMessage({ id: "COMMON.PREVIOUS" })}
                      </a>
                    </li>
                    {[...Array(totalPages)].map((_, i) => {
                      return (
                        <li
                          className={
                            page === i + 1 ? "page-item active" : "page-item"
                          }
                          onClick={() => selectPageHandler(i + 1)}
                          key={i}
                        >
                          <a
                            className="page-link"
                            style={{ cursor: "pointer" }}
                          >
                            {i + 1}
                          </a>
                        </li>
                      );
                    })}

                    <li
                      className={`paginate_button page-item next ${
                        page === totalPages ? "disable" : ""
                      } `}
                    >
                      <a
                        className="page-link page-text"
                        style={{ cursor: "pointer" }}
                        onClick={() => selectPageHandler(page + 1)}
                      >
                        {intl.formatMessage({ id: "COMMON.NEXT" })}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          {filteredData && filteredData.length > 0 && (
            <Pagination page={page} totalPages={totalPages} setPage={setPage} />
          )}
          {/* end:: pagination */}
        </div>

        {/* begin::Body */}
      </div>
    </>
  );
};

export default PosExpenseModal;
