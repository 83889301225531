import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetSupplyMents: "[GetSupplyMents] Action",
};

const initialAuthState: ICenterState = {
  Supplyments: undefined,
};

export interface ICenterState {
  Supplyments?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["Supplyments"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetSupplyMents: {
        const Supplyments = action.payload?.Supplyments;
        return { ...state, Supplyments };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetSupplyMents: (Supplyments: string) => ({
    type: actionTypes.GetSupplyMents,
    payload: { Supplyments },
  }),
};
