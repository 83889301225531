import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
// import EnquiryStatusModal from "./modal/EnquiryStatusModal";

import * as myStock from "../../../modules/newPos/modules/myStock/redux/MyStockRedux";

// import Logo from "../../../.../../../loading_assests/logormbg.png";
// import swal from "sweetalert";
import { useIntl } from "react-intl";
import { Form, Row, Col, Card, Button, Dropdown } from "react-bootstrap";

import { CSVLink } from "react-csv";
// import PackagePeriodModal from "./modal/EnquiryStatusModal";
import { KTSVG } from "../../../../_metronic/helpers";

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Pagination from "../../../modules/GenricComponant/Pagination";
// import { Direction } from "react-toastify/dist/utils";
// import AddFollowUpModal from "../../enquiry/component/modal/AddFollowUpModal";
import { exportToXLS } from "../../../modules/GenricComponant/exportToxls/exportToxls";
// import { GetFollowUpDetails } from "../../enquiry/redux/EnquiryCRUD";
// import FollowUpModal from "./modal/FollowUpModal";
import permission from "../../../modules/GenricComponant/permissions/permission";
// import DateRangePicker from "rsuite/esm/DateRangePicker";
import moment from "moment";
import { GetMyStockList } from "../../../modules/newPos/modules/myStock/redux/MyStockCRUD";
import { getActiveSupplier } from "../../../modules/newPos/modules/purchase_Prodcut/redux/PurchaseProductCRUD";
// import FoodItemModal from "./modal/SupplierModal";
// import ReminderModal from "./modal/ActivityModal";
// import Loading from "../../../../Genric componant/loading/Loading";
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: object) => jsPDF;
  }
}

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const data: any = localStorage.getItem("CountryCurrencycode");
const CountryCode = JSON.parse(data);

const StockModal = ({ stockType }: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "dtModified_Date", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  const myStocks = useSelector((state: any) => state?.myStock?.myStock?.data);

  // active branch data taken from local storage
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const Access = permission("supplier");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });

  // const [UpdateUser, setUpdateUser] = useState<any>();
  // const [selectCat, setSelectCat] = useState<any>("");
  //   const [error, setError] = useState<any>("");
  //page state
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  console.log(stockType, "stockType");
  let initialState = {
    branchID: ActiveBranch.iBranchID,
    page_limit: pageLimit,
    stockType: stockType ? stockType : "",
    status: "",
    page_no: "1",
    search: "",
    start_date: "",
    end_date: "",
    order_key: " dtModified_Date",
    order_value: "desc",
  };

  // const [show, setShow] = useState<any>(false);
  // const handleShow = () => {
  //   setShow(true);
  //   // setIsfollowup(true);
  // };

  // const [isUpdate, setisUpdate] = useState(false);
  //for check box
  const [checkedRows, setCheckedRows] = useState<any>([]);
  // const [isAllRowsChecked, setIsAllRowsChecked] = useState<any>(false);
  // const [branch_id, setBranch_id] = useState<any>("0");
  // for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // for error
  // const [error, setError] = useState("");
  const [isFilter, setisFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState(initialState);
  // const [searchValues, setSearchValues] = useState(initialState);
  const [filteredData, setFilteredData] = useState<any>([]);
  // const [enqOption, setEnqOption] = useState<any>([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [isFollowup, setIsfollowup] = useState(false);
  // const [updateFollowUpUser, setUpdateFollowUpUser] = useState<any>({});
  const [dateFormat, setdateFormat] = useState<any>(ActiveBranch?.idateFormate);
  const initialDates = [new Date(), new Date()];
  const [value, setValue] = React.useState<any>();
  const [TBLData, setTBLData] = useState<any>([]);
  const [selectSupp, setSelectSupp] = useState<any>("");
  const [SupplierOpt, setSupplierOpt] = useState<any>("");

  //define column of listing
  const Idcolumn = `${intl.formatMessage({
    id: "POS.PURCHASEPRODUCT.ID",
  })}`;
  const column1 = `${intl.formatMessage({
    id: "POS.SUPPLIER.NAME",
  })}`;
  const column2 = `${intl.formatMessage({
    id: "MYSTOCK.PRODUCT",
  })}`;
  const column3 = `${intl.formatMessage({
    id: "MYSTOCK.PURCHASEPRCE",
  })}`;
  const column4 = `${intl.formatMessage({
    id: "MYSTOCK.PURCHASEQUANTITY",
  })}`;
  const column5 = `${intl.formatMessage({
    id: "MYSTOCK.STOCKQUANTITY",
  })}`;

  const column6 = `${intl.formatMessage({ id: "COMMON.ACTIONS" })}`;

  //download fileName
  const downloadfileName = "productStock" + Date.now();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: (values) => {
      setFilterValues(values);

      setPage(1);
    },
  });
  const getReminder = useCallback(() => {
    if (UserType === "admin" || view !== 0 || UserType === "channelPartner") {
      // setIsLoading(true);
      GetMyStockList({
        ...filterValues,
        page_no: page,
        page_limit: pageLimit,
        order_key: sortConfig.key,
        order_value: sortConfig.direction,
      }).then((data: any) => {
        // console.log(data.data.totalRecords, "dataforenqui");
        if (data?.status !== 200) {
          setFilteredData([]);
          // setIsLoading(false);
        } else {
          dispatch(myStock?.actions?.GetMyStock(data));
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          // setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, filterValues, page, sortConfig, view]);

  useEffect(() => {
    getReminder();
  }, [getReminder]);

  useEffect(() => {
    setFilteredData(myStocks?.data);
    handleExports();
  }, [myStocks?.data]);

  useEffect(() => {
    GetActiveSupplierList(ActiveBranch.iBranchUUID);
  }, []);

  const GetActiveSupplierList = async (branchUUID: any) => {
    const response = await getActiveSupplier(branchUUID);
    setSupplierOpt(response?.data?.data);
    // setTableData(response?.data?.data);
  };
  // update Role Status

  //handle check box

  const handleReset = () => {
    formik.resetForm();
    setFilterValues(initialState);
    // setSelectCat("");
    setValue(null);
    setSelectSupp("");
  };

  const handleExports = () => {
    const tableRows = myStocks?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [Idcolumn]: element?.iSupplierPurchaseProductID,
        [column1]:
          element?.supplierName &&
          `${element.contactPerson} ${
            element.countryCode + "-" + element.mobileno
          } ${element.email} `,
        [column2]: element?.ProductName,
        [column3]: element?.purchase_price,
        [column4]: element?.purchaseQuantity,
        [column5]: element?.stockQuantity,
      };
    });

    setTBLData(tableRows);
  };

  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    const titleFontSize = 9;
    const tableFontSize = 6;

    doc.setFontSize(titleFontSize);
    doc.text(`${intl.formatMessage({ id: "POS.SUPPLIER.PAGE" })}`, 10, 10);
    const tableRows = myStocks.data.map((element: any, index: number) => {
      return [
        index + 1,
        element.iSupplierPurchaseProductID,
        `${element.supplierName}( ${
          element.countryCode + "-" + element.mobileno
        }) (${element.email}) `,
        element.ProductName,
        element.purchase_price,
        element.purchaseQuantity,
        element.stockQuantity,

        /*new Date(element.dtCreated_Date).toLocaleString("en-US", {
          dateStyle: "medium",
          timeStyle: "short",
        }),*/
      ];
    });
    doc.autoTable({
      head: [["#", Idcolumn, column1, column2, column3, column4, column5]],
      body: tableRows,
      startY: 50,
      styles: {
        fontSize: tableFontSize,
      },
      columnStyles: {
        // 0: { columnWidth: 20 },
        // Set custom width for the first column
        // Add more column styles as needed
      },
    });

    doc.save(downloadfileName + ".pdf");
  };

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
  };

  const handleRefreshButton = () => {
    // setIsLoading(true);
    setCheckedRows([]);
    setPage(1);
    setPageLimit(pageDefaultValue);
    // setSearchValues(initialState);
    setSortConfig({ key: "dtModified_Date", direction: "desc" });
  };

  const handleDateReset = (values: any) => {
    setValue(null);
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
  };
  const handleChange = (values: any) => {
    setValue(values);
    // console.log(values,)
    if (values && values.length !== 0) {
      formik.setFieldValue("start_date", moment(values[0]).format(dateFormat));
      formik.setFieldValue("end_date", moment(values[1]).format(dateFormat));
    }
  };

  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };

  const handleChangeSupplier = async (e: any) => {
    if (e.value) {
      setSelectSupp({ value: e.value, label: e.label });
      formik.setFieldValue("supplierID", e.value);
    }
  };

  return (
    <>
      {/* Filter Section Start of Package Period List */}
      {isFilter && (
        <div className="card my-4">
          <div className="card-header">
            {" "}
            <Card className="w-100">
              <Card.Body className="py-5">
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label className="form-label">
                          {intl.formatMessage({
                            id: "POS.PURCHASEPRODUCT.SUPPLIER",
                          })}
                        </label>
                        <Select
                          className="basic-single"
                          name="supplierID"
                          id="supplierID"
                          value={selectSupp}
                          //  value={formik.values.countryCode}
                          onChange={(e) => {
                            handleChangeSupplier(e);
                          }}
                          options={SupplierOpt?.map((item: any) => {
                            // console.log(item, "aray");
                            return {
                              value: item?.isupplierID,
                              label: item?.supplierName,
                            };
                          })}
                        />
                      </div>
                    </Col>

                    <Col md={3} className="mb-4">
                      <Form.Group controlId="search">
                        <Form.Label>
                          {intl.formatMessage({
                            id: "MODULE.ENQUIRYSTATUS.SEARCH",
                          })}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder={intl.formatMessage({
                            id: "MODULE.ENQUIRYSTATUS.ENTERSEARCH",
                          })}
                          onChange={formik.handleChange}
                          value={formik.values.search}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={3} className="mb-4">
                      <Form.Group controlId="status">
                        <Form.Label>
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={formik.handleChange}
                          value={formik.values.status}
                        >
                          <option value="">
                            {intl.formatMessage({ id: "COMMON.ALL" })}
                          </option>
                          <option value="1">
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    {/* Rest of the form fields */}
                    <Col
                      md={3}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <Button type="submit" variant="primary" className="me-3 ">
                        {intl.formatMessage({
                          id: "COMMON.APPLY",
                        })}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleReset}
                        className="me-3"
                      >
                        {intl.formatMessage({
                          id: "COMMON.RESET",
                        })}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      {/* Filtsser Section End */}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div
                className="d-flex flex-wrap gap-1"
                data-kt-user-table-toolbar="base"
              >
                {checkedRows?.length > 0 ? (
                  <>
                    <div className="fw-bold me-5">
                      <span className="me-2"> {checkedRows?.length}</span>
                      {intl.formatMessage({ id: "COMMON.SELECTED" })}
                    </div>
                  </>
                ) : (
                  <>
                    <span
                      className="min-w-40px mb-3 d-flex align-items-center"
                      style={{ color: "#7e8299", fontSize: "14px" }}
                    >
                      {intl.formatMessage({ id: "COMMON.SHOW" })}
                    </span>
                    <div>
                      <select
                        className="form-control form-select form-select-solid me-3 mb-4"
                        value={pageLimit}
                        onChange={(e) => getPageLimit(e.target.value)}
                      >
                        {finalPageLimitArray?.map((item: any, i = 0) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex flex-wrap gap-1"
              data-kt-user-table-toolbar="base"
            >
              {checkedRows?.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3 mb-2"
                    onClick={handleRefreshButton}
                  >
                    {" "}
                    {intl.formatMessage({ id: "COMMON.REFRESH" })}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary me-3 mb-2"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                  <Dropdown className="mb-2">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="btn btn-light-primary me-3"
                    >
                      <KTSVG
                        path="/media/icons/duotune/files/fil021.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                      {intl.formatMessage({ id: "MODULE.BRANCH.EXPORT" })}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4">
                      <CSVLink
                        data={TBLData || []}
                        filename={downloadfileName + ".csv"}
                        className="menu-item px-3"
                      >
                        <span className="px-3">
                          {intl.formatMessage({ id: "COMMON.EXPORTCSV" })}
                        </span>
                      </CSVLink>
                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={() => exportToXLS(TBLData, downloadfileName)}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTEXCEL" })}
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={exportToPDF}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTPDF" })}
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* modal start */}
        {/* modal start */}
        {/* <FollowUpModal
          show={show}
          setShow={setShow}
          isFollowup={isFollowup}
          setIsfollowup={setIsfollowup}
          isUpdate={isUpdate}
          enqOption={enqOption}
          updateFollowUpUser={updateFollowUpUser}
        /> */}

        {/* <Main show={show} setShow={setShow}/> */}

        {/* modal End */}

        {/* begin::Body */}

        <div className="card-body py-3">
          {/* {isLoading && <Loading />} */}
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="isupplierID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {Idcolumn}
                      {sortConfig.key !== "isupplierID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "isupplierID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "isupplierID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="supplierName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column1}
                      {sortConfig.key !== "supplierName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "supplierName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "supplierName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="ProductName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column2}
                      {sortConfig.key !== "ProductName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "ProductName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "ProductName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="purchase_price"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column3}
                      {sortConfig.key !== "purchase_price" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "purchase_price" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "purchase_price" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="purchaseQuantity"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column4}
                      {sortConfig.key !== "purchaseQuantity" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "purchaseQuantity" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "purchaseQuantity" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="stockQuantity"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column5}
                      {sortConfig.key !== "stockQuantity" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "stockQuantity" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "stockQuantity" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  {/* <th className="min-w-100px notVisibleInMobile">{column6}</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredData && filteredData.length === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                    </td>
                  </tr>
                ) : UserType === "employee" && view === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center text-danger fs-4">
                      {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((element: any, index: number) => {
                    const isChecked = checkedRows.includes(
                      element.iSupplierPurchaseProductUUID
                    );
                    return (
                      <tr key={index} className="fs-5">
                        <td data-label={Idcolumn}>
                          {element.iSupplierPurchaseProductID}
                        </td>

                        <td data-label={column1}>
                          <div>{element.supplierName}</div>
                          {element?.countryCode && element?.mobileno && (
                            <div>
                              <a
                                href={`tel:${
                                  element?.countryCode + element?.mobileno
                                }`}
                              >
                                (
                                {element?.countryCode + "-" + element?.mobileno}
                                )
                              </a>
                            </div>
                          )}
                          {element?.email && (
                            <div>
                              <a href={`mailto:${element?.email}`}>
                                ({element?.email})
                              </a>
                            </div>
                          )}
                        </td>
                        <td data-label={column2}>{element.ProductName}</td>
                        <td data-label={column3}>{element.purchase_price}</td>
                        <td data-label={column4}>{element.purchaseQuantity}</td>
                        <td data-label={column5}>{element.stockQuantity}</td>

                        {/* <td data-label={column6}>
                              {StatusBadge(element.eStatus)}
                            </td> */}

                        <td data-label={column6}>
                          <div className="d-flex flex-shrink-0 floatRight"></div>
                        </td>
                      </tr>
                    );
                  })
                )}
                <tr className="fw-bolder text-muted text-capitalize fs-4">
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="isupplierID"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {Idcolumn}
                      {sortConfig.key !== "isupplierID" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "isupplierID" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "isupplierID" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="supplierName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column1}
                      {sortConfig.key !== "supplierName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "supplierName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "supplierName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="ProductName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column2}
                      {sortConfig.key !== "ProductName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "ProductName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "ProductName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="purchase_price"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column3}
                      {sortConfig.key !== "purchase_price" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "purchase_price" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "purchase_price" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="purchaseQuantity"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column4}
                      {sortConfig.key !== "purchaseQuantity" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "purchaseQuantity" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "purchaseQuantity" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="stockQuantity"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {column5}
                      {sortConfig.key !== "stockQuantity" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "stockQuantity" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "stockQuantity" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  {/* <th className="min-w-100px notVisibleInMobile">{column6}</th> */}
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {/* begin:: pagination  */}

          {filteredData && filteredData.length > 0 && (
            <Pagination page={page} totalPages={totalPages} setPage={setPage} />
          )}
          {/* end:: pagination */}
        </div>

        {/* begin::Body */}
      </div>
    </>
  );
};

export default StockModal;
