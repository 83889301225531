import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { validateCoupon } from "../../redux/AuthCRUD";
import { toast } from "react-toastify";
import { createRazorpayOrder, loadScript } from "./paymentUtils/razorpayUtils";
import axios from "axios";
import clsx from "clsx";
import data from "../../../../../config.json";
// import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { useHistory } from "react-router-dom";
import { CountryCode } from "../../../../data/CountryCode";
import { findBY } from "../../../GenricComponant/findMethodBykey/findBY";
import { getStateBasedOnCountryID } from "../../../newPos/modules/Customer/redux/CustomerCRUD";
import { useIntl } from "react-intl";
// const couponSchema = Yup.object().shape({
//   couponcode: Yup.string()
//     .min(3, "Minimum 3 symbols")
//     .max(50, "Maximum 50 symbols")
//     .required(""),
// });

// Add 'Razorpay' property to Window interface
interface CustomWindow extends Window {
  Razorpay: any;
}

declare let window: CustomWindow;
const Checkout = (props: any) => {
  const intl = useIntl();
  const CheckoutSchema = Yup.object().shape({
    billing_address: Yup.string()
      .test("only-spaces", "Please enter a valid address", (value: any) => {
        return !/^[\s]*$/.test(value); // Check if input consists only of spaces
      })
      .required(intl.formatMessage({ id: "VALIDATION.ADDRESS" })),
    billing_email: Yup.string()
      .email(intl.formatMessage({ id: "VALIDATION.EMAIL.WRONG_FORMAT" }))
      .required(intl.formatMessage({ id: "VALIDATION.EMAIL.REQUIRED" })),
    billing_country: Yup.string().required(
      intl.formatMessage({ id: "CHECKOUT.VALIDATION.COUNTRY" })
    ),
    // billing_state: Yup.string().required(
    //   intl.formatMessage({ id: "CHECKOUT.VALIDATION.STATE" })
    // ),
    // billing_zipcode: Yup.string().required(
    //   intl.formatMessage({ id: "CHECKOUT.VALIDATION.ZIPCODE" })
    // ),
  });
  const history = useHistory();
  const {
    planData,
    userData,
    userId,
    extraData,
    currencySymbol,
    userPlanUUID,
  } = props.location.state;
  console.log(planData, userData, userId);
  console.log(userId, "checkoout1");
  console.log(planData, "checkoout2");
  console.log(userData, "checkoout3");
  console.log(extraData, "checkoout34");
  console.log(currencySymbol, "checkoout6");
  const gst = findBY(extraData?.taxData, "GST");
  const taxApplicable = findBY(extraData?.taxData, "TaxEnable");
  const gstr = taxApplicable === "YES" ? gst : 0;
  // const { planData, values } = props.location.state;
  const priceSelectedplan =
    currencySymbol === "$"
      ? (planData?.price / extraData?.currentDollarPriceInINR)?.toFixed(2)
      : planData?.price;
  const [finalPrice, setFinalPrice] = useState(
    planData.price ? priceSelectedplan : priceSelectedplan
  );

  const [couponCode, setCouponCode] = useState<any>("");
  const [gstRate, setGstRate] = useState(gstr);
  const [error, seterror] = useState<string>("");
  const [discount, setDiscount] = useState(0);
  const [StateOpt, setStateOpt] = useState<any>([]);
  const displayRolename = (roleid: any) => {
    return roleid === "2" ? "Fitness Center" : "Channel Partner";
  };

  const halfrate = gstRate / 2;
  const halftax = (finalPrice * halfrate) / 100;
  console.log(gst, taxApplicable, gstRate, "Tax");
  const [TotalPrice, setTotalPrice] = useState<any>(
    Number(finalPrice) + Number(halftax) * 2 || 0
  );
  // useEffect(() => {
  //   if (halftax) {
  //     const tax = halftax * 2 || 0;
  //     const price = planData.price + tax;
  //     setFinalPrice(price);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (discount) {
  //     const tax = halftax * 2 || 0;
  //     const price = planData.price + tax;
  //     setFinalPrice(price - discount);
  //   }
  // }, [discount]);

  // const getSettings = async () => {
  //   try {
  //     const response = await GetAdminSettings();
  //     if (response.status === 200) {
  //       const gstOnOff = response.data.data[2].superadminValue === 'YES';
  //       console.log("colled", gstOnOff);
  //       setGST(gstOnOff);
  //       let FinalPrice = 0;

  //       if (gstOnOff) {
  //         FinalPrice = finalPrice + finalPrice * gstRate;
  //       }
  //       formik.setFieldValue('plan_price',FinalPrice)
  //       setFinalPrice(FinalPrice);

  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   getSettings();

  // }, [])
  console.log(planData.iplanID);

  // const couponFormik = useFormik({
  //   initialValues: {
  //     couponcode: "",
  //     plan_price: finalPrice,
  //   },
  //   validationSchema: couponSchema,
  //   onSubmit: async (values) => {
  //     const response = await validateCoupon({
  //       ...values,
  //       plan_id: planData.iplanID,
  //     });
  //     // console.log(response)
  //     console.log(values);
  //     if (response.status === 200) {
  //       toast.success(response.data.message);
  //       setFinalPrice(response.data.data.finalPrice);
  //       formik.setFieldValue("plan_price", response.data.data.finalPrice);
  //       setDiscount(response.data.data.discount_amount);
  //     } else {
  //       toast.error("Invalid coupon code");
  //     }
  //   },
  // });

  const handleValidate = async (e: any) => {
    e.preventDefault(e);
    const values = {
      couponcode: couponCode || "",
      plan_price: Number(finalPrice) + Number(halftax) * 2,
    };
    const response = await validateCoupon({
      ...values,
      plan_id: planData.iplanID,
    });
    console.log(response, "response");
    console.log(values);
    if (response.status === 200) {
      toast.success(response.data.message);
      setTotalPrice(response.data.data.finalPrice);
      formik.setFieldValue("plan_price", response.data.data.finalPrice);
      setDiscount(response.data.data.discount_amount);
    } else {
      toast.error("Invalid coupon code");
    }
  };

  const formik = useFormik({
    initialValues: {
      plan_price: TotalPrice,
      billing_address: userData?.address,
      billing_email: userData?.user_email,
      billing_mobile: userData?.user_mobile,
      billing_country: userData?.user_country_code,
      billing_zipcode: "",
      billing_state: "",
      user_id: userId,
      currency: currencySymbol === "$" ? "USD" : "INR",
      couponDiscount: discount,
    },
    validationSchema: CheckoutSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const res: any = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
          alert("Razorpay SDK failed to load");
          return;
        }

        const roundedTotalPrice = Math.round(TotalPrice * 100) / 100;
        const formData = {
          plan_price: roundedTotalPrice,
          billing_address: userData?.address,
          billing_email: userData?.user_email,
          billing_mobile: userData?.user_mobile,
          billing_country: userData?.user_country_code,
          billing_zipcode: values?.billing_zipcode,
          billing_state: values?.billing_state,
          user_id: userId,
          currency: currencySymbol === "$" ? "USD" : "INR",
          planId: planData.iplanID,
          userPlanUUID: userPlanUUID,
        };

        const orderDetails = await createRazorpayOrder(formData);
        const options = {
          key: process.env.REACT_APP_RAZOR_PAY_SECRET_KEY,
          amount: orderDetails.amount,
          currency: currencySymbol === "$" ? "USD" : "INR",
          order_id: orderDetails.id,
          name: "Automize Gym",
          description: "Payment for Plan",
          handler: async function (response: any) {
            console.log("Payment successful:", response);
            const {
              razorpay_order_id,
              razorpay_payment_id,
              razorpay_signature,
            } = response || {};
            const updateResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/v1/user/updateplan`,
              {
                ...values,
                razorpay_order_id,
                razorpay_payment_id,
                razorpay_signature,
                plan_id: planData.iplanID,
                userPlanUUID: userPlanUUID,
              }
            );

            if (updateResponse?.status === 200) {
              history.push("/admin/auth/login");
              toast.success(
                "Billing information and payment status updated successfully!"
              );
            } else {
              toast.error(updateResponse?.data?.replyMsg);
            }
          },
          prefill: {
            name: userData.billing_email || "Default Name",
            email: userData.user_email || "default@email.com",
          },
          notes: {
            address: data?.Address,
          },
          theme: {
            color: "#61dafb",
          },
          // modal: {
          //   ondismiss: function () {
          //     console.log("Payment form closed");
          //     toast.error(
          //       "Failed to update billing information and payment status."
          //     );
          //     // Additional actions like redirecting the user can be performed here
          //   },
          // },
        };

        const razorpayInstance = new window.Razorpay(options);
        razorpayInstance.on("payment.failed", async (response: any) => {
          console.error("Payment failed:", response.error);

          const errorDetails = {
            error_code: response.error.code,
            error_description: response.error.description,
            error_source: response.error.source,
            error_step: response.error.step,
            error_reason: response.error.reason,
            razorpay_payment_id: response.error.metadata.payment_id,
            razorpay_order_id: response.error.metadata.order_id,
            razorpay_signature: response.error.metadata.razorpay_signature,
            userPlanUUID: userPlanUUID,
          };

          console.log("Failed Payment Details:", errorDetails);

          // ❗ Send Failure Details to Backend
          await axios
            .post(`${process.env.REACT_APP_API_URL}/v1/user/paymentfailure`, {
              ...values,
              ...errorDetails,
            })
            .catch((err) =>
              console.error("Error logging failed payment:", err)
            );

          // ❗ Display Error Message to User
          toast.error(`Payment Failed: ${response.error.description}`);
        });

        razorpayInstance.open();
      } catch (error) {
        console.log(error);
        toast.error("Error processing the payment");
      }
    },
  });

  useEffect(() => {
    if (userData && userData.country_currency_id) {
      GetStateByCuntryID(userData.country_currency_id);
    }
  }, [userData]);
  const GetStateByCuntryID = async (ID: any) => {
    const result = await getStateBasedOnCountryID(ID);
    console.log(result, "States");
    setStateOpt(result?.data?.data || []);
  };

  return (
    <>
      <div
        className="container shadow-sm bg-white"
        style={{ marginTop: "-95px", zIndex: 3 }}
      >
        <h1 className="text-center my-3 pb-5 pt-4">
          {intl.formatMessage({ id: "CHECKOUT.PAGE" })}
        </h1>
        <div className="row">
          {/* left Section */}
          <div className="col-md-7 p-4">
            <div className="plan-details">
              <h1 className="">
                {intl.formatMessage({ id: "COMMON.PLANDETAILS" })} :
              </h1>
              <div className="row bg-light p-3 rounded">
                <div className="col-md-12">
                  <p className="fw-bold text-dark fs-2">
                    {planData.membershipTitle}{" "}
                  </p>
                  {/* <p className="fs-6">Descriprion: {planData.description}</p> */}
                  <div className="d-flex flex-column flex-md-row justify-content-md-between   ">
                    <div className="fs-6">
                      <b>{intl.formatMessage({ id: "FITNESS.BRANCHES" })}: </b>
                      {planData.noOfBrachAllow || "unlimited"}
                    </div>
                    <div className="fs-6">
                      <b> {intl.formatMessage({ id: "FITNESS.MEMBERS" })}:</b>
                      {planData.maxMember || "unlimited"}
                    </div>
                    <div className="fs-6">
                      <b> {intl.formatMessage({ id: "FITNESS.ENQUIRIES" })}:</b>
                      {planData.MaxInquiry || "unlimited"}
                    </div>
                  </div>
                  <div className="fs-6">
                    <b>
                      {intl.formatMessage({ id: "CHECKOUT.PLANDURATION" })}:
                    </b>
                    {planData.days}Days
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h1 className="my-6">
                {intl.formatMessage({ id: "CHECKOUT.USERDETAILS" })} :
              </h1>
            </div>
            <div className="row">
              <div className="col-xl-6 my-3">
                <label className="form-label fw-bolder text-dark fs-6">
                  {intl.formatMessage({ id: "CHECKOUT.USERNAME" })}
                </label>
                <input
                  placeholder="Name"
                  type="text"
                  autoComplete="off"
                  disabled
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  value={
                    userData?.user_first_name + " " + userData?.user_last_name
                  }
                />
              </div>
              <div className="col-xl-6 my-3">
                <label className="form-label fw-bolder text-dark fs-6">
                  {intl.formatMessage({ id: "COMMON.EMAIL" })}
                </label>
                <input
                  placeholder="Email"
                  type="text"
                  autoComplete="off"
                  disabled
                  {...formik.getFieldProps("billing_email")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                />
                {formik.touched.billing_email && formik.errors.billing_email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span className="error">
                        {formik.errors.billing_email}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 my-3">
                <label className="form-label fw-bolder text-dark fs-6">
                  {intl.formatMessage({ id: "COMMON.MOBILE" })}
                </label>
                <input
                  placeholder="Mobile Number"
                  type="text"
                  autoComplete="off"
                  disabled
                  {...formik.getFieldProps("billing_mobile")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                />
              </div>
              <div className="col-xl-6 my-3">
                <label className="form-label fw-bolder text-dark fs-6">
                  {intl.formatMessage({ id: "MODULE.ROLE.ROLENAME" })}
                </label>
                <input
                  placeholder="Mobile Number"
                  type="text"
                  autoComplete="off"
                  disabled
                  value={displayRolename(userData?.user_role_id)}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                />
              </div>
            </div>

            <h1 className="my-6">
              {intl.formatMessage({ id: "CHECKOUT.BILLINGDETAILS" })}
            </h1>
            <div className="col-xl-12 my-3">
              <label className="form-label fw-bolder text-dark fs-6">
                {intl.formatMessage({ id: "CHECKOUT.ENTERURADDRESS" })}
              </label>
              <textarea
                placeholder={intl.formatMessage({
                  id: "CHECKOUT.ENTERURADDRESS",
                })}
                // type="text"
                autoComplete="off"
                {...formik.getFieldProps("billing_address")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.billing_address &&
                      formik.errors.billing_address,
                  },
                  {
                    "is-valid":
                      formik.touched.billing_address &&
                      !formik.errors.billing_address,
                  }
                )}
              />
              {formik.touched.billing_address && formik.errors.billing_address && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="error">
                      {formik.errors.billing_address}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="row my-3">
              <div className="col-6">
                <label className="form-label fw-bolder text-dark fs-6">
                  {intl.formatMessage({ id: "CHECKOUT.ZIPCode" })}
                </label>
                <input
                  placeholder={intl.formatMessage({ id: "CHECKOUT.ZIPCode" })}
                  type="number"
                  autoComplete="off"
                  {...formik.getFieldProps("billing_zipcode")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    {
                      "is-invalid":
                        formik.touched.billing_zipcode &&
                        formik.errors.billing_zipcode,
                    },
                    {
                      "is-valid":
                        formik.touched.billing_zipcode &&
                        !formik.errors.billing_zipcode,
                    }
                  )}
                />
                {formik.touched.billing_zipcode &&
                  formik.errors.billing_zipcode && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span className="error">
                          {formik.errors.billing_zipcode}
                        </span>
                      </div>
                    </div>
                  )}
              </div>

              <div className="col-6">
                <label className="form-label fw-bolder text-dark fs-6">
                  {intl.formatMessage({ id: "CHECKOUT.YOURCOUNTRY" })}
                </label>
                <div className="">
                  <select
                    name="billing_country"
                    className="form-control form-control-lg form-control-solid"
                    onChange={formik.handleChange("billing_country")}
                    onBlur={formik.handleBlur("billing_country")}
                    value={formik.values.billing_country}
                    disabled
                  >
                    <option value="">
                      {intl.formatMessage({ id: "COMMON.SELECT" })}
                    </option>
                    {CountryCode?.map((element: any, index) => {
                      return (
                        <option value={element.code} key={index}>
                          {element.country}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.billing_country &&
                    formik.errors.billing_country && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span className="error">
                            {formik.errors.billing_country}
                          </span>
                        </div>
                      </div>
                    )}

                  <span className="absolute right-4 top-1/2 z-30 -translate-y-1/2"></span>
                </div>
              </div>
              <div className="col-6">
                <label className="form-label fw-bolder text-dark fs-6">
                  {intl.formatMessage({ id: "CHECKOUT.STATE" })}
                </label>
                <select
                  name="billing_state"
                  className="form-control form-control-lg form-control-solid"
                  onChange={formik.handleChange("billing_state")}
                  onBlur={formik.handleBlur("billing_state")}
                  value={formik.values.billing_state}
                  // disabled
                >
                  <option value="">
                    {intl.formatMessage({ id: "COMMON.SELECT" })}
                  </option>
                  {StateOpt?.map((element: any, index: any) => {
                    return (
                      <option value={element.state_name} key={index}>
                        {element.state_name}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.billing_state && formik.errors.billing_state && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span className="error">
                        {formik.errors.billing_state}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="col-md-5 p-4 position-relative ">
            <div className="d-flex flex-column h-100 ">
              {/* First Div: Half Height */}
              {/* <div className='bg-light p-4 mb-3 text-center rounded' style={{ height: '40%' }}>
                <div className=' bg-white d-inline-block p-3 shadow-sm'>

                  <div className='symbol symbol-100px rounded-circle '>
                    <div className='symbol-label fs-1 fw-semibold text-danger'>
                      {userData && userData.user_first_name
                        ? userData.user_first_name.substring(0, 1).toUpperCase()
                        : 'NOO'}
                    </div>
                  </div>

                </div>
                <h1 className='mt-3'>User Details :</h1>

                <p className='mb-1'>
                  <strong>Name:</strong> {userData?.user_first_name}
                </p>
                <p className='mb-1'>
                  <strong>Email:</strong> {userData?.user_email}
                </p>
                <p className='mb-0'>
                  <strong>role:</strong> {displayRolename(userData?.user_role_id)}
                </p>
                <p className='mb-1'>
                  <strong>Country Code:</strong> {userData?.user_country_code}
                </p>
              </div> */}

              {/* Third Div: 50px Height (Positioned Absolutely) */}

              {/* Second Div: Half Height */}
              <div className="p-4  bg-light rounded">
                {/* final amount price  */}
                <div className="plan_price_details">
                  <div className="mt-2">{/* Apply coupon code here  */}</div>
                  <div className="p-4">
                    <h1 className="py-4">
                      {intl.formatMessage({ id: "CHECKOUT.PLANPRICEDETAILS" })}
                    </h1>
                    <div className="d-flex justify-content-between align-items-center mb-3 pt-2 pb-2 ">
                      <h4 className="mb-0">
                        {intl.formatMessage({ id: "CHECKOUT.PLANPRICE" })}
                      </h4>
                      <span>
                        {finalPrice}
                        <span>{currencySymbol || ""}</span>
                      </span>{" "}
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3 pt-2 pb-2">
                      <h4 className="mb-0">
                        {intl.formatMessage({ id: "CHECKOUT.IGST" })}:(
                        {halfrate} %)
                      </h4>
                      <span>
                        {halftax?.toFixed(2) || 0}
                        <span>{currencySymbol || ""}</span>
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3 pt-2 pb-2">
                      <h4 className="mb-0">
                        {intl.formatMessage({ id: "CHECKOUT.CGST" })} :(
                        {halfrate} %)
                      </h4>
                      <span>
                        {halftax?.toFixed(2) || 0}
                        <span>{currencySymbol || ""}</span>
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3 pt-2 pb-2">
                      <h4 className="mb-0">
                        {intl.formatMessage({ id: "CHECKOUT.TOTAL" })}:
                      </h4>
                      <span>
                        {(
                          Number(finalPrice) + Number(halftax + halftax)
                        )?.toFixed(2) || 0}{" "}
                        <span>{currencySymbol || ""}</span>
                      </span>{" "}
                    </div>
                    <div className="py-1">
                      {/* <form onSubmit={couponFormik.handleSubmit}> */}
                      <div className="row my-4">
                        <label className="form-label fw-bolder text-dark fs-6">
                          {intl.formatMessage({ id: "CHECKOUT.ENTER.COUPON" })}:
                        </label>
                        <div className="col-7">
                          <input
                            type="text"
                            name="couponcode"
                            className="form-control form-control-lg "
                            onChange={(e) => setCouponCode(e.target.value)}
                            // onBlur={couponFormik.handleBlur("couponcode")}
                            value={couponCode}
                          ></input>
                          {/* {couponFormik.touched.couponcode &&
                              couponFormik.errors.couponcode && (
                                <div className="fv-plugins-message-container">
                                  <span className="error">
                                    {couponFormik.errors.couponcode}
                                  </span>
                                </div>
                              )} */}
                          {error && (
                            <>
                              <div className="error">{error}</div>
                            </>
                          )}
                        </div>
                        <div className="col-4">
                          <button
                            type="button"
                            onClick={(e) => handleValidate(e)}
                            className="btn btn-primary "
                          >
                            {intl.formatMessage({ id: "COMMON.APPLY" })}
                          </button>
                        </div>
                      </div>
                      {/* </form> */}
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3 pt-2 pb-2">
                      <h4 className="mb-0">
                        {intl.formatMessage({ id: "FITNESS.DISCOUNT" })} :
                      </h4>
                      <span>
                        {discount}
                        <span>{currencySymbol || ""}</span>
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-2 pb-2 border-top">
                      <h4 className="mb-0">
                        {intl.formatMessage({ id: "CHECKOUT.FINAL.PRICE" })}:
                      </h4>
                      <span>
                        {TotalPrice?.toFixed(2)}
                        <span>{currencySymbol || ""}</span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <button
                        type="submit"
                        className="form-control form-control-lg  btn-primary text-light"
                      >
                        {intl.formatMessage({ id: "CHECKOUT.MAKE.PAYMENT" })}
                      </button>
                    </form>
                  </div>
                </div>

                {/* <div className='ms-5'>
                 <h2 className='mt-10'>Module Information</h2>
                 <ul className='fs-4'>
                    {
                      planData.modules.map((element:any)=>{
                          return <li className='my-2'>{element.moduleName}</li>
                      })
                    }
                 </ul>
                 </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
