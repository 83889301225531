import { Modal, Button } from "react-bootstrap";

import { useIntl } from "react-intl";
import RecieptModal from "./RecieptModal";
import DueAmountModal from "./DueAmountModal";
import MyPurchaseModal from "./MyPurchaseModal";
import MyMembershipModal from "./MyMembershipModal";

const DashboardModal = ({
  showModal,
  setShowModal,
  selectedDate,
  cardTitle,
  isFrom,
}: any) => {
  // console.log(cardTitle);
  const intl = useIntl();
  console.log(isFrom, "Expiry all");
  const handleClose = () => setShowModal(false);
  let modalContent, modalTitle;

  switch (cardTitle) {
    case "Receipts":
      modalContent = <RecieptModal />;
      modalTitle = intl.formatMessage({ id: "DASHBOARD.MEMBER.INVOICE" });
      break;
    case "DueAmount":
      modalContent = <DueAmountModal />;
      modalTitle = intl.formatMessage({ id: "DASHBOARD.MEMBER.DUEAMOUNT" });
      break;
    case "MyPurchase":
      modalContent = <MyPurchaseModal />;
      modalTitle = intl.formatMessage({ id: "DASHBOARD.MEMBER.MYPURCHASE" });
      break;
    case "Enrollment":
      modalContent = <MyMembershipModal />;
      modalTitle = intl.formatMessage({
        id: "DASHBOARD.MEMBER.ENROLMENTAMOUNT",
      });
      break;
    default:
      modalContent = null;
  }
  // console.log(selectedDate, "selectedDate");
  return (
    <Modal show={showModal} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalContent}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DashboardModal;
