import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Table, Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { activeNotesApi } from "../../../../../finance/modules/invoice/component/redux/invoiceCRUD";
import "../product.css";
import { DateFormate } from "../../../../../../../_metronic/helpers";
import moment from "moment";

const BillPage = ({
  printShow,
  setPrintShow,
  printData,
  CurrentCurrency,
  currencySymbol,
  baseURL,
}: any) => {
  const Intl = useIntl();
  const handleClose = () => setPrintShow(false);
  // const [tax, setTax] = useState<any>();
  const [notes, setNotes] = useState<any>("");
  const currency = CurrentCurrency;
  const printRef = useRef<any>();

  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);

  useEffect(() => {
    getnotesForInvoice(ActiveBranchUUID);
  }, []);

  const getnotesForInvoice = async (branchUUID: any) => {
    const response = await activeNotesApi(
      UserType !== "member" ? branchUUID : ActiveBranch[0]?.iBranchUUID
    );
    setNotes(response?.data?.data?.suppInvocieNotes);
    // console.log(response?.data, "reponseofactive");
  };
  const splittedNote = notes?.split("\n");
  // console.log("spliitedd", splittedNote);
  const handlePrint = () => {
    const printContent: any = printRef.current.innerHTML;
    const printWindow: any = window.open("", "", "height=800,width=900");
    printWindow.document.write("<html><head>");
    printWindow.document.write(
      '<link href="https://stackpath.bootstrapcdn.com/bootstrap/5.0.0-alpha1/css/bootstrap.min.css" rel="stylesheet">'
    );
    printWindow.document.write("<title>Print</title><style>");
    // Add your custom styles here
    printWindow.document.write(`
      .marg { margin: 1rem; }
      .text-end { text-align: right; }
      .rightpara { text-align: right; }
      .alert-primary {
        color: #073984;
        background-color: #cfe2ff;
        border-color: #bbd6fe;
      }
      .lineSpace {
        line-height: 0.5;
      }
        
    `);
    printWindow.document.write("</style></head><body>");
    printWindow.document.write(printContent);
    printWindow.document.write("</body></html>");
    setTimeout(() => printWindow.print(), 1000);
  };

  const Tax = printData?.taxPercentage;
  let totalTax = 0;
  const NetAmount = printData?.totalSellPrice + totalTax - printData?.discount;
  let subtotal = 0;
  // console.log(printData, "printdata");
  const totalTaxx: any = parseFloat(
    (
      (printData?.data?.paidamount *
        (printData?.data?.taxeData[0]?.rate +
          printData?.data?.taxeData[1]?.rate +
          printData?.data?.taxeData[2]?.rate)) /
      100
    ).toFixed(2)
  );
  const withoutTax = parseFloat(
    (printData?.data?.paidamount - totalTax).toFixed(2)
  );
  const discount = printData?.data?.discountAmt;
  const startIndex = discount && discount?.indexOf("(") + 1;
  const endIndex = discount && discount?.indexOf("%");
  const percentage = discount && discount?.slice(startIndex, endIndex);
  const discountAmount = (withoutTax * percentage) / 100;
  console.log(printData, "printdata");
  return (
    <>
      <Modal
        show={printShow}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {`${Intl.formatMessage({
              id: "PRINT.SUPPLIMENT.HEADING.BILL",
            })}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body scroll-y mx-5 mx-xl-15 my-7">
          <Container>
            <div ref={printRef} className="marg">
              <Row className="my-4">
                <Col>
                  <h2>
                    {Intl.formatMessage({
                      id: "PRINT.SUPPLIMENT.HEADING.BILL",
                    })}
                  </h2>
                  <div className="fs-5 fw-bold">
                    {DateFormate(printData?.sell_date)}
                  </div>
                </Col>
                <Col className="d-flex justify-content-start ">
                  {baseURL && printData?.companyData?.vImageName && (
                    <img
                      src={`${baseURL}/${printData?.companyData?.vImageName}`}
                      width={50}
                      height={50}
                      className="border border-2"
                    />
                  )}
                </Col>
              </Row>
              {/* <div className="row mb-5">
                <div className="col-6">
                  <h2>{`${Intl.formatMessage({
                    id: "MODULE.MEMBERS.RECIPTNO",
                  })} -  ${printData?.data?.invocieSerialNo}`}</h2>
                </div>
                <div className="col-6 text-md-end rightpara">
                  <img
                    src={`${printData?.imageBaseURL}/${printData?.data?.companyData?.vImageName}`}
                    width={130}
                    height={130}
                    className="border"
                  />
                </div>
              </div> */}
              <style>
                {`
            .lh-1 {
             line-height: 0.6 !important; /* Sets the line height */
          }
        `}
              </style>
              <div className="row">
                <div className="col-6 lh-1 lineSpace">
                  <h5> {Intl.formatMessage({ id: "INVOICE.FROM" })}</h5>
                  <p>{printData?.companyData?.vCompanyName}</p>
                  <p>{printData?.companyData?.vGymName}</p>

                  <p>
                    {/* {Intl.formatMessage({ id: "INVOICE.CONTACT" })} :-{" "} */}
                    {printData?.companyData?.vCountryCode}{" "}
                    {printData?.companyData?.vPhoneNo}
                  </p>
                  <p>
                    {" "}
                    {/* {Intl.formatMessage({ id: "INVOICE.EMAIL" })}:-{" "} */}
                    {printData?.companyData?.vEmail}
                  </p>
                  <p>
                    {" "}
                    {/* {Intl.formatMessage({ id: "INVOICE.WEBSITE" })}:- */}
                    {printData?.companyData?.vWebsiteName}
                  </p>
                  <p>
                    {" "}
                    {/* {Intl.formatMessage({ id: "INVOICE.GSTNO" })}:- */}
                    {printData?.companyData?.vGstNo}
                  </p>
                  <p
                    style={{
                      whiteSpace: "pre-line",
                      lineHeight: "19px",
                      // width: "100px",
                    }}
                  >
                    {" "}
                    {/* {Intl.formatMessage({ id: "INVOICE.ADDRESS" })} :- */}
                    {printData?.companyData?.tAddress}
                  </p>
                </div>
                <div className="col-6  lh-1 text-end rightpara lineSpace">
                  <h5> {Intl.formatMessage({ id: "INVOICE.TO" })}</h5>
                  <p>{printData?.saleUserType}</p>
                  <p>
                    {printData?.saleUserType === "Member" ||
                    printData?.PaymentUserType === "Member"
                      ? printData?.memberName
                      : printData?.customerName}
                  </p>

                  <p>
                    {printData?.saleUserType === "Member" ||
                    printData?.PaymentUserType === "Member"
                      ? printData?.MemberCountryCode
                      : printData?.customerCountryCode}
                    {"  "}
                    {printData?.saleUserType === "Member" ||
                    printData?.PaymentUserType === "Member"
                      ? printData?.memberMobileNo
                      : printData?.customerMobileno}
                  </p>

                  <p
                    style={{
                      whiteSpace: "pre-line",
                      lineHeight: "19px",
                      // width: "100px",
                    }}
                    className="rightpara"
                  >
                    {printData?.saleUserType === "Member" ||
                    printData?.PaymentUserType === "Member"
                      ? printData?.memberAddress
                      : printData?.customerAddress}
                  </p>
                </div>
              </div>
              {printData?.iProductSellDetailID ? (
                <div className="row">
                  <div className="alert alert-primary">
                    {`${Intl.formatMessage({
                      id: "PRINT.SUPPLIMENT.HEADING.BILLNO",
                    })} -${printData?.iProductSellDetailID}`}
                  </div>
                </div>
              ) : (
                ""
              )}
              {printData?.iProductSellDetailID ? (
                <Row className="my-4">
                  <Col>
                    <h5>
                      {`${Intl.formatMessage({
                        id: "MODULE.SUPPLIMENT.PRODUCTDETAILS",
                      })}
                    ${
                      printData?.taxesApplicable == 1
                        ? printData?.TaxesWithSellAmt == 1
                          ? "( tax on product )"
                          : "( Tax outside the product )"
                        : ""
                    } `}
                    </h5>
                    {/* <h6>{`${Intl.formatMessage({
                    id: "MODULE.MEMBERS.RECIPTNO",
                  })}`}</h6> */}
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr className="fw-bolder text-dark text-uppercase">
                          <th className="notVisibleInMobile">{`${Intl.formatMessage(
                            {
                              id: "PRINT.SUPPLIMENT.HEADING.NAME",
                            }
                          )} `}</th>
                          <th className="notVisibleInMobile">{
                            `${Intl.formatMessage({
                              id: "PRINT.SUPPLIMENT.HEADING.PRICE",
                            })} `
                            // ${printData?.data?.companyData?.vHsnCode}
                          }</th>
                          {/* <th>X</th> */}
                          <th className="notVisibleInMobile">{`${Intl.formatMessage(
                            {
                              id: "PRINT.SUPPLIMENT.HEADING.QUANTITY",
                            }
                          )} `}</th>

                          {currency == "INR" ? (
                            printData?.taxesApplicable == 1 && (
                              <>
                                {/* <th>+</th> */}
                                <th className="notVisibleInMobile">{`${Intl.formatMessage(
                                  {
                                    id: "MODULE.SUPPLIMENT.TABLE.CGST",
                                  }
                                )} `}</th>
                                {/* <th>+</th> */}
                                <th className="notVisibleInMobile">{`${Intl.formatMessage(
                                  {
                                    id: "MODULE.SUPPLIMENT.TABLE.IGST",
                                  }
                                )} `}</th>
                              </>
                            )
                          ) : (
                            <>
                              {/* <th>+</th> */}
                              <th className="notVisibleInMobile">{`${Intl.formatMessage(
                                {
                                  id: "MODULE.SUPPLIMENT.TABLE.GST",
                                }
                              )} `}</th>
                            </>
                          )}
                          {/* <th>=</th> */}
                          <th className="text-center notVisibleInMobile">{`${Intl.formatMessage(
                            {
                              id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                            }
                          )} `}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {printData?.sellProducts?.map((item: any, i: any) => {
                          const sprice = item.selll_price;
                          const ogPrice = item.originalSellPrice;
                          const quantity = item.no_of_quantity;
                          const taxPercent = item.taxInPercentage;
                          const half = taxPercent / 2;
                          const halftax = (ogPrice * quantity * half) / 100;
                          const fulltax = halftax * 2;
                          totalTax += fulltax;
                          const total = ogPrice * quantity + fulltax;
                          subtotal +=
                            printData?.TaxesWithSellAmt !== 1
                              ? ogPrice * quantity + fulltax
                              : ogPrice * quantity;

                          return (
                            <tr key={i}>
                              <td
                                className=""
                                data-label={Intl.formatMessage({
                                  id: "PRINT.SUPPLIMENT.HEADING.NAME",
                                })}
                              >
                                {item.name}
                              </td>
                              <td
                                className=""
                                data-label={Intl.formatMessage({
                                  id: "PRINT.SUPPLIMENT.HEADING.PRICE",
                                })}
                              >
                                {ogPrice}
                              </td>
                              {/* <td className="">X</td> */}
                              <td
                                className=""
                                data-label={Intl.formatMessage({
                                  id: "PRINT.SUPPLIMENT.HEADING.QUANTITY",
                                })}
                              >
                                {quantity}
                              </td>
                              {currency == "INR" ? (
                                printData?.taxesApplicable == 1 && (
                                  <>
                                    {/* <td className="">+</td> */}
                                    <td
                                      className=""
                                      data-label={Intl.formatMessage({
                                        id: "MODULE.SUPPLIMENT.TABLE.CGST",
                                      })}
                                    >{`${halftax} ( ${
                                      item?.taxInPercentage / 2
                                    } %)`}</td>
                                    {/* <td className="">+</td> */}
                                    <td
                                      className=""
                                      data-label={Intl.formatMessage({
                                        id: "MODULE.SUPPLIMENT.TABLE.IGST",
                                      })}
                                    >{`${halftax} ( ${
                                      item?.taxInPercentage / 2
                                    } %)`}</td>
                                  </>
                                )
                              ) : (
                                <>
                                  {/* <td>+</td> */}
                                  <td
                                    data-label={Intl.formatMessage({
                                      id: "MODULE.SUPPLIMENT.TABLE.GST",
                                    })}
                                  >
                                    {fulltax}
                                  </td>
                                </>
                              )}
                              {/* <td className="">=</td> */}
                              <td
                                className="text-md-center floatRight"
                                data-label={Intl.formatMessage({
                                  id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                                })}
                              >
                                {printData?.TaxesWithSellAmt !== 1
                                  ? total
                                  : ogPrice * quantity}
                              </td>
                            </tr>
                          );
                        })}
                        <tr className="fw-bolder text-dark ">
                          <td
                            colSpan={
                              currency == "INR"
                                ? printData?.taxesApplicable == 1
                                  ? 5
                                  : 3
                                : 4
                            }
                            className="text-end pe-5 notVisibleInMobile"
                          >
                            {Intl.formatMessage({
                              id: "MODULE.SUPPLIMENT.TABLE.SUBTOTAL",
                            })}
                          </td>
                          <td
                            colSpan={2}
                            className="text-md-center"
                            data-label={Intl.formatMessage({
                              id: "MODULE.SUPPLIMENT.TABLE.SUBTOTAL",
                            })}
                            // data-label={Intl.formatMessage({
                            //   id: "MODULE.SUPPLIMENT.TABLE.SUBTOTAL",
                            // })}
                          >
                            {subtotal}
                          </td>
                        </tr>
                        <tr className="fw-bolder text-dark">
                          <td
                            colSpan={
                              currency == "INR"
                                ? printData?.taxesApplicable == 1
                                  ? 5
                                  : 3
                                : 4
                            }
                            className="text-end pe-5 notVisibleInMobile"
                          >
                            {`${Intl.formatMessage({
                              id: "MODULE.MEMBERS.DISCOUNT",
                            })}   ${
                              printData?.discountPercentage !== 0
                                ? `(${printData?.discountPercentage} %)`
                                : ""
                            } `}
                          </td>
                          <td
                            colSpan={2}
                            className="text-md-center "
                            data-label={`${Intl.formatMessage({
                              id: "MODULE.MEMBERS.DISCOUNT",
                            })}  `}
                          >
                            {printData?.discount}
                          </td>
                        </tr>
                        {printData?.taxeswithsaleAmount === 1 && (
                          <tr className="fw-bolder text-dark">
                            <td
                              colSpan={
                                currency == "INR"
                                  ? printData?.taxesApplicable == 1
                                    ? 5
                                    : 3
                                  : 4
                              }
                              className="text-end pe-5 notVisibleInMobile"
                            >
                              {`${Intl.formatMessage({
                                id: "MODULE.SUPPLIMENT.TABLE.GST",
                              })}  ( + )`}
                            </td>
                            <td
                              colSpan={2}
                              className="text-md-center"
                              data-label={`${Intl.formatMessage({
                                id: "MODULE.SUPPLIMENT.TABLE.GST",
                              })}  `}
                            >
                              {totalTax.toFixed(2)}
                            </td>
                          </tr>
                        )}
                        <tr className="fw-bolder text-dark">
                          <td
                            colSpan={
                              currency == "INR"
                                ? printData?.taxesApplicable == 1
                                  ? 5
                                  : 3
                                : 4
                            }
                            className="text-end pe-5 notVisibleInMobile"
                          >
                            {Intl.formatMessage({
                              id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                            })}
                          </td>
                          <td
                            colSpan={2}
                            className="text-md-center"
                            data-label={Intl.formatMessage({
                              id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                            })}
                          >
                            {(
                              printData?.totalSellPrice +
                              totalTax -
                              printData?.discount
                            ).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {console.log(printData, "printedData")}
              {printData?.paymentDetails?.amount ? (
                <Row className="mb-4">
                  <Col>
                    <h5>
                      {Intl.formatMessage({
                        id: "MODULE.INVOICES.PAYMENTDETAILS",
                      })}{" "}
                    </h5>

                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th className="notVisibleInMobile">
                            {Intl.formatMessage({
                              id: "SELLPAYMENT.DATE&TIME",
                            })}
                          </th>
                          {/* <th>{
                          `${Intl.formatMessage({
                            id: "MODULE.RECIEPT.HSNNO",
                          })} `
                          // ${printData?.data?.companyData?.vHsnCode}
                        }</th> */}
                          {/* {printData?.iProductSellDetailID ? (
                            <th className="notVisibleInMobile">{`${Intl.formatMessage(
                              {
                                id: "MODULE.RECIEPT.BALNCEAMOUNT",
                              }
                            )} `}</th>
                          ) : (
                            ""
                          )} */}
                          <th className="notVisibleInMobile">{`${Intl.formatMessage(
                            {
                              id: "MODULE.RECIEPT.PAIDAMOUNT",
                            }
                          )} `}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            data-label={`${Intl.formatMessage({
                              id: "SELLPAYMENT.DATE&TIME",
                            })} `}
                          >
                            {printData?.paymentDetails?.paymentDate +
                              `  (${moment(
                                printData?.paymentDetails?.paymentTime,
                                "HH:mm"
                              ).format("hh:mm A")})`}
                          </td>
                          {/* <td>{printData?.data?.companyData?.vHsnCode}</td> */}
                          {/* {printData?.iProductSellDetailID ? (
                            <td
                              data-label={`${Intl.formatMessage({
                                id: "MODULE.RECIEPT.BALNCEAMOUNT",
                              })} `}
                            >
                              {printData?.dueAmount}
                            </td>
                          ) : (
                            ""
                          )} */}
                          <td
                            data-label={`${Intl.formatMessage({
                              id: "MODULE.RECIEPT.PAIDAMOUNT",
                            })} `}
                          >
                            {printData?.paymentDetails?.amount}
                          </td>
                        </tr>

                        <tr className="fw-bolder text-dark fs-4">
                          <td
                            colSpan={1}
                            className="text-end fw-bold notVisibleInMobile"
                          >
                            {Intl.formatMessage({
                              id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                            })}
                          </td>
                          <td
                            className="fw-bold"
                            data-label={`${Intl.formatMessage({
                              id: "PRINT.SUPPLIMENT.HEADING.TOTAL",
                            })} `}
                          >
                            {printData?.paymentDetails?.amount}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              <Row>
                <p>{`${Intl.formatMessage({
                  id: "COMMON.NOTE",
                })} `}</p>

                <div className=" suppnote">
                  {splittedNote?.map((item: any, i: any) => (
                    <p
                      key={i}
                      className="fs-7 "
                      style={{
                        lineHeight: "19px",
                      }}
                    >
                      {item}
                    </p>
                  ))}
                </div>
                <Row className="mt-5">
                  <Col>{`${Intl.formatMessage({
                    id: "NOTE.AUTHIRIZE",
                  })} `}</Col>
                  <Col className="text-center">_________________</Col>
                </Row>
              </Row>
            </div>
            <Row className="my-4">
              <Col className="text-md-end">
                {/* <Button variant="primary" className="me-2">
                  Download PDF
                </Button> */}
                <Button onClick={handlePrint} variant="secondary">
                  {Intl.formatMessage({ id: "COMMON.PRINT" })}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BillPage;
