import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { findBY } from "../../../../app/modules/GenricComponant/findMethodBykey/findBY";

const RemainModal = ({ show, setShow }: any) => {
  const footer: any = localStorage.getItem("footer");
  const data = JSON.parse(footer);

  const value = findBY(data, "contactDetails");
  // console.log(value, "footer");
  const intl = useIntl();
  const handleClose = () => {
    setShow(false);
  };
  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      {" "}
      <Modal.Header closeButton>
        <Modal.Title>
          {`${intl.formatMessage({
            id: "MODULE.REMAININGTIME",
          })}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body scroll-y mx-2 mx-xl-5 my-2">
        <div></div>
        <div>
          <pre className="fs-4">{value}</pre>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RemainModal;
