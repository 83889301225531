// razorpayUtils.ts
import axios from "axios";
// Function to dynamically load the Razorpay script
export const loadScript = (src: string): Promise<void> => {
  return new Promise((resolve: any) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

// Function to create a Razorpay order on the server (Mock API using JSONPlaceholder)
export const createRazorpayOrder = async (values: any): Promise<any> => {
  try {
    // Mock API endpoint URL for creating a Razorpay order
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/user/checkout`;

    // Make a POST request to the mock API to create the Razorpay order

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: values.plan_price,
        currency: values.currency,
        userId: values.user_id,
        planId: values.planId,
        userPlanUUID: values.userPlanUUID,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to create Razorpay order");
    }

    const data = await response.json();
    // Simulate the response from the server (replace with actual order details)
    const orderId = data.order.id; // Use some property from the API response to represent the order ID

    // Simulate the order details received from the server
    const orderDetails = {
      id: orderId,
      amount: data.order.amount, // Replace with the actual order amount received from the API
      currency: "INR", // Replace with the actual currency received from the API
    };

    return orderDetails; // Return the simulated order details
  } catch (error: any) {
    throw new Error("Error creating Razorpay order: " + error.message);
  }
};
