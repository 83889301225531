/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Userinvoices from "../../finance/invoice/redux/invoiceRedux";
import { CSVLink } from "react-csv";
import { Form, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import swal from "sweetalert";
import { useFormik } from "formik";
import { KTSVG } from "../../../../_metronic/helpers";

// import * as member from "./redux/invoiceRedux";
import {
  ActiveEnquiryList,
  ActiveEmpList,
  ActivePackageList,
  GetGenders,
  GetMaritalStatus,
  ActiveOccupationList,
  ActiveBatchList,
} from "../../../modules/members/redux/MembersCRUD";
import {
  GetMembers,
  UpdateStatus,
  DeleteMember,
  batchIDAPII,
  // ActiveMemberList,
  activeUserList,
  getMemberDetailsByRecipt,
  PrintRecipt,
} from "../../finance/invoice/redux/invoiceCRUD";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/DateRangePicker";
import { useIntl } from "react-intl";
import Select from "react-select";
import moment from "moment";
// import InvoiceModal from "./modal/InvoiceModal";
// import UpdateInvoiceModal from "./modal/UpdateInvoiceModal";
import InvoicePage from "../../../modules/finance/modules/invoice/component/modal/InvoicePage";
import Pagination from "../../../modules/GenricComponant/Pagination";
import { exportToXLS } from "../../../modules/GenricComponant/exportToxls/exportToxls";
import { jsPDF } from "jspdf";
import InvoiceBill from "../../finance/invoice/componants/InvoiceBill";
// import Loading from "../../../../modules/GenricComponant/loading/Loading";
// import { exportToXLS } from "../../Genric componant/exportToxls/exportToxls";
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: object) => jsPDF;
  }
}

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const RecieptModal = () => {
  const intl = useIntl();
  const [sortConfig, setSortConfig] = useState<any>({
    key: " iReceiptID", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  let language: any = localStorage.getItem("setLanguage");
  const LanguageSet = JSON.parse(language);
  const lang = LanguageSet?.languageSlug || "en";

  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchUUID = ActiveBranch?.[0]?.iBranchUUID;
  const activeBranchID = ActiveBranch?.[0]?.iBranchID;

  const dispatch = useDispatch();
  const Userinvoice: any = useSelector(
    (state: any) => state?.Userinvoices?.Userinvoices?.data
  );
  // console.log("reduxxxx", Userinvoice);

  // const [UUID, setUUID] = useState<any>();
  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  // const [UpdateUser, setUpdateUser] = useState<any>({});
  const [printShow, setPrintShow] = useState<boolean>(false);
  const [printData, setPrintData] = useState<any>();
  // const [baseURL, setBaseURL] = useState<any>([]);
  const [selectMembershipType, setSelectMembershipType] = useState({
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
    value: "",
  });
  const [selectPaymentMode, setSelectPaymentMode] = useState({
    label: intl.formatMessage({ id: "COMMON.SELECT" }),
    value: "",
  });
  // const [selectMember, setSelectedMember] = useState({
  //   label: intl.formatMessage({ id: "COMMON.SELECT" }),
  //   value: "",
  // });
  const [selectedPackage, setSelectedPackage] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.NAME",
    })}`,
  });
  const [selectConsultant, setSelectedConsultant] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.CONSULTANT",
    })}`,
  });
  const [selectedOccupation, setSelectedOccupation] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.OCCUPATION",
    })}`,
  });
  const [selectedGender, setSelectedGender] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.GENDER",
    })}`,
  });

  const [selectedMarital, setSelectedMarital] = useState<any>({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.SELECTPACKAGE.MARITAL",
    })}`,
  });
  const [batchData, setBatchData] = useState<any>();
  const [batchid, setBatchid] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const [selectedDate, setSelectedDate] = useState({
    value: "",
    label: `${intl.formatMessage({
      id: "MODULE.EMPLOYEE.SELECTINITIAL",
    })}`,
  });
  const option1 = {
    label: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE1" }),
    value: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE1" }),
  };
  const option2 = {
    label: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE2" }),
    value: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE2" }),
  };
  const option3 = {
    label: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE3" }),
    value: intl.formatMessage({ id: "MODULE.RECIEPT.DTYPE3" }),
  };
  const dateOption = [option1, option2, option3];
  const paymentModeOptions = [
    {
      value: "Cash",
      label: intl.formatMessage({ id: "MODULE.PAYNTMODE.CASH" }),
    },
    {
      value: "Cheque",
      label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.CHEQUE" }),
    },
    {
      value: "Debit",
      label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.DEBIT" }),
    },
    {
      value: "Credit",
      label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.CREDIT" }),
    },
    {
      value: "Online",
      label: intl.formatMessage({ id: "MODULE.PAYMENTMODE.ONLINE" }),
    },
  ];
  // const [activityStatus, setActivityStatus] = useState<any>({
  //   value: "",
  //   label: `${intl.formatMessage({
  //     id: "MODULE.SELECTPACKAGE.ACTIVITYSTATUS",
  //   })}`,
  // });
  const [TBLData, setTBLData] = useState<any>([]);
  const [dateFormat, setdateFormat] = useState<any>(ActiveBranch?.idateFormate);
  const initialDates = [new Date(), new Date()];
  const [value, setValue] = React.useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [activeMemberList, setActiveMemberList] = useState<any>([]);
  const [activeUser, setActiveUser] = useState<any>([]);
  let initialState = {
    branchID: activeBranchID,
    status: "",
    page_limit: pageLimit,
    // page_no: 1,
    paymentMode: "",
    membershipType: "",
    employeeID: "",
    occuptionID: "",
    gender: "",
    maritalStatus: "",
    batchID: "",
    packageUUID: "",
    packageGroupUUID: "",
    search: "",
    start_date: "",
    end_date: "",
    order_key: " iReceiptID",
    order_value: "desc",
  };
  const [updateModalShow, setUpdateModalShow] = useState<boolean>(false);
  const [memberUID, setMemberUID] = useState<any>();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  //props states
  const [isUpdate, setisUpdate] = useState(false);
  const [activeEnqList, setActiveEnqList] = useState<any>([]);
  const [activeEmpList, setActiveEmpList] = useState<any>([]);
  const [activeOccList, setActiveOccList] = useState<any>([]);
  // const [branch_id, setBranch_id] = useState<any>("0");
  const [activePackageList, setActivePackageList] = useState<any>([]);
  const [gender, setGender] = useState<any>([]);
  const [maritalStatus, setMaritalStatus] = useState<any>([]);
  const [activeBatchList, setActiveBatchList] = useState<any>([]);
  //for check box
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [isAllRowsChecked, setIsAllRowsChecked] = useState<any>(false);
  //const [filteredData, setfilteredData] = useState<any>([]);
  // for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // for error
  // const [error, setError] = useState("");
  const [isFilter, setisFilter] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState(initialState);
  // const [searchValue, setSearchValue] = useState<string>();
  // const [status, setStatus] = useState<any>();
  const [filteredData, setFilteredData] = useState<any>([]);

  // const [isLoading, setIsLoading] = useState<boolean>(false);

  // selected user
  // const [selecteduser, setselecteduser] = useState<any>({});
  //option for status
  const activeMemberShip = [
    {
      value: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE1",
      })}`,
      label: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE1",
      })}`,
    },
    {
      value: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE2",
      })}`,
      label: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE2",
      })}`,
    },
    {
      value: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE3",
      })}`,
      label: `${intl.formatMessage({
        id: "MODULE.RECIEPT.MTYPE3",
      })}`,
    },
  ];
  // define rows for listing
  const firstColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERSHIP.MEMBER",
  })}`;
  const secondColumnTitle = `${intl.formatMessage({
    id: "COMMON.PHOTO",
  })}`;
  const thirdColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGE",
  })}`;
  const fourthColumnTitle = `${intl.formatMessage({
    id: "MODULE.RECIEPT.AMOUNT",
  })}`;
  const fifthColumnTitle = `${intl.formatMessage({
    id: "MODULE.RECIEPT.PAYMENTMODE",
  })}`;
  const sixthColumnTitle = `${intl.formatMessage({
    id: "MODULE.RECIEPT.INVOICENO",
  })}`;
  const seventhColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.RECIEVER",
  })}`;
  const eigthColumnTitle = `${intl.formatMessage({
    id: "MODULE.INVOICES.PAYMENTDATE",
  })}`;
  const ninthColumnTitle = `${intl.formatMessage({
    id: "MODULE.INVOICES.NEXTPAYMENTDATE",
  })}`;

  const tenthColumnTitle = `${intl.formatMessage({
    id: "COMMON.ACTIONS",
  })}`;
  // const eleventhColumnTitle = `${intl.formatMessage({
  //   id:, "MODULE.RECIEPT.RECIPTNO"
  // })}`;
  // const twelthColumnTitle = `${intl.formatMessage({
  //   id: "MODULE.RECIEPT.EMPLOYEE",
  // })}`;
  // const thirteenthColumnTitle = `${intl.formatMessage({
  //   id: "MODULE.RECIEPT.ENROLTYPE",
  // })}`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: async (values, action) => {
      // console.log("valee", values);
      setIsLoading(true);
      // setFilterValues(values);
      setPage(1);
      const response = await GetMembers({
        ...values,
        page_no: page,
      });
      setFilteredData(response?.data?.data);
      // console.log("responsed", response);
      if (response) {
        setIsLoading(false);
      }
      // ;
      // filterValues = { ...filterValues, values };
      // action.resetForm();
    },
  });
  // console.log("formikvalues", formik.values);
  const getMembers = useCallback(() => {
    setIsLoading(true);
    GetMembers({
      ...filterValues,
      page_no: page,
      page_limit: pageLimit,
      order_key: sortConfig.key,
      order_value: sortConfig.direction,
    }).then((data: any) => {
      // console.log(data, "InvoiceList");

      dispatch(Userinvoices.actions.GetUserInvoices(data));
      setTotalPages(Math.ceil(data?.data?.data?.totalRecords / pageLimit));
      setIsLoading(false);
    });
  }, [dispatch, pageLimit, filterValues, page, sortConfig]);

  /*   useEffect(() => {
    getMembers();
  }, [pageLimit]);
 */
  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    setFilteredData(Userinvoice?.data);
    // setBaseURL(Userinvoice?.imageBaseURL);
    handleExports();
  }, [Userinvoice?.data]);
  // console.log(Userinvoice, "dhundh");
  /*   const getMembers = async () => {
    const response = await GetMembers({
      page_limit: pageLimit,
      order_key: " iSpotID",
      order_value: "desc",
      Search: searchValue,
      status: status,
    });
    console.log("members responseeee", response?.data?.data);
    setfilteredData(response?.data?.data);
  };
 */
  const downloadfileName = `Invoices_${Date.now()}`;

  const handleExports = () => {
    const tableRows = Userinvoice?.data?.Resultdata?.map(
      (element: any, index: number) => {
        return {
          "#": index + 1,
          [sixthColumnTitle]: element?.VInvoiceNO,
          [thirdColumnTitle]:
            element.packageName + " " + `(${element.eEnrol_Type})`,
          [fourthColumnTitle]: element.amount,
          [fifthColumnTitle]: element?.paymentMode,
          [eigthColumnTitle]: element.paymentDate + `(${element.paymentTime})`,
          [ninthColumnTitle]: element.dtNext_Payment,
          [seventhColumnTitle]: element?.receiveEemployeeName,
        };
      }
    );

    setTBLData(tableRows);
  };

  const exportToPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    doc.text(intl.formatMessage({ id: "COMMON.INVOICE" }), 10, 10);
    const tableRows = Userinvoice.data.Resultdata.map(
      (element: any, index: number) => {
        return [
          index + 1,
          element.VInvoiceNO,
          element.packageName + " " + `(${element.eEnrol_Type})`,
          element.amount,
          element.paymentMode,
          element.paymentDate + `(${element.paymentTime})`,
          element.dtNext_Payment,
          element.receiveEemployeeName,
        ];
      }
    );
    doc.autoTable({
      head: [
        [
          "#",
          sixthColumnTitle,
          thirdColumnTitle,
          fourthColumnTitle,
          fifthColumnTitle,
          eigthColumnTitle,
          ninthColumnTitle,
          seventhColumnTitle,
        ],
      ],
      body: tableRows,
      startY: 50,
    });

    doc.save(downloadfileName + "pdf");
  };

  const getMemberDetails = async (branchID: any, spotUUID: any) => {
    const response = await getMemberDetailsByRecipt(branchID, spotUUID);
    // setUpdateUser(response?.data?.data);
    // console.log("updateresponse", response?.data?.data);
  };
  // console.log(updateModalShow, "updateModalshow");
  useEffect(() => {
    // getActiveMemberList(ActiveBranchUUID);
    getActiveEnquiryList(ActiveBranchUUID);
    getActiveEmpList(ActiveBranchUUID);
    getActiveOccupationList(ActiveBranchUUID);
    getActivePackageList(ActiveBranchUUID);
    getActiveBatchList(ActiveBranchUUID);
    getGenders(lang);
    getMaritalStatus(lang);
    getActiveUserList(ActiveBranchUUID);
  }, []);
  const getActiveUserList = async (branchUUID: any) => {
    const response = await activeUserList(branchUUID);
    setActiveUser(response?.data?.data);
    // console.log(response?.data, "reponseofactive");
  };
  //start get active enquiry list
  const getActiveEnquiryList = async (branchUUID: any) => {
    const response = await ActiveEnquiryList(branchUUID);
    setActiveEnqList(response?.data?.data);
  };
  // const getActiveMemberList = async (branchUUID: any) => {
  //   const response = await ActiveMemberList(branchUUID);
  //   console.log(response?.data, "faizl", branchUUID);
  //   setActiveMemberList(response?.data?.data);
  // };

  //end get active enquiry list

  //start get active member list
  const getActiveEmpList = async (branchUUID: any) => {
    const response = await ActiveEmpList(branchUUID);
    setActiveEmpList(response?.data?.data);
  };
  //end get active member list

  //start get active occupation list
  const getActiveOccupationList = async (branchUUID: any) => {
    const response = await ActiveOccupationList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActiveOccList(response?.data?.data);
  };
  //end get active occupation list

  //start get active batch list
  const getActiveBatchList = async (branchUUID: any) => {
    const response = await ActiveBatchList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActiveBatchList(response?.data?.data);
    setBatchData(response?.data?.data);
  };
  //end get active batch list

  //start get active package list
  const getActivePackageList = async (branchUUID: any) => {
    const response = await ActivePackageList(branchUUID);
    // console.log("response active enquiry list", response?.data?.data);
    setActivePackageList(response?.data?.data);
  };
  //end get active package list

  //start get active gender list
  const getGenders = async (lang: any) => {
    const response = await GetGenders(lang);
    setGender(response?.data?.data);
  };
  //end get active gender list

  //start get active marital list
  const getMaritalStatus = async (lang: any) => {
    const response = await GetMaritalStatus(lang);
    setMaritalStatus(response?.data?.data);
  };
  //end get active marital list

  const UpdateUserStatus = (prop: any) => {
    swal({
      title: `${intl.formatMessage({ id: "COMMON.AREYOUSURE" })}`,
      text: intl.formatMessage({ id: "COMMON.NOTPERFORMOPERATION" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SUBMIT" }),
      ], //["Cancel", "Submit"],
      dangerMode: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        const Update_user = {
          branchID: activeBranchID,
          uuid_array: checkedRows,
          select_type: "",
          unchecked_uuid_array: [],
          status: prop.status,
        };
        if (prop.status !== "2") {
          UpdateStatus(Update_user)
            .then((data: any) => {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });

              getMembers();
            })
            .catch(() => {
              // swal("Somthing Went Wrong!");
              swal(
                `${intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" })}`
              );
            });
        } else {
          DeleteMember(Update_user)
            .then((data: any) => {
              //swal(`Proof! Selected Users Is ${prop.message}!`, {
              swal(intl.formatMessage({ id: "COMMON.SUCCESSOPERATION" }), {
                icon: "success",
              });
              setCheckedRows([]);
              //getting all roled again
              getMembers();
            })
            .catch(() => {
              swal(intl.formatMessage({ id: "COMMON.SOMETHINGWENTWRONG" }));
            });
        }
      } else {
        swal(`${intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" })}`);
      }
    });
  };

  //define page limit
  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
    // console.log("page limit event", values);
    setPage(1);
  };
  //handle check box
  const handleRowCheckboxChange = (rowId: any) => {
    if (checkedRows.includes(rowId)) {
      setCheckedRows(checkedRows.filter((id: any) => id !== rowId));
    } else {
      setCheckedRows([...checkedRows, rowId]);
    }
  };
  // handle all check box
  const handleSelectAllCheckboxChange = (Event: any) => {
    if (isAllRowsChecked) {
      setCheckedRows([]);
      setIsAllRowsChecked(false);
    } else {
      const allRowIds = filteredData?.map(
        (element: any) => element?.iReceiptUUID
      );
      setCheckedRows(allRowIds);
      setIsAllRowsChecked(true);
    }
  };
  const selectPageHandler = (selectedPage: any) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPages &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  const handleReset = () => {
    formik.resetForm();
    handleResetFilter();
    setFilterValues(initialState);
    getMembers();
  };

  const handleRefreshButton = () => {
    setSortConfig({
      key: " iReceiptID",
      direction: "asc",
    });
    setFilterValues(initialState);
    getMembers();
    setCheckedRows([]);
    setPageLimit(pageDefaultValue);
    // setSearchValue("");
    // setStatus("");
  };

  const handleSearchClick = (Event: any) => {
    getMembers();
  };
  //console.log("updateUser", UpdateUser);
  const handleResetFilter = () => {
    // setSelectedMember({
    //   value: "",
    //   label: `${intl.formatMessage({ id: "COMMON.SELECT" })}`,
    // });
    setSelectedPackage({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.NAME" })}`,
    });
    setBatchid({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.NAME" })}`,
    });
    setSelectedConsultant({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.CONSULTANT" })}`,
    });

    setSelectMembershipType({
      value: "",
      label: `${intl.formatMessage({ id: "COMMON.SELECT" })}`,
    });
    setSelectedDate({
      value: "",
      label: `${intl.formatMessage({ id: "COMMON.SELECT" })}`,
    });
    setValue(null);
    setSearchText("");
    setSelectedOccupation({
      value: "",
      label: `${intl.formatMessage({ id: "MODULE.SELECTPACKAGE.OCCUPATION" })}`,
    });
  };
  const handleMemberShipType = async (Event: any) => {
    setSelectMembershipType({
      value: Event.value,
      label: Event.label,
    });
    formik.setFieldValue("membershipType", Event.value);
  };
  const handlePaymentModeChange = (Event: any) => {
    //   formik.setFieldValue("packageUUID", Event.value);
    formik.setFieldValue("paymentMode", Event.value);
    setSelectPaymentMode({
      value: Event.value,
      label: Event.label,
    });
    // handlePaymentReset();
  };

  const handleDateChoose = (Event: any) => {
    formik.setFieldValue("date_type", Event.value);
    setSelectedDate({
      value: Event.value,
      label: Event.label,
    });
  };

  // const handleMemberChange = async (Event: any) => {
  //   // // setSelectedMember({
  //   //   value: Event.value,
  //   //   label: Event.label,
  //   // });
  //   formik.setFieldValue("spotUUID", Event.value);
  //   // console.log("faiza", Event.value);

  //   // const response = await enrollListofFreeze(activeBranchID, Event.value);
  //   // setActivePackageList(response?.data?.data);
  //   // console.log("ressss", response);
  //   // handleResetForm();
  // };
  const handlePackageChange = async (Event: any) => {
    formik.setFieldValue("packageUUID", Event.value);

    setSelectedPackage({ value: Event.value, label: Event.label });
    // console.log("dssdsfsdda",iProgramgrpUUID)
    const result = activePackageList?.filter((ele: any) => {
      return ele.iProgramUUID === Event.value;
    });

    // console.log("datttta", result);
    const { iProgramgrpUUID } = result[0];
    formik.setFieldValue("packageGroupUUID", iProgramgrpUUID);
    if (Event.value) {
      const response = await batchIDAPII(Event.value);
      setBatchData(response?.data?.data);
      // console.log("resbatch", response);
    }
    setBatchid({ value: "", label: "select a batch" });
  };
  const handleEmpChange = (Event: any) => {
    formik.setFieldValue("employeeID", Event.value);
    setSelectedConsultant({
      value: Event.value,
      label: Event.label,
    });
  };
  const handleDateReset = (values: any) => {
    setValue(null);
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
  };
  const handleChange = (values: any) => {
    setValue(values);
    if (values && values.length !== 0) {
      formik.setFieldValue("start_date", moment(values[0]).format(dateFormat));
      formik.setFieldValue("end_date", moment(values[1]).format(dateFormat));
    }
  };

  // const handleGenderChange = (Event: any) => {
  //   formik.setFieldValue("gender", Event.value);
  //   setSelectedGender({
  //     value: Event.value,
  //     label: Event.label,
  //   });
  // };
  // const handleMaritalStatus = (Event: any) => {
  //   formik.setFieldValue("maritalStatus", Event.value);
  //   setSelectedMarital({
  //     value: Event.value,
  //     label: Event.value,
  //   });
  // };
  // const handleStatusChange = (Event: any) => {
  //   formik.setFieldValue("status", Event.value);
  //   console.log("hkads", Event.value);
  //   setActivityStatus({
  //     value: Event.value,
  //     label: Event.label,
  //   });
  // };
  const handlesearchChange = (Event: any) => {
    formik.setFieldValue("search", Event.target.value);
    setSearchText(Event.target.value);
  };
  const handleBatchID = (Event: any) => {
    formik.setFieldValue("batchID", Event.value);
    setBatchid({
      value: Event.value,
      label: Event.label,
    });
  };
  const handleOccupationChange = (Event: any) => {
    formik.setFieldValue("occuptionID", Event.value);
    setSelectedOccupation({
      value: Event.value,
      label: Event.label,
    });
  };
  const handlePrintReciepts = async (printUUID: any) => {
    const result = await PrintRecipt(activeBranchID, printUUID);
    // console.log(result, "resutlPrint");
    setPrintData(result?.data);
    setPrintShow(true);
  };
  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };
  // const formattedValue =
  //   value && value.length === 2
  //     ? `${moment(value[0]).format("MM/DD/YYYY")} - ${moment(value[1]).format(
  //         "MM/DD/YYYY"
  //       )}`
  //     : "";
  // console.log("memberlist");
  return (
    <>
      {/* Filter Section Start */}
      {isFilter && (
        <div className="card my-4">
          <div className="card-header">
            {" "}
            <Card className="w-100">
              <Card.Body className="py-5">
                <Form onSubmit={formik.handleSubmit}>
                  {/* <Col md={4} className="mb-4">
                      <Form.Group controlId="search">
                        <Form.Label>Search by Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="search"
                          placeholder="Enter name"
                          onChange={(e) => setSearchValue(e.target.value)}
                          value={searchValue}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={3} className="mb-4">
                      <Form.Group controlId="status">
                        <Form.Label>
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}
                        >
                          <option value="">
                            {intl.formatMessage({ id: "COMMON.ALL" })}
                          </option>
                          <option value="1">
                            {" "}
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {" "}
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col> */}
                  <Row>
                    {/* <Col md={3} className="mb-5 ">
                      <label htmlFor="spotUUID" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.RENEWAL.MEMBER.ENROLL",
                        })}
                      </label>
                      <Select
                        id="spotUUID"
                        name="spotUUID"
                        className="basic-single"
                        value={selectMember}
                        options={activeUser?.map((item: any) => ({
                          value: item.iSpotUUID,
                          label: `${item.name} ${item.countryCode} ${item.mobileno}`,
                        }))}
                        onChange={(e) => handleMemberChange(e)}
                      />

                    </Col> */}
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="packageUUID" className="form-label">
                          {intl.formatMessage({ id: "MODULE.MEMBERS.PACKAGE" })}
                        </label>
                        <Select
                          id="packageUUID"
                          name="packageUUID"
                          className="basic-single"
                          value={selectedPackage}
                          options={activePackageList.map((item: any) => ({
                            value: item.iProgramUUID,
                            label: item.vProgram,
                          }))}
                          onChange={(e) => handlePackageChange(e)}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="batch" className="form-label">
                          {intl.formatMessage({ id: "MODULE.MEMBERS.BATCH" })}
                        </label>
                        <Select
                          className=" basic-single"
                          id="batchID"
                          name="batchID"
                          value={batchid}
                          options={batchData?.map((item: any) => ({
                            value: item.iBatchID,
                            label: item.batchName,
                          }))}
                          onChange={(e) => handleBatchID(e)}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="employeeID" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.RECIEPT.RECIEVEDBY",
                          })}
                        </label>
                        <Select
                          className=" basic-single"
                          id="employeeID"
                          name="employeeID"
                          value={selectConsultant}
                          options={activeEmpList.map((item: any) => ({
                            value: item.iEmployeeID,
                            label:
                              item.vEFirstname +
                              ` (${item?.vEmpCountryCode}-${item?.vEPhoneno})`,
                          }))}
                          onChange={(e) => handleEmpChange(e)}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-5 ">
                      <label htmlFor="membershipType" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBER.MEMBERSHIPTYPE",
                        })}
                      </label>
                      <Select
                        id="membershipType"
                        name="membershipType"
                        className="basic-single"
                        value={selectMembershipType}
                        options={activeMemberShip?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                        onChange={(e) => handleMemberShipType(e)}
                      />

                      {/* </div> */}
                    </Col>
                    <Col>
                      <label htmlFor="paymentMode" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.RECIEPT.PAYMENTMODE",
                        })}
                      </label>
                      <Select
                        id="paymentMode"
                        name="paymentMode"
                        className="basic-single"
                        value={selectPaymentMode}
                        options={paymentModeOptions?.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                        onChange={(e) => handlePaymentModeChange(e)}
                      />
                    </Col>
                    <Col md={3} className="mb-3">
                      <label htmlFor="date_type" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.EMPLOYEE.DATEOPTION",
                        })}
                      </label>
                      <Select
                        id="date_type"
                        name="date_type"
                        className="basic-single"
                        value={selectedDate}
                        options={dateOption.map((item: any) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                        onChange={(e) => handleDateChoose(e)}
                      />
                    </Col>
                    <Col md={3} className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.DATERANGE",
                          })}
                        </label>

                        <DateRangePicker
                          value={value}
                          onChange={handleChange}
                          format="MM/dd/yyyy"
                          placeholder="Select Date Range"
                          placement="auto"
                          style={{ margin: "2px" }}
                          onClean={handleDateReset}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="search" className="form-label">
                          {intl.formatMessage({
                            id: "MODULE.MEMBERS.SEARCHLABEL",
                          })}
                        </label>
                        <input
                          className=" form-control"
                          id="search"
                          name="search"
                          placeholder={intl.formatMessage({
                            id: "MODULE.EXPIREPACKAGE.SEARCHLABEL",
                          })}
                          value={searchText}
                          onChange={(e) => handlesearchChange(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          {intl.formatMessage({ id: "COMMON.GENDER" })}
                        </label>
                        <Select
                          id="gender"
                          name="gender"
                          className="basic-single"
                          value={selectedGender}
                          options={gender.map((item: any) => ({
                            value: item.key,
                            label: item.value,
                          }))}
                          onChange={(e) => handleGenderChange(e)}
                        />
                      </div>
                    </Col>
                    <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="martialStatus" className="form-label">
                          {intl.formatMessage({ id: "COMMON.MARITALSTATUS" })}
                        </label>

                        <Select
                          id="maritalStatus"
                          name="maritalStatus"
                          value={selectedMarital}
                          options={maritalStatus.map((item: any) => ({
                            value: item?.key,
                            label: item?.value,
                          }))}
                          onChange={(e) => handleMaritalStatus(e)}
                        />
                      </div>
                    </Col>

                    {/* <Col md={3} className="mb-4">
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          {intl.formatMessage({ id: "COMMON.STATUS" })}
                        </label>
                        <Select
                          id="status"
                          name="status"
                          className="basic-single"
                          onChange={(e) => handleStatusChange(e)}
                          // onBlur={formik.handleBlur("status")}
                          value={activityStatus}
                          options={StatusOption.map((item: any) => ({
                            value: item?.value,
                            label: item?.label,
                          }))}
                        />
                        {/* <option>select status</option>
                          <option value="1">
                            {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                          </option>
                          <option value="0">
                            {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                          </option>
                        </select> 
                      </div>
                    </Col> 
                    <Col md={3} className="mb-5 ">
                      {/* <div className="col-md-5"> 
                      <label htmlFor="department_name" className="form-label">
                        {intl.formatMessage({
                          id: "MODULE.MEMBERS.OCCUPATION",
                        })}
                      </label>
                      <Select
                        className=" basic-single "
                        id="occupationID"
                        name="occupationID"
                        value={selectedOccupation}
                        options={activeOccList.map((item: any) => ({
                          value: item?.iOccupationID,
                          label: item?.occupationName,
                        }))}
                        onChange={(e) => handleOccupationChange(e)}
                      />
                      
                    </Col>
                    
                  </Row> */}

                  {/* Rest of the form fields */}
                  <Row>
                    <Col
                      md={3}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <Button
                        type="submit"
                        variant="primary"
                        className="me-3 mr-4 "
                        // onClick={(e) => handleSearchClick(e)}
                        disabled={isLoading}
                      >
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={handleReset}
                        className="me-3"
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      {/* Filtsser Section End */}
      {/* <InvoicePage /> */}
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        {/* begin::Header */}
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div
                className="d-flex  flex-wrap gap-1"
                data-kt-user-table-toolbar="base"
              >
                {checkedRows?.length > 0 ? (
                  <>
                    <div className="fw-bold me-5">
                      <span className="me-2"> {checkedRows?.length}</span>
                      {intl.formatMessage({ id: "COMMON.SELECTED" })}
                    </div>

                    <>
                      <button
                        type="button"
                        className="btn btn-success me-3 d-none"
                        onClick={() =>
                          UpdateUserStatus({
                            status: "1",
                            message: "Activated",
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className="btn btn-light-warning me-3 d-none"
                        onClick={() =>
                          UpdateUserStatus({
                            status: "0",
                            message: " Inactivated",
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger me-3 "
                        onClick={() =>
                          UpdateUserStatus({
                            status: "2",
                            message: "deleted",
                          })
                        }
                      >
                        {intl.formatMessage({ id: "COMMON.DELETE" })}
                      </button>
                    </>
                  </>
                ) : (
                  <>
                    <span
                      className="min-w-40px mb-3 d-flex  align-items-center"
                      style={{ color: "#7e8299", fontSize: "14px" }}
                    >
                      {intl.formatMessage({ id: "COMMON.SHOW" })}
                    </span>
                    <div>
                      <select
                        className="form-control form-select form-select-solid me-3 mb-4"
                        value={pageLimit}
                        onChange={(e) => getPageLimit(e.target.value)}
                      >
                        {finalPageLimitArray?.map((item: any, i = 0) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex  flex-wrap gap-1"
              data-kt-user-table-toolbar="base"
            >
              <button
                type="button"
                className="btn btn-primary me-3"
                onClick={handleRefreshButton}
              >
                {intl.formatMessage({ id: "COMMON.REFRESH" })}
              </button>

              {checkedRows?.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>

                  <Button
                    variant="primary"
                    className="d-none"
                    onClick={() => {
                      handleShow();
                      setisUpdate(false);
                    }}
                  >
                    {intl.formatMessage({
                      id: "MODULE.INVOICES.ADD",
                    })}
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={() => setisFilter(!isFilter)}
                  >
                    {isFilter
                      ? `${intl.formatMessage({
                          id: "MODULE.BRANCH.HIDEFILTER",
                        })}`
                      : `${intl.formatMessage({
                          id: "MODULE.BRANCH.SHOWFILTER",
                        })}`}
                  </button>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="btn btn-light-primary me-3"
                    >
                      <KTSVG
                        path="/media/icons/duotune/files/fil021.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                      {intl.formatMessage({ id: "MODULE.BRANCH.EXPORT" })}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4">
                      <CSVLink
                        data={TBLData || []}
                        filename={downloadfileName + ".csv"}
                        className="menu-item px-3"
                      >
                        <span className="px-3">
                          {intl.formatMessage({ id: "COMMON.EXPORTCSV" })}
                        </span>
                      </CSVLink>
                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={() => exportToXLS(TBLData, downloadfileName)}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTEXCEL" })}
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item className="menu-item px-3">
                        <a
                          href="#"
                          className="menu-link px-3"
                          onClick={exportToPDF}
                        >
                          {intl.formatMessage({ id: "COMMON.EXPORTPDF" })}
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* This is workable button of adding */}

                  <Button
                    variant="primary"
                    className="d-none"
                    onClick={() => {
                      // handleShow();
                      // setisUpdate(false);
                      setMemberUID(null);
                      setShow(true);
                    }}
                  >
                    {intl.formatMessage({
                      id: "MODULE.INVOICES.ADD",
                    })}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* modal start */}
        {/* modal start */}

        {/* <MemberModal
          show={show}
          setShow={setShow}
          getMembers={getMembers}
          UpdateUser={UpdateUser}
          setUpdateUser={setUpdateUser}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          activeEnqList={activeEnqList}
          activeEmpList={activeEmpList}
          activeOccList={activeOccList}
          activePackageList={activePackageList}
          gender={gender}
          maritalStatus={maritalStatus}
          activeBatchList={activeBatchList}
          UpdatUser={UpdateUser}
          error={error}
          setError={setError}
        /> */}

        {/* <InvoiceModal
          show={show}
          setShow={setShow}
          activeUser={activeUser}
          activePackageList={activePackageList}
          activeBatchList={activeBatchList}
          activeEmpList={activeEmpList}
          memberUID={memberUID}
          getMembers={getMembers}
        />
        <UpdateInvoiceModal
          updateModalShow={updateModalShow}
          setUpdateModalShow={setUpdateModalShow}
          UpdateUser={UpdateUser}
          memberUID={memberUID}
          isUpdate={isUpdate}
          activePackageList={activePackageList}
          activeBatchList={activeBatchList}
          getMembers={getMembers}
          activeEmpList={activeEmpList}
        /> */}

        <InvoiceBill
          printShow={printShow}
          setPrintShow={setPrintShow}
          printData={printData}
        />

        {/* modal End */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* {isLoading && <Loading />} */}
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bolder text-muted text-capitalize fs-6">
                  {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="memberName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {firstColumnTitle}
                      {sortConfig.key !== "memberName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  {/* <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="memberName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {secondColumnTitle}
                      {sortConfig.key !== "memberName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "memberName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="VInvoiceNO"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumnTitle}
                      {sortConfig.key !== "VInvoiceNO" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "VInvoiceNO" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "VInvoiceNO" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="packageName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumnTitle}
                      {sortConfig.key !== "packageName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="amount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fourthColumnTitle}
                      {sortConfig.key !== "amount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentMode"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fifthColumnTitle}
                      {sortConfig.key !== "paymentMode" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentMode" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentMode" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentDate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eigthColumnTitle}
                      {sortConfig.key !== "paymentDate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtNext_Payment"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumnTitle}
                      {sortConfig.key !== "dtNext_Payment" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtNext_Payment" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtNext_Payment" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="receiveEemployeeName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumnTitle}
                      {sortConfig.key !== "receiveEemployeeName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "receiveEemployeeName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "receiveEemployeeName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {tenthColumnTitle}
                  </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredData && filteredData?.Resultdata?.length === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                    </td>
                  </tr>
                ) : (
                  filteredData?.Resultdata?.map(
                    (element: any, index: number) => {
                      // setUUID(element.iEnrolUUID);
                      const isChecked = checkedRows.includes(
                        element.iReceiptUUID
                      );
                      // console.log("uidofmember", element);
                      return (
                        <tr key={index} className="fs-6">
                          {/* <td data-label={firstColumnTitle}>
                              <div className="d-flex align-items-center border-0">
                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                  <div className="symbol-label">
                                    <img
                                      src={
                                        element.vPhoto
                                          ? `${baseURL}${element.vPhoto}`
                                          : Dummy
                                      }
                                      width={50}
                                      height={50}
                                      className="w-100"
                                    />
                                  </div>
                                </div>

                                <div className="d-flex flex-column">
                                  {element.memberName}
                                  <span>
                                    <a
                                      href={`tel:${
                                        element.MemberCountryCode +
                                        element.memnerMobileNo
                                      }`}
                                    >
                                      {element.MemberCountryCode}-
                                      {element.memnerMobileNo}
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </td> */}
                          {/* <td data-label={firstColumnTitle}>
                            {element.memberName}
                            <a
                              href={`tel:${element.MemberCountryCode}-${element.memnerMobileNo}`}
                            >{`${element.MemberCountryCode}-${element.memnerMobileNo}`}</a>
                          </td>
                          <td data-label={secondColumnTitle}>
                            {element.vPhoto && (
                              <img
                                src={`${baseURL}${element.vPhoto}`}
                                width={50}
                                height={50}
                              />
                            )}
                          </td> */}
                          <td data-label={sixthColumnTitle}>
                            {element.VInvoiceNO}
                          </td>
                          <td data-label={thirdColumnTitle}>
                            {`${element.packageName} (${element.eEnrol_Type})`}{" "}
                          </td>
                          <td
                            data-label={fourthColumnTitle}
                          >{`${element?.amount} `}</td>
                          <td data-label={fifthColumnTitle}>
                            {" "}
                            {element.paymentMode}
                          </td>

                          <td data-label={eigthColumnTitle}>
                            {moment(element.paymentDate).format(dateFormat)} (
                            {element.paymentTime})
                          </td>
                          <td data-label={ninthColumnTitle}>
                            {moment(element.dtNext_Payment).format(dateFormat)}
                          </td>
                          <td data-label={seventhColumnTitle}>
                            {element.receiveEemployeeName}
                          </td>
                          {/* <td>
                            {new Date(element.paymentDate).toLocaleString(
                              "en-US",
                              {
                                dateStyle: "medium",
                              }
                            )}
                          </td> */}

                          <td data-label={tenthColumnTitle}>
                            <div className="d-flex flex-shrink-0 floatRight">
                              <span
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                onClick={() => {
                                  handlePrintReciepts(element.iReceiptUUID);
                                }}
                              >
                                <i className="bi bi-eye"></i>
                              </span>

                              <a
                                href="#g"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm d-none"
                                onClick={() => {
                                  setUpdateModalShow(true);
                                  setisUpdate(true);
                                  setMemberUID(element.iReceiptUUID);
                                  getMemberDetails(
                                    activeBranchID,
                                    element.iReceiptUUID
                                  );
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/art/art005.svg"
                                  className="svg-icon-3"
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )
                )}
                <tr className="fw-bolder text-muted text-capitalize fs-6">
                  {/* <th className="min-w-100px notVisibleInMobile">
                   <div
                     className="d-flex justify-content-between cursor-pointer"
                     data-key="memberName"
                     onClick={(e) => {
                       handleSort(e);
                     }}
                   >
                     {firstColumnTitle}
                     {sortConfig.key !== "memberName" ? (
                       <div>
                         <i className="bi bi-arrow-down-up"></i>
                       </div>
                     ) : (
                       <div>
                         <i
                           className={`bi bi-arrow-up  ${
                             sortConfig.key == "memberName" &&
                             sortConfig.direction == "asc"
                               ? "d-block text-primary fw-bold fs-5"
                               : "d-none"
                           }`}
                         ></i>
                         <i
                           className={`bi bi-arrow-down  ${
                             sortConfig.key == "memberName" &&
                             sortConfig.direction == "desc"
                               ? "d-block text-primary fw-bold fs-5"
                               : "d-none"
                           }`}
                         ></i>
                       </div>
                     )}
                   </div>
                 </th> */}
                  {/* <th className="min-w-100px notVisibleInMobile">
                   <div
                     className="d-flex justify-content-between cursor-pointer"
                     data-key="memberName"
                     onClick={(e) => {
                       handleSort(e);
                     }}
                   >
                     {secondColumnTitle}
                     {sortConfig.key !== "memberName" ? (
                       <div>
                         <i className="bi bi-arrow-down-up"></i>
                       </div>
                     ) : (
                       <div>
                         <i
                           className={`bi bi-arrow-up  ${
                             sortConfig.key == "memberName" &&
                             sortConfig.direction == "asc"
                               ? "d-block text-primary fw-bold fs-5"
                               : "d-none"
                           }`}
                         ></i>
                         <i
                           className={`bi bi-arrow-down  ${
                             sortConfig.key == "memberName" &&
                             sortConfig.direction == "desc"
                               ? "d-block text-primary fw-bold fs-5"
                               : "d-none"
                           }`}
                         ></i>
                       </div>
                     )}
                   </div>
                 </th> */}
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="VInvoiceNO"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {sixthColumnTitle}
                      {sortConfig.key !== "VInvoiceNO" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "VInvoiceNO" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "VInvoiceNO" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="packageName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {thirdColumnTitle}
                      {sortConfig.key !== "packageName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "packageName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="amount"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fourthColumnTitle}
                      {sortConfig.key !== "amount" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "amount" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentMode"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {fifthColumnTitle}
                      {sortConfig.key !== "paymentMode" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentMode" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentMode" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>

                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="paymentDate"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {eigthColumnTitle}
                      {sortConfig.key !== "paymentDate" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "paymentDate" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="dtNext_Payment"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {ninthColumnTitle}
                      {sortConfig.key !== "dtNext_Payment" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "dtNext_Payment" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "dtNext_Payment" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    <div
                      className="d-flex justify-content-between cursor-pointer"
                      data-key="receiveEemployeeName"
                      onClick={(e) => {
                        handleSort(e);
                      }}
                    >
                      {seventhColumnTitle}
                      {sortConfig.key !== "receiveEemployeeName" ? (
                        <div>
                          <i className="bi bi-arrow-down-up"></i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className={`bi bi-arrow-up  ${
                              sortConfig.key == "receiveEemployeeName" &&
                              sortConfig.direction == "asc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                          <i
                            className={`bi bi-arrow-down  ${
                              sortConfig.key == "receiveEemployeeName" &&
                              sortConfig.direction == "desc"
                                ? "d-block text-primary fw-bold fs-5"
                                : "d-none"
                            }`}
                          ></i>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="min-w-100px notVisibleInMobile">
                    {tenthColumnTitle}
                  </th>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {/* begin:: pagination  */}
          {/* <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination">
                  <li className="page-item previous">
                    <a
                      className={`page-link page-text me-5 paginate_button page-item previous ${
                        page === 1 ? "disable" : ""
                      } `}
                      style={{ cursor: "pointer" }}
                      onClick={() => selectPageHandler(page - 1)}
                    >
                      Previous
                    </a>
                  </li>
                  {[...Array(totalPages)].map((_, i) => {
                    return (
                      <li
                        className={
                          page === i + 1 ? "page-item active" : "page-item"
                        }
                        onClick={() => selectPageHandler(i + 1)}
                        key={i}
                      >
                        <a className="page-link" style={{ cursor: "pointer" }}>
                          {i + 1}
                        </a>
                      </li>
                    );
                  })}

                  <li
                    className={`paginate_button page-item next ${
                      page === totalPages ? "disable" : ""
                    } `}
                  >
                    <a
                      className="page-link page-text"
                      style={{ cursor: "pointer" }}
                      onClick={() => selectPageHandler(page + 1)}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
          {/* end:: pagination */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  );
};

export default RecieptModal;
