import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const GET_PURCHASEPRODUCT_LIST = `${API_URL}/v1/purchaseproduct/list`;
export const GET_PURCHASE_PAYMENT_LIST = `${API_URL}/v1/supplierpayment/list`;
export const ADD_PURCHASEPRODUCT = `${API_URL}/v1/purchaseproduct/add`;
export const UPDATE_PURCHASEPRODUCT = `${API_URL}/v1/purchaseproduct/update/`;
export const UPDDATE_STATUS = `${API_URL}/v1/purchaseproduct/update_status`;
export const DELETE_STATUS = `${API_URL}/v1/purchaseproduct/delete`;
export const DETAILS_PURCHASEPRODUCT = `${API_URL}/v1/purchaseproduct/details`;
export const ACTIVE_SUPPLIER = `${API_URL}/v1/supplier/listbyBranch`;
export const ACTIVE_PRODUCT = `${API_URL}/v1/product/listbyBranch`;
export const ADD_INVOICE_SUPPLIER = `${API_URL}/v1/supplierpayment/add`;
export const UPDATE_INVOICE_SUPPLIER = `${API_URL}/v1/supplierpayment/update`;
export const DETAILS_INVOICE_SUPPLIER = `${API_URL}/v1/supplierpayment/details`;

export function GetPurchaseProductList(body: any) {
  return axios.post(GET_PURCHASEPRODUCT_LIST, body).catch((error) => {
    return error.response;
  });
}
export function AddInvoiceSupplier(body: any) {
  return axios.post(ADD_INVOICE_SUPPLIER, body).catch((error) => {
    return error.response;
  });
}
export function UpdateInvoiceSupplier(body: any) {
  return axios.put(UPDATE_INVOICE_SUPPLIER, body).catch((error) => {
    return error.response;
  });
}
export function DetailsInvoiceSupplier(branch: any, uuid: any) {
  return axios
    .get(`${DETAILS_INVOICE_SUPPLIER}/${branch}/${uuid}`)
    .catch((error) => {
      return error.response;
    });
}
export function GetPurchasePaymentList(body: any) {
  return axios.post(GET_PURCHASE_PAYMENT_LIST, body).catch((error) => {
    return error.response;
  });
}
export function AddPurchaseProduct(body: any) {
  return axios.post(ADD_PURCHASEPRODUCT, body).catch((error) => {
    return error.response;
  });
}
export function ActiveProductList(body: any) {
  return axios.get(`${ACTIVE_PRODUCT}/${body}`).catch((error) => {
    return error.response;
  });
}
export function getActiveSupplier(body: any) {
  return axios.get(`${ACTIVE_SUPPLIER}/${body}`).catch((error) => {
    return error.response;
  });
}
export function UpdatePurchaseProduct(body: any) {
  return axios.put(UPDATE_PURCHASEPRODUCT, body).catch((error) => {
    return error.response;
  });
}
export function UpdateStatus(body: any) {
  return axios.patch(UPDDATE_STATUS, body).catch((error) => {
    return error.response;
  });
}
export function deletePurchaseProduct(body: any) {
  return axios.patch(DELETE_STATUS, body).catch((error) => {
    return error.response;
  });
}
export function getDetailsOfPurchaseProduct(body: any, uuid: any) {
  return axios
    .get(`${DETAILS_PURCHASEPRODUCT}/${body}/${uuid}`)
    .catch((error) => {
      return error.response;
    });
}
