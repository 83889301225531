import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
// import AssignToModal from "./Componants/Modal/AssignToModal";
// import { GetAssignMemberDetails } from "../../../member_body_assistment/componant/My DietPlan/redux/MyDietPlanCrud";
// import DietPlanDetails from "../../../member_body_assistment/componant/My DietPlan/Componants/DietPlanDetails";
import DietPlansheet from "./DietPlansheet";
import { getmemberDietPlanAssignByDate } from "../../redux/dashboardCRUD";
// import MyDietListModal from "./Componants/Modal/MyDietListModal";

const MyDietPlan = ({ UpdateUser, selectedDate }: any) => {
  const intl = useIntl();
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  // const ActiveBranchUUID = ActiveBranch?.[0]?.iBranchUUID;
  const activeBranchID = ActiveBranch?.[0]?.iBranchID;
  // const [show, setShow] = useState<any>(false);
  // const [showList, setShowList] = useState<any>(false);
  const [Data, setData] = useState<any>("");
  const [IsVisible, setIsVisible] = useState<any>(false);

  const assignDetailsPlan = async (branch: any, SpotID: any, date: any) => {
    const response = await getmemberDietPlanAssignByDate(branch, SpotID, date);
    if (response.status === 200) {
      setData(response?.data);
      const result = isDayVisible(date, response?.data?.data?.DaysInWeek);
      // console.log(result, response?.data?.data?.DaysInWeek, "Days");
      setIsVisible(result);
    } else {
      setData("");
      setIsVisible(false);
    }
  };
  useEffect(() => {
    if (UpdateUser?.iSpotID) {
      assignDetailsPlan(
        activeBranchID,
        UpdateUser?.iSpotID,
        selectedDate.format("YYYY-MM-DD")
      );
    }
  }, [UpdateUser, selectedDate]);

  const isDayVisible = function (dateString: any, daysInWeekArray: any) {
    // Convert the date string to a Date object
    const date = new Date(dateString);

    // Get the day name from the date
    const options: any = { weekday: "long" };
    const dayName = date.toLocaleDateString("en-US", options);

    // Find the corresponding object in the array
    const dayObject = daysInWeekArray?.find((d: any) => d.day === dayName);
    console.log(dayObject, daysInWeekArray, "dayObject");
    // If found and dayStatus is true, return true (visible), else false (not visible)
    return dayObject ? dayObject.dayStatus : false;
  };
  return (
    <>
      {/* <AssignToModal
        show={show}
        setShow={setShow}
        UpdateUser={UpdateUser}
        getList={assignDetailsPlan}
      /> */}
      {/* <MyDietListModal
        show={showList}
        setShow={setShowList}
        UpdateUser={UpdateUser}
      /> */}
      <div className="container mt-4 border-top border-2 p-3">
        <div className="d-flex justify-content-between">
          {/* <h2 className="text-center">
            {intl.formatMessage({ id: "DIET.MYDIETPLAN" })}
          </h2> */}
          <div className="d-flex gap-2 ">
            {/* <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setShow(true);
              }}
            >
              {intl.formatMessage({ id: "DIET.ASSIGNTO" })}
            </button> */}
            {/* {Data?.data?.planName ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setShowList(true);
                }}
              >
                {intl.formatMessage({ id: "DIET.ASSIGN.LIST" })}
              </button>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <div className="row m-2">
          {IsVisible && Data?.data?.planName && Data?.dietsheet.length > 0 ? (
            <DietPlansheet UpdateUser={Data} />
          ) : (
            <div className="d-flex justify-content-center align-items-center fs-4 text-info">
              {intl.formatMessage({ id: "DIET.PLAN.NOTEXIST" })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyDietPlan;
