import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Pagination from "../../../modules/GenricComponant/Pagination";
import permission from "../../../modules/GenricComponant/permissions/permission";
import Dummy from "../../../../loading_assests/dummy.png";
import { useDispatch, useSelector } from "react-redux";
import * as remainingBalances from "../../../modules/finance/modules/remaining_balance/redux/remaingBalanceREDUX";
// import { GetMembers } from "../../../memberModules/finance/remainingbalance/redux/remainingBalanceCRUD";
import { getRemaingBalance, SendNotification } from "../redux/dashboardCRUD";
import swal from "sweetalert";

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const DueModalAmount = (selectedDate: any) => {
  const intl = useIntl();
  const [sortConfig, setSortConfig] = useState<any>({
    key: "modifiedDate", // Default sorting column
    direction: "desc", // Default sorting direction
  });

  const dispatch = useDispatch();
  const invoices: any = useSelector(
    (state: any) => state?.remainingBalances?.remainingBalances?.data
  );

  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;
  const activeBranchID = ActiveBranch.iBranchID;

  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);

  const Access = permission("remaining-balance");
  let add = 0,
    edit = 0,
    del = 0,
    view = 0;
  Access?.map((item: any) => {
    // console.log(item, "itemsss");
    if (item === "edit") {
      edit = 1;
    } else if (item === "delete") {
      del = 1;
    } else if (item === "add") {
      add = 1;
    } else {
      view = 1;
    }
  });

  const [checkedRows, setCheckedRows] = useState<any>([]);

  const [pageLimit, setPageLimit] = useState(pageDefaultValue);
  const [baseURL, setBaseURL] = useState<any>([]);
  const [totalDueAMT, setTotalDueAMT] = useState<any>("");

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [TBLData, setTBLData] = useState<any>([]);

  const [filteredData, setFilteredData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const startDate = selectedDate.selectedDate.startDate;
  const endDate = selectedDate.selectedDate.endDate;

  let initialState = {
    branchID: activeBranchID,
    page_limit: 10,
    page_no: 1,
    spotID: "",
    enrollID: "",
    membershipType: "",
    packageID: "",
    packageGroupID: "",
    search: "",
    start_date: "",
    end_date: "",
    order_key: " iEnrolID",
    order_value: "desc",
  };

  const [filterValues, setFilterValues] = useState(initialState);

  // console.log(filterValues)

  const firstColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.MEMBER",
  })}`;
  const secondColumnTitle = `${intl.formatMessage({
    id: "COMMON.PHOTO",
  })}`;
  const thirdColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBER.MEMBERSHIPTYPE",
  })}`;
  const fourthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.PACKAGE",
  })}`;
  const fifthColumnTitle = `${intl.formatMessage({
    id: "MODULE.EMPLOYEE.joinDate",
  })}`;
  const sixthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.STARTDATE",
  })}`;
  const seventhColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBERS.ENDDATE",
  })}`;
  const eigthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBER.TOTALFEES",
  })}`;
  const ninthColumnTitle = `${intl.formatMessage({
    id: "MODULE.MEMBER.DUEBALANCE",
  })}`;
  const tenthColumnTitle = `${intl.formatMessage({
    id: "COMMON.ACTIONS",
  })}`;

  const handleExports = () => {
    const tableRows = invoices?.data?.map((element: any, index: number) => {
      return {
        "#": index + 1,
        [firstColumnTitle]: ` ${element.memberName} ${element.MemberCountryCode}- ${element.memnerMobileNo}`,
        [thirdColumnTitle]: element.eEnrol_Type,
        [fourthColumnTitle]: element.packageName,
        [fifthColumnTitle]: element.dtJoin,
        [sixthColumnTitle]: element?.dtStartingdate,
        [seventhColumnTitle]: element.dtExpirydate,
        [eigthColumnTitle]: element?.tatalpayableAmt,
        [ninthColumnTitle]: element?.fDue,
      };
    });

    setTBLData(tableRows);
  };

  const getMembers = useCallback(() => {
    if (UserType === "admin" || view !== 0) {
      setIsLoading(true);
      getRemaingBalance({
        ...filterValues,
        page_no: page,
        page_limit: pageLimit,
        order_key: sortConfig.key,
        order_value: sortConfig.direction,
      }).then((data: any) => {
        console.log(data, "data");
        if (data?.status !== 200) {
          setFilteredData([]);
          setIsLoading(false);
        } else {
          dispatch(remainingBalances.actions.GetRemainingBalances(data));
          setTotalPages(Math.ceil(data?.data?.totalRecords / pageLimit));
          setIsLoading(false);
        }
      });
    }
  }, [dispatch, pageLimit, filterValues, page, sortConfig]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    setFilteredData(invoices?.data);
    setBaseURL(invoices?.imageBaseURL);
    setTotalDueAMT(invoices?.totalOutstandingAmt);
    handleExports();
  }, [invoices?.data]);

  const getPageLimit = (values: any) => {
    setPageLimit(Number(values));
    setPage(1);
  };

  const handleSort = (Event: any) => {
    const key = Event.currentTarget.getAttribute("data-key");
    const value =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: key, direction: value });
  };

  // Send Notificaiton

  const handleSendNotifications = async (branchID: any, enrolID: any) => {
    // Show a confirmation dialog
    const willSend = await swal({
      title: intl.formatMessage({ id: "COMMON.AREYOUSURE" }),
      text: intl.formatMessage({ id: "COMMON.DOYOUSEND" }),
      icon: "warning",
      buttons: [
        intl.formatMessage({ id: "COMMON.CANCEL" }),
        intl.formatMessage({ id: "COMMON.SEND" }),
      ], // Cancel and Send buttons
      dangerMode: true, // Optional: Highlights the "Send" button
    });

    // If the user confirms, proceed with the API call
    if (willSend) {
      const obj = { branchID: branchID, enrollmentID: enrolID };
      const response = await SendNotification(obj);
      if (response?.status === 200) {
        swal(response?.data?.replyMsg, { icon: "success" });
      } else {
        swal(response?.data?.replyMsg, { icon: "error" });
      }
    } else {
      // Handle the cancel action (optional)
      swal(
        "Cancelled",
        intl.formatMessage({ id: "COMMON.NOTHINGCHANGE" }),
        "info"
      );
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <span
          className="min-w-40px d-flex  align-items-center"
          style={{ color: "#7e8299", fontSize: "14px" }}
        >
          {intl.formatMessage({ id: "COMMON.SHOW" })}
        </span>
        <div>
          <select
            className="form-control form-select form-select-solid me-3 mb-4"
            value={pageLimit}
            onChange={(e) => getPageLimit(e.target.value)}
          >
            {finalPageLimitArray?.map((item: any, i = 0) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <h1 className="text-dark px-2 d-flex align-items-center">
          {intl.formatMessage({
            id: "MODULE.EXPENSECATEGORY.OUTSTANDING",
          })}
          {totalDueAMT}
        </h1>
      </div>
      <div className="card-body py-3">
        {/* begin::Table container */}
        {/* {isLoading && <Loading />} */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="memberName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {firstColumnTitle}
                    {sortConfig.key !== "memberName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "memberName" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "memberName" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eEnrol_Type"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {thirdColumnTitle}
                    {sortConfig.key !== "eEnrol_Type" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "eEnrol_Type" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "eEnrol_Type" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fourthColumnTitle}
                    {sortConfig.key !== "packageName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "packageName" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "packageName" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtJoin"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fifthColumnTitle}
                    {sortConfig.key !== "dtJoin" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "dtJoin" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "dtJoin" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtStartingdate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {sixthColumnTitle}
                    {sortConfig.key !== "dtStartingdate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtExpirydate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {seventhColumnTitle}
                    {sortConfig.key !== "dtExpirydate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-50px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="tatalpayableAmt"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {eigthColumnTitle}
                    {sortConfig.key !== "tatalpayableAmt" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "tatalpayableAmt" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "tatalpayableAmt" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-50px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="fDue"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {ninthColumnTitle}
                    {sortConfig.key !== "fDue" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "fDue" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "fDue" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  {tenthColumnTitle}
                </th>
                {/* <th className="min-w-100px">{tenthColumnTitle}</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredData && filteredData?.length === 0 ? (
                <tr>
                  <td colSpan={10} className="text-center">
                    {intl.formatMessage({ id: "COMMON.NOTFOUND" })}
                  </td>
                </tr>
              ) : UserType === "employee" && view === 0 ? (
                <tr>
                  <td colSpan={10} className="text-center text-danger fs-4">
                    {intl.formatMessage({ id: "COMMON.NOTAUTHRIZE" })}
                  </td>
                </tr>
              ) : (
                filteredData?.map((element: any, index: number) => {
                  // setUUID(element.iEnrolUUID);
                  const isChecked = checkedRows.includes(element.iEnrolUUID);
                  return (
                    <>
                      <tr key={index} className="fs-5">
                        <td data-label={firstColumnTitle}>
                          <div className="d-flex align-items-center border-0 ">
                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                              {/* <a href="../../demo1/dist/apps/user-management/users/view.html"> */}
                              <div className="symbol-label">
                                <img
                                  src={
                                    element?.vPhoto
                                      ? `${baseURL}${element.vPhoto}`
                                      : Dummy
                                  }
                                  width={50}
                                  height={50}
                                  // className="w-100"
                                />
                              </div>
                              {/* </a> */}
                            </div>
                            <div className="d-flex flex-column ">
                              {`${element.memberName}`}
                              <a
                                href={`tel:${element.MemberCountryCode}-${element.memnerMobileNo}`}
                              >
                                {`(${element.MemberCountryCode}-${element.memnerMobileNo})`}
                              </a>{" "}
                              <div>({element?.eEnrol_Type})</div>
                            </div>
                          </div>
                        </td>

                        {/* <td data-label={thirdColumnTitle}>
                          {element.eEnrol_Type}
                        </td> */}
                        <td data-label={fourthColumnTitle}>
                          {element.packageName}
                        </td>
                        <td data-label={fifthColumnTitle}>
                          {element.dtJoin?.split("T")[0]}
                        </td>
                        <td data-label={sixthColumnTitle}>
                          {element.dtStartingdate?.split("T")[0]}
                        </td>
                        <td data-label={seventhColumnTitle}>
                          {element.dtExpirydate?.split("T")[0]}
                        </td>
                        <td data-label={eigthColumnTitle}>
                          {element.tatalpayableAmt}
                        </td>
                        <td data-label={ninthColumnTitle}>{element.fDue}</td>
                        {/* <td>
                            {new Date(element.dtExpirydate).toLocaleString(
                              "en-US",
                              {
                                dateStyle: "medium",
                              }
                            )}
                          </td> */}
                        {/* <td> {StatusBadge(element.eStatus)}</td> */}
                        <td data-label={tenthColumnTitle}>
                          <div className="d-flex flex-shrink-0 floatRight">
                            <span
                              className="btn btn-primary"
                              onClick={() => {
                                handleSendNotifications(
                                  element?.iBranchID,
                                  element.iEnrolID
                                );
                              }}
                            >
                              {intl.formatMessage({ id: "COMMON.SEND" })}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })
              )}
              <tr className="fw-bolder text-muted text-capitalize fs-4">
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="memberName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {firstColumnTitle}
                    {sortConfig.key !== "memberName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "memberName" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "memberName" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                {/* <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="eEnrol_Type"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {thirdColumnTitle}
                    {sortConfig.key !== "eEnrol_Type" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "eEnrol_Type" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "eEnrol_Type" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th> */}
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="packageName"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fourthColumnTitle}
                    {sortConfig.key !== "packageName" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "packageName" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "packageName" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtJoin"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {fifthColumnTitle}
                    {sortConfig.key !== "dtJoin" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "dtJoin" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "dtJoin" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtStartingdate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {sixthColumnTitle}
                    {sortConfig.key !== "dtStartingdate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "dtStartingdate" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="dtExpirydate"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {seventhColumnTitle}
                    {sortConfig.key !== "dtExpirydate" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "dtExpirydate" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-50px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="tatalpayableAmt"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {eigthColumnTitle}
                    {sortConfig.key !== "tatalpayableAmt" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "tatalpayableAmt" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "tatalpayableAmt" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-50px notVisibleInMobile">
                  <div
                    className="d-flex justify-content-between cursor-pointer"
                    data-key="fDue"
                    onClick={(e) => {
                      handleSort(e);
                    }}
                  >
                    {ninthColumnTitle}
                    {sortConfig.key !== "fDue" ? (
                      <div>
                        <i className="bi bi-arrow-down-up"></i>
                      </div>
                    ) : (
                      <div>
                        <i
                          className={`bi bi-arrow-up  ${
                            sortConfig.key == "fDue" &&
                            sortConfig.direction == "asc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                        <i
                          className={`bi bi-arrow-down  ${
                            sortConfig.key == "fDue" &&
                            sortConfig.direction == "desc"
                              ? "d-block text-primary fw-bold fs-5"
                              : "d-none"
                          }`}
                        ></i>
                      </div>
                    )}
                  </div>
                </th>
                <th className="min-w-100px notVisibleInMobile">
                  {tenthColumnTitle}
                </th>

                {/* <th className="min-w-100px">{tenthColumnTitle}</th> */}
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {/* begin:: pagination  */}

        {filteredData && filteredData.length > 0 && (
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        )}
        {/* end:: pagination */}
      </div>
    </>
  );
};

export default DueModalAmount;
