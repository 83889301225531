import { useFormik } from "formik";
// import "./setting.css";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import data from "../../../../config.json";
import { UpdateCompany } from "../redux/settingCRUD";
import Select from "react-select";
const Whatsapp = ({
  // whatsappData,
  // setWhatsappData,
  UpdateUser,
  ActiveBranchID,
  ActiveBranchUUID,
  detailsCompany,
  setSuccessResponse,
  setErrorResponse,
  ActiveBranchSettingUUID,
  setErr,
  Settingedit,
}: any) => {
  let data1: any = localStorage?.getItem("activeUserType");
  const userType = JSON.parse(data1);
  const intl = useIntl();
  const Schemas = Yup.object().shape({
    whatsAppCompany: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.COMPANY" })),
    whatsUsername: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.USERNAME" })),
    whatsaAppPassword: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.PASSWORD" })),
    whatsAppBalToken: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.PASSWORD1" })),
    whatsAppStatus: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.Status" })),
  });
  const initialState = {
    whatsAppCompany: UpdateUser?.whatsAppCompany,
    whatsUsername: UpdateUser?.whatsUsername,
    whatsaAppPassword: UpdateUser?.whatsaAppPassword,
    whatsAppBalToken: UpdateUser?.whatsAppBalToken,
    whatsAppStatus: UpdateUser?.whatsAppStatus || "Active",
    whatsAppSupplementStatus: UpdateUser?.whatsAppSupplementStatus,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,

    validationSchema: Schemas,

    onSubmit: async (values) => {
      // console.log(values, "valee");
      // setWhatsappData(values);
      const formData = {
        smsCompany: UpdateUser?.smsCompany || "",
        smsUsername: UpdateUser?.smsUsername || "",
        smsPassword: UpdateUser?.smsPassword || "",
        smsSenderID: UpdateUser?.smsSenderID || "",
        smsStatus: UpdateUser?.smsStatus || "",
        smsSupplementStatus: UpdateUser?.smsSupplementStatus || "",
        iBranchSettingUUID:
          UpdateUser?.iBranchSettingUUID || ActiveBranchSettingUUID,
        branchID: ActiveBranchID || "",
        branchUUID: ActiveBranchUUID || "",
        courrencyID: UpdateUser?.iCountryCurrencyID || "",
        dataFormate: UpdateUser?.idateFormate || "",
        timeFormate: UpdateUser?.itimeFormate || "",
        qrAttendanceLogType: UpdateUser?.displayQRAttendanceLogType || "",
        minSuppProductNotify: UpdateUser?.minSuppProductNotify || "",
        membershipFreezeShargeable:
          UpdateUser?.membershipFreezeChargeable || "",
        invoiceNotes: UpdateUser?.InvoiceNotes || "",
        invoiceSupplementNotes: UpdateUser?.suppInvocieNotes || "",
        invoiceprefx: UpdateUser?.inVoicePrefix || "",
        expiredPackageDays: UpdateUser?.expiredListInDays || "",
        whatsAppCompany: values?.whatsAppCompany,
        whatsAppUsername: values?.whatsUsername,
        whatsaAppPassword: values?.whatsaAppPassword,
        whatsAppBalToken: values?.whatsAppBalToken,
        whatsAppStatus: values?.whatsAppStatus,
        whatsAppSupplementStatus: values?.whatsAppSupplementStatus || "",
        SMTPUserName: UpdateUser?.SMTPUserName || "",
        SMTPPassword: UpdateUser?.SMTPPassword || "",
        SMTPHost: UpdateUser?.SMTPHost || "",
        SMTPFromEmail: UpdateUser?.SMTPFromEmail || "",
        SMTPPort: UpdateUser?.SMTPPort || "",
        SMTPSecure: UpdateUser?.SMTPSecure || "",
        SMTPrequireTLS: UpdateUser?.SMTPrequireTLS || "",
        SMTPStatus: UpdateUser?.SMTPStatus || "",
        SMTPSupplementStatus: UpdateUser?.SMTPSupplementStatus || "",
        notifyreceiverMobilenoCountryCode:
          UpdateUser?.notifyreceiverMobilenoCountryCode || "",
        notifyreceiverMobileNos: UpdateUser?.notifyreceiverMobileNos || "",
        notifyreceiverWhatsAPPCountryCode:
          UpdateUser?.notifyreceiverWhatsAPPCountryCode || "",
        notifyreceiverWhatsAPPMobileNos:
          UpdateUser?.notifyreceiverWhatsAPPMobileNos || "",
        notifyreceiverEmailIDs: UpdateUser?.notifyreceiverEmailIDs || "",
        notifytodayExpire: UpdateUser?.notifytodayExpire || 0,
        notifytodayBirthday: UpdateUser?.notifytodayBirthday || 0,
        notifytodayAnniversary: UpdateUser?.notifytodayAnniversary || 0,
        notifytodayFollowup: UpdateUser?.notifytodayFollowup || 0,
        notifytodayPresent: UpdateUser?.notifytodayPresent || 0,
        notifytodayInquiry: UpdateUser?.notifytodayInquiry || 0,
        notifytodayEnrollment: UpdateUser?.notifytodayEnrollment || 0,
        notifytodayEmployee: UpdateUser?.notifytodayEmployee || 0,
        notifytodayCollection: UpdateUser?.notifytodayCollection || 0,
        notifytodayExpense: UpdateUser?.notifytodayExpense || 0,
        notifytodayIncome: UpdateUser?.notifytodayIncome || 0,
        notifytodayPurchase: UpdateUser?.notifytodayPurchase || 0,
        notifytodaySell: UpdateUser?.notifytodaySell || 0,
        notifytodayStock: UpdateUser?.notifytodayStock || 0,
        notifytodayDue: UpdateUser?.notifytodayDue || 0,
        notifytotalmember: UpdateUser?.notifytotalmember || 0,
        notifytotalActivemember: UpdateUser?.notifytotalActivemember || 0,
        notifytotalInActivemember: UpdateUser?.notifytotalInActivemember || 0,
        notifyexpiringMemberBeforeDays:
          UpdateUser?.notifyexpiringMemberBeforeDays || 0,
        notifyexpiringMemberBeforeDayInterval:
          UpdateUser?.notifyexpiringMemberBeforeDayInterval || 0,
        notifyexpiredMemberAfterDays:
          UpdateUser?.notifyexpiredMemberAfterDays || 0,
        notifyexpiredMemberAfterDayInterval:
          UpdateUser?.notifyexpiredMemberAfterDayInterval || 0,
        notifyisBranchReceiveNotification:
          UpdateUser?.notifyisBranchReceiveNotification || 0,
        notifyisCompanyReceiveNotification:
          UpdateUser?.notifyisCompanyReceiveNotification || 0,
        notifyisSMSNotification: UpdateUser?.notifyisSMSNotification || 0,
        notifyisEmailNotification: UpdateUser?.notifyisEmailNotification || 0,
        notifyExpiredPackage: UpdateUser?.notifyExpiredPackage || 0,
        notifyExpiringPackage: UpdateUser?.notifyExpiringPackage || 0,
        notifyisWhatsAPPNotification:
          UpdateUser?.notifyisWhatsAPPNotification || 0,
        dashTotalMember: UpdateUser?.dashTotalMember || 0,
        dashTotalActiveMember: UpdateUser?.dashTotalActiveMember || 0,
        dashTotalInactiveMember: UpdateUser?.dashTotalInactiveMember || 0,
        dashTodayExpiry: UpdateUser?.dashTodayExpiry || 0,
        dashTotalInvoice: UpdateUser?.dashTotalInvoice || 0,
        dashTodayEnrolled: UpdateUser?.dashTodayEnrolled || 0,
        dashTodayEMPBirthday: UpdateUser?.dashTodayEMPBirthday || 0,
        dashTodayInvocie: UpdateUser?.dashTodayInvocie || 0,
        dashTotalDueAmt: UpdateUser?.dashTotalDueAmt || 0,
        dashTodayMemBirtdhay: UpdateUser?.dashTodayMemBirtdhay || 0,
        dashTodayExpense: UpdateUser?.dashTodayExpense || 0,
        dashTodayEmployee: UpdateUser?.dashTodayEmployee || 0,
        dashTotalExpense: UpdateUser?.dashTotalExpense || 0,
        dashAllExpiredPackage: UpdateUser?.dashAllExpiredPackage || 0,
        dashTotalProfit: UpdateUser?.dashTotalProfit || 0,
        dashTodayEnquiry: UpdateUser?.dashTodayEnquiry || 0,
        dashTodayFollowup: UpdateUser?.dashTodayFollowup || 0,
        dashTodayPurchase: UpdateUser?.dashTodayPurchase || 0,
        dashTotalPurchase: UpdateUser?.dashTotalPurchase || 0,
        dashTotalClearingAmount: UpdateUser?.dashTotalClearingAmount || 0,
        dashTodaySell: UpdateUser?.dashTodaySell || 0,
        dashTotalSell: UpdateUser?.dashTotalSell || 0,
        dashtotalsellDueAmt: UpdateUser?.dashtotalsellDueAmt || 0,
        dashlowstock: UpdateUser?.dashlowstock || 0,
        dashEnrolledAmount: UpdateUser?.dashEnrolledAmount || 0,
        dashStockValue: UpdateUser?.dashStockValue || 0,
        dashtSellPurchaseValue: UpdateUser?.dashtSellPurchaseValue || 0,
        dashtodayPOSAmtInvoice: UpdateUser?.dashtodayPOSAmtInvoice || 0,
        dashtotalPOSAmtInvoice: UpdateUser?.dashtotalPOSAmtInvoice || 0,
        dashTotalMemberProfit: UpdateUser?.dashTotalMemberProfit || 0,
        dashTotalPOSProfit: UpdateUser?.dashTotalPOSProfit || 0,
        dashPOSProfitFromBill: UpdateUser?.dashPOSProfitFromBill || 0,
        dashTodayPosExpense: UpdateUser?.dashTodayPosExpense || 0,
        dashTotalPOSExpense: UpdateUser?.dashTotalPOSExpense || 0,
      };
      const response = await UpdateCompany(formData);
      if (response.status === 200) {
        detailsCompany();
        // setWhatsappData(response);
        setSuccessResponse(true);

        setErrorResponse(false);
        setErr("");
      } else {
        setSuccessResponse(false);
        setErr(response?.data?.replyMsg);
        setErrorResponse(true);
        if (Array.isArray(response?.data?.replyMsg)) {
          let er = response?.data?.replyMsg;
          setErr(er);
        }
      }
    },
  });
  return (
    <div className="container form">
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-10">
              <label htmlFor="whatsAppCompany" className="required form-label">
                {intl.formatMessage({ id: "MODULE.SETTING.WHATSAPPCOMPANY" })}
              </label>
              <Select
                className="basic-single"
                name="whatsAppCompany"
                id="whatsAppCompany"
                value={
                  formik.values.whatsAppCompany
                    ? {
                        value: formik.values.whatsAppCompany,
                        label: formik.values.whatsAppCompany,
                      }
                    : null
                }
                placeholder={intl.formatMessage({
                  id: "PLACEHOLDER.COMPANY",
                })}
                onChange={(options) =>
                  formik.setFieldValue("whatsAppCompany", options?.value)
                }
                options={data?.whatsapp?.map((item: any) => ({
                  value: item,
                  label: item,
                }))}
              />
              {formik.errors.whatsAppCompany && (
                <div className="error mt-2">
                  {formik.touched.whatsAppCompany &&
                    formik.errors.whatsAppCompany}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.WHATSAPPUSERNAME",
                })}
              </label>
              <input
                type="text"
                name="whatsUsername"
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "PLACEHOLDER.USERNAME",
                })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.whatsUsername}
              />
              {formik.errors.whatsUsername && (
                <div className="error mt-2">
                  {formik.touched.whatsUsername && formik.errors.whatsUsername}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.WHATSAPPPASSWORD",
                })}
              </label>
              <input
                type="text"
                name="whatsaAppPassword"
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "PLACEHOLDER.PASSWORD",
                })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.whatsaAppPassword}
              />
              {formik.errors.whatsaAppPassword && (
                <div className="error mt-2">
                  {formik.touched.whatsaAppPassword &&
                    formik.errors.whatsaAppPassword}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.WHATSAPPBALANCETOKEN",
                })}
              </label>
              <input
                type="text"
                name="whatsAppBalToken"
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "PLACEHOLDER.PASSWORD",
                })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.whatsAppBalToken}
              />
              {formik.errors.whatsAppBalToken && (
                <div className="error mt-2">
                  {formik.touched.whatsAppBalToken &&
                    formik.errors.whatsAppBalToken}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="whatsAppStatus" className="required form-label">
                {intl.formatMessage({ id: "MODULE.SETTING.WHATSAPPSTATUS" })}
              </label>
              <select
                id="whatsAppStatus"
                name="whatsAppStatus"
                className="form-select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.whatsAppStatus}
              >
                <option value="Active">
                  {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                </option>
                <option value="Inactive">
                  {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                </option>
              </select>
              {formik.errors.whatsAppStatus && (
                <div className="error mt-2">
                  {formik.touched.whatsAppStatus &&
                    formik.errors.whatsAppStatus}
                </div>
              )}
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="smsSupplementStatus" className="form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.WHATSAPPSUPPLYMENTSTATUS",
                })}
              </label>
              <select
                id="smsSupplementStatus"
                name="whatsAppSupplementStatus"
                className="form-select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.whatsAppSupplementStatus}
              >
                <option value="Active">
                  {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                </option>
                <option value="Inactive">
                  {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                </option>
              </select>
            </div>
          </div> */}
        </div>
        {(Settingedit === 1 && userType === "employee") ||
        userType === "admin" ||
        userType === "channelPartner" ? (
          <button type="submit" className="btn btn-primary btn-block m-4">
            {intl.formatMessage({
              id: "MODULE.PACKAGEPERIOD.SAVEBUTTON",
            })}
          </button>
        ) : (
          ""
        )}
      </Form>
    </div>
  );
};

export default Whatsapp;
